import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box, Grid, TextField, MenuItem, Typography } from '@mui/material';
import * as Yup from 'yup';

import { Formik, Form } from 'formik';
import { ButtonSubmit, ComponentLoading, Checkbox, TicketTextareaWithFile, MuiFormikInputWithWarning } from 'components';
import { useCheckbox } from 'hooks';

import { useMutation, useLazyQuery } from '@apollo/client';
import { MUTATION_CREATE_ACCOUNT_SUPPORT_TICKET, QUERY_USER_TICKET_ELIGIBILITY } from 'gql';
import { setAlert } from 'state';
import { IAircraftEligibility, IUploadedFile } from 'types';
import { aircraftRegistrationMakeModel } from 'helpers';
import { PATH_TICKETS_VIEW, MAX_SUBJECT_LENGTH, SHOW_SUBJECT_WARNING } from 'const';

const AccountSupportFormSchema = Yup.object().shape({
  subject: Yup.string().max(MAX_SUBJECT_LENGTH, `Too long: 1-${MAX_SUBJECT_LENGTH} characters required`).required('Required'),
  body: Yup.string().max(10000, 'Too long: 1-10000 characters required').required('Required'),
});

interface submitValueProps {
  subject: string;
  body: string;
}

interface AccountSupportFormProps {
  ticketAircraft: IAircraftEligibility | undefined;
}

const AccountSupportForm: React.FC<AccountSupportFormProps> = (props) => {
  const { ticketAircraft } = props;

  const dispatch = useDispatch();
  const [fetchAircrafts, { data, loading }] = useLazyQuery(QUERY_USER_TICKET_ELIGIBILITY);

  const navigate = useNavigate();

  const [selectedAircraft, setSelectedAircraft] = useState<IAircraftEligibility | undefined>(ticketAircraft);

  const [createTicket, { data: dataCreateTicket, error: errorCreateTicket, loading: loadingCreateTicket }] = useMutation(
    MUTATION_CREATE_ACCOUNT_SUPPORT_TICKET,
    {
      errorPolicy: 'all',
    },
  );

  const [isUploading, setIsUploading] = useState(false);
  const [uploadedFileList, setUploadedFileList] = useState<IUploadedFile[]>([]);

  const [errorAircraft, setErrorAircraft] = useState('');

  const { value: valueAgreeEdas, onChange: onChangeAgreeEdas } = useCheckbox();

  const params = useParams();

  useEffect(() => {
    if (params && !params?.id) {
      fetchAircrafts();
    }
  }, [params, fetchAircrafts]);

  useEffect(() => {
    if (errorCreateTicket) {
      dispatch(setAlert('error', 'Unable to create ticket'));
    } else if (dataCreateTicket) {
      if (dataCreateTicket.createAccountSupportTicket?.ok) {
        dispatch(setAlert('success', 'Created new ticket'));
        navigate(`${PATH_TICKETS_VIEW}/${dataCreateTicket.createAccountSupportTicket.ticket.id}`);
      } else {
        dispatch(setAlert('error', dataCreateTicket.createAccountSupportTicket?.error || 'Unable to create ticket'));
      }
    }
  }, [errorCreateTicket, dispatch, dataCreateTicket, navigate]);

  const onSubmit = async (value: submitValueProps) => {
    if (!params?.id && !valueAgreeEdas && !selectedAircraft?.id) {
      setErrorAircraft('If your ticket is related to a specific aircraft, please select aircraft.');
      return;
    }

    const documentIds = uploadedFileList.map((file: IUploadedFile) => {
      return file.id;
    });

    if (valueAgreeEdas) {
      await createTicket({
        variables: {
          body: value.body,
          subject: value.subject,
          documentIds: documentIds ? documentIds : [],
        },
      });
    } else {
      await createTicket({
        variables: {
          body: value.body,
          subject: value.subject,
          aircraftId: selectedAircraft?.id,
          documentIds: documentIds ? documentIds : [],
        },
      });
    }
  };

  return (
    <ComponentLoading loading={loading}>
      <Box py={3}>
        {data?.me?.aircraft && data?.me?.aircraft?.length !== 0 && (
          <Box py={1}>
            {!valueAgreeEdas && (
              <TextField
                select
                value={selectedAircraft ? selectedAircraft.id : ''}
                label="Choose your aircraft"
                variant="outlined"
                style={{
                  marginBottom: 0,
                  minWidth: '300px',
                }}
                fullWidth
                onChange={(e: any) => {
                  const tmpAircraft = data?.me?.aircraft.filter((aircraft: IAircraftEligibility) => aircraft.id === e.target.value);

                  setSelectedAircraft(tmpAircraft[0]);
                  setErrorAircraft('');
                }}
                disabled={valueAgreeEdas}
                error={Boolean(errorAircraft)}
                helperText={errorAircraft ? errorAircraft : ''}
                FormHelperTextProps={{
                  sx: {
                    marginLeft: 0,
                  },
                }}
              >
                {data?.me?.aircraft?.map((item: IAircraftEligibility, key: number) => {
                  return (
                    <MenuItem key={key} value={item.id} title={aircraftRegistrationMakeModel(item)}>
                      <Typography
                        sx={{
                          fontSize: '18px',
                          fontWeight: 500,
                          color: 'text.primary',
                          lineHeight: '25px',
                        }}
                      >
                        {aircraftRegistrationMakeModel(item)}
                      </Typography>
                    </MenuItem>
                  );
                })}
              </TextField>
            )}
            <Checkbox
              value={valueAgreeEdas}
              label={<Typography>Request not related to a specific aircraft</Typography>}
              onChange={(e) => onChangeAgreeEdas(e)}
            />
          </Box>
        )}
        <Formik
          initialValues={{
            subject: '',
            body: '',
          }}
          validationSchema={AccountSupportFormSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, handleChange, handleBlur, touched, errors }) => {
            return (
              <Form>
                <Box py={1}>
                  <MuiFormikInputWithWarning
                    name="subject"
                    label="Subject"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    maxLength={MAX_SUBJECT_LENGTH}
                    closeLength={SHOW_SUBJECT_WARNING}
                  />
                </Box>
                <Box pt={1} pb={3}>
                  <TicketTextareaWithFile
                    name="body"
                    label="Message"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    rows={5}
                    maxLength={10000}
                    maxLine={10}
                    isUploading={isUploading}
                    setIsUploading={setIsUploading}
                    uploadedFileList={uploadedFileList}
                    setUploadedFileList={setUploadedFileList}
                  />
                </Box>
                <Grid container spacing={2}>
                  <Grid item md={3} xs={false}></Grid>
                  <Grid item md={6} xs={12}>
                    <ButtonSubmit
                      text="Create Ticket"
                      loading={isSubmitting || loadingCreateTicket}
                      disabled={isSubmitting || loadingCreateTicket || isUploading}
                    />
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </ComponentLoading>
  );
};

export default AccountSupportForm;
