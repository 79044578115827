import {
  ITicketEligibility,
  ISerieSet,
  IAircraft,
  IServiceCenter,
  ITechnicianTableItem,
  ITechnician,
  IAircraftEligibility,
  IContactType,
  IFlight,
  IDropDownOption,
  ITabLink,
  ISavvyTicket,
  IAnalysisPack,
  ICohort,
  IKmlPoint,
  IFlightData,
} from 'types';
import { services, seriesOrder, enginesForBorescope } from 'api';
import {
  MUTATION_GET_UPLOAD_ENGINE_DATA_FILE_URL,
  MUTATION_GET_UPLOAD_DOCUMENT_URL,
  MUTATION_GET_QIF_UPLOAD_URL,
  QUERY_USER_FLIGHTS_PER_AIRCRAFT,
  QUERY_AIRCRAFT_FLIGHT_LIST,
  MUTATION_CREATE_DOCUMENT_FROM_S3_PATH,
  MUTATION_CREATE_BORESCOPE_IMAGE,
} from 'gql';
import { apolloClient } from 'services';
import {
  SAVVY_MX,
  SAVVY_QA,
  SAVVY_BREAKDOWN,
  SAVVY_ANALYSIS,
  SAVVY_ANALYSIS_PRO,
  SAVVY_BASIC,
  TO_MODE,
  TO_MODE_LEFT,
  TO_MODE_RIGHT,
  SAVVY_PREBUY,
  UPLOAD_BORESCOPE_PERMISSION,
  CREATE_BORESCOPE_REPORT_PERMISSION,
} from 'const';

import { NavigateFunction } from 'react-router-dom';

import dayjs from 'dayjs';

import * as Sentry from '@sentry/browser';

const readableMonth = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const PURCHASE_LINK = '/aircraft/:id/upgrade-from-free?services=';

export const humanDate = (value: Date | undefined) => {
  if (!value) {
    return 'Not Determined';
  }
  const date = new Date(value);
  return `${readableMonth[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
};

export const humanDateTime = (value: Date) => {
  const date = new Date(value);

  let hour = '';
  const minute = ('0' + date.getMinutes()).slice(-2);
  let postfix = 'AM';

  const currentHour = date.getHours();
  if (currentHour >= 12) {
    postfix = 'PM';
  }

  if (date.getHours() > 12) {
    hour = String(currentHour - 12);
  } else {
    hour = String(currentHour);
  }

  hour = ('0' + hour).slice(-2);

  const year = date.getFullYear() === new Date().getFullYear() ? '' : ` ${date.getFullYear()}`;

  return `${readableMonth[date.getMonth()]} ${date.getDate()},${year} ${hour}:${minute}${postfix}`;
};

export const urlify = (text: string) => {
  const urlRegex = /(http(s?):\/\/[^\s]+)/g;
  return text.replace(urlRegex, function (url) {
    return '<a href="' + url + '" target="_blank">' + url + '</a>';
  });
};

export const post_html_processing = (value: string) => {
  let replaceResult = value;
  const urlRegex = /[\s\n](https?:\/\/[^\s]+)/g;
  replaceResult = replaceResult.replace(urlRegex, function (url) {
    return '<br/><a href="' + url.substring(1, url.length) + '" target="_blank">' + url.substring(1, url.length) + '</a>';
  });

  return replaceResult.replace(/\n/g, ' <br/>').replace(/<[/]{0,1}(blockquote)[^><]*>/g, ''); //remove blockquote tag
};

export const post_html_processing_remove_linebreak = (value: string) => {
  if (value.indexOf('\n') === 0) {
    value.replace('\n', '');
  }

  return value;
};

export const aircraftRegistrationMakeModel = (aircraft: any, ellipsis = false, length = 20) => {
  if (aircraft === undefined) return '';
  let result = `${aircraft?.registration}${aircraft?.aircraftManufacturer?.name ? ' ' + aircraft.aircraftManufacturer.name : ''}${
    aircraft?.aircraftModel?.name ? ' ' + aircraft.aircraftModel.name : ''
  }`;
  if (result.length > length && ellipsis) {
    result = result.substring(0, length) + '...';
  }
  return result;
};

export const getBodyHeight = () => {
  const windowHeight = window.innerHeight - 71; // Minus header
  return windowHeight;
};

export const getCookie = (cookieName: string) => {
  if (!document.cookie) {
    return '';
  }
  const token = document.cookie
    .split(';')
    .map((c) => c.trim())
    .filter((c) => c.startsWith(cookieName + '='));

  if (token.length === 0) {
    return '';
  }
  return decodeURIComponent(token[0].split('=')[1]);
};

export const secondsToHms = (value: number) => {
  const hour = Math.floor(value / 3600);
  const min = Math.floor((value % 3600) / 60);
  const sec = value % 60;

  return `${hour}h ${min}m ${sec}s`;
};

export const makeEligibilityLabel = (type: string, eligibility: ITicketEligibility | undefined) => {
  if (type === 'preliminaryLogbookReview') {
    return 'free';
  }

  if (type === 'prebuy' && !eligibility) {
    return 'with SavvyPrebuy';
  }

  if (!eligibility) return '';

  let result = '';

  if (eligibility.free) {
    return 'free';
  }

  if (eligibility.currentlyEligibleVia.length) {
    result = eligibility.currentlyEligibleVia.join(', ');
    if (result) {
      return 'with ' + result;
    }
  } else {
    result = 'with';

    for (let i = 0; i < eligibility.eligibleViaPurchase.length; i++) {
      const matchedService = services.filter((service: any) => service.id === eligibility.eligibleViaPurchase[i]);
      if (matchedService) {
        result += ' ' + matchedService[0].name;
      }
    }

    if (result !== 'with') {
      return result;
    } else {
      return '';
    }
  }
  return '';
};

export const changeLabelToTicketType = (type: string) => {
  return type as
    | 'analysis'
    | 'service'
    | 'serviceAnnual'
    | 'breakdown'
    | 'techSupport'
    | 'accountSupport'
    | 'prebuy'
    | 'preliminaryLogbookReview';
};

export const isFreeEligibility = (type: string, eligibility: ITicketEligibility | undefined) => {
  if (type === 'preliminaryLogbookReview') {
    return true;
  }

  if (type === 'prebuy' && !eligibility) {
    return false;
  }

  if (!eligibility) {
    return false;
  }

  if (eligibility.currentlyEligibleVia.length) {
    return true;
  } else {
    return false;
  }
};

export const sizeWithUnit = (value: number) => {
  const kb = Math.round(value / 1024);

  if (kb > 1024) return (kb / 1024).toFixed(2) + ' MB';
  return kb + 'KB';
};

export const getGapTime = (value: Date) => {
  const now = new Date().getTime();
  const gap = now - new Date(value).getTime();

  const hour = 1000 * 3600;
  const day = 1000 * 3600 * 24; // day in milliseconds
  const year = day * 365;

  if (gap >= year) {
    return `${Math.floor(gap / year)} year(s) ago`;
  } else if (gap >= 30 * day) {
    return `${Math.floor(gap / day / 30)} month(s) ago`;
  } else if (gap >= 24 * hour) {
    return `${Math.floor(gap / day)} day(s) ago`;
  } else if (gap >= hour) {
    return `${Math.floor(gap / hour)} hr(s) ago`;
  } else {
    return `${Math.floor(gap / (1000 * 60))} min(s) ago`;
  }
};

export const getNextSnoozeGapTime = (value: Date) => {
  const now = new Date().getTime();
  const gap = new Date(value).getTime() - now;

  const hour = 1000 * 3600;
  const day = 1000 * 3600 * 24; // day in milliseconds
  const year = day * 365;

  if (gap >= year) {
    return `${Math.floor(gap / year)} year(s)`;
  } else if (gap >= 30 * day) {
    return `${Math.floor(gap / day / 30)} month(s)`;
  } else if (gap >= 24 * hour) {
    return `${Math.floor(gap / day)} day(s)`;
  } else if (gap >= hour) {
    return `${Math.floor(gap / hour)} hr(s)`;
  } else {
    return `${Math.floor(gap / (1000 * 60))} min(s)`;
  }
};

export const getGapInHourMin = (value: Date) => {
  const now = new Date().getTime();
  const gap = now - new Date(value).getTime();

  const day = Math.floor(gap / (1000 * 3600 * 24));
  const hour = Math.floor((gap - day * (1000 * 3600 * 24)) / (1000 * 3600));
  const min = Math.floor((gap - day * (1000 * 3600 * 24) - hour * (1000 * 3600)) / (1000 * 60));

  let hourStr = '';
  let minStr = '';
  let dayStr = '';
  if (day > 0) {
    dayStr = day.toString() + 'd ';
  }
  if (hour < 10) {
    hourStr = ('0' + hour).slice(-2);
  } else {
    hourStr = hour.toString();
  }

  if (min < 10) {
    minStr = ('0' + min).slice(-2);
  } else {
    minStr = min.toString();
  }

  return `${dayStr}${hourStr}h ${minStr}m ago`;
};

export const ellipsisString = (value: string, len: number) => {
  if (value.length > len) {
    return value.substring(0, len) + '...';
  }
  return value;
};

export const limitSerieSet = (serieSet: ISerieSet, cylinderValue: number | undefined, isGami = false) => {
  if (!cylinderValue) return serieSet;

  const cylinderCount = cylinderValue;

  const newResult: string[] = [];

  const tit_match = new RegExp(/(?:[LR]-)?(TIT)(\d)/);

  for (let i = 0; i < serieSet.series.length; i++) {
    const cylinder = serieSet.series[i].match(/(?:[LR]-)?(?:EGT|CHT)\s*(\d+)/);

    if (isGami && tit_match.exec(serieSet.series[i])) continue;

    if (!cylinder) newResult.push(serieSet.series[i]);
    else {
      if (parseInt(cylinder[1]) && parseInt(cylinder[1]) <= cylinderCount) {
        newResult.push(serieSet.series[i]);
      }
    }
  }

  return {
    name: serieSet.name,
    series: [...newResult].sort((item1, item2) => {
      return item1.replaceAll(' ', '') > item2.replaceAll(' ', '') ? 1 : -1;
    }),
  };
};

export const isGami = (mode: string) => {
  if (mode === 'gami' || mode === 'gami-left' || mode === 'gami-right') {
    return true;
  }

  return false;
};

export const isMag = (str: string) => {
  if (str === 'mag' || str === 'mag-left' || str === 'mag-right') return true;

  return false;
};

export const getCylinderNumber = (cylinder: number) => {
  return cylinder;
};

export const getSerieColor = (name: string) => {
  const serieName = name.replace(' ', '');
  switch (serieName) {
    case 'EGT1':
    case 'L-EGT1':
    case 'R-EGT1':
    case 'CHT1':
    case 'L-CHT1':
    case 'R-CHT1':
      return '#F44336'; // Red
    case 'EGT2':
    case 'L-EGT2':
    case 'R-EGT2':
    case 'CHT2':
    case 'L-CHT2':
    case 'R-CHT2':
      return '#FFA726'; // Orange
    case 'EGT3':
    case 'L-EGT3':
    case 'R-EGT3':
    case 'CHT3':
    case 'L-CHT3':
    case 'R-CHT3':
      return '#7B3F00'; // Brown
    case 'EGT4':
    case 'L-EGT4':
    case 'R-EGT4':
    case 'CHT4':
    case 'L-CHT4':
    case 'R-CHT4':
      return '#9C27B0';
    case 'EGT5':
    case 'L-EGT5':
    case 'R-EGT5':
    case 'CHT5':
    case 'L-CHT5':
    case 'R-CHT5':
      return '#4CAF50'; // LawnGreen
    case 'EGT6':
    case 'L-EGT6':
    case 'R-EGT6':
    case 'CHT6':
    case 'L-CHT6':
    case 'R-CHT6':
      return '#42a5f5'; // Blueish
    case 'EGT7':
    case 'L-EGT7':
    case 'R-EGT7':
    case 'CHT7':
    case 'L-CHT7':
    case 'R-CHT7':
      return '#8b0000';
    case 'EGT8':
    case 'L-EGT8':
    case 'R-EGT8':
    case 'CHT8':
    case 'L-CHT8':
    case 'R-CHT8':
      return '#00008B';
    case 'TIT1':
    case 'L-TIT1':
    case 'R-TIT1':
      return '#333333';
    case 'TIT2':
    case 'L-TIT2':
    case 'R-TIT2':
      return '#666666';
    case 'FF':
    case 'L-FF':
    case 'R-FF':
      return '#70A0FF'; // Blue-ish
    case 'RPM':
    case 'L-RPM':
    case 'R-RPM':
      return '#009900'; // Blue-ish
    case 'MAP':
    case 'L-MAP':
    case 'R-MAP':
      return '#CC0000'; // Blue-ish
    case 'OIL_PRESS':
    case 'L-OIL_PRESS':
    case 'R-OIL_PRESS':
      return '#006699'; // Blue-ish
    case 'OIL_TEMP':
    case 'L-OIL_TEMP':
    case 'R-OIL_TEMP':
      return '#333399'; // Blue-ish
    case 'OAT':
    case 'L-OAT':
    case 'R-OAT':
      return '#CC6600'; // Blue-ish
    case 'VOLTS':
    case 'L-VOLTS':
    case 'R-VOLTS':
    case 'VOLT1':
    case 'L-VOLT1':
    case 'R-VOLT1':
    case 'VOLT2':
    case 'L-VOLT2':
    case 'R-VOLT2':
      return '#333333'; // Blue-ish
  }
};

export const copyClipboard = (value: string) => {
  navigator.clipboard.writeText(value);
};

export const copyClipboardTextArea = (value: string) => {
  const inputElem = document.body.appendChild(document.createElement('textarea'));
  inputElem.value = value;
  inputElem.select();
  document.execCommand('copy');
  inputElem.parentNode?.removeChild(inputElem);
};

export const isTwinEngine = (seriesSet: ISerieSet[]) => {
  for (const serieSet of seriesSet) {
    if (!serieSet) continue;

    if (serieSet.name === 'R-N1' || serieSet.name === 'R-N2') continue;

    if (serieSet.name.match(/^[LR]-/)) return true;
  }

  return false;
};

export const getAngleLineOnMap = (lon1: number, lat1: number, lon2: number, lat2: number) => {
  if (lat1 === lat2) {
    return 90 * (lon1 > lon2 ? -1 : 1);
  } else {
    const theta = (Math.atan((lon2 - lon1) / (lat2 - lat1)) / Math.PI) * 180;
    if (lat1 < lat2) return theta;
    return theta + 180;
  }
};

export const snoozeDuration = (value: string) => {
  const date = new Date();

  if (value === 'tm') {
    return 32 - date.getHours() + 'h'; // Consider next morning as 8 AM
  }

  const weekday = date.getDay();
  if (value === 'nm') {
    if (weekday === 0) {
      return '1d';
    } else {
      return 8 - weekday + 'd';
    }
  }

  return value;
};

export const getTagName = (tag: any) => {
  if (tag.__typename === 'UserTagType') {
    return tag.userTag?.name ? tag.userTag.name : '';
  }

  if (tag.__typename === 'TicketTagType') {
    return tag.reportingTag?.name ? tag.reportingTag.name : '';
  }

  return tag.name ? tag.name : '';
};

export const getDefaultAircraft = (aircrafts: IAircraft[]) => {
  const filtered = aircrafts.filter((aircraft: IAircraft) => aircraft?.default);

  if (filtered.length) return filtered[0];

  return aircrafts[0];
};

export const getVisibilityName = (visibililty: string) => {
  if (visibililty === 'M') return 'Savvy Only';

  if (visibililty === 'M,C') return 'Savvy and Clients';

  if (visibililty === 'M,C,T') return 'Everybody';

  if (visibililty === 'C,X') return 'Client and Management';

  if (visibililty === 'X') return 'Management';

  return '';
};

export const binarySearchInexact = (data: number[], p: number) => {
  let low = 0;
  let high = data.length - 1;
  let mid = 0;

  while (low <= high) {
    mid = Math.floor((low + high) / 2);
    const attempt = data[mid];
    if (attempt > p) high = mid - 1;
    else if (attempt < p) low = mid + 1;
    else return mid; // Exact guess
  }

  let distance = Math.abs(p - data[mid]);
  let best_guess = mid;

  if (best_guess - 1 > 0) {
    const prev_distance = Math.abs(p - data[mid - 1]);
    if (prev_distance < distance) {
      best_guess = mid - 1;
      distance = prev_distance;
    }
  }

  if (mid + 1 < data.length - 1) {
    const next_distance = Math.abs(p - data[mid + 1]);
    if (next_distance < distance) {
      best_guess = mid + 1;
    }
  }

  return best_guess;
};

export const convertTagNameToLabel = (tagName: string | undefined) => {
  const splits = tagName?.split('-').map((element: string) => {
    return element.charAt(0).toUpperCase() + element.slice(1);
  });

  return splits?.join(' ');
};

export const getServiceCenterColor = (serviceCenter: IServiceCenter) => {
  if (serviceCenter.verificationStatus === 'very-outdated') {
    return 'error.main';
  } else if (serviceCenter.verificationStatus === 'outdated') {
    return 'warning.main';
  }
  return 'success.main';
};

export const getTechnicianColor = (technician: ITechnicianTableItem | ITechnician) => {
  const now = new Date();
  now.setFullYear(now.getFullYear() - 2);

  if (technician) {
    if (new Date(technician.updated) < now) return 'error.main';

    now.setFullYear(new Date().getFullYear() - 1);
    if (new Date(technician.updated) < now) return 'warning.main';

    if (!technician.classifications?.length) {
      return 'warning.main';
    }

    if (technician.needsUpdate) {
      return 'warning.main';
    }
  }

  return 'success.main';
};

export const camelCaseToSnake = (str: string) => {
  return str.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`);
};

export const capitalizeToLowercase = (str: string) => {
  const splits = str.split(' ').map((element: string) => {
    return element.toLowerCase();
  });

  return splits.join('-');
};

export const getServiceName = (value: string) => {
  const result = services.find((service) => service.id === value);
  return typeof result === 'undefined' ? '' : result.name;
};

export const joinOrString = (arr: string[]) => {
  let result = getServiceName(arr[0]);

  for (let i = 1; i < arr.length - 1; i++) {
    result += ', ' + getServiceName(arr[i]);
  }
  if (arr.length > 1) result += ' or ' + getServiceName(arr[arr.length - 1]);

  return result;
};

export const goToPurchase = (ticketAircraft: IAircraftEligibility, eligibility: ITicketEligibility) => {
  let link = PURCHASE_LINK.replace(':id', ticketAircraft.id);
  link += eligibility.eligibleViaPurchase.join(',');
  window.location.href = link;
};

export const getContactAddress = (contact: IContactType | IServiceCenter) => {
  const addressArr: string[] = [];
  if (contact.street) {
    addressArr.push(contact.street);
  }
  if (contact.city) {
    addressArr.push(contact.city);
  }
  if (contact.state) {
    addressArr.push(contact.state);
  }
  if (contact.country) {
    addressArr.push(contact.country);
  }

  if (addressArr.length) {
    return addressArr.join(', ');
  }

  return null;
};

export const isEqualTwoArrays = (first: any[], second: any[]) => {
  if (first.length !== second.length) {
    return false;
  }

  for (let i = 0; i < first.length; i++) {
    if (first[i] !== second[i]) return false;
  }

  return true;
};

export const getLocalDate = (input: Date) => {
  const year = input.getFullYear();
  const month = ('0' + (input.getMonth() + 1)).slice(-2);
  const date = ('0' + input.getDate()).slice(-2);

  return `${year}-${month}-${date}`;
};

export const ctrlCmdClick = (event: any, url: string, navigate?: NavigateFunction, legacy?: boolean) => {
  if (event?.ctrlKey || event?.metaKey) {
    window.open(url, '_blank');
  } else {
    if (legacy) {
      window.location.href = url;
    } else {
      navigate?.(url);
    }
  }
};

export const getFlightDescription = (flight: IFlight) => {
  let str = '';

  str = flight.date.toString().substring(0, 10) + ' ' + flight.date.toString().substring(11, 16);

  if (flight.aircraft) str += ` ${flight.aircraft.registration}`;

  if (flight.aircraft?.aircraftManufacturer && flight.aircraft?.aircraftModel) {
    str += ` (${flight.aircraft.aircraftManufacturer.name}/${flight.aircraft.aircraftModel.name})`;
  }

  if (flight.aircraft?.owner) {
    str += ` ${flight.aircraft.owner.firstName} ${flight.aircraft.owner.lastName}`;
  }

  return str;
};

export const getScColorFromCode = (code: string | undefined) => {
  if (code === 'G') return 'success.main';
  if (code === 'Y') return 'warning.main';
  if (code === 'R') return 'error.main';

  return 'white';
};

export const getSeriesOrderIndex = (series: string) => {
  if (series.toLowerCase() === 'none') return 0;
  for (let i = 0; i < seriesOrder.length; i++) {
    if (seriesOrder[i].toLowerCase() === series.toLowerCase()) return i + 1;
  }
  return 100000;
};

export const getDuration = (value: number) => {
  const hour = Math.floor(value / 3600);
  const min = Math.floor((value % 3600) / 60);
  const sec = value % 60;

  return sec ? `${hour}h ${min}m ${sec}s` : `${hour}h ${min}m`;
};

export const getS3UrlForEDF = async (filename: string, aircraftId: string) => {
  const { data } = await apolloClient.mutate({
    mutation: MUTATION_GET_UPLOAD_ENGINE_DATA_FILE_URL,
    variables: {
      filename,
      aircraftId,
    },
  });

  return data.getUploadEngineDataFileUrl;
};

export const getS3UrlForDoc = async (filename: string) => {
  const { data } = await apolloClient.mutate({
    mutation: MUTATION_GET_UPLOAD_DOCUMENT_URL,
    variables: {
      filename,
    },
  });

  return data.getUploadDocumentUrl;
};

export const getS3UrlForQif = async (filename: string) => {
  const { data } = await apolloClient.mutate({
    mutation: MUTATION_GET_QIF_UPLOAD_URL,
    variables: {
      filename,
    },
  });

  return data.getQifUploadUrl;
};

export const uploadQifFile = async (file: File) => {
  try {
    const uploadData = await getS3UrlForQif(file.name);
    const urlData = JSON.parse(uploadData.url);

    const formData = new FormData();
    formData.append('AWSAccessKeyId', urlData.fields.AWSAccessKeyId);
    formData.append('key', urlData.fields.key);
    formData.append('policy', urlData.fields.policy);
    formData.append('signature', urlData.fields.signature);
    if (urlData.fields['x-amz-security-token'] !== undefined) {
      formData.append('x-amz-security-token', urlData.fields['x-amz-security-token']);
    }
    formData.append('file', file);

    const result = await fetch(urlData.url, {
      method: 'POST',
      mode: 'no-cors',
      body: formData,
    });

    if (result.status < 300) {
      return {
        success: true,
        key: urlData.fields.key,
      };
    } else {
      return {
        success: false,
        key: '',
      };
    }
  } catch (error) {
    return {
      success: false,
      key: '',
    };
  }
};

export const uploadFileDirectS3 = async (file: File, isDoc: boolean, aircraftId: string) => {
  let urlData: any = {};
  try {
    if (isDoc) {
      const uploadingData = await getS3UrlForDoc(file.name);
      urlData = JSON.parse(uploadingData.url);
    } else {
      const uploadingData = await getS3UrlForEDF(file.name, aircraftId);
      urlData = JSON.parse(uploadingData.url);
    }

    const formData = new FormData();
    formData.append('AWSAccessKeyId', urlData.fields.AWSAccessKeyId);
    formData.append('key', urlData.fields.key);
    formData.append('policy', urlData.fields.policy);
    formData.append('signature', urlData.fields.signature);
    if (urlData.fields['x-amz-security-token'] !== undefined) {
      formData.append('x-amz-security-token', urlData.fields['x-amz-security-token']);
    }
    formData.append('file', file);

    const result = await fetch(urlData.url, {
      method: 'POST',
      mode: 'no-cors',
      body: formData,
    });

    if (result.status < 300) {
      return {
        success: true,
        key: urlData.fields.key,
        url: urlData.url,
        edfId: urlData.edfId || '',
      };
    } else {
      return {
        success: false,
        key: '',
        url: '',
        edfId: '',
      };
    }
  } catch (error) {
    return {
      success: false,
      key: '',
      url: '',
    };
  }
};

export const getS3UrlForBorescopeImage = async (filename: string, borescopeImageSetId: string) => {
  try {
    const { data } = await apolloClient.mutate({
      mutation: MUTATION_CREATE_BORESCOPE_IMAGE,
      variables: {
        filename,
        borescopeImageSetId,
      },
    });

    return data.createBorescopeImage;
  } catch {
    return null;
  }
};

export const uploadBorescopeImageDirectS3 = async (file: File, url: string, borescopeImageId: string) => {
  let urlData: any = {};
  try {
    urlData = JSON.parse(url);

    const formData = new FormData();
    formData.append('AWSAccessKeyId', urlData.fields.AWSAccessKeyId);
    formData.append('key', urlData.fields.key);
    formData.append('policy', urlData.fields.policy);
    formData.append('signature', urlData.fields.signature);
    if (urlData.fields['x-amz-security-token'] !== undefined) {
      formData.append('x-amz-security-token', urlData.fields['x-amz-security-token']);
    }
    formData.append('file', file);
    const result = await fetch(urlData.url, {
      method: 'POST',
      mode: 'no-cors',
      body: formData,
    });

    if (result.status < 300) {
      return {
        success: true,
        key: urlData.fields.key,
        url: urlData.url,
        borescopeImageId,
      };
    } else {
      return {
        success: false,
        key: '',
        url: '',
        edfId: '',
      };
    }
  } catch (error) {
    return {
      success: false,
      key: '',
      url: '',
    };
  }
};

export const createDocumentFromS3 = async (documentPath: string) => {
  const { data } = await apolloClient.mutate({
    mutation: MUTATION_CREATE_DOCUMENT_FROM_S3_PATH,
    variables: {
      documentPath,
    },
  });

  return data.createDocumentFromS3Path;
};

export const getMaintenanceCategoryName = (id: string, maintenanceCategories: IDropDownOption[]) => {
  for (const category of maintenanceCategories) {
    if (category.id === id) {
      return category.name;
    }
  }

  return '';
};

export const evictChtTemperature = (aircraftId: string, isSavvy: boolean) => {
  if (isSavvy) {
    const cachedShortFlights = apolloClient.readQuery({
      query: QUERY_AIRCRAFT_FLIGHT_LIST,
      variables: {
        aircraftId: aircraftId,
        hideShortFlights: true,
      },
    });

    cachedShortFlights?.savvy?.aircraft?.flights?.map((flight: any) => {
      apolloClient.cache.evict({
        id: `FlightType:${flight?.id}`,
        fieldName: 'chtTemperatureAlert',
      });
      return null;
    });

    const cachedLongFlights = apolloClient.readQuery({
      query: QUERY_AIRCRAFT_FLIGHT_LIST,
      variables: {
        aircraftId: aircraftId,
        hideShortFlights: false,
      },
    });

    cachedLongFlights?.savvy?.aircraft?.flights?.map((flight: any) => {
      apolloClient.cache.evict({
        id: `FlightType:${flight?.id}`,
        fieldName: 'chtTemperatureAlert',
      });
      return null;
    });
  } else {
    const cachedShortFlights = apolloClient.readQuery({
      query: QUERY_USER_FLIGHTS_PER_AIRCRAFT,
      variables: {
        id: parseInt(aircraftId),
        hideShortFlights: true,
      },
    });

    cachedShortFlights?.me?.aircraft[0]?.flights?.map((flight: any) => {
      apolloClient.cache.evict({
        id: `FlightType:${flight?.id}`,
        fieldName: 'chtTemperatureAlert',
      });
      return null;
    });

    const cachedLongFlights = apolloClient.readQuery({
      query: QUERY_USER_FLIGHTS_PER_AIRCRAFT,
      variables: {
        id: parseInt(aircraftId),
        hideShortFlights: false,
      },
    });

    cachedLongFlights?.me?.aircraft[0]?.flights?.map((flight: any) => {
      apolloClient.cache.evict({
        id: `FlightType:${flight?.id}`,
        fieldName: 'chtTemperatureAlert',
      });
      return null;
    });
  }
};

export const getTabSuffix = (list: ITabLink[], index: number) => {
  const matchedItem = list.filter((item) => item.index === index);

  if (matchedItem?.length) {
    return matchedItem[0].suffix;
  }

  return '';
};

export const includeTicketSubject = (ticket: ISavvyTicket, search: string) => {
  if (ticket.subject.toLowerCase().includes(search.toLowerCase())) {
    return true;
  }

  const clientName = ticket.client ? `${ticket.client.firstName} ${ticket.client.lastName}` : '';

  if (clientName && clientName.toLowerCase().includes(search.toLowerCase())) {
    return true;
  }

  if (ticket.aircraft && ticket.aircraft.registration.toLowerCase().includes(search.toLowerCase())) {
    return true;
  }

  return false;
};

export const hasAnalysisPack = (analysisPacks: IAnalysisPack[]) => {
  for (let i = 0; i < analysisPacks.length; i++) {
    if (new Date(analysisPacks[i].endDate) > new Date() && analysisPacks[i].remainingIncidents > 0) {
      return true;
    }
  }

  return false;
};

export const makeCohortName = (cohort: ICohort, ellipsis = false, length = 20) => {
  let result = `${cohort.aircraftModel.manufacturer.name}/${cohort.aircraftModel.name} (${cohort.engineModel.manufacturer.name}/${cohort.engineModel.name})`;
  if (result.length > length && ellipsis) {
    result = result.substring(0, length) + '...';
  }
  return result;
};

export const getStartEndDate = (year: number, week: number) => {
  const newWeek = week + 1;
  const firstFriday = new Date(year, 0, 1);
  let firstDayFirstWeek = new Date(firstFriday);
  if (firstFriday.getDay() !== 5) {
    while (firstFriday.getDay() !== 5) {
      firstFriday.setDate(firstFriday.getDate() + 1);
    }

    firstDayFirstWeek = new Date(firstFriday.setDate(firstFriday.getDate() - 7));
  }
  return {
    startDate: new Date(new Date(firstDayFirstWeek).setDate(firstDayFirstWeek.getDate() + (newWeek - 1) * 7)),
    endDate: new Date(new Date(firstDayFirstWeek.setDate(firstDayFirstWeek.getDate() + (newWeek - 1) * 7 + 6)).setHours(23, 59, 60)),
  };
};

export const getCurrentWeek = () => {
  const now = new Date();
  const currentYear = now.getFullYear();

  for (let i = 0; i < 54; i++) {
    const dateRange = getStartEndDate(currentYear, i);
    if (now >= dateRange.startDate && now <= dateRange.endDate) return i;
  }

  return 0;
};

export const generateYearRange = () => {
  const current = new Date().getFullYear();
  const yearRange: number[] = [];
  for (let i = current - 10; i < current + 5; i++) {
    yearRange.push(i);
  }

  return yearRange;
};

export const getTimesheetDateString = (value: Date) => {
  return dayjs(value).format('YYYY-MM-DD hh:mm');
};

export const isHeic = (fileName: string) => {
  return fileName.toLowerCase().endsWith('.heic');
};

export const hasBreakdownAircraft = (aircrafts: IAircraft[]) => {
  for (const aircraft of aircrafts) {
    if (!aircraft.subscriptions?.length) {
      continue;
    }

    for (const subscription of aircraft.subscriptions) {
      if (
        (subscription.serviceCode === SAVVY_MX || subscription.serviceCode === SAVVY_QA || subscription.serviceCode === SAVVY_BREAKDOWN) &&
        subscription.status.toLowerCase() === 'active'
      ) {
        return true;
      }
    }
  }

  return false;
};

export const getUserTypeFromRole = (role: string | undefined) => {
  if (!role) {
    return 'Anonymous';
  }

  if (role === 'M') {
    return 'Savvy';
  } else if (role === 'C') {
    return 'Client';
  } else {
    return 'Technician';
  }
};

export const getFevaAlertChartStatus = (value: string) => {
  switch (value) {
    case 'U':
      return 'Unverified';
    case 'P':
      return 'Verified Positive';
    case 'N':
      return 'Verified Negative';
    case 'X':
      return 'Indeterminate';
    default:
      return 'Unverified';
  }
};

export const getFevaAlertPhysicalStatus = (value: string) => {
  switch (value) {
    case 'U':
      return 'Waiting for Client';
    case 'P':
      return 'Verified Positive';
    case 'N':
      return 'Verified Negative';
    case 'X':
      return 'Indeterminate';
    case 'R':
      return 'No Response Received';
    case 'C':
      return 'No Response Requested (Chart Negative)';
    case 'V':
      return 'No Response Received (Self Resolved)';
    default:
      return 'Waiting for Client';
  }
};

export const isSharedLog = (tags: any[]) => {
  if (!tags) {
    return false;
  }

  for (let i = 0; i < tags.length; i++) {
    if (tags[i].name === 'share-maintenance-logs') {
      return true;
    }
  }

  return false;
};

export const isPresetToMode = (preset: string) => {
  if (preset === TO_MODE || preset === TO_MODE_LEFT || preset === TO_MODE_RIGHT) {
    return true;
  }

  return false;
};

export const presetSeries = (preset: string) => {
  if (preset === TO_MODE) {
    return ['FF', 'RPM', 'MAP'];
  }

  if (preset === TO_MODE_LEFT) {
    return ['L-FF', 'L-RPM', 'L-MAP'];
  }

  if (preset === TO_MODE_RIGHT) {
    return ['R-FF', 'R-RPM', 'R-MAP'];
  }

  return ['None', 'None', 'None'];
};

export const getFewDaysAgo = (day: number) => {
  const date = new Date();
  return new Date(date.setDate(date.getDate() - day));
};

export const makeKmlString = (flight: IFlight, points: IKmlPoint[]) => {
  const kmlString = `<?xml version="1.0" encoding="UTF-8"?>
<kml xmlns="http://www.opengis.net/kml/2.2">
  <Document>
    <name>${flight.aircraft.registration}: ${flight.date ? flight.date.toString().substring(0, 10) : ''} ${
      flight.date ? flight.date.toString().substring(11, 16) : ''
    } UTC</name>
    <description></description>
    <Style id="yellowLineGreenPoly">
      <LineStyle>
        <color>7f00ffff</color>
        <width>4</width>
      </LineStyle>
      <PolyStyle>
        <color>7f00ff00</color>
      </PolyStyle>
    </Style>
    <Placemark>
      <name>${flight.aircraft.registration}: ${flight.date ? flight.date.toString().substring(0, 10) : ''} ${
        flight.date ? flight.date.toString().substring(11, 16) : ''
      } UTC</name>
      <styleUrl>#yellowLineGreenPoly</styleUrl>
      <LineString>
        <extrude>1</extrude>
        <tessellate>1</tessellate>
        <altitudeMode>absolute</altitudeMode>
        <coordinates>
           ${points
             .map((point: IKmlPoint) => {
               return point.lon + ',' + point.lat + ',' + point.alt;
             })
             .join('\n           ')}
        </coordinates>
      </LineString>
    </Placemark>
  </Document>
</kml>`;
  return kmlString;
};

export const downloadKmlFile = (data: string, flight: IFlightData) => {
  let date = `${flight.date ? flight.date.toString().substring(0, 10) : ''}`;
  let time = `${flight.date ? flight.date.toString().substring(11, 19) : ''}`;
  const fileName = `${date} ${time.replaceAll(':', '')}`;
  const blob = new Blob([data], { type: 'text/plain' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.download = `${flight.aircraft.registration} ${fileName}.kml`;
  link.href = url;
  link.click();
};

export const downloadFileInNewTab = (url: string) => {
  const downloadLink = document.createElement('a');
  downloadLink.href = url;
  downloadLink.setAttribute('download', '');
  downloadLink.target = '_blank';
  downloadLink.click();
};

export const isMacDevice = () => {
  return navigator.userAgent.indexOf('Mac OS X') !== -1;
};

export const isFeatureService = (serviceCode: string) => {
  if (serviceCode === SAVVY_PREBUY) {
    return true;
  }

  if (serviceCode === SAVVY_ANALYSIS_PRO) {
    return true;
  }

  if (serviceCode === SAVVY_BREAKDOWN) {
    return true;
  }

  return false;
};

export const turnService = (serviceCode: string) => {
  if (serviceCode === SAVVY_PREBUY) {
    return SAVVY_MX;
  }

  if (serviceCode === SAVVY_ANALYSIS_PRO) {
    return SAVVY_ANALYSIS;
  }

  if (serviceCode === SAVVY_BREAKDOWN) {
    return SAVVY_BASIC;
  }

  return serviceCode;
};

export const setContextInSentry = (eventName: string, params: any) => {
  Sentry.setContext(eventName, {
    ...params,
  });
};

export const getEngineNameFromBorescopeLabel = (label: string) => {
  const matched = enginesForBorescope.find((engines) => engines.label === label);
  if (matched) {
    return matched.value;
  }

  return '';
};

export const existingTextOnUpdate = (ticket: ISavvyTicket, viewerId: string) => {
  const matchedItem = ticket.watchers?.find((item) => item.textOnUpdate && item.contact?.id === viewerId);
  if (matchedItem) {
    return true;
  }
  return false;
};

export const sortCohortsByAircraftName = (cohorts: any[]) => {
  return [...cohorts].sort((item1: any, item2: any) => {
    const firstName = item1.aircraftModel ? `${item1.aircraftModel.manufacturer.name} ${item1.aircraftModel.name}` : '';
    const secondName = item2.aircraftModel ? `${item2.aircraftModel.manufacturer.name} ${item2.aircraftModel.name}` : '';
    return firstName > secondName ? 1 : -1;
  });
};

export const getSubjectLabel = (list: string[][], value: string) => {
  const matched = list.find((item) => item[0] === value);
  if (matched) {
    return matched[1];
  }
  return value;
};

export const isMatchedGamiSerie = (serieName: string) => {
  return Boolean(serieName.match(/([LR]-)?EGT\s*\d/));
};

export const isNonUploadPermission = (aircraft: IAircraft) => {
  if (!aircraft.shared) {
    return false;
  }
  if (aircraft.permissions?.length) {
    const uploadPermission = aircraft.permissions.find((permission) => permission === UPLOAD_BORESCOPE_PERMISSION);
    if (uploadPermission) {
      return false;
    } else {
      return true;
    }
  }
  return false;
};

export const isNonCreatePermission = (aircraft: IAircraft) => {
  if (!aircraft.shared) {
    return false;
  }
  if (aircraft.permissions?.length) {
    const createPermission = aircraft.permissions.find((permission) => permission === CREATE_BORESCOPE_REPORT_PERMISSION);
    if (createPermission) {
      return false;
    } else {
      return true;
    }
  }
  return false;
};

export const getRenderSerieName = (option: ISerieSet) => {
  if (option.series.length) {
    for (let i = 0; i < option.series.length; i++) {
      if (option.series[i].includes('TIT')) {
        return `${option.name}/TIT`;
      }
    }
  }
  return option.name;
};
