import React, { useState, useEffect } from 'react';
import { Box, Hidden, Slide } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import { QUERY_GET_AIRCRAFT_SUBSCRIPTION, QUERY_GET_AIRCRAFT_SUBSCRIPTION_DETAIL } from 'gql';
import { useLazyQuery } from '@apollo/client';
import { ComponentLoading } from 'components';

import { PATH_SAVVY_AIRCRAFT } from 'const';

import { List, Detail } from './SubscriptionComponents';

import { setAlert } from 'state';

interface SubscriptionsProps {
  aircraftId: string;
  isLoad: boolean;
  contactId: string;
}

const Subscriptions: React.FC<SubscriptionsProps> = (props) => {
  const { aircraftId, isLoad, contactId } = props;

  const [fetchSubscriptions, { data: dataSubscriptions, loading: loadingSubscriptions }] = useLazyQuery(QUERY_GET_AIRCRAFT_SUBSCRIPTION);
  const [fetchSubscription, { data: dataSubscription, error: errorSubscription, loading: loadingSubscription }] = useLazyQuery(
    QUERY_GET_AIRCRAFT_SUBSCRIPTION_DETAIL,
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { subscriptionId } = useParams();

  const [slideNum, setSlideNum] = useState(1);

  const handleClick = (id: string) => {
    navigate(`${PATH_SAVVY_AIRCRAFT}/${aircraftId}/subscriptions/${id}`);
  };

  useEffect(() => {
    if (isLoad && aircraftId) {
      fetchSubscriptions({
        variables: {
          aircraftId,
        },
      });
    }
  }, [aircraftId, isLoad, fetchSubscriptions]);

  useEffect(() => {
    if (subscriptionId && aircraftId && !isNaN(parseInt(subscriptionId))) {
      fetchSubscription({
        variables: {
          aircraftId,
          subscriptionId,
        },
      });
    }
  }, [subscriptionId, aircraftId, fetchSubscription]);

  useEffect(() => {
    if (subscriptionId && isNaN(parseInt(subscriptionId))) {
      dispatch(setAlert('error', 'Invalid subscription ID'));
    }
  }, [subscriptionId, dispatch]);

  useEffect(() => {
    if (errorSubscription) {
      dispatch(setAlert('error', 'Unable to load subscription data'));
    }
  }, [errorSubscription, dispatch]);

  useEffect(() => {
    if (!loadingSubscription && dataSubscription && subscriptionId) {
      setSlideNum(2);
    }
  }, [loadingSubscription, dataSubscription, subscriptionId]);

  return (
    <Box>
      <ComponentLoading loading={loadingSubscriptions}>
        <Hidden lgDown>
          <Box
            sx={{
              minHeight: 'calc(100% - 75px)',
              display: 'flex',
            }}
          >
            <Box
              sx={{
                minHeight: '80%',
                display: 'flex',
                py: 2,
              }}
            >
              <List
                subscriptions={dataSubscriptions?.savvy?.aircraft?.subscriptions ? dataSubscriptions?.savvy?.aircraft?.subscriptions : []}
                handleClick={handleClick}
                setSlideNum={setSlideNum}
              />
              <Box>
                {!!subscriptionId && (
                  <Detail
                    loading={loadingSubscription}
                    subscription={
                      dataSubscription?.savvy?.aircraft?.subscriptions[0] ? dataSubscription?.savvy?.aircraft?.subscriptions[0] : undefined
                    }
                    contactId={contactId}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Hidden>
        <Hidden lgUp>
          <ComponentLoading loading={loadingSubscription}>
            <Box
              sx={{
                overflowX: 'hidden',
              }}
            >
              <Slide
                direction="left"
                in={slideNum === 1}
                unmountOnExit
                timeout={{
                  exit: 0,
                  enter: 300,
                }}
              >
                <Box>
                  <List
                    subscriptions={
                      dataSubscriptions?.savvy?.aircraft?.subscriptions ? dataSubscriptions?.savvy?.aircraft?.subscriptions : []
                    }
                    handleClick={handleClick}
                    setSlideNum={setSlideNum}
                  />
                </Box>
              </Slide>
              {subscriptionId && (
                <Slide
                  direction="left"
                  in={slideNum === 2}
                  mountOnEnter
                  unmountOnExit
                  timeout={{
                    exit: 0,
                    enter: 300,
                  }}
                >
                  <Box>
                    {!!subscriptionId && (
                      <Detail
                        loading={loadingSubscription}
                        subscription={
                          dataSubscription?.savvy?.aircraft?.subscriptions[0]
                            ? dataSubscription?.savvy?.aircraft?.subscriptions[0]
                            : undefined
                        }
                        setSlideNum={setSlideNum}
                        contactId={contactId}
                      />
                    )}
                  </Box>
                </Slide>
              )}
            </Box>
          </ComponentLoading>
        </Hidden>
      </ComponentLoading>
    </Box>
  );
};

export default Subscriptions;
