import React, { useState, useEffect } from 'react';
import { Box, Slide } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { setAlert } from 'state';
import { PATH_REPORTS_BETA, BORESCOPE_ANALYSIS_REPORT_TYPE } from 'const';

import {
  QUERY_USER_AIRCRAFTS_WITHOUT_SUBSCRIPTION,
  QUERY_REPORT_CARDS_BY_AIRCRAFT,
  QUERY_BORESCOPE_IMAGE_SETS_WITH_IMAGE_LENGTH,
  QUERY_REPORT_DETAIL,
} from 'gql';
import { useQuery, useLazyQuery } from '@apollo/client';

import { PageLoading, LayoutDashboard, ComponentLoading } from 'components';
import { NewReportsCardList, NewReportCardContext, BorescopeReport } from './components';
import { IBorescopeImageSet } from 'types';

const Reports: React.FC = () => {
  const { data: dataAircrafts, loading: loadingAircrafts } = useQuery(QUERY_USER_AIRCRAFTS_WITHOUT_SUBSCRIPTION);

  const [fetchReports, { data: dataFetchReports, loading: loadingFetchReports, error: errorFetchReports }] =
    useLazyQuery(QUERY_REPORT_CARDS_BY_AIRCRAFT);
  const [fetchBorescopeReports, { data: dataBorescopeReports, loading: loadingBorescopeReports, error: errorBorescopeReports }] =
    useLazyQuery(QUERY_BORESCOPE_IMAGE_SETS_WITH_IMAGE_LENGTH);
  const [fetchReportDetail, { data: dataReportDetail, loading: loadingReportDetail, error: errorReportDetail }] =
    useLazyQuery(QUERY_REPORT_DETAIL);

  const { storedAircraftId } = useSelector((state: any) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, reportType, reportId: propsReportId } = useParams();

  const [slideNum, setSlideNum] = useState(1);
  const [reportFilter, setReportFilter] = useState('all');
  const [cardType, setCardType] = useState('report_card');

  useEffect(() => {
    if (id) {
      fetchReports({
        variables: {
          id: parseInt(id),
        },
      });
      fetchBorescopeReports({
        variables: {
          id: parseInt(id),
        },
      });
    }
  }, [id]);

  useEffect(() => {
    if (storedAircraftId) {
      navigate(`${PATH_REPORTS_BETA}/${storedAircraftId}`);
    }
  }, [storedAircraftId]);

  useEffect(() => {
    if (errorFetchReports) {
      dispatch(setAlert('error', 'Unable to load reports card for selected aircraft'));
    }
  }, [errorFetchReports, dispatch]);

  useEffect(() => {
    if (errorBorescopeReports) {
      dispatch(setAlert('error', 'Unable to load borescope analysis reports for selected aircraft'));
    }
  }, [errorBorescopeReports, dispatch]);

  useEffect(() => {
    if (errorReportDetail) {
      dispatch(setAlert('error', 'Unable to load selected report card'));
    }
  }, [errorReportDetail, dispatch]);

  useEffect(() => {
    if (id && reportType && propsReportId) {
      if (reportType !== BORESCOPE_ANALYSIS_REPORT_TYPE.toLowerCase()) {
        fetchReportDetail({
          variables: {
            aircraftId: parseInt(id),
            reportCardId: propsReportId,
          },
        });
      }
    }
  }, [id, reportType, propsReportId]);

  useEffect(() => {
    if (dataReportDetail?.me?.aircraft?.length) {
      setSlideNum(2);
    }
  }, [dataReportDetail]);

  useEffect(() => {
    if (propsReportId) {
      setSlideNum(2);
    }
  }, [propsReportId]);

  return (
    <LayoutDashboard backgroundColor="#FFFFFF" flexGrow={1}>
      {loadingAircrafts && <PageLoading />}
      <Box
        flexGrow={1}
        width="100%"
        sx={{
          mb: 1,
          height: '100%',
        }}
      >
        <Box
          sx={{
            display: {
              xl: 'block',
              xs: 'none',
            },
          }}
        >
          <Box
            sx={{
              minHeight: 'calc(100% - 75px)',
              display: 'flex',
            }}
          >
            {Boolean(dataAircrafts?.me?.aircraft?.length) && (
              <NewReportsCardList
                aircrafts={dataAircrafts?.me?.aircraft}
                aircraft={id && dataFetchReports?.me?.aircraft[0]?.id === id ? dataFetchReports?.me?.aircraft[0] : undefined}
                reports={id && dataFetchReports?.me?.aircraft[0]?.reportCards ? dataFetchReports.me.aircraft[0].reportCards : []}
                loading={loadingFetchReports || loadingBorescopeReports}
                borescopeImageSets={
                  id
                    ? dataBorescopeReports?.me?.aircraft[0]?.borescopeImageSets?.filter(
                        (item: IBorescopeImageSet) => item.images.length && item.status === 'REPORT_SENT',
                      ) || []
                    : []
                }
                reportFilter={reportFilter}
                setReportFilter={setReportFilter}
                cardType={cardType}
                setCardType={setCardType}
              />
            )}
            {Boolean(dataAircrafts?.me?.aircraft.length) && reportType !== BORESCOPE_ANALYSIS_REPORT_TYPE.toLowerCase() && (
              <NewReportCardContext
                aircraft={id && dataFetchReports?.me?.aircraft[0]?.id === id ? dataFetchReports?.me?.aircraft[0] : undefined}
                reportContext={propsReportId ? dataReportDetail?.me?.aircraft[0]?.reportCards[0]?.context : undefined}
                loadingContext={loadingReportDetail}
                reportId={propsReportId || ''}
                reportFilter={reportFilter}
                reportType={reportType}
                length={
                  (dataFetchReports?.me?.aircraft[0]?.reportCards?.length || 0) +
                  (dataBorescopeReports?.me?.aircraft[0]?.borescopeImageSets?.filter(
                    (item: IBorescopeImageSet) => item.images.length && item.status === 'REPORT_SENT',
                  )?.length || 0)
                }
                cardType={cardType}
              />
            )}
            {Boolean(dataAircrafts?.me?.aircraft.length) && reportType === BORESCOPE_ANALYSIS_REPORT_TYPE.toLowerCase() && (
              <BorescopeReport />
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: {
              xl: 'none',
              xs: 'block',
            },
          }}
        >
          <ComponentLoading loading={loadingReportDetail}>
            <Box
              sx={{
                overflowX: 'hidden',
              }}
            >
              <Slide
                direction="left"
                in={slideNum === 1}
                unmountOnExit
                timeout={{
                  exit: 0,
                  enter: 300,
                }}
              >
                <Box>
                  {Boolean(dataAircrafts?.me?.aircraft?.length) && (
                    <NewReportsCardList
                      aircrafts={dataAircrafts?.me?.aircraft}
                      aircraft={id && dataFetchReports?.me?.aircraft[0]?.id === id ? dataFetchReports?.me?.aircraft[0] : undefined}
                      reports={id && dataFetchReports?.me?.aircraft[0]?.reportCards ? dataFetchReports.me.aircraft[0].reportCards : []}
                      loading={loadingFetchReports || loadingBorescopeReports}
                      setSlideNum={setSlideNum}
                      borescopeImageSets={
                        id
                          ? dataBorescopeReports?.me?.aircraft[0]?.borescopeImageSets?.filter(
                              (item: IBorescopeImageSet) => item.images.length && item.status === 'REPORT_SENT',
                            ) || []
                          : []
                      }
                      reportFilter={reportFilter}
                      setReportFilter={setReportFilter}
                      cardType={cardType}
                      setCardType={setCardType}
                    />
                  )}
                </Box>
              </Slide>
              {id && (
                <Slide
                  direction="left"
                  in={slideNum === 2}
                  mountOnEnter
                  unmountOnExit
                  timeout={{
                    exit: 0,
                    enter: 300,
                  }}
                >
                  <Box>
                    {Boolean(dataAircrafts?.me?.aircraft.length) && reportType !== BORESCOPE_ANALYSIS_REPORT_TYPE.toLowerCase() && (
                      <NewReportCardContext
                        aircraft={id && dataFetchReports?.me?.aircraft[0]?.id === id ? dataFetchReports?.me?.aircraft[0] : undefined}
                        reportContext={propsReportId ? dataReportDetail?.me?.aircraft[0]?.reportCards[0]?.context : undefined}
                        loadingContext={loadingReportDetail}
                        setSlideNum={setSlideNum}
                        reportId={propsReportId || ''}
                        reportFilter={reportFilter}
                        reportType={reportType}
                        length={
                          (dataFetchReports?.me?.aircraft[0]?.reportCards?.length || 0) +
                          (dataBorescopeReports?.me?.aircraft[0]?.borescopeImageSets?.filter(
                            (item: IBorescopeImageSet) => item.images.length && item.status === 'REPORT_SENT',
                          )?.length || 0)
                        }
                        cardType={cardType}
                      />
                    )}
                    {Boolean(dataAircrafts?.me?.aircraft.length) && reportType === BORESCOPE_ANALYSIS_REPORT_TYPE.toLowerCase() && (
                      <BorescopeReport setSlideNum={setSlideNum} />
                    )}
                  </Box>
                </Slide>
              )}
            </Box>
          </ComponentLoading>
        </Box>
      </Box>
    </LayoutDashboard>
  );
};

export default Reports;
