import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box, Typography, Link, Button, IconButton } from '@mui/material';
import { DataGridPro, GridRenderCellParams } from '@mui/x-data-grid-pro';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { LayoutDashboard, PageLoading, ComponentLoading, DeleteDialog } from 'components';

import { QUERY_COHORTS_DETAIL, QUERY_COHORT_PARAMS, MUTATION_CLEAR_COHORT, MUTATION_DELETE_REPORT_CARD_PARAMETER } from 'gql';
import { useLazyQuery, useMutation } from '@apollo/client';

import { setAlert } from 'state';
import { ctrlCmdClick, getTimesheetDateString } from 'helpers';
import { PATH_SAVVY_ENGINE_PARAMETERS, PATH_SAVVY_COHORTS } from 'const';

import { EditCohortDialog, DuplicateCohortDialog, CreateReportCardParameterDialog, UpdateReportCardParameterDialog } from './components';

import { IReportCardParameter } from 'types';

const ViewCohort: React.FC = () => {
  const { id: cohortId } = useParams();

  const [fetchCohorts, { data: dataCohorts, error: errorCohorts, loading: loadingCohorts }] = useLazyQuery(QUERY_COHORTS_DETAIL);
  const [clearCohort, { data: dataClear, error: errorClear, loading: loadingClear }] = useMutation(MUTATION_CLEAR_COHORT);

  const [fetchCohortParams, { loading: loadingCohortParams }] = useLazyQuery(QUERY_COHORT_PARAMS);

  const [
    deleteReportCardParam,
    { data: dataDeleteReportCardParam, loading: loadingDeleteReportCardParam, error: errorDeleteReportCardParam },
  ] = useMutation(MUTATION_DELETE_REPORT_CARD_PARAMETER);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showDuplicate, setShowDuplicate] = useState(false);

  const [openEdit, setOpenEdit] = useState(false);

  const [pageSize, setPageSize] = useState(10);

  const [createReportCardParameterDialogOpen, setCreateReportCardParameterDialogOpen] = useState(false);

  const [deleteParamId, setDeleteParamId] = useState('');
  const [deleteParamDialog, setDeleteParamDialog] = useState(false);

  const [success, setSuccess] = useState(false);

  const [cardParamEditOpen, setCardParamEditOpen] = useState(false);
  const [selectedCardParam, setSelectedCardParam] = useState<IReportCardParameter>();

  const deleteParamAction = async () => {
    if (!deleteParamId) {
      return;
    }
    await deleteReportCardParam({
      variables: {
        reportCardParamId: deleteParamId,
      },
    });
  };

  const handleClearClick = async () => {
    await clearCohort({
      variables: {
        cohortId,
      },
    });
  };

  useEffect(() => {
    if (cohortId) {
      fetchCohorts({
        variables: {
          cohortId,
        },
      });
    }
  }, [cohortId, fetchCohorts]);

  useEffect(() => {
    if (errorCohorts) {
      dispatch(setAlert('error', 'Unable to load cohort data'));
    }
  }, [dispatch, errorCohorts]);

  useEffect(() => {
    if (errorClear) {
      dispatch(setAlert('error', 'Unable to clear cohort'));
    } else if (dataClear) {
      if (dataClear?.clearCohort?.ok) {
        dispatch(setAlert('success', 'Cleared cohort'));
      } else {
        dispatch(setAlert('error', dataClear?.clearCohort?.error || 'Unable to clear cohort'));
      }
    }
  }, [errorClear, dataClear, dispatch]);

  useEffect(() => {
    if (errorDeleteReportCardParam) {
      dispatch(setAlert('error', 'Unable to delete report card parameter'));
    } else if (dataDeleteReportCardParam) {
      if (dataDeleteReportCardParam.deleteReportCardParameter?.ok) {
        dispatch(setAlert('success', 'Deleted report card parameter'));
        setSuccess(true);
      } else {
        dispatch(setAlert('error', dataDeleteReportCardParam.deleteReportCardParameter?.error || 'Unable to delete report card parameter'));
      }
    }
  }, [dataDeleteReportCardParam, errorDeleteReportCardParam, dispatch]);

  useEffect(() => {
    if (success && cohortId) {
      fetchCohortParams({
        variables: {
          cohortId,
        },
        fetchPolicy: 'network-only',
      });
    }
  }, [success, cohortId]);

  return (
    <LayoutDashboard backgroundColor="#fff">
      {loadingCohorts && <PageLoading />}
      {!!dataCohorts?.cohorts?.length && (
        <Box p={4}>
          <Typography variant="h2" gutterBottom>
            {`${dataCohorts?.cohorts[0].aircraftModel.manufacturer.name} ${dataCohorts?.cohorts[0].aircraftModel.name}, ${dataCohorts?.cohorts[0].engineModel.manufacturer.name} ${dataCohorts?.cohorts[0].engineModel.name}`}
          </Typography>
          <Box py={2}>
            <Typography
              variant="h3"
              gutterBottom
              sx={{
                color: 'error.main',
              }}
            >
              {`${dataCohorts?.cohorts[0].aircraftModel.manufacturer.name} ${dataCohorts?.cohorts[0].aircraftModel.name}`}
            </Typography>
            <Box>
              <Box py={1}>
                <Typography
                  variant="h3"
                  sx={{
                    color: 'error.main',
                  }}
                >
                  {`${dataCohorts?.cohorts[0].engineModel.manufacturer.name} ${dataCohorts?.cohorts[0].engineModel.name}`}
                </Typography>
              </Box>
              <Box py={1}>
                <Typography>ID: {dataCohorts?.cohorts[0]?.id}</Typography>
              </Box>
              <Box py={1}>
                <Typography>Active: {dataCohorts?.cohorts[0]?.active ? 'Active' : 'Inactive'}</Typography>
              </Box>
              <Box py={1}>
                <Typography
                  sx={{
                    color: dataCohorts?.cohorts[0]?.clearOnNextCycle ? 'error.main' : 'text.primary',
                  }}
                >
                  {dataCohorts?.cohorts[0]?.clearOnNextCycle
                    ? 'This cohort will clear on the next ingestion cycle.'
                    : 'This cohort will not clear on the next ingestion cycle.'}
                </Typography>
              </Box>
              <Box py={1}>
                <Typography>
                  Flights: {dataCohorts?.cohorts[0]?.numberOfFlights ? dataCohorts?.cohorts[0]?.numberOfFlights : 'None'}
                </Typography>
              </Box>
              <Box py={1}>
                <Typography>
                  Power Model:{' '}
                  <Link
                    href={`${PATH_SAVVY_ENGINE_PARAMETERS}/${dataCohorts?.cohorts[0]?.engineParameters?.id}`}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      ctrlCmdClick(e, `${PATH_SAVVY_ENGINE_PARAMETERS}/${dataCohorts?.cohorts[0]?.engineParameters?.id}`, navigate);
                    }}
                  >
                    {dataCohorts?.cohorts[0]?.engineParameters?.name}
                  </Link>
                </Typography>
              </Box>
              <Box py={1}>
                <Typography>Last Updated: {getTimesheetDateString(dataCohorts.cohorts[0].updated)}</Typography>
              </Box>
              <Box py={1}>
                <Typography>Value Dictionary: {dataCohorts.cohorts[0].valueDict ? dataCohorts.cohorts[0].valueDict : ''}</Typography>
              </Box>
              <Box py={1}>
                <Typography>
                  Quantile Dictionary: {dataCohorts.cohorts[0].quantileDict ? dataCohorts.cohorts[0].quantileDict : ''}
                </Typography>
              </Box>
              <Box py={1}>
                <Button
                  variant="contained"
                  onClick={() => {
                    setOpenEdit(true);
                  }}
                  sx={{
                    mr: 1,
                  }}
                >
                  Edit
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    navigate(PATH_SAVVY_COHORTS);
                  }}
                  sx={{
                    mr: 1,
                  }}
                >
                  Back to List
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    setShowDuplicate(true);
                  }}
                >
                  Duplicate To...
                </Button>
              </Box>
            </Box>
            <ComponentLoading loading={loadingClear}>
              <Box py={1}>
                <Typography
                  variant="h3"
                  sx={{
                    color: 'error.main',
                  }}
                >
                  Clear Cohort Data
                </Typography>
              </Box>
              <Box py={1}>
                <Typography>
                  <b>No confirmation on the button below.</b> Click it and it will set a flag to clear the cohort flight data and it will
                  immediately clear the quantile data.
                </Typography>
                <Typography>
                  The cohort flight data will be cleared the next time the flight ingestion job starts. This runs every 15m.
                </Typography>
                <Typography>If one is currently in progress, the deletion will happen on the next cycle.</Typography>
                <Typography>
                  Additionally, the quantile data is regenerated once per day at 3am, so there will be no reports until then for this
                  cohort.
                </Typography>
              </Box>
              <Box
                py={1}
                sx={{
                  textAlign: 'center',
                }}
              >
                <Button variant="contained" color="error" disabled={loadingClear} onClick={handleClearClick}>
                  Clear
                </Button>
              </Box>
            </ComponentLoading>
            <Box>
              <Box py={1}>
                <Typography
                  variant="h3"
                  sx={{
                    color: 'error.main',
                  }}
                >
                  History
                </Typography>
              </Box>
              <Box py={1}>
                <Typography>TODO: Make thise changes browseable.</Typography>
              </Box>
            </Box>
            <Box>
              <Box
                py={1}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    color: 'error.main',
                  }}
                >
                  Report Card Parameters
                </Typography>
                <IconButton
                  onClick={() => {
                    setCreateReportCardParameterDialogOpen(true);
                    setSuccess(false);
                  }}
                  sx={{
                    ml: 1,
                  }}
                  title="Add New"
                >
                  <AddCircleOutlineIcon />
                </IconButton>
              </Box>
              <ComponentLoading loading={loadingDeleteReportCardParam || loadingCohortParams}>
                <Box>
                  <DataGridPro
                    sx={{
                      width: '100%',
                      minWidth: '100px',
                      '& .MuiDataGrid-cell:focus': {
                        outline: 'none',
                      },
                      '& .MuiDataGrid-row': {
                        cursor: 'pointer',
                        borderBottom: '1px solid',
                        borderColor: 'grey.200',
                      },
                      '& .MuiDataGrid-columnHeader:focus': {
                        outline: 'none',
                      },
                      '& .MuiDataGrid-toolbarContainer .MuiTextField-root': {
                        mb: 0,
                      },
                      border: 'none',
                    }}
                    autoHeight
                    disableSelectionOnClick
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    pageSize={pageSize}
                    onPageSizeChange={(newPage) => {
                      setPageSize(newPage);
                    }}
                    pagination
                    showColumnRightBorder={false}
                    disableColumnSelector
                    disableColumnMenu
                    rows={dataCohorts?.cohorts[0]?.reportCardsParams || []}
                    columns={[
                      {
                        field: 'name',
                        headerName: 'Name',
                        flex: 0.3,
                      },
                      {
                        field: 'titleName',
                        headerName: 'Title Name',
                        flex: 0.5,
                      },
                      {
                        field: 'outlierLeftValueLimit',
                        headerName: 'Outlier Left Value Limit',
                        flex: 0.6,
                      },
                      {
                        field: 'outlierRightValueLimit',
                        headerName: 'Outlier Right Value Limit',
                        flex: 0.6,
                      },
                      {
                        field: 'outlierLeftStdDevLimit',
                        headerName: 'Outlier Left Standard Dev Limit',
                        flex: 0.6,
                      },
                      {
                        field: 'outlierRightStdDevLimit',
                        headerName: 'Outlier Right Standard Dev Limit',
                        flex: 0.6,
                      },
                      {
                        field: 'specMin',
                        headerName: 'Spec Min',
                        flex: 0.3,
                      },
                      {
                        field: 'specMax',
                        headerName: 'Spec Max',
                        flex: 0.3,
                      },
                      {
                        field: 'presentationOrder',
                        headerName: 'Presentation Order',
                        flex: 0.5,
                      },
                      {
                        field: 'scalingMultiplier',
                        headerName: 'Scaling Multiplier',
                        flex: 0.5,
                      },
                      {
                        field: 'units',
                        headerName: 'Units',
                        flex: 0.3,
                      },
                      {
                        field: 'colorBarReverseStatus',
                        headerName: 'ColorBar Reverse Status',
                        flex: 0.5,
                      },
                      {
                        field: 'id',
                        headerName: 'Actions',
                        renderCell: (params: GridRenderCellParams) => {
                          return (
                            <Box>
                              <IconButton
                                onClick={() => {
                                  setSelectedCardParam(params.row as IReportCardParameter);
                                  setCardParamEditOpen(true);
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                sx={{
                                  ml: 1,
                                }}
                                onClick={() => {
                                  setDeleteParamId(params.value || '');
                                  setDeleteParamDialog(true);
                                  setSuccess(false);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          );
                        },
                        flex: 0.5,
                      },
                    ]}
                  />
                </Box>
              </ComponentLoading>
            </Box>
          </Box>
          <EditCohortDialog open={openEdit} setOpen={setOpenEdit} selectedCohort={dataCohorts?.cohorts[0]} />
          <DuplicateCohortDialog open={showDuplicate} setOpen={setShowDuplicate} sourceCohortId={cohortId} />
          <CreateReportCardParameterDialog
            open={createReportCardParameterDialogOpen}
            setOpen={setCreateReportCardParameterDialogOpen}
            cohortId={cohortId}
            setSuccess={setSuccess}
          />
          <DeleteDialog
            open={deleteParamDialog}
            setOpen={setDeleteParamDialog}
            title="Delete Report Card Parameter"
            text="Are you sure to delete this parameter?"
            onSubmitAction={deleteParamAction}
          />
          {selectedCardParam && (
            <UpdateReportCardParameterDialog open={cardParamEditOpen} setOpen={setCardParamEditOpen} parameter={selectedCardParam} />
          )}
        </Box>
      )}
    </LayoutDashboard>
  );
};

export default ViewCohort;
