import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Dialog, Box, Typography, DialogContent, Button, Tooltip, styled, IconButton, Link } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { IBorescopeImageFileType, ICylinderAndSubject, IBorescopeImageSet } from 'types';
import { CylinderList } from './GuideMeComponents';
import { getSubjectLabel, uploadBorescopeImageDirectS3, convertTagNameToLabel } from 'helpers';
import { FileUploader } from 'react-drag-drop-files';
import { QUERY_ME_AIRCRAFT_BORESCOPE_IMAGE_SET_DETAIL } from 'gql';
import { useLazyQuery } from '@apollo/client';
import { setAlert } from 'state';
import { FLIGHT_FILE_MAX_SIZE, PATH_BETA_PLANS } from 'const';
import { ComponentLoading } from 'components';
import { ctrlCmdClick, joinOrString } from 'helpers';

interface GuideMeDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  uploadedFiles: IBorescopeImageFileType[];
  subjectList: string[][];
  cylinderCount: number;
  borescopeImageSet: IBorescopeImageSet;
  deleteBorescopeImageAction: (id: string) => void;
  requestAnalysis: () => void;
  eligibleViaPurchase: string[];
}

const classes = {
  dragDropUpload: 'dragDropUpload',
};

const StyledDiv = styled('div')({
  [`& .${classes.dragDropUpload}`]: {
    height: '240px !important',
    maxWidth: '1200px !important',
    width: '100%',
    border: '2px dashed #BBC0D4 !important',
    borderRadius: '6px !important',
    padding: 0,
    '& svg': {
      display: 'none',
    },
    '& div': {
      display: 'block',
      textAlign: 'center',
      '& span': {
        fontSize: '20px',
        fontWeight: 400,
        color: 'rgba(75, 84, 112, 0.5)',
        lineHeight: '27px',
        display: 'block',
        alignItems: 'start',
        justifyContent: 'center',
        '& span': {
          textDecoration: 'none',
          display: 'contents',
          whiteSpace: 'nowrap',
          marginRight: '5px',
        },
      },
    },
  },
});

const GuideMeDialog: React.FC<GuideMeDialogProps> = (props) => {
  const {
    open,
    setOpen,
    uploadedFiles,
    subjectList,
    cylinderCount,
    borescopeImageSet,
    deleteBorescopeImageAction,
    requestAnalysis,
    eligibleViaPurchase,
  } = props;
  const [fetchBorescopeImages] = useLazyQuery(QUERY_ME_AIRCRAFT_BORESCOPE_IMAGE_SET_DETAIL);
  const dispatch = useDispatch();
  const { id } = useParams();

  const navigate = useNavigate();

  const [selectedIndex, setSelectedIndex] = useState<ICylinderAndSubject>({
    cylinder: 1,
    subject: subjectList.length ? subjectList[0][0] : '',
  });

  const handleClose = () => {
    if (isUploading) {
      return;
    }
    setOpen(false);
  };

  const [isUploading, setIsUploading] = useState(false);

  const isFilledCylinderSubject = (cylinder: number, subject: string) => {
    const matched = uploadedFiles.find((file) => file.cylinder === cylinder && file.subject === subject);
    return Boolean(matched);
  };

  const getMatchedFile = (cylinder: number, subject: string) => {
    const matched = uploadedFiles.find((file) => file.cylinder === cylinder && file.subject === subject);
    return matched;
  };

  const handleChange = async (uploadedFile: File, cylinderNumber: number, subject: string) => {
    setIsUploading(true);
    const result = await uploadBorescopeImageDirectS3(uploadedFile, '', borescopeImageSet.id);
    if (result.success) {
      setTimeout(async () => {
        await fetchBorescopeImages({
          variables: {
            aircraftId: parseInt(id || ''),
            borescopeImageSetId: borescopeImageSet.id,
            completedOnly: true,
          },
          fetchPolicy: 'network-only',
        });
        setIsUploading(false);
      }, 5000);
    } else {
      setIsUploading(false);
      return;
    }
  };

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      PaperProps={{
        sx: {
          p: 0,
          maxWidth: '900px',
        },
      }}
    >
      <DialogContent
        sx={{
          p: 0,
        }}
      >
        {!!eligibleViaPurchase.length && (
          <Box
            sx={{
              backgroundColor: '#F7E8D3',
              width: '100%',
              px: 3,
              py: '14px',
            }}
          >
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '22px',
                color: 'warning.main',
                '& a': {
                  color: 'warning.main',
                  textDecoration: 'underline',
                },
              }}
            >
              To request analysis, {joinOrString(eligibleViaPurchase)} is required.{' '}
              <Link
                href={`${PATH_BETA_PLANS}/${id}`}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  ctrlCmdClick(e, `${PATH_BETA_PLANS}/${id}`, navigate);
                }}
                sx={{
                  fontWeight: 700,
                }}
              >
                Choose Your Plan
              </Link>
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <Box
            sx={{
              backgroundColor: 'grey.800',
              minWidth: '280px',
            }}
            py={2}
            pt={4}
          >
            <Typography
              sx={{
                fontSize: '24px',
                lineHeight: '32px',
                fontWeight: 700,
                color: 'text.secondary',
                px: 2,
              }}
              gutterBottom
            >
              Guide Me
            </Typography>
            <CylinderList
              cylinderCount={cylinderCount}
              subjectList={subjectList}
              selectedIndex={selectedIndex}
              setSelectedIndex={setSelectedIndex}
              uploadedFiles={uploadedFiles}
            />
          </Box>
          <Box
            sx={{
              flexGrow: 1,
            }}
          >
            <Box
              sx={{
                textAlign: 'right',
              }}
            >
              <Box
                sx={{
                  borderRadius: '6px',
                  backgroundColor: 'grey.800',
                  p: 1,
                  display: 'inline-flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={handleClose}
              >
                <Typography
                  sx={{
                    fontWeight: 500,
                    color: 'grey.600',
                  }}
                >
                  EXIT
                </Typography>
                <CloseIcon
                  sx={{
                    ml: 2,
                    color: 'grey.600',
                  }}
                />
              </Box>
            </Box>
            <Box px={3}>
              {!!selectedIndex.cylinder && (
                <Box
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Typography
                    sx={{
                      color: 'grey.600',
                    }}
                  >
                    Cylinder {selectedIndex.cylinder}
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography
                      sx={{
                        color: 'text.secondary',
                        fontWeight: 500,
                        fontSize: '20px',
                        lineHeight: '28px',
                        textTransform: 'capitalize',
                      }}
                    >
                      {getSubjectLabel(subjectList, selectedIndex.subject)}
                    </Typography>
                    <Tooltip
                      title={
                        <Box p={1}>
                          <Box
                            component="img"
                            src={`/images/borescope-examples/${convertTagNameToLabel(selectedIndex.subject)}.jpg`}
                            sx={{
                              width: '100%',
                            }}
                          />
                          <Box pt={1}>
                            <Typography
                              align="center"
                              sx={{
                                fontSize: '15px',
                                lineHeight: '21px',
                                color: 'text.primary',
                                textTransform: 'capitalize',
                              }}
                            >
                              {getSubjectLabel(subjectList, selectedIndex.subject)}
                            </Typography>
                          </Box>
                        </Box>
                      }
                      componentsProps={{
                        tooltip: {
                          sx: {
                            maxWidth: '420px',
                            backgroundColor: 'background.default',
                            borderRadius: '6px',
                            border: '1px solid',
                            borderColor: 'info.main',
                            boxShadow: '0px 1px 1px 0px #131E400F',
                          },
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: '600',
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                      >
                        Sample Image
                      </Typography>
                    </Tooltip>
                  </Box>
                  <Box py={2}>
                    {!isFilledCylinderSubject(selectedIndex.cylinder, selectedIndex.subject) && (
                      <ComponentLoading loading={isUploading}>
                        <StyledDiv
                          sx={{
                            width: '100%',
                          }}
                        >
                          <FileUploader
                            handleChange={async (files: File) => {
                              if (selectedIndex.cylinder && selectedIndex.subject) {
                                await handleChange(files, selectedIndex.cylinder, selectedIndex.subject);
                              }
                            }}
                            name={`borescopeImageUploader_${selectedIndex.cylinder}`}
                            label={`Click Here or Drag and Drop ${getSubjectLabel(subjectList, selectedIndex.subject)} for Cylinder ${
                              selectedIndex.cylinder
                            }`}
                            classes={[classes.dragDropUpload]}
                            maxSize={FLIGHT_FILE_MAX_SIZE}
                            onSizeError={() => {
                              dispatch(setAlert('error', 'File is over 100MB limit.'));
                            }}
                            multiple={false}
                          />
                        </StyledDiv>
                      </ComponentLoading>
                    )}
                    {isFilledCylinderSubject(selectedIndex.cylinder, selectedIndex.subject) && (
                      <Box>
                        <Box
                          component={'img'}
                          src={getMatchedFile(selectedIndex.cylinder, selectedIndex.subject)?.previewUrl || ''}
                          alt="borescope images"
                          sx={{
                            width: '100%',
                            objectFit: 'contain',
                            maxHeight: '500px',
                          }}
                        />
                        <Box
                          py={0.5}
                          sx={{
                            textAlign: 'right',
                          }}
                        >
                          <IconButton
                            onClick={async () => {
                              if (isFilledCylinderSubject(selectedIndex.cylinder, selectedIndex.subject)) {
                                await deleteBorescopeImageAction(getMatchedFile(selectedIndex.cylinder, selectedIndex.subject)?.id || '');
                              }
                            }}
                            title="Delete"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Box
          py={1}
          px={2}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            boxShadow: '0px 1px 15px 0px #2E313833',
            borderTop: '1px solid',
            borderColor: '#E4E6EC',
          }}
        >
          <Box>
            <Button
              variant="contained"
              onClick={() => {
                requestAnalysis();
              }}
              sx={{
                width: '240px',
              }}
            >
              {'Done & Request Analysis'}
            </Button>
          </Box>
          <Box>
            <Button
              variant="outlined"
              onClick={() => {
                const matchedSubjectIndex = subjectList.findIndex((item) => item[0] === selectedIndex.subject);
                if (matchedSubjectIndex === 0) {
                  if (cylinderCount > 1) {
                    setSelectedIndex({
                      cylinder: selectedIndex.cylinder - 1,
                      subject: subjectList[subjectList.length - 1][0],
                    });
                  }
                } else if (matchedSubjectIndex > 0) {
                  setSelectedIndex({
                    cylinder: selectedIndex.cylinder,
                    subject: subjectList[matchedSubjectIndex - 1][0],
                  });
                }
              }}
              disabled={
                !selectedIndex.cylinder ||
                !selectedIndex.subject ||
                (selectedIndex.cylinder === 1 && selectedIndex.subject === subjectList[0][0])
              }
              sx={{
                width: '140px',
              }}
            >
              Previous
            </Button>
            <Button
              variant="contained"
              sx={{
                width: '140px',
                ml: 1,
              }}
              onClick={() => {
                const matchedSubjectIndex = subjectList.findIndex((item) => item[0] === selectedIndex.subject);
                if (matchedSubjectIndex < subjectList.length - 1) {
                  setSelectedIndex({
                    cylinder: selectedIndex.cylinder,
                    subject: subjectList[matchedSubjectIndex + 1][0],
                  });
                } else if (matchedSubjectIndex === subjectList.length - 1) {
                  if (selectedIndex.cylinder < cylinderCount) {
                    setSelectedIndex({
                      cylinder: selectedIndex.cylinder + 1,
                      subject: subjectList[0][0],
                    });
                  }
                }
              }}
              disabled={selectedIndex.cylinder === cylinderCount && selectedIndex.subject === subjectList[subjectList.length - 1][0]}
            >
              {getMatchedFile(selectedIndex.cylinder, selectedIndex.subject) ? 'Next' : 'Skip'}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default GuideMeDialog;
