import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink as RouterLink } from 'react-router-dom';

import { Grid, Box, Button } from '@mui/material';

import { UserMenu, CollapsedMenu, UploadMenu } from '.';
import { default as HelpMenu } from './HelpMenu';

import { LOGGED_IN_LEFT_LINKS, LOGGED_IN_RIGHT_LINKS, PATH_TICKETS, PATH_SAVVY_TICKETS, PATH_FLIGHTS_UPLOAD } from 'const';

import { useXlUp } from 'hooks';

import { IAppBarLink } from 'types';

import { logAmplitudeEvent } from 'services';

import styles from './AppBarMenu/style.module.scss';

const LoggedInMenu: React.FC = () => {
  const role = useSelector((state: any) => {
    if (state.auth?.isClient) {
      return 'client';
    } else if (state.auth?.isSavvy) {
      return 'savvy';
    } else if (state.auth?.isTechnician) {
      return 'technician';
    } else {
      return '';
    }
  });

  const isHijacked = useSelector((state: any) => state.auth);

  const isXlUp = useXlUp();
  return (
    <Grid container justifyContent="space-between" alignItems="stretch" className="h-100">
      {/* Left */}
      {isXlUp && (
        <React.Fragment>
          <Grid item xs={4}>
            <Box
              pl={{
                xl: 5,
                lg: 3,
              }}
              sx={{
                height: '100%',
              }}
            >
              <Grid
                container
                className="h-100"
                columnSpacing={{
                  xl: 5,
                  lg: 3,
                }}
              >
                {LOGGED_IN_LEFT_LINKS.map((link: IAppBarLink) => (
                  <Grid item key={link.path}>
                    {!link.legacy && (
                      <RouterLink
                        to={link.path === PATH_TICKETS && role === 'savvy' && !isHijacked ? PATH_SAVVY_TICKETS : link.path}
                        className={({ isActive }) => (isActive ? `${styles.appBarLink} ${styles.appBarLinkActive}` : styles.appBarLink)}
                        end={link.exact}
                        onClick={() => {
                          logAmplitudeEvent('menu_' + link.text.replace(' ', '_').toLowerCase(), {
                            role,
                          });
                        }}
                      >
                        <Button color="inherit" className={styles.appBarLinkButton}>
                          {link.startIcon && (
                            <Box className={styles.icon} display="inherit" color="grey.400" mr={1}>
                              {link.startIcon}
                            </Box>
                          )}
                          {link.text}
                          {link.endIcon && (
                            <Box className={styles.icon} display="inherit" color="grey.400" ml={1}>
                              {link.endIcon}
                            </Box>
                          )}
                        </Button>
                      </RouterLink>
                    )}
                    {link.legacy && (
                      <a href={link.path} className={`${styles.appBarLink}`}>
                        <Button color="inherit" className={styles.appBarLinkButton}>
                          {link.startIcon && (
                            <Box className={styles.icon} display="inherit" color="grey.400" mr={1}>
                              {link.startIcon}
                            </Box>
                          )}
                          {link.text}
                          {link.endIcon && (
                            <Box className={styles.icon} display="inherit" color="grey.400" ml={1}>
                              {link.endIcon}
                            </Box>
                          )}
                        </Button>
                      </a>
                    )}
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
          {/* Right */}
          <Grid item xs={8}>
            <Grid
              container
              className="h-100"
              columnSpacing={{
                xl: 2.5,
                lg: 1,
              }}
              justifyContent="flex-end"
            >
              {LOGGED_IN_RIGHT_LINKS.map((link: IAppBarLink) => {
                if (link.path === PATH_FLIGHTS_UPLOAD) {
                  return null;
                }
                return (
                  <Grid item key={link.path}>
                    {!link.legacy && (
                      <RouterLink
                        to={link.path}
                        className={({ isActive }) => (isActive ? `${styles.appBarLink} ${styles.appBarLinkActive}` : styles.appBarLink)}
                        end={link.exact}
                        onClick={() => {
                          logAmplitudeEvent('menu_' + link.text.replace(' ', '_').toLowerCase(), {
                            role,
                          });
                        }}
                      >
                        <Button color="inherit" className={styles.appBarLinkButton}>
                          {link.startIcon && (
                            <Box className={styles.icon} display="inherit" color="grey.400" mr={1}>
                              {link.startIcon}
                            </Box>
                          )}
                          {link.text}
                          {link.endIcon && (
                            <Box className={styles.icon} display="inherit" color="grey.400" ml={1}>
                              {link.endIcon}
                            </Box>
                          )}
                        </Button>
                      </RouterLink>
                    )}
                    {link.legacy && (
                      <a href={link.path} className={`${styles.appBarLink}`}>
                        <Button color="inherit" className={styles.appBarLinkButton}>
                          {link.startIcon && (
                            <Box className={styles.icon} display="inherit" color="grey.400" mr={1}>
                              {link.startIcon}
                            </Box>
                          )}
                          {link.text}
                          {link.endIcon && (
                            <Box className={styles.icon} display="inherit" color="grey.400" ml={1}>
                              {link.endIcon}
                            </Box>
                          )}
                        </Button>
                      </a>
                    )}
                  </Grid>
                );
              })}
              <Grid item>
                <UploadMenu />
              </Grid>
              <Grid item>
                <UserMenu role={role} />
              </Grid>
              <Grid item>
                <HelpMenu />
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
      {!isXlUp && (
        <React.Fragment>
          <Grid item xs>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                height: '100%',
              }}
            >
              <CollapsedMenu role={role} />
            </Box>
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  );
};

export default LoggedInMenu;
