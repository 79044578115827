import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box, Typography, Container, TextField, MenuItem } from '@mui/material';
import { LayoutDashboard, PageLoading, Subsection, Section } from 'components';

import { QUERY_USER_ONLY_AIRCRAFTS } from 'gql';
import { useQuery } from '@apollo/client';

import { SAVVY_QA, PATH_BETA_PLANS, PATH_AIRCRAFT_CREATE, PATH_ADD_QA_PLAN, PATH_UPGRADE_QA_PLAN } from 'const';
import { getServiceName, aircraftRegistrationMakeModel } from 'helpers';
import { setAlert } from 'state';

const PURCHASE_NEW = 'purchase-new';
const ADD_NEW = 'add';
const UPGRADE = 'upgrade';

const UpgradeQa: React.FC = () => {
  const serviceCode = SAVVY_QA;
  const location = useLocation();

  const [selectedAircraftId, setSelectedAircraftId] = useState('');

  const { data: dataAircraft, loading: loadingAircraft, error: errorAircraft } = useQuery(QUERY_USER_ONLY_AIRCRAFTS);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  console.log(location);

  useEffect(() => {
    if (dataAircraft?.me?.aircraft?.length) {
      if (dataAircraft.me.aircraft.length === 1) {
        // setSelectedAircraftId(dataAircraft.me.aircraft[0].id);
        let isActiveService = false;

        for (const subscription of dataAircraft.me.aircraft[0].subscriptions) {
          if (subscription.status.toLowerCase() === 'active') {
            isActiveService = true;
          }
        }

        if (location.pathname === PATH_ADD_QA_PLAN) {
          if (!isActiveService) {
            navigate(`${PATH_BETA_PLANS}/${dataAircraft.me.aircraft[0].id}/${PURCHASE_NEW}/${serviceCode}`);
          } else {
            navigate(`${PATH_BETA_PLANS}/${dataAircraft.me.aircraft[0].id}/${ADD_NEW}/${serviceCode}`);
          }
        } else if (location.pathname === PATH_UPGRADE_QA_PLAN) {
          navigate(`${PATH_BETA_PLANS}/${dataAircraft.me.aircraft[0].id}/${UPGRADE}/${serviceCode}`);
        }
      }
    } else if (dataAircraft?.me) {
      navigate(PATH_AIRCRAFT_CREATE);
    }
  }, [dataAircraft, navigate, location]);

  useEffect(() => {
    if (selectedAircraftId) {
      // navigate(`${PATH_BETA_PLANS}/${selectedAircraftId}/${ADD_PRO}/${serviceCode}`);
      for (const aircraft of dataAircraft.me.aircraft) {
        let isActiveService = false;

        for (const subscription of aircraft.subscriptions) {
          if (subscription.status.toLowerCase() === 'active') {
            isActiveService = true;
          }
        }

        if (location.pathname === PATH_ADD_QA_PLAN) {
          if (!isActiveService) {
            navigate(`${PATH_BETA_PLANS}/${selectedAircraftId}/${PURCHASE_NEW}/${serviceCode}`);
          } else {
            navigate(`${PATH_BETA_PLANS}/${selectedAircraftId}/${ADD_NEW}/${serviceCode}`);
          }
        } else {
          navigate(`${PATH_BETA_PLANS}/${selectedAircraftId}/${UPGRADE}/${serviceCode}`);
        }
      }
    }
  }, [selectedAircraftId, navigate, dataAircraft, location]);

  useEffect(() => {
    if (errorAircraft) {
      dispatch(setAlert('error', 'Unable to load your aircraft'));
    }
  }, [errorAircraft, dispatch]);

  return (
    <LayoutDashboard backgroundColor="#fff">
      <Box
        width="100%"
        sx={{
          pl: {
            xs: 2,
            sm: 2,
            md: 2,
          },
          pr: {
            xs: 2,
            sm: 2,
            md: 2,
          },
        }}
      >
        {loadingAircraft && <PageLoading />}
        <Section>
          <Container>
            <Subsection>
              <Typography variant="h1" component="h2" gutterBottom>
                {getServiceName(serviceCode)}
              </Typography>
            </Subsection>
            <Box py={1}>
              {!!dataAircraft?.me?.aircraft?.length && (
                <TextField
                  name="aircraft"
                  label="Select Aircraft *"
                  value={selectedAircraftId}
                  onChange={(e) => {
                    setSelectedAircraftId(e.target.value);
                  }}
                  sx={{
                    mb: 0,
                    maxWidth: '400px',
                    mx: 'auto',
                  }}
                  data-testid="aircraft-selector"
                  fullWidth
                  select
                >
                  {dataAircraft.me.aircraft.map((item: any, key: number) => {
                    return (
                      <MenuItem key={key} value={item.id}>
                        {aircraftRegistrationMakeModel(item)}
                      </MenuItem>
                    );
                  })}
                </TextField>
              )}
            </Box>
          </Container>
        </Section>
      </Box>
    </LayoutDashboard>
  );
};

export default UpgradeQa;
