import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dialog, Box, DialogTitle, DialogContent, TextField, DialogActions, Button, Grid } from '@mui/material';
import { QUERY_REQUEST_COI_TEMPLATE_TEXT, MUTATION_REQUEST_CERTIFICATE_OF_INSURANCE } from 'gql';
import { useLazyQuery, useMutation } from '@apollo/client';

import { ComponentLoading } from 'components';
import { PATH_SAVVY_TICKETS } from 'const';

import { setAlert } from 'state';

interface RequestCoiDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  aircraftId: string;
  registration: string;
}

const RequestCoiDialog: React.FC<RequestCoiDialogProps> = (props) => {
  const { open, setOpen, aircraftId, registration } = props;

  const [fetchTemplateText, { data: dataText, loading: loadingText, error: errorText }] = useLazyQuery(QUERY_REQUEST_COI_TEMPLATE_TEXT);
  const [requestCoi, { data: dataCoi, loading: loadingCoi, error: errorCoi }] = useMutation(MUTATION_REQUEST_CERTIFICATE_OF_INSURANCE);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [postBody, setPostBody] = useState('');

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    await requestCoi({
      variables: {
        aircraftId,
        postBody,
      },
    });
  };

  useEffect(() => {
    if (open) {
      fetchTemplateText({
        variables: {
          aircraftId,
        },
      });
    }
  }, [open, aircraftId, fetchTemplateText]);

  useEffect(() => {
    if (errorText) {
      dispatch(setAlert('error', 'Unable to load coi template text'));
    } else if (open && dataText) {
      if (dataText.requestCoiTemplateText) {
        setPostBody(dataText.requestCoiTemplateText);
      }
    }
  }, [errorText, dataText, dispatch, open]);

  useEffect(() => {
    if (errorCoi) {
      dispatch(setAlert('error', 'Unable to request certificate of insurance'));
    } else if (dataCoi) {
      if (dataCoi?.requestCertificateOfInsurance?.ok) {
        dispatch(setAlert('success', 'Requested certificate of insurance'));
        navigate(`${PATH_SAVVY_TICKETS}/${dataCoi?.requestCertificateOfInsurance?.ticket?.id}`);
      } else {
        dispatch(setAlert('error', dataCoi?.requestCertificateOfInsurance?.error || 'Unable to request certificate of insurance'));
      }
    }
  }, [dataCoi, errorCoi, dispatch, navigate]);

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: {
            md: '600px',
            xs: 'calc(100% - 50px)',
          },
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontSize: '24px',
          fontWeight: 700,
        }}
      >
        Request COI for {registration}
      </DialogTitle>
      <DialogContent>
        <ComponentLoading loading={loadingText || loadingCoi}>
          <Box py={1}>
            <TextField
              label="Post Message"
              name="message"
              multiline
              minRows={5}
              fullWidth
              value={postBody}
              onChange={(e) => {
                setPostBody(e.target.value);
              }}
              id="message"
              placeholder="Type your message here..."
              sx={{
                mb: 0,
              }}
            />
          </Box>
        </ComponentLoading>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button variant="outlined" onClick={handleClose} disabled={loadingText || loadingCoi} fullWidth>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" onClick={onSubmit} disabled={loadingText || loadingCoi} fullWidth>
              Submit
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default RequestCoiDialog;
