import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, Typography, FormControlLabel, Radio, RadioGroup, FormControl, Tooltip, CSSObject } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

import { ComponentLoading } from 'components';

import { useMutation, useLazyQuery } from '@apollo/client';
import { MUTATION_DELETE_FLIGHTS, MUTATION_UNDELETE_FLIGHTS, QUERY_AIRCRAFT_RECENT_UPLOADS } from 'gql';
import { setAlert } from 'state';

import { getFewDaysAgo } from 'helpers';

import { default as UploadFlightsTable } from './UploadFlightsTable';

interface RecentUploadsProps {
  fromTime: Date;
  setFromTime: (value: Date) => void;
}

const tooltipSx: CSSObject = {
  backgroundColor: 'background.default',
  border: '1px solid',
  borderColor: 'grey.200',
  boxShadow: '0px 1px 1px rgba(19, 30, 64, 0.06)',
  borderRadius: '6px !important',
  '& p': {
    color: 'text.primary',
  },
  color: 'text.primary',
};

const LABEL_24_HOURS_AGO = 'LABEL_24_HOURS_AGO';
const A_WEEK_AGO = 'A_WEEK_AGO';

const RecentUploads: React.FC<RecentUploadsProps> = (props) => {
  const { fromTime, setFromTime } = props;

  const [fromTimeLabel, setFromTimeLabel] = useState(LABEL_24_HOURS_AGO);

  const dispatch = useDispatch();
  const { id } = useParams();

  const [recentUploads, { data: dataRecentUploads, loading: loadingRecentUploads, error: errorRecentUploads }] = useLazyQuery(
    QUERY_AIRCRAFT_RECENT_UPLOADS,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  const [deleteFlights, { data: dataDelete, loading: loadingDelete, error: errorDelete }] = useMutation(MUTATION_DELETE_FLIGHTS);
  const [undeleteFlights, { data: dataUndelete, loading: loadingUndelete, error: errorUndelete }] = useMutation(MUTATION_UNDELETE_FLIGHTS);

  const deleteAction = async (flightId: string) => {
    await deleteFlights({
      variables: {
        flightIds: [flightId],
      },
    });
  };

  const restoreAction = async (flightId: string) => {
    await undeleteFlights({
      variables: {
        flightIds: [flightId],
      },
    });
  };

  useEffect(() => {
    if (errorRecentUploads) {
      dispatch(setAlert('error', 'Unable to load recent uploads'));
    }
  }, [errorRecentUploads, dispatch]);

  useEffect(() => {
    if (id && fromTime) {
      recentUploads({
        variables: {
          id: parseInt(id),
          fromTime,
        },
      });
    }
  }, [id, fromTime]);

  useEffect(() => {
    if (fromTimeLabel === LABEL_24_HOURS_AGO) {
      setFromTime(getFewDaysAgo(1));
    } else {
      setFromTime(getFewDaysAgo(7));
    }
  }, [fromTimeLabel]);

  const refetchRecentUploads = useCallback(() => {
    recentUploads({
      variables: {
        id: parseInt(id || ''),
        fromTime,
      },
    });
  }, [id, fromTime]);

  useEffect(() => {
    if (errorDelete) {
      dispatch(setAlert('error', 'Unable to delete flight'));
    } else if (dataDelete) {
      if (dataDelete.deleteFlights?.ok) {
        dispatch(setAlert('success', 'Deleted flight'));
        refetchRecentUploads();
      } else {
        dispatch(setAlert('error', dataDelete.deleteFlights?.error || 'Unable to delete flight'));
      }
    }
  }, [errorDelete, dataDelete, dispatch]);

  useEffect(() => {
    if (errorUndelete) {
      dispatch(setAlert('error', 'Unable to restore flight'));
    } else if (dataUndelete) {
      if (dataUndelete.undeleteFlights?.ok) {
        dispatch(setAlert('success', 'Restored flight'));
        refetchRecentUploads();
      } else {
        dispatch(setAlert('error', dataUndelete.undeleteFlights?.error || 'Unable to restore flight'));
      }
    }
  }, [errorUndelete, dataUndelete, dispatch]);

  return (
    <ComponentLoading loading={loadingRecentUploads || loadingDelete || loadingUndelete}>
      <Box
        sx={{
          height: '100%',
          overflowY: 'auto',
          backgroundColor: 'background.default',
          borderLeft: '1px solid',
          borderColor: 'grey.200',
        }}
        py={2}
      >
        <Typography
          variant="h2"
          sx={{
            fontWeight: 700,
          }}
          align="center"
        >
          Recent Flights
        </Typography>
        <FormControl
          sx={{
            p: 1,
            display: 'block',
          }}
        >
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={fromTimeLabel}
            onChange={(e) => {
              setFromTimeLabel(e.target.value);
            }}
            sx={{
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <FormControlLabel value={LABEL_24_HOURS_AGO} control={<Radio />} label="24 hours ago" />
            <FormControlLabel value={A_WEEK_AGO} control={<Radio />} label="A week ago" />
          </RadioGroup>
        </FormControl>
        {(Boolean(dataRecentUploads?.me?.aircraft[0]?.recentUploads?.accepted?.length) ||
          Boolean(dataRecentUploads?.me?.aircraft[0]?.recentUploads?.rejected?.length)) && (
          <Box>
            <Box
              px={3}
              py={3}
              sx={{
                backgroundColor: 'background.paper',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    fontWeight: 700,
                  }}
                >
                  Accepted
                </Typography>
                <Tooltip
                  title="Flights have been auto-saved. If they appear incorrect, you can delete them."
                  enterTouchDelay={100}
                  componentsProps={{
                    tooltip: {
                      sx: tooltipSx,
                    },
                  }}
                >
                  <HelpIcon
                    sx={{
                      ml: 1.5,
                    }}
                  />
                </Tooltip>
              </Box>
              <UploadFlightsTable
                flights={dataRecentUploads?.me?.aircraft[0]?.recentUploads?.accepted || []}
                action={deleteAction}
                label="Delete"
              />
            </Box>
            <Box
              px={3}
              py={3}
              sx={{
                backgroundColor: 'background.paper',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    fontWeight: 700,
                  }}
                >
                  Rejected
                </Typography>
                <Tooltip
                  title="Flights automatically rejected, typically due to duplication or insufficient flight duration."
                  enterTouchDelay={100}
                  componentsProps={{
                    tooltip: {
                      sx: tooltipSx,
                    },
                  }}
                >
                  <HelpIcon
                    sx={{
                      ml: 1.5,
                    }}
                  />
                </Tooltip>
              </Box>
              <UploadFlightsTable
                flights={dataRecentUploads?.me?.aircraft[0]?.recentUploads?.rejected || []}
                action={restoreAction}
                label="Restore"
              />
            </Box>
          </Box>
        )}
        {!dataRecentUploads?.me?.aircraft[0]?.recentUploads?.accepted?.length &&
          !dataRecentUploads?.me?.aircraft[0]?.recentUploads?.rejected?.length && (
            <Box py={1}>
              <Typography align="center">No recent uploads</Typography>
            </Box>
          )}
      </Box>
    </ComponentLoading>
  );
};

export default RecentUploads;
