import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Grid, Typography, Button } from '@mui/material';
import { FileUploadWithAircraft, RecentUploads, SpecificFileFlights } from './components';
import { PageLoading, LayoutDashboard } from 'components';

import { PATH_FLIGHTS_UPLOAD, PATH_AIRCRAFT_CREATE } from 'const';

import { QUERY_USER_AIRCRAFTS_WITHOUT_SUBSCRIPTION } from 'gql';
import { useQuery } from '@apollo/client';

import { setAlert, storeSelectedAircraft } from 'state';
import { getFewDaysAgo } from 'helpers';

const FileUploads: React.FC = () => {
  const { data: aircraftData, loading, error } = useQuery(QUERY_USER_AIRCRAFTS_WITHOUT_SUBSCRIPTION);

  const [fromTime, setFromTime] = useState<Date>(getFewDaysAgo(1));

  const { storedAircraftId } = useSelector((state: any) => state.auth);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { id } = useParams();

  const [engineDataFileId, setEngineDataFileId] = useState('');

  useEffect(() => {
    if (!id && aircraftData?.me?.aircraft?.length) {
      if (storedAircraftId) {
        navigate(`${PATH_FLIGHTS_UPLOAD}/${storedAircraftId}`);
        return;
      }

      let existingDefault = false;

      for (let i = 0; i < aircraftData?.me?.aircraft.length; i++) {
        if (aircraftData.me.aircraft[i].default) {
          navigate(`${PATH_FLIGHTS_UPLOAD}/${aircraftData.me.aircraft[i].id}`);
          dispatch(storeSelectedAircraft(aircraftData.me.aircraft[i].id));
          existingDefault = true;
        }
      }

      if (!existingDefault) {
        dispatch(storeSelectedAircraft(aircraftData.me.aircraft[0].id));
        navigate(`${PATH_FLIGHTS_UPLOAD}/${aircraftData.me.aircraft[0].id}`);
      }
    }
  }, [id, aircraftData, storedAircraftId, navigate, dispatch]);

  useEffect(() => {
    if (error) {
      dispatch(setAlert('error', 'Unable to load aircraft data'));
    }
  }, [error, dispatch]);

  return (
    <LayoutDashboard backgroundColor="#FFFFFF">
      <Box>
        {loading && <PageLoading />}
        {!aircraftData?.me?.aircraft?.length && (
          <Box
            py={1}
            sx={{
              textAlign: 'center',
            }}
          >
            <Typography
              sx={{
                fontSize: '20px',
              }}
              gutterBottom
            >
              You have no aircraft.
            </Typography>
            <Button
              variant="contained"
              onClick={() => {
                navigate(PATH_AIRCRAFT_CREATE);
              }}
            >
              Add Aircraft...
            </Button>
          </Box>
        )}
        <Grid container spacing={2}>
          <Grid item xl={6} lg={6} md={12} xs={12}>
            {aircraftData && (
              <FileUploadWithAircraft
                aircrafts={aircraftData.me?.aircraft || []}
                fromTime={fromTime}
                setEngineDataFileId={setEngineDataFileId}
              />
            )}
          </Grid>
          <Grid item xl={6} lg={6} md={12} xs={12}>
            <Box>
              <RecentUploads fromTime={fromTime} setFromTime={setFromTime} />
              <SpecificFileFlights engineDataFileId={engineDataFileId} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </LayoutDashboard>
  );
};

export default FileUploads;
