import { Navigate } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { PageLoading } from 'components';
import { NotFound } from 'pages';

import { PATH_LOGIN, PATH_SECURITY_CHECK } from 'const';

interface IPrivateRoute {
  element: any;
  path: string;
  exact?: boolean;
}

const PrivateTechRoute = (props: IPrivateRoute) => {
  const { element: Component } = props;
  const { loggedIn, incompleteAircraft, isTechnician, missingToken } = useSelector((state: any) => {
    return state.auth;
  }); // Get auth state (Redux)

  // If loading
  if (loggedIn === null) return <PageLoading />;

  if (missingToken) {
    return <Navigate to={PATH_SECURITY_CHECK} replace />;
  }

  // NOT logged in
  if (loggedIn !== null && !loggedIn) {
    // Get next prop from url
    const next = window.location.pathname;

    // Redirect to login
    return <Navigate to={PATH_LOGIN} state={{ next }} />;
  }

  if (loggedIn && incompleteAircraft?.length > 0) {
    if (incompleteAircraft[0].subscriptions?.length > 0) {
      return <Navigate to={`/signup/${incompleteAircraft[0].subscriptions[0].serviceCode}/additional-info`} />;
    }
  }

  // If logged IN - proceed to page
  return isTechnician ? Component : <NotFound />;
};

export default PrivateTechRoute;
