import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Link, IconButton, TextField, Popover, List, ListItem } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';

import { IFlightData, ISubscription, IComment, IFlightChartSetting, IAircraftSetting } from 'types';
import { humanDate, getCylinderNumber, copyClipboard, downloadFileInNewTab } from 'helpers';
import { PATH_AIRCRAFT_VIEW, PATH_SHARED_FLIGHT_VIEW, PATH_SAVVY_AIRCRAFT } from 'const';
import { setAlert } from 'state';

import { ChartNumSettings, ChartSizeSettings } from './SettingComponents';
import { default as FlightMainInfo } from './FlightMainInfo';
import { AddScaleDialog, EditScaleDialog } from './components';
import { ComponentLoading, DeleteDialog } from 'components';

import { MUTATION_ENABLE_DISABLE_CHART_SCALE_SETTING, MUTATION_DELETE_AIRCRAFT_CHART_SCALE_SETTING, QUERY_EDF_DOWNLOAD_URL } from 'gql';
import { useMutation } from '@apollo/client';
import { apolloClient } from 'services';

interface InformationTabProps {
  flightData: IFlightData;
  chartNum: number;
  setChartNum: (value: number) => void;
  isShare?: boolean;
  aircraftComments?: IComment[];
  contactComments?: IComment[];
  settings: IFlightChartSetting | undefined;
  underAnalysis?: boolean;
  aircraftSettings: IAircraftSetting[];
}

const PARSER_LINK = '/files/:fileId/log';

const InformationTab: React.FC<InformationTabProps> = (props) => {
  const {
    flightData,
    chartNum,
    setChartNum,
    isShare = false,
    aircraftComments = [],
    contactComments = [],
    settings,
    underAnalysis = false,
    aircraftSettings,
  } = props;

  const subscriptions = flightData.aircraft.subscriptions
    ?.filter((subscription: ISubscription) => subscription.status === 'Active')
    .map((subscription: ISubscription) => {
      return subscription.serviceName;
    });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isSavvy } = useSelector((state: any) => state.auth);

  const [openScaleDialog, setOpenScaleDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedSetting, setSelectedSetting] = useState<IAircraftSetting>();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);

  const [enableChartScale, { data: dataScale, error: errorScale, loading: loadingScale }] = useMutation(
    MUTATION_ENABLE_DISABLE_CHART_SCALE_SETTING,
  );

  const [deleteChartScale, { data: dataDeleteChartScale, error: errorDeleteChartScale, loading: loadingDeleteChartScale }] = useMutation(
    MUTATION_DELETE_AIRCRAFT_CHART_SCALE_SETTING,
  );
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const InfoLine = (props: { title: string; value: string; link?: string; isSubscription?: boolean }) => {
    const { title, value, link = '', isSubscription = false } = props;

    return (
      <Box
        sx={{
          display: 'flex',
          py: 1,
        }}
      >
        <Typography
          sx={{
            width: '40%',
            color: 'grey.600',
            fontWeight: 600,
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            width: '60%',
            color: isSubscription ? 'success.dark' : 'text.secondary',
            fontSize: '14px',
            lineHeight: '19px',
          }}
        >
          {value}
          {Boolean(link) && (
            <React.Fragment>
              <br />
              <Link href={PARSER_LINK.replace(':fileId', link)} target="_blank">
                View Parser Logs
              </Link>
            </React.Fragment>
          )}
        </Typography>
      </Box>
    );
  };

  const InfoComments = (props: { title: string; comments: IComment[] }) => {
    const { title, comments } = props;

    const pinnedComments = comments?.filter((comment: IComment) => comment.pinned);

    if (!pinnedComments.length) return null;

    return (
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Typography
          sx={{
            width: '40%',
            color: 'grey.600',
            fontWeight: 600,
            py: 1,
          }}
        >
          {title}
        </Typography>
        <Box
          sx={{
            width: '60%',
          }}
        >
          {pinnedComments.map((item: IComment, key: number) => {
            return (
              <Box py={1} key={key}>
                <Typography
                  sx={{
                    fontSize: '14px',
                    lineHeight: '19px',
                    color: 'text.secondary',
                  }}
                >
                  {item.comment}, {humanDate(item.date)}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    );
  };

  const copySharedUrl = (e: any) => {
    if (document.location?.origin) {
      e.preventDefault();
      e.stopPropagation();
      copyClipboard(`${document.location.origin}${PATH_SHARED_FLIGHT_VIEW}/${flightData.id}/${flightData.publicUuid}`);
      dispatch(setAlert('success', 'Shareable flight link copied to clipboard'));
    }
  };

  const deleteAction = async () => {
    if (!selectedSetting) return;
    await deleteChartScale({
      variables: {
        aircraftChartScaleSettingsId: selectedSetting.id,
      },
    });
  };

  const [loadingDownloadUrl, setLoadingDownloadUrl] = useState(false);

  const downloadEdf = async (fileId: string) => {
    if (fileId && parseInt(fileId)) {
      setLoadingDownloadUrl(true);
      const { data: dataDownloadUrl } = await apolloClient.query({
        query: QUERY_EDF_DOWNLOAD_URL,
        variables: {
          fileId: parseInt(fileId),
        },
        fetchPolicy: 'no-cache',
      });
      setLoadingDownloadUrl(false);
      if (dataDownloadUrl?.edfDownloadUrl) {
        downloadFileInNewTab(dataDownloadUrl.edfDownloadUrl);
      }
    }
  };

  useEffect(() => {
    if (errorScale) {
      dispatch(setAlert('error', "Unable to change chart scale setting's status"));
    } else if (dataScale) {
      if (dataScale.updateAircraftChartScaleSettings?.ok) {
        dispatch(setAlert('success', "Changed chart scale setting's status"));
      } else {
        dispatch(setAlert('error', dataScale.updateAircraftChartScaleSettings?.error || "Unable to change chart scale setting's status"));
      }
    }
  }, [dataScale, errorScale, dispatch]);

  useEffect(() => {
    if (errorDeleteChartScale) {
      dispatch(setAlert('error', 'Unable to delete chart scale setting'));
    } else if (dataDeleteChartScale) {
      if (dataDeleteChartScale.deleteAircraftChartScaleSettings?.ok) {
        dispatch(setAlert('success', 'Deleted chart scale setting'));
      } else {
        dispatch(setAlert('error', dataDeleteChartScale.deleteAircraftChartScaleSettings?.error || 'Unable to delete chart scale setting'));
      }
    }
  }, [dataDeleteChartScale, errorDeleteChartScale, dispatch]);

  return (
    <Box>
      <FlightMainInfo flightData={flightData} isShare={isShare} smallPadding={underAnalysis} />
      {!underAnalysis && (
        <Box
          py={1}
          sx={{
            backgroundColor: 'grey.800',
            pl: {
              xl: 5,
              xs: 2,
            },
          }}
        >
          <Typography
            sx={{
              color: 'text.secondary',
              fontSize: '16px',
              lineHeight: '22px',
              fontWeight: 'bold',
            }}
          >
            Share Flight Link
          </Typography>
        </Box>
      )}
      {!underAnalysis && (
        <Box
          py={1}
          sx={{
            pl: {
              xl: underAnalysis ? 2 : 5,
              xs: 2,
            },
            pr: 1,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <TextField
            value={`${document.location.origin}${PATH_SHARED_FLIGHT_VIEW}/${flightData.id}/${flightData.publicUuid}`}
            InputProps={{
              readOnly: true,
            }}
            sx={{
              mb: 0,
            }}
            fullWidth
            disabled={true}
          />
          <IconButton
            sx={{
              backgroundColor: 'grey.800',
              borderRadius: '6px',
              ml: '10px',
            }}
            onClick={copySharedUrl}
          >
            <ContentCopyIcon />
          </IconButton>
        </Box>
      )}
      <Box
        py={1}
        sx={{
          backgroundColor: 'grey.800',
          pl: {
            xl: underAnalysis ? 2 : 5,
            xs: 2,
          },
        }}
      >
        <Typography
          sx={{
            color: 'text.secondary',
            fontSize: '16px',
            lineHeight: '22px',
            fontWeight: 'bold',
          }}
        >
          Aircraft
        </Typography>
      </Box>
      <Box
        py={1}
        sx={{
          pl: {
            xl: underAnalysis ? 2 : 5,
            xs: 2,
          },
        }}
      >
        {flightData.aircraft.aircraftManufacturer?.name && flightData.aircraft.aircraftModel?.name && (
          <InfoLine title="Aircraft" value={`${flightData.aircraft.aircraftManufacturer.name} ${flightData.aircraft.aircraftModel.name}`} />
        )}
        {flightData.aircraft.serial && flightData.aircraft.year && (
          <InfoLine title="Serial / Year" value={`${flightData.aircraft.serial} / ${flightData.aircraft.year}`} />
        )}
        {flightData.aircraft.registration && (
          <Box
            sx={{
              display: 'flex',
              py: 1,
            }}
          >
            <Typography
              sx={{
                width: '40%',
                color: 'grey.600',
                fontWeight: 600,
              }}
            >
              Registration
            </Typography>
            {!isShare && (
              <Link
                sx={{
                  width: '60%',
                  fontSize: '14px',
                  lineHeight: '19px',
                }}
                href={isSavvy ? `${PATH_SAVVY_AIRCRAFT}/${flightData.aircraft.id}` : `${PATH_AIRCRAFT_VIEW}/${flightData.aircraft.id}`}
                onClick={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  navigate(
                    isSavvy ? `${PATH_SAVVY_AIRCRAFT}/${flightData.aircraft.id}` : `${PATH_AIRCRAFT_VIEW}/${flightData.aircraft.id}`,
                  );
                }}
              >
                {flightData.aircraft.registration}
              </Link>
            )}
            {isShare && (
              <Typography
                sx={{
                  width: '60%',
                  color: 'text.secondary',
                  fontSize: '14px',
                  lineHeight: '19px',
                }}
              >
                {flightData.aircraft.registration}
              </Typography>
            )}
          </Box>
        )}
        {flightData.aircraft.engineManufacturer?.name && flightData.aircraft.engineModel?.name && (
          <InfoLine title="Engine" value={`${flightData.aircraft.engineManufacturer.name} ${flightData.aircraft.engineModel.name}`} />
        )}
        {flightData?.aircraft?.engineMonitorManufacturer?.name && (
          <InfoLine
            title="Engine Monitor"
            value={
              `${flightData.aircraft.engineMonitorManufacturer?.name}` +
              (flightData?.aircraft?.engineMonitorModel && flightData?.aircraft?.engineMonitorModel?.name)
                ? ` ${flightData?.aircraft?.engineMonitorModel?.name}`
                : ''
            }
          />
        )}
        {flightData.aircraft.cylinderCount && (
          <InfoLine title="Cylinder Count" value={`${getCylinderNumber(flightData.aircraft.cylinderCount)}`} />
        )}
        {flightData.aircraft.chtWarningTemperature && (
          <InfoLine title="CHT Warning" value={`${flightData.aircraft.chtWarningTemperature}°`} />
        )}
        {subscriptions && subscriptions.length !== 0 && (
          <InfoLine title="Subscription" value={subscriptions.join(', ')} isSubscription={true} />
        )}
        {!!aircraftComments?.length && <InfoComments title="Aircraft Notes" comments={aircraftComments} />}
        {!!contactComments?.length && <InfoComments title="Client Notes" comments={contactComments} />}
      </Box>
      {flightData.importFile && (
        <Box
          py={1}
          sx={{
            backgroundColor: 'grey.800',
            pl: {
              xl: underAnalysis ? 2 : 5,
              xs: 2,
            },
          }}
        >
          <Typography
            sx={{
              color: 'text.secondary',
              fontSize: '16px',
              lineHeight: '22px',
              fontWeight: 'bold',
            }}
          >
            File
          </Typography>
        </Box>
      )}
      {flightData.importFile && (
        <ComponentLoading loading={loadingDownloadUrl}>
          <Box
            py={1}
            sx={{
              pl: {
                xl: underAnalysis ? 2 : 5,
                xs: 2,
              },
            }}
          >
            {!flightData.canDownloadEdf && <InfoLine title="Filename" value={flightData.importFile.name} />}
            {flightData.canDownloadEdf && (
              <Box
                sx={{
                  display: 'flex',
                  py: 1,
                }}
              >
                <Typography
                  sx={{
                    width: '40%',
                    color: 'grey.600',
                    fontWeight: 600,
                  }}
                >
                  Filename
                </Typography>
                <Link
                  sx={{
                    width: '60%',
                    fontSize: '14px',
                    lineHeight: '19px',
                  }}
                  href="#"
                  onClick={(e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                    downloadEdf(flightData.importFile.id);
                  }}
                >
                  {flightData.importFile.name}
                </Link>
              </Box>
            )}
            <InfoLine title="Upload Date" value={humanDate(flightData.importFile.uploadDate)} />
          </Box>
        </ComponentLoading>
      )}
      {!underAnalysis && (
        <Box
          py={1}
          sx={{
            backgroundColor: 'grey.800',
            pl: {
              xl: underAnalysis ? 2 : 5,
              xs: 2,
            },
          }}
        >
          <Typography
            sx={{
              color: 'text.secondary',
              fontSize: '16px',
              lineHeight: '22px',
              fontWeight: 'bold',
            }}
          >
            Settings
          </Typography>
        </Box>
      )}
      {!underAnalysis && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            pl: {
              xl: underAnalysis ? 2 : 5,
              xs: 2,
            },
            py: 2,
          }}
        >
          <Typography
            sx={{
              color: 'grey.600',
              fontSize: '13px',
              lineHeight: '18px',
              mr: '10px',
            }}
          >
            Charts View
          </Typography>
          <Box
            sx={{
              display: 'inline-flex',
              px: 2,
              py: 0.75,
              backgroundColor: '#F5F6FA',
              borderRadius: '6px',
            }}
          >
            <ChartNumSettings chartNum={chartNum} setChartNum={setChartNum} />
            <ChartSizeSettings chartNum={chartNum} settings={settings} />
          </Box>
        </Box>
      )}
      {!underAnalysis && !isSavvy && (
        <Box
          py={1}
          sx={{
            backgroundColor: 'grey.800',
            pl: {
              xl: underAnalysis ? 2 : 5,
              xs: 2,
            },
            pr: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            sx={{
              color: 'text.secondary',
              fontSize: '16px',
              lineHeight: '22px',
              fontWeight: 'bold',
            }}
          >
            Custom y-axis scales
          </Typography>
          <IconButton
            onClick={() => {
              setOpenScaleDialog(true);
            }}
          >
            <AddIcon />
          </IconButton>
        </Box>
      )}
      {!underAnalysis && !isSavvy && (
        <Box
          py={1}
          sx={{
            pl: {
              xl: underAnalysis ? 2 : 5,
              xs: 2,
            },
            pr: 1,
          }}
        >
          <ComponentLoading loading={loadingScale || loadingDeleteChartScale}>
            {aircraftSettings?.map((setting: IAircraftSetting, key: number) => {
              return (
                <Box
                  py={1}
                  key={key}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Typography>{setting.seriesName}</Typography>
                    <Typography
                      sx={{
                        ml: 1,
                      }}
                    >
                      {setting.scaleMin !== null ? setting.scaleMin : ''} ~ {setting.scaleMax !== null ? setting.scaleMax : ''} (
                      {setting.enabled ? 'Enabled' : 'Disabled'})
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <IconButton
                      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                        setAnchorEl(e.currentTarget);
                        setSelectedSetting(setting);
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </Box>
                </Box>
              );
            })}
          </ComponentLoading>
        </Box>
      )}
      <AddScaleDialog open={openScaleDialog} setOpen={setOpenScaleDialog} flightData={flightData} />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        <List component="div">
          <ListItem
            ContainerComponent="div"
            button
            onClick={() => {
              setAnchorEl(null);
              if (selectedSetting) {
                enableChartScale({
                  variables: {
                    aircraftChartScaleSettingsId: selectedSetting.id,
                    enabled: !selectedSetting.enabled,
                  },
                });
              }
            }}
          >
            {selectedSetting?.enabled ? 'Disable' : 'Enable'}
          </ListItem>
          <ListItem
            ContainerComponent="div"
            button
            onClick={() => {
              setAnchorEl(null);
              setShowEditDialog(true);
            }}
          >
            Edit
          </ListItem>
          <ListItem
            ContainerComponent="div"
            button
            onClick={() => {
              setAnchorEl(null);
              setShowDeleteDialog(true);
            }}
          >
            Delete
          </ListItem>
        </List>
      </Popover>
      <DeleteDialog
        open={showDeleteDialog}
        setOpen={setShowDeleteDialog}
        onSubmitAction={deleteAction}
        title="Delete chart scale setting"
        text="Are you sure to delete chart scale setting?"
      />
      {selectedSetting && (
        <EditScaleDialog
          open={showEditDialog}
          setOpen={setShowEditDialog}
          setting={selectedSetting}
          flightData={flightData}
          aircraftSettings={aircraftSettings}
        />
      )}
    </Box>
  );
};

export default InformationTab;
