import { Navigate } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { PageLoading } from 'components';

import { PATH_LOGIN, PATH_SAVVY_TICKETS, PATH_SECURITY_CHECK } from 'const';

interface IPrivateTicket {
  element: JSX.Element;
  path: string;
  exact?: boolean;
}

const PrivateTicket = (props: IPrivateTicket) => {
  const { path, element: Element } = props; // Get Element user try access
  const { loggedIn, incompleteAircraft, isSavvy, missingToken } = useSelector((state: any) => {
    return state.auth;
  }); // Get auth state (Redux)

  // If loading
  if (loggedIn === null) return <PageLoading />;

  if (missingToken) {
    return <Navigate to={PATH_SECURITY_CHECK} />;
  }

  // NOT logged in
  if (loggedIn !== null && !loggedIn) {
    // Get next prop from url
    const next = window.location.pathname;

    // Redirect to login
    return <Navigate to={PATH_LOGIN} state={{ next }} />;
  }

  if (loggedIn && incompleteAircraft?.length > 0) {
    if (incompleteAircraft[0].subscriptions?.length > 0) {
      return <Navigate to={`/signup/${incompleteAircraft[0].subscriptions[0].serviceCode}/additional-info`} />;
    }
  }

  if (isSavvy && !path.includes(PATH_SAVVY_TICKETS)) {
    return <Navigate to={`/savvy${window.location.pathname}`} />;
  }

  if (!isSavvy && path.includes(PATH_SAVVY_TICKETS)) {
    return <Navigate to={path.replace('/savvy', '')} />;
  }

  // If logged IN - proceed to page
  return Element;
};

export default PrivateTicket;
