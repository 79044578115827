import React, { Fragment, useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LOGOUT_SUCCESS, LOGOUT_ERROR } from 'state/types';

import { useMutation, useQuery } from '@apollo/client';
import { MUTATION_LOGOUT, QUERY_USER_DATA } from 'gql';

import { Box, Button, List, ListItem, Avatar, Hidden } from '@mui/material';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import Skeleton from '@mui/material/Skeleton';

import { Dropdown, DropdownMenu } from 'components';
import styles from './style.module.scss';

import { handleSendError } from 'helpers';

const SignupMenu: React.FC = () => {
  const dispatch = useDispatch();

  // Dropdown User Menu
  const [open, setOpen] = useState(false);
  const toggleMenu = () => {
    setOpen(!open);
  };
  const closeMenu = () => {
    setOpen(false);
  };

  // Get user data
  const { loading: userDataLoading, data: userData } = useQuery(QUERY_USER_DATA); // GraphQL. Rename to userData to prevent naming conflicts

  // Initial
  let email = '';
  let firstName = '';
  let lastName = '';
  let nickname = '';
  let avatar = '';

  // Response
  if (userData && userData?.me) {
    // Update user data
    email = userData.me.email;
    firstName = userData.me.firstName;
    lastName = userData.me.lastName;
    nickname = userData.me.nickname;
    avatar = userData.me.avatar;
  }

  // Define User Name to show in dropdown
  // Email as fallback
  let userName = email;

  // If first or last name specified - use them
  if (firstName?.length > 0 || lastName?.length > 0) {
    userName = `${firstName} ${lastName}`;
  }

  // If nickname specififed - use it
  if (nickname?.length > 0) {
    userName = nickname;
  }

  const navigate = useNavigate();

  // Logout
  const handleLogout = () => {
    closeMenu(); // Hide user menu
    logout(); // Call GraphQL
  };

  // GraphQL
  const [logout, { data: dataLogout, error }] = useMutation(MUTATION_LOGOUT); // Rename data to prevent naming conflicts

  // Send Error
  error && handleSendError();

  // useEffect to prevent re-renders limit
  useEffect(() => {
    // Response
    if (dataLogout) {
      const { ok } = dataLogout.logout;

      if (!ok) {
        // Error
        dispatch({ type: LOGOUT_ERROR });
      } else {
        // Success
        navigate('/login');
        dispatch({ type: LOGOUT_SUCCESS });
      }
    }
  }, [dataLogout, dispatch, navigate]);

  useEffect(() => {
    if (!userData || !userData?.me) {
      closeMenu();
      dispatch({ type: LOGOUT_SUCCESS });
    }
  }, [userData, dispatch]);

  return (
    <Box
      height="100%"
      alignItems="center"
      sx={{
        display: 'inline-flex',
      }}
    >
      {userDataLoading ? (
        <Fragment>
          <Box mr={2}>
            <Skeleton variant="circular" animation="wave" width={36} height={36} />
          </Box>
          <Skeleton variant="text" animation="wave" width={100} height={20} />
        </Fragment>
      ) : (
        userName && (
          <Box>
            <Dropdown onClickAway={closeMenu}>
              <Box display="flex" height="100%">
                <Button color="inherit" endIcon={<ExpandMoreOutlinedIcon />} onClick={toggleMenu}>
                  <Hidden mdDown>
                    <Box mr={1}>
                      <Avatar src={avatar} alt={userName} className={styles.avatar} />
                    </Box>
                  </Hidden>
                  {userName}
                </Button>
              </Box>

              <DropdownMenu open={open}>
                <List component="div">
                  <ListItem ContainerComponent="div" button onClick={handleLogout}>
                    Logout
                  </ListItem>
                </List>
              </DropdownMenu>
            </Dropdown>
          </Box>
        )
      )}
    </Box>
  );
};

export default SignupMenu;
