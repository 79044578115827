import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { ISubscriptionDetail } from 'types';
import { humanDateTime, ctrlCmdClick } from 'helpers';
import { PATH_SAVVY_AIRCRAFT } from 'const';

interface SubscriptionInfoProps {
  subscription: ISubscriptionDetail;
}

const SubscriptionInfo: React.FC<SubscriptionInfoProps> = (props) => {
  const { subscription } = props;

  const navigate = useNavigate();

  const RenderLabelRow = (props: { name: string; text: string | number; link?: boolean; href?: string }) => {
    const { name, text, link = false, href = '' } = props;

    return (
      <Box
        py={1}
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            width: '40%',
          }}
        >
          {name}
        </Typography>
        {!link && (
          <Typography
            sx={{
              width: '60%',
            }}
          >
            {text}
          </Typography>
        )}
        {link && (
          <Typography
            sx={{
              width: '60%',
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
            onClick={(e) => {
              ctrlCmdClick(e, href, navigate);
            }}
          >
            {text}
          </Typography>
        )}
      </Box>
    );
  };

  return (
    <Box>
      <Box>
        <RenderLabelRow name="Subscription ID:" text={subscription.id} />
        <RenderLabelRow name="Service:" text={subscription.serviceName} />
        <RenderLabelRow
          name="Tail:"
          text={subscription.aircraft.registration}
          link={true}
          href={`${PATH_SAVVY_AIRCRAFT}/${subscription.aircraft.id}`}
        />
        {subscription.aircraft.owner && (
          <RenderLabelRow name="Owner Name" text={`${subscription.aircraft.owner?.firstName} ${subscription.aircraft.owner?.lastName}`} />
        )}
        {subscription.aircraft.owner && <RenderLabelRow name="Owner Email" text={subscription.aircraft.owner?.email} />}
        <RenderLabelRow name="Created:" text={humanDateTime(subscription.creationDate)} />
        <RenderLabelRow name="Starts:" text={humanDateTime(subscription.startDate)} />
        <RenderLabelRow name="End:" text={humanDateTime(subscription.endDate)} />
        <RenderLabelRow name="Cancelation Date:" text={subscription.cancelDate ? humanDateTime(subscription.cancelDate) : 'None'} />
        {!!subscription.reasonToCancel && <RenderLabelRow name="Cancelation Reason:" text={subscription.reasonToCancel} />}
        <RenderLabelRow name="Amount" text={subscription.amountPaid} />
        <RenderLabelRow name="Promo Code" text={subscription.promoCode ? subscription.promoCode : 'None'} />
        <RenderLabelRow name="Test Mode" text={subscription.testMode ? 'Yes' : 'No'} />
        <RenderLabelRow name="Last Warning" text={subscription.lastSentWarning ? subscription.lastSentWarning : 'None'} />
        {subscription.terminationCode && <RenderLabelRow name="Termination Reason" text={subscription.terminationCode} />}
        <RenderLabelRow
          name="Renewal Status"
          text={subscription.succeededBySubscription ? 'Renewed' : subscription.cancelDate ? 'Canceled' : 'No Action'}
        />
        {subscription.preceededBySubscription && (
          <RenderLabelRow
            name="Preceeding Subscription"
            text={subscription.preceededBySubscription.id}
            link={true}
            href={`${PATH_SAVVY_AIRCRAFT}/${subscription.preceededBySubscription.aircraft.id}/subscriptions/${subscription.preceededBySubscription.id}`}
          />
        )}
        {subscription.succeededBySubscription && (
          <RenderLabelRow
            name="Succeeded Subscription"
            text={subscription.succeededBySubscription.id}
            link={true}
            href={`${PATH_SAVVY_AIRCRAFT}/${subscription.succeededBySubscription.aircraft.id}/subscriptions/${subscription.succeededBySubscription.id}`}
          />
        )}
      </Box>
    </Box>
  );
};

export default SubscriptionInfo;
