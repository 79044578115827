import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Typography, Tooltip, Link } from '@mui/material';
import { IFlight } from 'types';
import { DataGridPro, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-pro';
import { PATH_FLIGHTS } from 'const';
import { ctrlCmdClick } from 'helpers';

import { useLazyQuery } from '@apollo/client';
import { QUERY_ME_ENGINE_DATA_FILE_FLIGHTS } from 'gql';
import { ComponentLoading } from 'components';

interface SpecificFileFlightsProps {
  engineDataFileId: string;
}

const SpecificFileFlights: React.FC<SpecificFileFlightsProps> = (props) => {
  const { engineDataFileId } = props;

  const { id } = useParams();

  const [fetchEngineDataFileFlights, { data: dataFlights, loading: loadingFlights }] = useLazyQuery(QUERY_ME_ENGINE_DATA_FILE_FLIGHTS);

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();

  const [flights, setFlights] = useState<IFlight[]>([]);

  useEffect(() => {
    if (id && engineDataFileId) {
      fetchEngineDataFileFlights({
        variables: {
          id: parseInt(id),
          engineDataFileId,
        },
      });
    }
  }, [id, engineDataFileId]);

  useEffect(() => {
    if (dataFlights?.me?.aircraft[0]?.engineDataFiles[0]?.flights?.length) {
      setFlights(dataFlights.me.aircraft[0].engineDataFiles[0].flights);
    }
  }, [dataFlights]);

  if (!engineDataFileId) {
    return null;
  }

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        borderLeft: '1px solid',
        borderColor: 'grey.200',
      }}
    >
      <ComponentLoading loading={loadingFlights}>
        {engineDataFileId && (
          <Box>
            {dataFlights?.me?.aircraft[0]?.engineDataFiles[0] && (
              <Typography
                variant="h2"
                sx={{
                  fontWeight: 700,
                }}
                align="center"
              >
                Flights for File {dataFlights?.me?.aircraft[0]?.engineDataFiles[0].name}
              </Typography>
            )}
            <DataGridPro
              sx={{
                width: '100%',
                minWidth: '100px',
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-row': {
                  cursor: 'pointer',
                  borderBottom: '1px solid',
                  borderColor: 'grey.200',
                },
                '& .MuiDataGrid-columnHeader:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-cellContent': {
                  whiteSpace: 'break-spaces',
                },
                border: 'none',
              }}
              autoHeight
              rowsPerPageOptions={[5, 10, 25, 100]}
              pagination
              pageSize={pageSize}
              onPageSizeChange={(newPage) => setPageSize(newPage)}
              page={page}
              onPageChange={(newPage) => setPage(newPage)}
              showColumnRightBorder={false}
              disableColumnSelector
              disableDensitySelector
              disableColumnMenu
              disableSelectionOnClick
              hideFooter
              rows={[...flights].sort((a: IFlight, b: IFlight) => {
                if (a.date > b.date) {
                  return -1;
                } else {
                  return 1;
                }
              })}
              columns={[
                {
                  field: 'date',
                  headerName: 'Date',
                  valueFormatter: (params) => {
                    return params.value ? params.value.toString().substring(0, 10) : '';
                  },
                  flex: 0.5,
                },
                {
                  field: 'departureAirport',
                  headerName: 'Departure Airport',
                  renderCell: (params: GridRenderCellParams<string>) => {
                    return (
                      <Tooltip title={params.value || ''}>
                        <Typography
                          sx={{
                            maxWidth: '100%',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {params.value || ''}
                        </Typography>
                      </Tooltip>
                    );
                  },
                  flex: 1,
                },
                {
                  field: 'destinationAirport',
                  headerName: 'Destination Airport',
                  renderCell: (params: GridRenderCellParams<string>) => {
                    return (
                      <Tooltip title={params.value || ''}>
                        <Typography
                          sx={{
                            maxWidth: '100%',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {params.value || ''}
                        </Typography>
                      </Tooltip>
                    );
                  },
                  flex: 1,
                },
                {
                  field: 'duration',
                  headerName: 'Duration',
                  valueFormatter: (params) => {
                    if (!params?.value) return '';
                    const hour = Math.floor(params.value / 3600);
                    const min = Math.floor((params.value % 3600) / 60);
                    const sec = params.value % 60;

                    return `${hour}h ${min}m ${sec}s`;
                  },
                  flex: 0.5,
                },
              ]}
              onRowClick={(params: GridRowParams, event?: any) => {
                const { row } = params;
                if (row?.id) {
                  ctrlCmdClick(event, `${PATH_FLIGHTS}/${row.id}`, navigate);
                }
              }}
            />
            {pageSize < flights.length && (
              <Box
                py={1}
                sx={{
                  textAlign: 'center',
                }}
              >
                <Link
                  href="#"
                  onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (pageSize < flights.length) {
                      setPageSize(flights.length);
                    }
                  }}
                >
                  Show More
                </Link>
              </Box>
            )}
          </Box>
        )}
      </ComponentLoading>
    </Box>
  );
};

export default SpecificFileFlights;
