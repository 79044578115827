import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Grid, List, ListItem, ListItemText, RadioGroup, Radio, FormControlLabel } from '@mui/material';
import * as Yup from 'yup';

import { Formik, Form } from 'formik';
import { MuiFormikInput, ButtonSubmit, MuiFormikTextarea, ComponentLoading, Checkbox } from 'components';

import { useMutation } from '@apollo/client';
import { MUTATION_CREATE_PREBUY_INQUIRY_TICKET } from 'gql';
import { useCheckbox } from 'hooks';
import { setAlert } from 'state';
import { IAircraftEligibility } from 'types';
import { PATH_TICKETS_VIEW } from 'const';

const PostTicketSchema = Yup.object().shape({
  telephone: Yup.string().max(256, 'Too long: 1-256 characters required').required('Required'),
  homeAirportId: Yup.string().max(255, 'Too long: 1-255 characters required').required('Required'),
  remarks: Yup.string().max(10000, 'Too long: 1-10000 characters required'),
  modelsConsidered: Yup.string().max(255, 'Too long: 1-255 characters required'),
  missionProfile: Yup.string().max(10000, 'Too long: 1-10000 characters required'),
  modelDecided: Yup.string().max(255, 'Too long: 1-255 characters required'),
  candidate1: Yup.string().max(255, 'Too long: 1-255 characters required'),
  candidate2: Yup.string().max(255, 'Too long: 1-255 characters required'),
  candidate3: Yup.string().max(255, 'Too long: 1-255 characters required'),
  candidateChosen: Yup.string().max(255, 'Too long: 1-255 characters required'),
  candidateRegistration: Yup.string().max(255, 'Too long: 1-255 characters required'),
  candidateAirport: Yup.string().max(255, 'Too long: 1-255 characters required'),
});

interface LogbookPreviewFormComponentProps {
  ticketAircraft: IAircraftEligibility | undefined;
}

const LogbookPreviewFormComponent: React.FC<LogbookPreviewFormComponentProps> = () => {
  const dispatch = useDispatch();

  const [createTicket, { data: dataCreateTicket, error: errorCreateTicket, loading: loadingCreateTicket }] = useMutation(
    MUTATION_CREATE_PREBUY_INQUIRY_TICKET,
    {
      errorPolicy: 'all',
    },
  );

  const navigate = useNavigate();

  const { value: firstTimeBuyer, onChange: onChangeFirstAircraft } = useCheckbox();
  const { value: notQuiteSure, onChange: onChangeNotSure } = useCheckbox();
  const { value: knowExactly, onChange: onChangeIsSure } = useCheckbox();
  const { value: narrowedToAFew, onChange: onChangeSearchFewAircrafts } = useCheckbox();
  const { value: narrowedToOne, onChange: onChangeSearchOneAircraft } = useCheckbox();

  const [commitment, setCommitment] = useState('');
  const { value: brokerInvolved, onChange: onChangeAircraftBroker } = useCheckbox();
  const { value: scannedLogbooks, onChange: onChangePdfFormat } = useCheckbox();

  const notSureDescription = [
    'Will you be using the airplane mostly for business or for pleasure?',
    'How many hours per year do you expect to fly it?',
    'How many passengers will you typically be carrying? How much baggage?',
    'Do you anticipate using the airplane for long trips (over 1000 nm) or mostly for short trips (less than 500 nm)?',
    'Do you fly mostly VFR, or do you need to be able to fly in hard IFR?',
  ];

  useEffect(() => {
    if (errorCreateTicket) {
      dispatch(setAlert('error', 'Unable to create ticket'));
    } else if (dataCreateTicket) {
      if (dataCreateTicket.createPrebuyInquiryTicket?.ok) {
        dispatch(setAlert('success', 'Created new ticket'));
        navigate(`${PATH_TICKETS_VIEW}/${dataCreateTicket.createPrebuyInquiryTicket.ticket.id}`);
      } else {
        dispatch(setAlert('error', dataCreateTicket.createPrebuyInquiryTicket?.error || 'Unable to create ticket'));
      }
    }
  }, [errorCreateTicket, dispatch, dataCreateTicket, navigate]);

  const onSubmit = async (value: any) => {
    await createTicket({
      variables: {
        brokerInvolved: brokerInvolved ? 'Y' : 'N',
        commitment,
        firstTimeBuyer: firstTimeBuyer ? 'Y' : 'N',
        knowExactly: knowExactly ? 'Y' : 'N',
        narrowedToAFew: narrowedToAFew ? 'Y' : 'N',
        narrowedToOne: narrowedToOne ? 'Y' : 'N',
        notQuiteSure: notQuiteSure ? 'Y' : 'N',
        scannedLogbooks: scannedLogbooks ? 'Y' : 'N',
        ...value,
      },
    });
  };

  return (
    <ComponentLoading loading={loadingCreateTicket}>
      <Box py={3}>
        <Formik
          initialValues={{
            telephone: '',
            homeAirportId: '',
            remarks: '',
            modelsConsidered: '',
            missionProfile: '',
            modelDecided: '',
            candidate1: '',
            candidate2: '',
            candidate3: '',
            candidateChosen: '',
            candidateRegistration: '',
            candidateAirport: '',
          }}
          validationSchema={PostTicketSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, handleChange, handleBlur, touched, errors }) => {
            return (
              <Form>
                <Box py={1}>
                  <Typography variant="body1">
                    Please complete the applicable fields below to help us understand where you are in your prebuy process. (Leave any
                    fields that don't apply blank.) This is NOT a commitment to subscribe to the SavvyPrebuy service.
                  </Typography>
                </Box>
                <Box pb={1} pt={2}>
                  <MuiFormikInput
                    name="telephone"
                    label="Phone"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                  />
                </Box>
                <Box py={1}>
                  <MuiFormikInput
                    name="homeAirportId"
                    label="Home airport identifier"
                    placeholder="Home Airport Identifier, e.g. KSMX"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                  />
                </Box>
                <Box py={1}>
                  <Checkbox
                    value={firstTimeBuyer}
                    label={<Typography>This is my first aircraft purchase.</Typography>}
                    onChange={(e) => onChangeFirstAircraft(e)}
                    noMargin
                    noPadding
                  />
                </Box>
                <Box py={1}>
                  <Checkbox
                    value={notQuiteSure}
                    label={<Typography>I'm not quite sure what make and model to buy; I could use some help.</Typography>}
                    onChange={(e) => onChangeNotSure(e)}
                    noMargin
                    noPadding
                  />
                </Box>
                {notQuiteSure && (
                  <Box px={2}>
                    <Box py={1}>
                      <MuiFormikInput
                        name="modelsConsidered"
                        label="Makes and models I'm considering"
                        placeholder="Cessna 182, Piper Dakota, Beech Musketeer"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        noMargin
                      />
                    </Box>
                    <Box py={1}>
                      <Typography>Please describe your mission profile in the text box below.</Typography>
                      <List>
                        {notSureDescription.map((value: string, key: number) => {
                          return (
                            <ListItem
                              key={key}
                              sx={{
                                display: 'list-item',
                                listStyle: 'disc',
                                left: '20px',
                                px: 0,
                                py: 0.5,
                              }}
                            >
                              <ListItemText primary={value}></ListItemText>
                            </ListItem>
                          );
                        })}
                      </List>
                      <Typography>
                        The more we know about your mission profile, the better we can advise you on your choice of aircraft.
                      </Typography>
                    </Box>
                    <Box py={1}>
                      <MuiFormikTextarea
                        name="missionProfile"
                        label="Mission Profile"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        maxLength={10000}
                        rows={5}
                        maxLine={10}
                      />
                    </Box>
                  </Box>
                )}
                <Box py={1}>
                  <Checkbox
                    value={knowExactly}
                    label={<Typography>I know exactly what make and model I want to buy.</Typography>}
                    onChange={(e) => onChangeIsSure(e)}
                    noMargin
                    noPadding
                  />
                </Box>
                {knowExactly && (
                  <Box px={2}>
                    <Box py={1}>
                      <MuiFormikInput
                        name="modelDecided"
                        label="Model decided"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        noMargin
                      />
                    </Box>
                  </Box>
                )}
                <Box py={1}>
                  <Checkbox
                    value={narrowedToAFew}
                    label={<Typography>I've narrowed my search to a few specific aircraft that are listed for sale.</Typography>}
                    onChange={(e) => onChangeSearchFewAircrafts(e)}
                    noMargin
                    noPadding
                  />
                </Box>
                {narrowedToAFew && (
                  <Box px={2}>
                    <Typography>
                      If you have URLs of classified ad listings for these aircraft, please provide them; otherwise, please enter the year,
                      make, model and tail number.
                    </Typography>
                    <Box py={1}>
                      <MuiFormikInput
                        name="candidate1"
                        label="Candidate #1 URL or description:"
                        placeholder="URL or year/make/model/tail number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        maxLength={250}
                        noMargin
                      />
                    </Box>
                    <Box py={1}>
                      <MuiFormikInput
                        name="candidate2"
                        label="Candidate #2 URL or description:"
                        placeholder="URL or year/make/model/tail number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        maxLength={250}
                        noMargin
                      />
                    </Box>
                    <Box py={1}>
                      <MuiFormikInput
                        name="candidate3"
                        label="Candidate #3 URL or description:"
                        placeholder="URL or year/make/model/tail number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        maxLength={250}
                        noMargin
                      />
                    </Box>
                  </Box>
                )}
                <Box py={1}>
                  <Checkbox
                    value={narrowedToOne}
                    label={<Typography>I've narrowed my search to one specific aircraft:</Typography>}
                    onChange={(e) => onChangeSearchOneAircraft(e)}
                    noPadding
                    noMargin={narrowedToOne}
                  />
                </Box>
                {narrowedToOne && (
                  <Box px={2}>
                    <Typography>
                      If you have a URL of a classified ad listing for this aircraft, please provide it; otherwise, please enter the year,
                      make, model and tail number.
                    </Typography>
                    <Box py={1}>
                      <MuiFormikInput
                        name="candidateChosen"
                        label="Aircraft URL or description:"
                        placeholder="URL or year/make/model/tail number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        maxLength={250}
                        noMargin
                      />
                    </Box>
                    <Box py={1}>
                      <MuiFormikInput
                        name="candidateRegistration"
                        label="Aircraft Registration"
                        placeholder="Registration number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        noMargin
                      />
                    </Box>
                    <Box py={1}>
                      <MuiFormikInput
                        name="candidateAirport"
                        label="Aircraft location (airport ID):"
                        placeholder="KAPA"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        noMargin
                      />
                    </Box>
                    <Box py={1}>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={commitment}
                        name="radio-buttons-group"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setCommitment(e.target.value);
                        }}
                        sx={{
                          mb: 0,
                        }}
                      >
                        <FormControlLabel
                          sx={{
                            ml: '-4px',
                          }}
                          value="ready-to-offer"
                          control={
                            <Radio
                              sx={{
                                padding: '4px',
                              }}
                            />
                          }
                          label="I'm ready to make an offer on this aircraft."
                        />
                        <FormControlLabel
                          sx={{
                            ml: '-4px',
                          }}
                          value="made-offer"
                          control={
                            <Radio
                              sx={{
                                padding: '4px',
                              }}
                            />
                          }
                          label="I've already made an offer on this aircraft."
                        />
                        <FormControlLabel
                          sx={{
                            ml: '-4px',
                          }}
                          value="signed-contract"
                          control={
                            <Radio
                              sx={{
                                padding: '4px',
                              }}
                            />
                          }
                          label="I've signed a contract with the seller."
                        />
                      </RadioGroup>
                    </Box>
                    <Box py={1}>
                      <Checkbox
                        value={brokerInvolved}
                        label={<Typography>This aircraft is being offered through an aircraft broker.</Typography>}
                        onChange={(e) => onChangeAircraftBroker(e)}
                        noPadding
                        noMargin
                      />
                    </Box>
                    <Box py={1}>
                      <Checkbox
                        value={scannedLogbooks}
                        label={
                          <Typography>I have access to a copy of this aircraft's scanned maintenance logbooks in PDF format.</Typography>
                        }
                        onChange={(e) => onChangePdfFormat(e)}
                        noPadding
                        noMargin
                      />
                    </Box>
                  </Box>
                )}
                <Box py={1}>
                  <MuiFormikTextarea
                    name="remarks"
                    label="Remarks"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    maxLength={10000}
                    rows={5}
                    maxLine={10}
                  />
                </Box>
                <Box py={1}>
                  <Typography variant="body1" gutterBottom>
                    Expect to hear from Savvy's technical team within one or two business days after you submit this form. We look forward
                    to assisting you with your prebuy!
                  </Typography>
                </Box>
                <Grid container spacing={2}>
                  <Grid item md={3} xs={false}></Grid>
                  <Grid item md={6} xs={12}>
                    <ButtonSubmit
                      text="Create Ticket"
                      loading={isSubmitting || loadingCreateTicket}
                      disabled={isSubmitting || loadingCreateTicket}
                    />
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </ComponentLoading>
  );
};

export default LogbookPreviewFormComponent;
