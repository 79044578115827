import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Typography, TextField } from '@mui/material';
import {
  QUERY_SAVVY_AIRCRAFT_BORESCOPE_IMAGES_WITH_PREVIEW_URL,
  QUERY_SHARED_AIRCRAFT_BORESCOPE_IMAGES_WITH_PREVIEW_URL,
  QUERY_ME_AIRCRAFT_BORESCOPE_IMAGES_WITH_PREVIEW_URL,
  QUERY_BORESCOPE_REPORT_PDF_DOWNLOAD_FOR_SAVVY,
} from 'gql';
import { useLazyQuery } from '@apollo/client';
import { ComponentLoading, Checkbox } from 'components';
import { IBorescopeImageFileType } from 'types';
import { BorescopeReportContext } from 'pages/Reports/components';
import { setAlert } from 'state';

interface PreviewReportProps {
  aircraftId?: string;
  borescopeImageSetId?: string;
  message: string;
  setMessage: (value: string) => void;
  messageError: string;
  setMessageError: (value: string) => void;
  autoCloseTicket: boolean;
  setAutoCloseTicket: (value: boolean) => void;
  sentReport: boolean;
  loadingSendReport: boolean;
  setLoadingPreviewReport: (value: boolean) => void;
  cylinderCount: number;
  subjectList: string[][];
  isOwn?: boolean;
}

const PreviewReport: React.FC<PreviewReportProps> = (props) => {
  const {
    aircraftId,
    borescopeImageSetId,
    message,
    setMessage,
    messageError,
    setMessageError,
    autoCloseTicket,
    setAutoCloseTicket,
    sentReport,
    loadingSendReport,
    setLoadingPreviewReport,
    cylinderCount,
    subjectList,
    isOwn = false,
  } = props;

  const { isSavvy } = useSelector((state: any) => state.auth);
  const [images, setImages] = useState<IBorescopeImageFileType[]>([]);

  const [fetchPreviewUrlsAtOnce, { data: dataPreviewUrlAtOnce, loading: loadingPreviewUrlAtOnce }] = useLazyQuery(
    QUERY_SAVVY_AIRCRAFT_BORESCOPE_IMAGES_WITH_PREVIEW_URL,
    {
      fetchPolicy: 'network-only',
    },
  );

  const [fetchSharedPreviewUrlsAtOnce, { data: dataSharedPreviewUrlAtOnce, loading: loadingSharedPreviewUrlAtOnce }] = useLazyQuery(
    QUERY_SHARED_AIRCRAFT_BORESCOPE_IMAGES_WITH_PREVIEW_URL,
    {
      fetchPolicy: 'network-only',
    },
  );

  const [fetchPdfUrl, { data: dataPdfUrl, loading: loadingPdfUrl, error: errorPdfUrl }] = useLazyQuery(
    QUERY_BORESCOPE_REPORT_PDF_DOWNLOAD_FOR_SAVVY,
  );
  const [fetchOwnPreviewUrlsAtOnce, { data: dataOwnPreviewUrlAtOnce, loading: loadingOwnPreviewUrlAtOnce }] = useLazyQuery(
    QUERY_ME_AIRCRAFT_BORESCOPE_IMAGES_WITH_PREVIEW_URL,
    {
      fetchPolicy: 'network-only',
    },
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (aircraftId && borescopeImageSetId) {
      if (isSavvy) {
        fetchPreviewUrlsAtOnce({
          variables: {
            aircraftId,
            borescopeImageSetId,
          },
        });
      } else if (!isOwn) {
        fetchSharedPreviewUrlsAtOnce({
          variables: {
            aircraftId,
            borescopeImageSetId,
          },
        });
      } else {
        fetchOwnPreviewUrlsAtOnce({
          variables: {
            aircraftId: parseInt(aircraftId),
            borescopeImageSetId,
          },
        });
      }
    }
  }, [aircraftId, borescopeImageSetId, isSavvy, isOwn]);

  useEffect(() => {
    setLoadingPreviewReport(loadingPreviewUrlAtOnce);
  }, [loadingPreviewUrlAtOnce]);

  useEffect(() => {
    if (errorPdfUrl) {
      dispatch(setAlert('error', 'Unable to download borescope analysis report'));
    } else if (dataPdfUrl) {
      if (dataPdfUrl.savvy?.aircraft?.borescopeImageSets[0]?.downloadUrl) {
        window.open(dataPdfUrl.savvy?.aircraft?.borescopeImageSets[0]?.downloadUrl, '_blank');
      }
    }
  }, [dataPdfUrl, errorPdfUrl, dispatch]);
  const downloadBorescopeReport = async (borescopeImageSetId: string) => {
    const id = dataPreviewUrlAtOnce?.savvy?.aircraft?.id;
    if (id) {
      await fetchPdfUrl({
        variables: {
          id: parseInt(id),
          borescopeImageSetId,
        },
        fetchPolicy: 'network-only',
      });
    }
  };

  useEffect(() => {
    setLoadingPreviewReport(loadingSharedPreviewUrlAtOnce);
  }, [loadingSharedPreviewUrlAtOnce]);

  useEffect(() => {
    setLoadingPreviewReport(loadingOwnPreviewUrlAtOnce);
  }, [loadingOwnPreviewUrlAtOnce]);

  useEffect(() => {
    if (dataPreviewUrlAtOnce?.savvy?.aircraft?.borescopeImageSets[0]?.images?.length) {
      setImages([...dataPreviewUrlAtOnce.savvy.aircraft.borescopeImageSets[0].images]);
    } else if (dataSharedPreviewUrlAtOnce?.sharedAircraftWithUploadBorescope[0]?.aircraft?.borescopeImageSets[0]?.images?.length) {
      setImages([...dataSharedPreviewUrlAtOnce.sharedAircraftWithUploadBorescope[0].aircraft.borescopeImageSets[0].images]);
    } else if (dataOwnPreviewUrlAtOnce?.me?.aircraft[0]?.borescopeImageSets[0]?.images?.length) {
      setImages([...dataOwnPreviewUrlAtOnce.me.aircraft[0].borescopeImageSets[0].images]);
    }
  }, [dataPreviewUrlAtOnce, dataSharedPreviewUrlAtOnce, dataOwnPreviewUrlAtOnce]);

  return (
    <Box
      sx={{
        minHeight: '500px',
      }}
      py={3}
    >
      <ComponentLoading loading={loadingPreviewUrlAtOnce || loadingSendReport || loadingOwnPreviewUrlAtOnce || loadingPdfUrl}>
        {dataPreviewUrlAtOnce && (
          <BorescopeReportContext
            imageSet={dataPreviewUrlAtOnce?.savvy?.aircraft?.borescopeImageSets[0]}
            aircraft={dataPreviewUrlAtOnce.savvy.aircraft}
            downloadReport={downloadBorescopeReport}
            cylinderCount={cylinderCount}
            images={images}
            subjectList={subjectList}
          />
        )}
        {!sentReport && isSavvy && (
          <Box>
            <Box
              py={1}
              sx={{
                textAlign: 'center',
              }}
            >
              <TextField
                name="message"
                label="Message"
                rows={10}
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                  setMessageError('');
                }}
                error={!!messageError}
                helperText={messageError}
                FormHelperTextProps={{
                  sx: {
                    ml: 0,
                  },
                }}
                sx={{
                  mb: 0,
                  maxWidth: '700px',
                  mx: 'auto',
                }}
                multiline
                fullWidth
              />
            </Box>
            <Box
              py={1}
              sx={{
                maxWidth: '500px',
                mx: 'auto',
              }}
            >
              <Checkbox
                value={autoCloseTicket}
                checked={autoCloseTicket}
                label={
                  <Typography
                    sx={{
                      display: 'inline-block',
                    }}
                  >
                    Snooze & Close in 5 days?
                  </Typography>
                }
                onChange={() => {
                  setAutoCloseTicket(!autoCloseTicket);
                }}
                noMargin
              />
            </Box>
          </Box>
        )}
      </ComponentLoading>
    </Box>
  );
};

export default PreviewReport;
