import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { ISubscription } from 'types';
import { humanDate } from 'helpers';

interface ListProps {
  subscriptions: ISubscription[];
  handleClick: (id: string) => void;
  setSlideNum: (value: number) => void;
}

const List: React.FC<ListProps> = (props) => {
  const { subscriptions, handleClick, setSlideNum } = props;

  const { subscriptionId } = useParams();

  return (
    <Box
      sx={{
        backgroundColor: 'grey.800',
        position: 'relative',
        minHeight: {
          md: 'inherit',
          lg: 'calc(100vh - 75px)',
        },
        minWidth: '250px',
      }}
    >
      {subscriptions.map((subscription: ISubscription, key: number) => {
        return (
          <Box
            py={1}
            key={key}
            sx={{
              cursor: 'pointer',
              backgroundColor: subscriptionId === subscription.id ? 'grey.200' : 'none',
              px: {
                xs: 2,
                sm: 3,
                md: 4,
                lg: 5,
              },
            }}
            onClick={() => {
              if (subscriptionId === subscription.id) {
                setSlideNum(2);
              } else {
                handleClick(subscription.id);
              }
            }}
          >
            <Typography
              sx={{
                fontWeight: 'bold',
              }}
            >
              {subscription.serviceName}
            </Typography>
            <Typography>
              {subscription.startDate ? humanDate(subscription.startDate) : ''}{' '}
              {subscription.endDate ? `- ${humanDate(subscription.endDate)}` : ''}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export default List;
