import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Box, Typography, Link, TextField, InputAdornment, Button } from '@mui/material';

import {
  PATH_WELCOME,
  ACCEPT_AGREEMENT_ERROR_MESSAGE,
  AIRCRAFT_MODEL_SELECT_MANUFACTURER_ERROR,
  AIRCRAFT_MANUFACTURER_EMPTY_ERROR,
  ADD_OPTION_CLICKED_NO_VALUE,
  ADD_OPTION_CLICKED_TYPED_VALUE,
  GRAPHQL_NETWORK_ERROR_MESSAGE,
  PAYMENT_UPDATE_MESSAGE,
} from 'const';
import { Checkbox, ComponentLoading, MuiFormikInput, Autocomplete, ButtonLoading } from 'components';
import { DialogAddAircraftManufacturer, DialogAddAircraftModel } from 'pages/Aircrafts/components/Dialog';

import {
  MUTATION_ADD_SUBSCRIPTION,
  QUERY_AIRCRAFT_MANUFACTURERS,
  MUTATION_UPDATE_AIRCRAFT,
  QUERY_AIRCRAFT_MODELS,
  MUTATION_CHANGE_CREDIT_CARD,
} from 'gql';
import { useMutation, useLazyQuery } from '@apollo/client';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

import { ChangeCcForm } from './PlanComponents';
import { useCheckbox, useAutocomplete } from 'hooks';
import { isEmpty, getOptionLabelIdName, formatOptionIdName } from 'helpers';

import { setAlert } from 'state';
import { IDropDownOption, IUpgradeCard } from 'types';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { apolloClient } from 'services';

interface NewPlanActionProps {
  aircraft: {
    id: string;
    registration: string;
    aircraftManufacturer: {
      id: string;
      name: string;
    };
    aircraftModel: {
      id: string;
      name: string;
      eligibleServices: {
        serviceCode: string;
        price: number;
      }[];
      propulsion?: string;
      enginesCount?: string;
    };
    year: string;
    serial: string;
    upgradeContext: {
      options: IUpgradeCard[];
      lessExpensiveOptions: IUpgradeCard[];
    };
  };
  creditCard: {
    cardType: string;
    last4: string;
    expirationMonth: number;
    expirationYear: number;
  };
}

const ValidationAircraftInfoSchema = Yup.object().shape({
  registration: Yup.string().max(20, 'Too long: Maximum 20 characters').required(),
  year: Yup.string().max(4, 'Too long: Maximum 4 characters').required(),
  serial: Yup.string().max(50, 'Too long: Maximum 50 characters').required('Required'),
});

const NewPlanAction: React.FC<NewPlanActionProps> = (props) => {
  const { aircraft, creditCard } = props;
  const { service } = useParams();

  const [showDifferentCard, setShowDifferentCard] = useState(false);

  const [price, setPrice] = useState(0);
  const [total, setTotal] = useState(0);
  const [discount, setDiscount] = useState(0);

  const [addSubscription, { data, loading, error }] = useMutation(MUTATION_ADD_SUBSCRIPTION, {
    errorPolicy: 'all',
  });
  const [updateAircraft, { data: dataUpdateAircraft, error: errorUpdateAircraft, loading: loadingUpdateAircraft }] = useMutation(
    MUTATION_UPDATE_AIRCRAFT,
    {
      errorPolicy: 'all',
    },
  );

  const {
    value: manufacturer,
    setValue: setManufacturer,
    error: errorManufacturer,
    setError: setErrorManufacturer,
    onChange: onChangeManufacturer,
  } = useAutocomplete();
  const [fetchManufacturer, { data: dataManufacturer, loading: loadingManufacturer, error: errorFetchManufacturer }] =
    useLazyQuery(QUERY_AIRCRAFT_MANUFACTURERS);
  const [manufacturerOptions, setManufacturerOptions] = useState<IDropDownOption[]>([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const stripe = useStripe();
  const elements = useElements();
  const [stripeError, setStripeError] = useState('');

  // Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.
  const stripeLoading = !stripe || !elements;
  const [loadingToken, setLoadingToken] = useState(false);

  const { value: model, setValue: setModel, error: errorModel, setError: setErrorModel, onChange: onChangeModel } = useAutocomplete();

  const [openAddManufacturer, setOpenAddManufacturer] = useState(false);
  const [openAddModel, setOpenAddModel] = useState(false);

  const [modelOptions, setModelOptions] = useState<IDropDownOption[]>([]);

  const [fetchModels, { data: dataFetchModels, loading: loadingFetchModels }] = useLazyQuery(QUERY_AIRCRAFT_MODELS);

  const { value: valueAgreeEdas, error: errorAgreeEdas, setError: setErrorAgreeEdas, onChange: onChangeAgreeEdas } = useCheckbox();

  const onSubmit = async (values: any) => {
    if (!valueAgreeEdas) {
      setErrorAgreeEdas(ACCEPT_AGREEMENT_ERROR_MESSAGE);
      return;
    }

    let hasError = false;

    if (!manufacturer?.id) {
      setErrorManufacturer('Required');
      hasError = true;
    }

    if (!model?.id) {
      setErrorModel('Required');
      hasError = true;
    }

    if (hasError) return;

    if (showDifferentCard || !creditCard) {
      if (stripe === null || elements === null) return;

      const cardElement = elements.getElement(CardElement);

      // Prevent Typescript errors if Stripe still loading
      if (cardElement === null) {
        return;
      }

      setLoadingToken(true);

      const { error, token } = await stripe.createToken(cardElement);

      if (error) {
        const { message } = error;
        setLoadingToken(false);

        if (message) {
          setStripeError(message);
          return;
        }
      } else if (token) {
        const { data: dataUpdateToken, errors: errorUpdateToken } = await apolloClient.mutate({
          mutation: MUTATION_CHANGE_CREDIT_CARD,
          variables: {
            token: token.id,
          },
          errorPolicy: 'all',
        });

        setLoadingToken(false);

        if (errorUpdateToken) {
          setStripeError(errorUpdateToken[0].message);
          dispatch(setAlert('error', errorUpdateToken[0].message));
          return;
        } else if (dataUpdateToken) {
          const { changeCreditCardToken } = dataUpdateToken;

          if (changeCreditCardToken.ok) {
            setLoadingToken(false);
            dispatch(setAlert('success', PAYMENT_UPDATE_MESSAGE));
            setShowDifferentCard(false);
          } else {
            dispatch(setAlert('error', changeCreditCardToken.error));
          }
        }
      }
    }

    await updateAircraft({
      variables: {
        aircraftId: aircraft.id,
        serial: values.serial,
        year: values.year ? parseInt(values.year) : null,
        registration: values.registration,
        aircraftManufacturerId: parseInt(manufacturer.id),
        aircraftModelId: parseInt(model.id),
      },
    });
  };

  useEffect(() => {
    fetchManufacturer();
  }, [fetchManufacturer]);

  useEffect(() => {
    if (errorFetchManufacturer) {
      dispatch(setAlert('error', GRAPHQL_NETWORK_ERROR_MESSAGE, 'ALERT_SERVER_ERROR_ID'));
    }
  }, [errorFetchManufacturer, dispatch]);

  useEffect(() => {
    if (error) {
      if (error.graphQLErrors && error.graphQLErrors[0]) {
        dispatch(setAlert('error', error.graphQLErrors[0].message));
      } else {
        dispatch(setAlert('error', 'Unable to add new plan'));
      }
    } else if (data) {
      if (data?.addSubscription?.ok) {
        dispatch(setAlert('success', 'Added new plan'));
        navigate(
          `${PATH_WELCOME}/${data.addSubscription.aircraft.id}/${service}?category=${aircraft.aircraftModel?.propulsion?.toLowerCase()}-${aircraft.aircraftModel?.enginesCount?.toLowerCase()}`,
        );
      } else {
        dispatch(setAlert('error', data?.addSubscription?.error || 'Unable to add new plan'));
      }
    }
  }, [error, data, dispatch, navigate, service]);

  useEffect(() => {
    if (showDifferentCard) {
      setStripeError('');
    }
  }, [showDifferentCard]);

  useEffect(() => {
    if (dataManufacturer) {
      const aircraftManufacturer = [...dataManufacturer.aircraftManufacturer];
      aircraftManufacturer.sort((x: IDropDownOption, y: IDropDownOption) => {
        if (x.name > y.name) return 1;
        else return -1;
      });
      const tmp = [...aircraftManufacturer]; // Update options
      tmp.push({
        id: '',
        name: '-- Add your own --',
      });

      setManufacturerOptions([...tmp]);
    }
  }, [dataManufacturer, setManufacturerOptions]);

  useEffect(() => {
    let tmp: IDropDownOption[] = [];
    if (manufacturer?.id && manufacturer?.aircraftmodelSet) {
      tmp = [...manufacturer.aircraftmodelSet];
      tmp.sort((x: IDropDownOption, y: IDropDownOption) => {
        if (x.name > y.name) return 1;
        else return -1;
      });
    }

    tmp.push({
      id: '',
      name: '-- Add your own --',
    });

    setModelOptions(tmp);
  }, [manufacturer]);

  useEffect(() => {
    setManufacturer(aircraft?.aircraftManufacturer);
    setModel(aircraft?.aircraftModel);
  }, [aircraft, setManufacturer, setModel]);

  useEffect(() => {
    if (manufacturer?.id && model?.id) {
      fetchModels({
        variables: {
          name: model?.name,
          manufacturerId: manufacturer.id,
        },
      });
    }
  }, [manufacturer, model, fetchModels]);

  const requireManufacturer = () => {
    if (manufacturer !== null && isEmpty(manufacturer.name)) {
      setErrorManufacturer(AIRCRAFT_MANUFACTURER_EMPTY_ERROR);
      setErrorModel(AIRCRAFT_MODEL_SELECT_MANUFACTURER_ERROR);
    }
  };

  useEffect(() => {
    // setResponseError([]);
    if (manufacturer?.type === ADD_OPTION_CLICKED_NO_VALUE || manufacturer?.type === ADD_OPTION_CLICKED_TYPED_VALUE) {
      setManufacturer({
        id: '',
        name: manufacturer?.name && manufacturer.name !== '-- Add your own --' ? manufacturer.name.replace('Add ', '') : '',
      });
      setOpenAddManufacturer(true);
      setOpenAddModel(false);
    } else if (model?.type === ADD_OPTION_CLICKED_NO_VALUE || model?.type === ADD_OPTION_CLICKED_TYPED_VALUE) {
      setModel({
        id: '',
        name: model?.name && model.name !== '-- Add your own --' ? model.name.replace('Add ', '') : '',
      });
      setOpenAddModel(true); // Open dialog
      setOpenAddManufacturer(false);
    }
  }, [manufacturer, model, setManufacturer, setModel]);

  const [modelDisabled, setModelDisabled] = useState(true);
  useEffect(() => {
    // If Manufacturer changed
    if (manufacturer && manufacturer?.id !== aircraft?.aircraftManufacturer?.id) setModel(null);

    // If Manufacturer Empty
    if (manufacturer === null || (!manufacturer?.id && !manufacturer?.name)) {
      setModelDisabled(true); // Disable Model
    } else {
      // If Manufacturer NOT Empty
      setErrorModel(null); // Clean Model Error
      setModelDisabled(false); // Enable Model
    }
  }, [manufacturer, setModel, setErrorModel, aircraft]);

  useEffect(() => {
    if (dataUpdateAircraft?.updateAircraft?.ok) {
      addSubscription({
        variables: {
          aircraftId: dataUpdateAircraft?.updateAircraft?.aircraft.id,
          serviceCode: service,
        },
      });
    }
  }, [dataUpdateAircraft, service, addSubscription]);

  useEffect(() => {
    if (errorUpdateAircraft) {
      if (errorUpdateAircraft.graphQLErrors) {
        dispatch(setAlert('error', errorUpdateAircraft.graphQLErrors[0].message));
      } else {
        dispatch(setAlert('error', 'Unable to update aircraft'));
      }
    } else if (dataUpdateAircraft) {
      if (!dataUpdateAircraft.updateAircraft?.ok) {
        dispatch(setAlert('error', dataUpdateAircraft.updateAircraft?.error || 'Unable to update aircraft'));
      }
    }
  }, [errorUpdateAircraft, dispatch]);

  useEffect(() => {
    if (aircraft && service) {
      const matchedService = aircraft.aircraftModel?.eligibleServices.filter((item: any) => item.serviceCode === service);

      if (matchedService?.length) {
        setPrice(matchedService[0].price);
      } else {
        setPrice(0);
      }

      const matchedUpgradeService = aircraft?.upgradeContext?.options?.filter(
        (option: IUpgradeCard) => option.actionLink === location.pathname,
      );
      if (matchedUpgradeService?.length) {
        setPrice(matchedUpgradeService[0].price);
        if (matchedUpgradeService[0].discount) {
          setTotal(matchedUpgradeService[0].total);
          setDiscount(matchedUpgradeService[0].discount);
        } else {
          setDiscount(0);
        }
      }
    }
  }, [service, aircraft]);

  useEffect(() => {
    if (dataFetchModels?.aircraftModel?.length > 0) {
      const { eligibleServices } = dataFetchModels.aircraftModel[0];

      const matchedService = eligibleServices?.find((element: any) => element.serviceCode === service);

      setPrice(matchedService && matchedService.price ? matchedService.price : 0);
    } else {
      setPrice(0);
    }
  }, [service, dataFetchModels]);

  return (
    <Box>
      <ComponentLoading loading={loading || loadingUpdateAircraft || loadingFetchModels}>
        <Box
          sx={{
            py: 1,
            borderTop: '1px solid',
            borderBottom: '1px solid',
            borderColor: 'grey.200',
            mb: 1,
          }}
        >
          <Box
            py={1}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography>{service === 'prebuy' ? 'Fixed fee for your plan:' : 'Fixed annual fee for your plan:'}</Typography>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 'bold',
              }}
            >
              {`$${price}/year`}
            </Typography>
          </Box>
          {!!discount && (
            <Box
              py={1}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography>Discount:</Typography>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 'bold',
                }}
              >
                {`$${discount}`}
              </Typography>
            </Box>
          )}
          {!!discount && (
            <Box
              py={1}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography>Total:</Typography>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 'bold',
                }}
              >
                {`$${total}`}
              </Typography>
            </Box>
          )}
        </Box>
        <Formik
          initialValues={{
            registration: aircraft?.registration ? aircraft.registration : '',
            year: aircraft?.year ? aircraft.year : '',
            serial: aircraft?.serial ? aircraft.serial : '',
          }}
          onSubmit={onSubmit}
          validationSchema={ValidationAircraftInfoSchema}
        >
          {({ submitForm, handleChange, handleBlur, touched, errors }) => {
            return (
              <React.Fragment>
                <Form>
                  <Box py={1}>
                    <MuiFormikInput
                      name="registration"
                      label="Registration Number"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      readOnly
                    />
                  </Box>
                  <Box py={1}>
                    <MuiFormikInput
                      name="serial"
                      label="Serial"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </Box>
                  <Box py={1}>
                    <MuiFormikInput
                      name="year"
                      label="Model Year"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </Box>
                  <Box py={1}>
                    <Autocomplete
                      label="Aircraft manufacturer *"
                      name="aircraft_manufacturer"
                      value={manufacturer ? manufacturer : null}
                      error={errorManufacturer}
                      onChange={onChangeManufacturer}
                      options={manufacturerOptions}
                      getOptionLabel={getOptionLabelIdName}
                      formatOption={formatOptionIdName}
                      loading={loadingManufacturer}
                      addOptionLabel="-- Add your own --"
                      renderOptionFlag={true}
                      placeholder="Start typing to search..."
                    />
                  </Box>
                  <Box py={1}>
                    <Autocomplete
                      label="Aircraft model *"
                      name="aircraft_model"
                      value={model ? model : null}
                      error={errorModel}
                      onChange={onChangeModel}
                      options={modelOptions}
                      getOptionLabel={getOptionLabelIdName}
                      formatOption={formatOptionIdName}
                      addOptionLabel="-- Add your own --"
                      disabled={modelDisabled}
                      onClick={requireManufacturer}
                      renderOptionFlag={true}
                      placeholder="Start typing to search..."
                    />
                  </Box>
                </Form>
                {!showDifferentCard && creditCard && (
                  <Box py={1}>
                    <Box>
                      <TextField
                        label={`${creditCard?.cardType || ''} Card Number`}
                        value={`xxxx-xxxx-xxxx-${creditCard?.last4 || ''}`}
                        inputProps={{
                          readOnly: true,
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">{`${('0' + (creditCard?.expirationMonth || '')).slice(
                              -2,
                            )}/${creditCard?.expirationYear}`}</InputAdornment>
                          ),
                        }}
                        fullWidth
                      />
                    </Box>
                    <Typography
                      sx={{
                        cursor: 'pointer',
                        textDecoration: 'underline',
                      }}
                      onClick={() => {
                        setShowDifferentCard(true);
                      }}
                    >
                      {creditCard ? 'Use a different card?' : "You don't "}
                    </Typography>
                  </Box>
                )}
                {(showDifferentCard || !creditCard) && (
                  <Box py={1}>
                    <ChangeCcForm setShowDifferentCard={setShowDifferentCard} isNew={!creditCard} errorMessage={stripeError} />
                  </Box>
                )}
                <Box py={1}>
                  <Checkbox
                    value={valueAgreeEdas}
                    error={errorAgreeEdas}
                    label={
                      <Typography>
                        I accept the terms and conditions of the{' '}
                        <Link
                          href="https://www.savvyaviation.com/master-service-agreement"
                          download="savvy-aviation-master-service-agreement.pdf"
                          target="_blank"
                        >
                          SavvyAviation Master Services Agreement
                        </Link>
                        . A copy of the agreement will be emailed to you.
                      </Typography>
                    }
                    onChange={(e) => onChangeAgreeEdas(e)}
                    checked={valueAgreeEdas}
                  />
                </Box>
                <Box
                  py={1}
                  sx={{
                    textAlign: 'center',
                  }}
                >
                  <Button variant="contained" onClick={submitForm}>
                    {loadingToken || stripeLoading ? <ButtonLoading /> : 'Pay and Continue'}
                  </Button>
                </Box>
              </React.Fragment>
            );
          }}
        </Formik>
      </ComponentLoading>
      <DialogAddAircraftManufacturer
        open={openAddManufacturer}
        setOpen={setOpenAddManufacturer}
        manufacturer={manufacturer}
        setManufacturer={setManufacturer}
      />

      <DialogAddAircraftModel
        open={openAddModel}
        setOpen={setOpenAddModel}
        manufacturer={manufacturer}
        setManufacturer={setManufacturer}
        model={model}
        setModel={setModel}
      />
    </Box>
  );
};

export default NewPlanAction;
