import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, TextField, MenuItem, IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { DataGridPro, GridValueGetterParams, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { QUERY_SAVVY_AIRCRAFT_BORESCOPE_IMAGES, QUERY_BORESCOPE_IMAGE_SUBJECTS_LIST, QUERY_SAVVY_BORESCOPE_IMAGE_PREVIEW_URL } from 'gql';
import { useLazyQuery, useQuery } from '@apollo/client';
import { ComponentLoading } from 'components';
import { IBorescopeImageSet } from 'types';
import { PATH_SAVVY_AIRCRAFT } from 'const';
import { ImgDialog } from 'pages/Tickets/components/ContentComponent';

interface BorescopeImageFilesProps {
  aircraftId: string;
  borescopeImageSetId: string;
  isLoad: boolean;
  sets: IBorescopeImageSet[];
}

const BorescopeImageFiles: React.FC<BorescopeImageFilesProps> = (props) => {
  const { aircraftId, borescopeImageSetId, isLoad, sets } = props;

  const [fetchBorescopeImageFiles, { data: dataFiles, loading: loadingFiles }] = useLazyQuery(QUERY_SAVVY_AIRCRAFT_BORESCOPE_IMAGES);
  const { data: dataSubjectList, loading: loadingSubjectList } = useQuery(QUERY_BORESCOPE_IMAGE_SUBJECTS_LIST);
  const [fetchBorescopeImagePreviewUrl, { data: dataUrl, loading: loadingUrl }] = useLazyQuery(QUERY_SAVVY_BORESCOPE_IMAGE_PREVIEW_URL);
  const navigate = useNavigate();

  const [pageSize, setPageSize] = useState(10);

  const [attachmentId, setAttachmentId] = useState('');
  const [showFileName, setShowFileName] = useState('');
  const [showFile, setShowFile] = useState('');
  const [showImgDialog, setShowImgDialog] = useState(false);

  const loadPreview = async (borescopeImageId: string) => {
    setAttachmentId(borescopeImageId);
    await fetchBorescopeImagePreviewUrl({
      variables: {
        aircraftId,
        borescopeImageSetId,
        borescopeImageId,
      },
    });
  };

  const nextAttachmentLoad = async (borescopeImageId: string) => {
    if (dataFiles?.savvy?.aircraft?.borescopeImageSets[0]?.images?.length) {
      for (let i = 0; i < dataFiles.savvy.aircraft.borescopeImageSets[0].images.length - 1; i++) {
        if (borescopeImageId === dataFiles.savvy.aircraft.borescopeImageSets[0].images[i].id) {
          await loadPreview(dataFiles.savvy.aircraft.borescopeImageSets[0].images[i + 1].id);
          return;
        }
      }
    }
  };

  const prevAttachmentLoad = async (borescopeImageId: string) => {
    if (dataFiles?.savvy?.aircraft?.borescopeImageSets[0]?.images?.length) {
      for (let i = 1; i < dataFiles.savvy.aircraft.borescopeImageSets[0].images.length; i++) {
        if (borescopeImageId === dataFiles.savvy.aircraft.borescopeImageSets[0].images[i].id) {
          await loadPreview(dataFiles.savvy.aircraft.borescopeImageSets[0].images[i - 1].id);
          return;
        }
      }
    }
  };

  const getSubjectName = (subject: string) => {
    if (dataSubjectList?.borescopeImageSubjectsList?.length) {
      const matched = dataSubjectList.borescopeImageSubjectsList.find((item: string[]) => item[0] === subject);
      if (matched) {
        return matched[1];
      }
    }
    return '';
  };

  useEffect(() => {
    if (isLoad && aircraftId && borescopeImageSetId) {
      fetchBorescopeImageFiles({
        variables: {
          aircraftId,
          borescopeImageSetId,
        },
      });
    }
  }, [isLoad, aircraftId, borescopeImageSetId]);

  useEffect(() => {
    if (dataUrl) {
      setShowFileName(dataUrl?.savvy?.aircraft?.borescopeImageSets[0]?.images[0]?.filename);
      setShowFile(dataUrl?.savvy?.aircraft?.borescopeImageSets[0]?.images[0]?.previewUrl);
    }
  }, [dataUrl]);

  return (
    <Box>
      <ComponentLoading loading={loadingFiles || loadingSubjectList}>
        <Box py={1}>
          <TextField
            select
            name="borescopeImageSet"
            label="Borescope Image Set"
            value={borescopeImageSetId}
            sx={{
              maxWidth: '300px',
              mb: 0,
            }}
            onChange={(e) => {
              navigate(`${PATH_SAVVY_AIRCRAFT}/${aircraftId}/borescope-image-sets?setId=${e.target.value}`);
            }}
            fullWidth
          >
            {sets.map((item: IBorescopeImageSet, key: number) => {
              return (
                <MenuItem value={item.id} key={key}>
                  {item.name}
                </MenuItem>
              );
            })}
          </TextField>
        </Box>
        <Box py={1}>
          <DataGridPro
            sx={{
              width: '100%',
              minWidth: '100px',
              '& .MuiDataGrid-columnSeparator': {
                display: 'none',
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
              '& .MuiDataGrid-row': {
                cursor: 'pointer',
                borderBottom: '1px solid',
                borderColor: 'grey.200',
              },
              '& .MuiDataGrid-columnHeader:focus': {
                outline: 'none',
              },
              '& .MuiDataGrid-cellContent': {
                whiteSpace: 'break-spaces',
              },
              border: 'none',
            }}
            autoHeight
            disableColumnResize={true}
            disableSelectionOnClick={true}
            rowsPerPageOptions={[5, 10, 25, 100]}
            pageSize={pageSize}
            onPageSizeChange={(newPage) => setPageSize(newPage)}
            pagination
            showColumnRightBorder={false}
            disableColumnMenu={true}
            rows={dataFiles?.savvy?.aircraft?.borescopeImageSets[0]?.images || []}
            columns={[
              {
                field: 'filename',
                headerName: 'File Name',
                flex: 1,
              },
              {
                field: 'uploadingStatus',
                headerName: 'Uploading Status',
                flex: 1,
              },
              {
                field: 'cylinder',
                headerName: 'Cylinder',
                flex: 1,
              },
              {
                field: 'subject',
                headerName: 'Subject',
                valueGetter: (params: GridValueGetterParams) => {
                  return params.value ? getSubjectName(params.value) : '';
                },
                flex: 1,
              },
              {
                field: 'id',
                headerName: 'Preview',
                renderCell: (params: GridRenderCellParams) => {
                  return (
                    <IconButton
                      onClick={async () => {
                        if (params.value) {
                          await loadPreview(params.value);
                          setShowImgDialog(true);
                        }
                      }}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  );
                },
                flex: 1,
              },
            ]}
          />
        </Box>
      </ComponentLoading>
      <ImgDialog
        showFileDialog={showImgDialog}
        setShowFileDialog={setShowImgDialog}
        showFileName={showFileName}
        showFile={showFile}
        previewAttachmentId={attachmentId}
        nextAttachmentLoad={nextAttachmentLoad}
        prevAttachmentLoad={prevAttachmentLoad}
        loadingAttachmentData={loadingUrl}
      />
    </Box>
  );
};

export default BorescopeImageFiles;
