import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setAlert } from 'state';
import { CREATE_ACCOUNT_ERROR } from 'state/types';

import { Formik, Form } from 'formik';

import { useMutation } from '@apollo/client';
import { MUTATION_CREATE_ACCOUNT } from 'gql';

import { Grid, Typography } from '@mui/material';
import { FormGroupInput } from 'components';
import { snakeToCamelCase } from 'helpers';

import { LayoutLoggedOut, Subsection, ButtonSubmit, SignupEmail, SignupPassword } from 'components';

import * as Yup from 'yup';
import { PATH_PREBUY_INQUIRY_SIGNUP, PATH_TICKETS_CREATE, PATH_SECURITY_CHECK } from 'const';

interface SignupFormValues {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
}

const SignupSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .min(3, 'Too short: 3-50 characters required')
    .max(50, 'Too long: 3-50 characters required')
    .required('Required'),
  password: Yup.string().min(8, 'Too short: 8-30 characters required').max(30, 'Too long: 8-30 characters required').required('Required'),
  firstName: Yup.string().min(1, 'Too short: 1-20 characters required').max(20, 'Too long: 1-20 characters required').required('Required'),
  lastName: Yup.string().min(1, 'Too short: 1-20 characters required').max(20, 'Too long: 1-20 characters required').required('Required'),
});

const REQUIRE_TOKEN_MSG = 'Need security token';

const SignupFree: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Form submit
  const onSubmit = async (values: SignupFormValues) => {
    try {
      await createAccount({
        variables: {
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          password: values.password,
          phone: 'last name',
        },
      });
    } catch (e) {
      dispatch(setAlert('error', 'Unable to signup'));
    }
  };

  const [createAccount, { loading, data, error }] = useMutation(MUTATION_CREATE_ACCOUNT);

  const [responseError, setResponseError] = useState<any[]>([]);

  const [emailError, setEmailError] = useState('');

  useEffect(() => {
    if (error) {
      dispatch(setAlert('error', error.message, 'ALERT_SERVER_ERROR_ID'));
      if (error.message === REQUIRE_TOKEN_MSG) {
        navigate(PATH_SECURITY_CHECK);
      }
    }
  }, [error, dispatch]);

  useEffect(() => {
    if (data) {
      const { errors } = data.createAccount;

      if (errors) {
        dispatch({ type: CREATE_ACCOUNT_ERROR });
        setResponseError(errors);
      } else {
        if (window.location.href.includes(PATH_PREBUY_INQUIRY_SIGNUP)) {
          window.location.href = `${PATH_TICKETS_CREATE}/prebuy-inquiry`;
        } else {
          window.location.href = '/';
        }
      }
    }
  }, [createAccount, data, dispatch]);

  return (
    <LayoutLoggedOut>
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} sm={10} md={6} lg={5}>
          <Subsection>
            <Typography variant="h1" align="center" gutterBottom>
              Signup for a Free Account
            </Typography>
          </Subsection>

          <Formik
            initialValues={{ email: '', password: '', firstName: '', lastName: '' }}
            onSubmit={onSubmit}
            validationSchema={SignupSchema}
          >
            {({ isSubmitting, handleChange, handleBlur, touched, errors }) => {
              if (emailError) {
                errors.email = emailError;
              }

              responseError &&
                responseError?.map((item: any) => {
                  const fieldName = snakeToCamelCase(item.field);

                  switch (fieldName) {
                    case 'email':
                      errors.email = item?.messages[0];
                      break;
                    case 'password':
                      errors.password = item?.messages[0];
                      break;
                    case 'firstName':
                      errors.firstName = item?.messages[0];
                      break;
                    case 'lastName':
                      errors.lastName = item?.messages[0];
                      break;
                  }

                  return fieldName;
                });

              return (
                <Form
                  onChange={() => {
                    setResponseError([]);
                  }}
                  data-testid="signupForm"
                >
                  <Subsection>
                    <SignupEmail
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                      emailError={emailError}
                      setEmailError={setEmailError}
                    />
                    <SignupPassword handleBlur={handleBlur} handleChange={handleChange} errors={errors} touched={touched} />
                    <FormGroupInput
                      name="firstName"
                      label="First Name *"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      maxLength={20}
                    />

                    <FormGroupInput
                      name="lastName"
                      label="Last Name *"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      maxLength={20}
                    />

                    <ButtonSubmit text="Sign Up" loading={loading} disabled={isSubmitting || loading} />
                  </Subsection>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </LayoutLoggedOut>
  );
};

export default SignupFree;
