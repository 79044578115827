import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box, Typography, ToggleButtonGroup, ToggleButton, Grid, TextField, MenuItem } from '@mui/material';
import * as Yup from 'yup';

import { Formik, Form } from 'formik';
import { MuiFormikInput, MuiFormikInputWithWarning, ButtonSubmit, TicketTextareaWithFile, ComponentLoading } from 'components';

import { useMutation, useLazyQuery } from '@apollo/client';
import { MUTATION_CREATE_ANALYSIS_TICKET, QUERY_USER_FLIGHTS_PER_AIRCRAFT } from 'gql';
import { setAlert } from 'state';
import { IAircraftEligibility, ITicketEligibility, IFlight, IUploadedFile, IAnalysisPack } from 'types';
import { secondsToHms, hasAnalysisPack } from 'helpers';
import { PATH_TICKETS_VIEW, MAX_SUBJECT_LENGTH, SHOW_SUBJECT_WARNING } from 'const';

const PostTicketSchema = Yup.object().shape({
  subject: Yup.string().max(MAX_SUBJECT_LENGTH, `Too long: 1-${MAX_SUBJECT_LENGTH} characters required`).required('Required'),
  body: Yup.string().max(10000, 'Too long: 1-10000 characters required').required('Required'),
  priorityExplanation: Yup.string().max(255, 'Too long: 1-255 characters required'),
});

interface submitValueProps {
  subject: string;
  body: string;
  priorityExplanation: string;
}

interface AnalysisFormComponentProps {
  ticketAircraft: IAircraftEligibility;
  eligibility: ITicketEligibility;
  analysisPacks?: IAnalysisPack[];
}

const AnalysisFormComponent: React.FC<AnalysisFormComponentProps> = (props) => {
  const { ticketAircraft, eligibility, analysisPacks = [] } = props;

  const [priority, setPriority] = useState('normal');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { search } = useLocation();

  const [createTicket, { data: dataCreateTicket, error: errorCreateTicket, loading: loadingCreateTicket }] = useMutation(
    MUTATION_CREATE_ANALYSIS_TICKET,
    {
      errorPolicy: 'all',
    },
  );

  const [fetchFlights, { data: dataFetchFlights, error: errorFetchFlights, loading: loadingFetchFlights }] =
    useLazyQuery(QUERY_USER_FLIGHTS_PER_AIRCRAFT);

  const [selectedFlight, setSelectedFlight] = useState<IFlight | undefined>(undefined);
  const [flightError, setFlightError] = useState('');

  const [isUploading, setIsUploading] = useState(false);
  const [uploadedFileList, setUploadedFileList] = useState<IUploadedFile[]>([]);

  useEffect(() => {
    if (ticketAircraft) {
      fetchFlights({
        variables: {
          id: parseInt(ticketAircraft.id),
        },
        fetchPolicy: 'cache-and-network',
      });
    }
  }, [ticketAircraft, fetchFlights]);

  useEffect(() => {
    if (search) {
      const str = new URLSearchParams(search);
      const flightId = str.get('flight');

      if (flightId) {
        const tmpFlight = dataFetchFlights?.me?.aircraft[0]?.flights.filter((flight: IFlight) => flight.id === flightId);
        if (tmpFlight && tmpFlight[0]) {
          setSelectedFlight(tmpFlight[0]);
        }
      }
    }
  }, [search, dataFetchFlights]);

  useEffect(() => {
    if (errorFetchFlights) {
      dispatch(setAlert('error', 'Unable to load your flights.'));
    }
  }, [errorFetchFlights, dispatch]);

  useEffect(() => {
    if (errorCreateTicket) {
      dispatch(setAlert('error', 'Unable to create ticket'));
    } else if (dataCreateTicket) {
      if (dataCreateTicket.createAnalysisTicket?.ok) {
        dispatch(setAlert('success', 'Created new ticket'));
        navigate(`${PATH_TICKETS_VIEW}/${dataCreateTicket.createAnalysisTicket.ticket.id}`);
      } else {
        dispatch(setAlert('error', dataCreateTicket.createAnalysisTicket?.error || 'Unable to create ticket'));
      }
    }
  }, [errorCreateTicket, dispatch, dataCreateTicket, navigate]);

  const onSubmit = async (value: submitValueProps) => {
    if (!selectedFlight) {
      setFlightError('Please select flight');
      return;
    }

    const documentIds = uploadedFileList.map((file: IUploadedFile) => {
      return file.id;
    });

    if (priority === 'high') {
      await createTicket({
        variables: {
          body: value.body,
          subject: value.subject,
          aircraftId: ticketAircraft.id,
          flightId: selectedFlight.id,
          priority: 'U',
          priorityExplanation: value.priorityExplanation,
          documentIds: documentIds ? documentIds : [],
        },
      });
    } else {
      await createTicket({
        variables: {
          body: value.body,
          subject: value.subject,
          aircraftId: ticketAircraft.id,
          flightId: selectedFlight.id,
          priority: 'N',
          documentIds: documentIds ? documentIds : [],
        },
      });
    }
  };

  if (!eligibility.free && !(eligibility.eligible && eligibility.currentlyEligibleVia.length) && !hasAnalysisPack(analysisPacks))
    return null;

  return (
    <ComponentLoading loading={loadingCreateTicket || loadingFetchFlights}>
      <Box py={3}>
        <Box py={1}>
          <TextField
            select
            value={selectedFlight?.id ? selectedFlight.id : ''}
            label="Choose your flight"
            variant="outlined"
            sx={{
              minWidth: '300px',
              textAlign: 'left',
            }}
            onChange={(e: any) => {
              let tmpAircraft: any = [];
              tmpAircraft = dataFetchFlights?.me?.aircraft[0]?.flights.filter((flight: IFlight) => flight.id === e.target.value);

              if (tmpAircraft) setSelectedFlight(tmpAircraft[0]);
              setFlightError('');
            }}
            fullWidth
            error={Boolean(flightError)}
            helperText={flightError ? flightError : ''}
            FormHelperTextProps={{
              style: {
                marginLeft: 0,
              },
            }}
          >
            {!dataFetchFlights?.me?.aircraft[0].flights.length && <MenuItem value="">No Flights</MenuItem>}
            {Boolean(dataFetchFlights?.me?.aircraft[0].flights.length) &&
              dataFetchFlights?.me?.aircraft[0].flights.map((flight: IFlight, key: number) => {
                return (
                  <MenuItem value={flight.id} key={key}>
                    {`${flight.date.toString().substring(0, 10) + ' ' + flight.date.toString().substring(11, 16)}, ${secondsToHms(
                      flight.duration,
                    )}, ${flight.departureAirport} -> ${flight.destinationAirport}`}
                  </MenuItem>
                );
              })}
          </TextField>
        </Box>
        <Formik
          initialValues={{
            subject: '',
            body: '',
            priorityExplanation: '',
          }}
          validationSchema={PostTicketSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, handleChange, handleBlur, touched, errors }) => {
            return (
              <Form>
                <Box py={1}>
                  <MuiFormikInputWithWarning
                    name="subject"
                    label="Subject"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    maxLength={MAX_SUBJECT_LENGTH}
                    closeLength={SHOW_SUBJECT_WARNING}
                  />
                </Box>
                <Box py={1} pb={2.5}>
                  <TicketTextareaWithFile
                    name="body"
                    label="Message"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    rows={5}
                    maxLength={10000}
                    maxLine={10}
                    isUploading={isUploading}
                    setIsUploading={setIsUploading}
                    uploadedFileList={uploadedFileList}
                    setUploadedFileList={setUploadedFileList}
                  />
                </Box>
                <Box pt={1} pb={2.5}>
                  <Typography
                    sx={{
                      color: 'grey.600',
                      fontSize: '12px',
                      fontWeight: 400,
                      lineHeight: '16px',
                    }}
                    gutterBottom
                  >
                    Priority
                  </Typography>
                  <ToggleButtonGroup
                    size="medium"
                    value={priority}
                    onChange={(event, priority) => {
                      setPriority(priority);
                    }}
                    exclusive={true}
                  >
                    <ToggleButton
                      value={'normal'}
                      key={'normal'}
                      sx={{
                        '&.Mui-selected': {
                          backgroundColor: 'warning.main',
                          color: 'background.default',
                          '&:hover': {
                            backgroundColor: 'warning.main',
                          },
                        },
                        width: '80px',
                        textTransform: 'uppercase',
                        fontSize: '12px',
                        fontWeight: 700,
                        py: 1,
                      }}
                    >
                      Normal
                    </ToggleButton>
                    <ToggleButton
                      value={'high'}
                      key={'high'}
                      sx={{
                        '&.Mui-selected': {
                          backgroundColor: 'error.dark',
                          color: 'background.default',
                          '&:hover': {
                            backgroundColor: 'error.dark',
                          },
                        },
                        width: '80px',
                        textTransform: 'uppercase',
                        fontSize: '12px',
                        fontWeight: 700,
                        py: 1,
                      }}
                    >
                      High
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
                {priority === 'high' && (
                  <Box mb={3}>
                    <Box py={1}>
                      <Typography
                        color="error"
                        variant="h3"
                        textAlign="center"
                        sx={{
                          mb: 1,
                        }}
                      >
                        Please Don't Abuse Urgent Priority
                      </Typography>
                      <Typography variant="body1" textAlign="center" gutterBottom>
                        We will do our best to do this analysis as soon as possible, but&nbsp;<b>we cannot guarantee a faster turnaround</b>
                        .
                      </Typography>
                    </Box>
                    <MuiFormikInput
                      name="priorityExplanation"
                      label="Priority explanation"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      noMargin
                    />
                  </Box>
                )}
                <Grid container spacing={2}>
                  <Grid item md={3} xs={false}></Grid>
                  <Grid item md={6} xs={12}>
                    <ButtonSubmit
                      text="Create Ticket"
                      loading={isSubmitting || loadingCreateTicket}
                      disabled={isSubmitting || loadingCreateTicket || isUploading}
                    />
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </ComponentLoading>
  );
};

export default AnalysisFormComponent;
