import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Collapse, IconButton, Typography, Button, Link, Grid } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import {
  QUERY_BORESCOPE_STATUS,
  QUERY_BORESCOPE_IMAGE_SUBJECTS_LIST,
  QUERY_ME_AIRCRAFT_BORESCOPE_IMAGES_WITH_DETAIL,
  MUTATION_UPDATE_BORESCOPE_IMAGE_SET_COMMENTS,
  MUTATION_REQUEST_UPDATED_IMAGES,
  MUTATION_COMPLETE_REPORT,
  MUTATION_CLEAR_ALL_BORESCOPE_TAG,
  QUERY_BORESCOPE_REPORT_FOR_CLIENT,
  QUERY_BORESCOPE_REPORT_PDF_DOWNLOAD,
} from 'gql';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { PageLoading, ComponentLoading, DeleteDialog } from 'components';
import { ICylinderAndSubject, IBorescopeImageFileType, IBorescopeStatusChoice, IBorescopeImageSet } from 'types';
import { CylinderList, ViewImage, PreviewReport, Information } from './GenerateComponents';
import { BAD_IMAGE_STATUS, BORESCOPE_SENT_STATUS, PATH_AIRCRAFT_VIEW, PATH_REPORTS_BETA } from 'const';
import { setAlert } from 'state';

import { default as WarningDialog } from './WarningDialog';
import { default as RequestImageDialog } from './RequestImageDialog';

import { getSubjectLabel } from 'helpers';
import { BorescopeReportContext } from 'pages/Reports/components';

const BorescopeOwnAnalysisReports: React.FC = () => {
  const { previewFlag } = useSelector((state: any) => state.analysis);
  const { data: dataStatuses, loading: loadingStatuses } = useQuery(QUERY_BORESCOPE_STATUS);
  const { data: dataSubjectsList, loading: loadingSubjectsList } = useQuery(QUERY_BORESCOPE_IMAGE_SUBJECTS_LIST);
  const [fetchBorescopeReport, { data: dataBorescopeReport, loading: loadingBorescopeReport }] =
    useLazyQuery(QUERY_BORESCOPE_REPORT_FOR_CLIENT);
  const [fetchBorescopeImageSet, { data: dataImageSet, loading: loadingImageSet }] = useLazyQuery(
    QUERY_ME_AIRCRAFT_BORESCOPE_IMAGES_WITH_DETAIL,
  );
  const [fetchPdfUrl, { data: dataPdfUrl, loading: loadingPdfUrl, error: errorPdfUrl }] = useLazyQuery(QUERY_BORESCOPE_REPORT_PDF_DOWNLOAD);
  const [showClear, setShowClear] = useState(false);
  const [sendReport, { data: dataSendReport, loading: loadingSendReport, error: errorSendReport }] = useMutation(MUTATION_COMPLETE_REPORT);
  const [updateComments, { data: dataComments, loading: loadingComments, error: errorComments }] = useMutation(
    MUTATION_UPDATE_BORESCOPE_IMAGE_SET_COMMENTS,
  );
  const [clearAllTag, { data: dataClear, loading: loadingClear }] = useMutation(MUTATION_CLEAR_ALL_BORESCOPE_TAG);

  const [requestUpdatedImages, { data: dataRequestUpdatedImages, loading: loadingRequestUpdatedImages, error: errorRequestUpdatedImages }] =
    useMutation(MUTATION_REQUEST_UPDATED_IMAGES);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { aircraftId, borescopeImageSetId } = useParams();

  const [showInfo, setShowInfo] = useState(true);
  const [imageSet, setImageSet] = useState<IBorescopeImageSet>();

  const [selectedIndex, setSelectedIndex] = useState<ICylinderAndSubject>({
    cylinder: 1,
    subject: '',
  });
  const [cylinderCount, setCylinderCount] = useState(0);
  const [images, setImages] = useState<IBorescopeImageFileType[]>([]);
  const [subjectList, setSubjectList] = useState<string[][]>([]);
  const [includingBad, setIncludingBad] = useState(false);
  const [isNonTaggedImages, setIsNonTaggedImages] = useState(false);
  const [showRequest, setShowRequest] = useState(false);

  const [pageIndex, setPageIndex] = useState(0);
  const [showWarning, setShowWarning] = useState(false);
  const [warningList, setWarningList] = useState<IBorescopeImageFileType[]>([]);
  const [comments, setComments] = useState('');

  const [message, setMessage] = useState('');
  const [messageError, setMessageError] = useState('');
  const [autoCloseTicket, setAutoCloseTicket] = useState(false);
  const [loadingPreviewReport, setLoadingPreviewReport] = useState(false);
  const [sentReport, setSentReport] = useState(false);

  const [showComplete, setShowComplete] = useState(false);

  const matchedChoices = (subject: string): IBorescopeStatusChoice[] => {
    const matched = dataStatuses?.borescopeStatus?.find((item: any) => item.slug === subject);
    if (matched) {
      return matched.choices;
    }
    return [];
  };

  const matchedImage = (cylinder: number, subject: string) => {
    const matched = images.find((file) => file.cylinder === cylinder && file.subject === subject);
    return matched;
  };

  const requestUpdatedImageAction = async (post: string) => {
    await requestUpdatedImages({
      variables: {
        imageSetId: borescopeImageSetId,
        post,
      },
    });
  };

  const downloadBorescopeReport = async (borescopeImageSetId: string) => {
    if (aircraftId) {
      await fetchPdfUrl({
        variables: {
          id: parseInt(aircraftId),
          borescopeImageSetId,
        },
        fetchPolicy: 'network-only',
      });
    }
  };
  const onSubmit = async () => {
    if (!isAvailableToTag()) {
      return;
    }
    await updateComments({
      variables: {
        imageSetId: borescopeImageSetId,
        comments,
      },
    });
    await sendReport({
      variables: {
        borescopeImageSetId,
      },
    });
  };

  const onClearTag = async () => {
    await clearAllTag({
      variables: {
        imageSetId: borescopeImageSetId,
      },
    });
  };

  const onClickNext = () => {
    const matchedSubjectIndex = subjectList.findIndex((item) => item[0] === selectedIndex.subject);
    for (let i = matchedSubjectIndex + 1; i < subjectList.length; i++) {
      if (matchedImage(selectedIndex.cylinder, subjectList[i][0])) {
        setSelectedIndex({
          cylinder: selectedIndex.cylinder,
          subject: subjectList[i][0],
        });
        return;
      }
    }
    let selectedCylinder = selectedIndex.cylinder + 1;
    while (selectedCylinder <= cylinderCount) {
      for (let i = 0; i < subjectList.length; i++) {
        if (matchedImage(selectedCylinder, subjectList[i][0])) {
          setSelectedIndex({
            cylinder: selectedCylinder,
            subject: subjectList[i][0],
          });
          return;
        }
      }
      selectedCylinder++;
    }
  };

  const isAvailableToTag = () => {
    if (imageSet?.reportCreator) {
      if (dataImageSet?.me?.id !== imageSet.reportCreator.id) {
        dispatch(
          setAlert(
            'warning',
            <Typography>
              {`${imageSet.reportCreator.firstName} ${imageSet.reportCreator.lastName} has already started a report on this borescope image set.`}
              {!imageSet.reportCreator.isSavvy && (
                <React.Fragment>
                  &nbsp;
                  <Link
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setShowClear(true);
                    }}
                  >
                    Would you like to clear it and start over?
                  </Link>
                </React.Fragment>
              )}
            </Typography>,
          ),
        );
        return false;
      }
    }
    return true;
  };

  const sendAndBack = async () => {
    if (pageIndex === 0) {
      if (!isAvailableToTag()) {
        return;
      }
      const tmp = images.filter((item) => !item.status.length);
      if (tmp.length) {
        setShowWarning(true);
        setWarningList([...tmp]);
        return;
      }
      await updateComments({
        variables: {
          imageSetId: borescopeImageSetId,
          comments,
        },
      });
      setShowComplete(true);
    } else {
      setPageIndex(0);
    }
  };

  useEffect(() => {
    if (aircraftId && borescopeImageSetId) {
      fetchBorescopeImageSet({
        variables: {
          aircraftId: parseInt(aircraftId),
          borescopeImageSetId,
        },
      });
      fetchBorescopeReport({
        variables: {
          id: parseInt(aircraftId),
          borescopeImageSetId,
        },
      });
    }
  }, [aircraftId, borescopeImageSetId, previewFlag]);

  useEffect(() => {
    if (errorPdfUrl) {
      dispatch(setAlert('error', 'Unable to download borescope analysis report'));
    } else if (dataPdfUrl) {
      if (dataPdfUrl.me?.aircraft[0]?.borescopeImageSets[0]?.downloadUrl) {
        window.open(dataPdfUrl.me?.aircraft[0]?.borescopeImageSets[0]?.downloadUrl, '_blank');
      }
    }
  }, [dataPdfUrl, errorPdfUrl, dispatch]);
  useEffect(() => {
    if (dataSubjectsList?.borescopeImageSubjectsList?.length) {
      setSelectedIndex({
        cylinder: 1,
        subject: dataSubjectsList?.borescopeImageSubjectsList[0][0],
      });
    }
  }, [dataSubjectsList]);

  useEffect(() => {
    if (dataImageSet?.me?.aircraft[0]?.borescopeImageSets[0]?.images?.length) {
      setImages([...dataImageSet.me.aircraft[0].borescopeImageSets[0].images]);
    }
    if (dataImageSet?.me?.aircraft[0]?.borescopeImageSets[0]?.comments) {
      setComments(dataImageSet?.me?.aircraft[0]?.borescopeImageSets[0]?.comments);
    }
  }, [dataImageSet]);

  useEffect(() => {
    if (dataImageSet) {
      setImageSet(dataImageSet?.me?.aircraft[0]?.borescopeImageSets[0]);
      if (dataImageSet?.me?.aircraft[0] && !dataImageSet.me.aircraft[0].cylinderCount) {
        dispatch(
          setAlert(
            'warning',
            <Typography>
              The number of cylinder's on this aircraft's engine are not configured. [
              <Link
                href={`${PATH_AIRCRAFT_VIEW}/${aircraftId}`}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  navigate(`${PATH_AIRCRAFT_VIEW}/${aircraftId}`);
                }}
              >
                Configure aircraft...
              </Link>
              ]
            </Typography>,
          ),
        );
      } else {
        setCylinderCount(dataImageSet?.me?.aircraft[0].cylinderCount);
      }
    }
  }, [dispatch, dataImageSet]);

  useEffect(() => {
    if (dataSubjectsList?.borescopeImageSubjectsList) {
      setSubjectList([...dataSubjectsList.borescopeImageSubjectsList]);
    }
  }, [dataSubjectsList]);

  useEffect(() => {
    if (images.length) {
      const badImage = images.find((image) => image.status.includes(BAD_IMAGE_STATUS));
      if (badImage) {
        setIncludingBad(true);
      } else {
        setIncludingBad(false);
      }

      const nonTaggedImages = images.filter((image) => !image.subject);
      if (nonTaggedImages && nonTaggedImages.length) {
        setIsNonTaggedImages(true);
      } else {
        setIsNonTaggedImages(false);
      }
    }
  }, [images]);

  useEffect(() => {
    if (errorSendReport) {
      dispatch(setAlert('error', 'Unable to complete borescope analysis report'));
    } else if (dataSendReport) {
      if (dataSendReport.completeBorescopeReport?.ok) {
        setSentReport(true);
        dispatch(setAlert('success', 'Completed borescope analysis report'));
        setTimeout(() => {
          navigate(`${PATH_REPORTS_BETA}/${aircraftId}/ba/${dataSendReport.completeBorescopeReport.imageSet.id}`);
        }, 100);
      } else {
        dispatch(setAlert('error', dataSendReport.completeBorescopeReport?.error || 'Unable to complete borescope analysis report'));
      }
    }
  }, [dataSendReport, errorSendReport, dispatch]);

  useEffect(() => {
    if (errorComments) {
      dispatch(setAlert('error', 'Unable to save comments'));
    } else if (dataComments) {
      if (dataComments.updateBorescopeImageSetComments?.ok) {
        setMessage(dataComments.updateBorescopeImageSetComments.borescopeImageSet?.reportCommentsDraft || '');
      } else {
        dispatch(setAlert('error', dataComments.updateBorescopeImageSetComments?.error || 'Unable to save comments'));
      }
    }
  }, [dataComments, errorComments, dispatch]);

  useEffect(() => {
    if (errorRequestUpdatedImages) {
      dispatch(setAlert('error', 'Unable to request updated images'));
    } else if (dataRequestUpdatedImages) {
      if (dataRequestUpdatedImages.requestUpdatedImages?.ok) {
        dispatch(setAlert('success', 'Requested updated images'));
        setShowRequest(false);
      } else {
        dispatch(setAlert('error', dataRequestUpdatedImages.requestUpdatedImages?.error || 'Unable to request updated images'));
      }
    }
  }, [dataRequestUpdatedImages, errorRequestUpdatedImages, dispatch]);

  useEffect(() => {
    if (dataClear?.clearAllBorescopeTag) {
      const { ok, error } = dataClear.clearAllBorescopeTag;
      if (ok) {
        dispatch(setAlert('success', 'Cleared all tags'));
      } else {
        dispatch(setAlert('error', error));
      }
    }
  }, [dataClear, dispatch]);

  return (
    <Box>
      {(loadingStatuses || loadingSubjectsList) && <PageLoading />}
      <ComponentLoading
        loading={loadingImageSet || loadingComments || loadingSendReport || loadingClear || loadingPdfUrl || loadingBorescopeReport}
      >
        {isNonTaggedImages && (
          <Box
            sx={{
              backgroundColor: 'warning.light',
              borderRadius: '5px',
              width: '100%',
              px: 5,
              py: '14px',
            }}
          >
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '22px',
                color: 'warning.main',
                '& a': {
                  color: 'warning.main',
                  textDecoration: 'underline',
                },
              }}
            >
              Untagged images exist on this image set.
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: 'calc(100vh - 153px)',
            justifyContent: 'space-between',
            backgroundColor: 'background.default',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexGrow: 1,
              height: 'calc(100% - 72px)',
            }}
          >
            <Box
              sx={{
                backgroundColor: 'grey.800',
              }}
            >
              <CylinderList
                subjectList={subjectList}
                cylinderCount={cylinderCount}
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
                images={images}
              />
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                backgroundColor: 'background.default',
                height: '100%',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  height: '100%',
                }}
              >
                <Box
                  p={4}
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    overflowY: 'auto',
                  }}
                >
                  {pageIndex === 0 && (
                    <Box
                      sx={{
                        flexGrow: 1,
                      }}
                    >
                      <Grid container spacing={0}>
                        <Grid item xs={3}>
                          <Typography
                            sx={{
                              color: 'grey.600',
                            }}
                          >
                            Cylinder {selectedIndex.cylinder}
                          </Typography>
                          <Typography
                            sx={{
                              textTransform: 'capitalize',
                              fontSize: '18px',
                              fontWeight: 700,
                              lineHeight: '25px',
                              color: 'text.secondary',
                            }}
                          >
                            {getSubjectLabel(subjectList, selectedIndex.subject)}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          {!previewFlag && (
                            <Box
                              sx={{
                                textAlign: 'center',
                              }}
                            >
                              <Button
                                variant="outlined"
                                onClick={() => {
                                  const matchedSubjectIndex = subjectList.findIndex((item) => item[0] === selectedIndex.subject);
                                  for (let i = matchedSubjectIndex - 1; i >= 0; i--) {
                                    if (matchedImage(selectedIndex.cylinder, subjectList[i][0])) {
                                      setSelectedIndex({
                                        cylinder: selectedIndex.cylinder,
                                        subject: subjectList[i][0],
                                      });
                                      return;
                                    }
                                  }
                                  let selectedCylinder = selectedIndex.cylinder - 1;
                                  while (selectedCylinder > 0) {
                                    for (let i = subjectList.length - 1; i >= 0; i--) {
                                      if (matchedImage(selectedCylinder, subjectList[i][0])) {
                                        setSelectedIndex({
                                          cylinder: selectedCylinder,
                                          subject: subjectList[i][0],
                                        });
                                        return;
                                      }
                                    }
                                    selectedCylinder--;
                                  }
                                }}
                                disabled={
                                  !selectedIndex.cylinder ||
                                  !selectedIndex.subject ||
                                  (selectedIndex.cylinder === 1 && selectedIndex.subject === subjectList[0][0])
                                }
                                sx={{
                                  width: '140px',
                                }}
                                data-testid="previous_image"
                              >
                                Previous
                              </Button>
                              <Button
                                variant="contained"
                                sx={{
                                  width: '140px',
                                  ml: 1,
                                }}
                                onClick={onClickNext}
                                disabled={
                                  selectedIndex.cylinder === cylinderCount &&
                                  selectedIndex.subject === subjectList[subjectList.length - 1][0]
                                }
                                data-testid="next_image"
                              >
                                Next
                              </Button>
                            </Box>
                          )}
                        </Grid>
                      </Grid>
                      {matchedImage(selectedIndex.cylinder, selectedIndex.subject) && (
                        <div>
                          {!previewFlag ? (
                            <ViewImage
                              aircraftId={aircraftId}
                              imageSet={dataImageSet?.me?.aircraft[0]?.borescopeImageSets[0]}
                              borescopeImageId={matchedImage(selectedIndex.cylinder, selectedIndex.subject)?.id || ''}
                              image={matchedImage(selectedIndex.cylinder, selectedIndex.subject)}
                              statuses={matchedChoices(selectedIndex.subject)}
                              onClickNext={onClickNext}
                              comments={comments}
                              isAvailableToTag={isAvailableToTag}
                              isOwn={true}
                            />
                          ) : (
                            <BorescopeReportContext
                              imageSet={dataBorescopeReport?.me?.aircraft[0]?.borescopeImageSets[0]}
                              aircraft={dataBorescopeReport?.me?.aircraft[0]}
                              downloadReport={downloadBorescopeReport}
                              cylinderCount={cylinderCount}
                              images={images}
                              subjectList={dataSubjectsList?.borescopeImageSubjectsList || []}
                            />
                          )}
                        </div>
                      )}
                    </Box>
                  )}
                  {pageIndex === 1 && (
                    <Box
                      sx={{
                        flexGrow: 1,
                      }}
                    >
                      <PreviewReport
                        aircraftId={aircraftId}
                        borescopeImageSetId={borescopeImageSetId}
                        message={message}
                        setMessage={setMessage}
                        messageError={messageError}
                        setMessageError={setMessageError}
                        autoCloseTicket={autoCloseTicket}
                        setAutoCloseTicket={setAutoCloseTicket}
                        sentReport={sentReport}
                        setLoadingPreviewReport={setLoadingPreviewReport}
                        loadingSendReport={loadingSendReport}
                        subjectList={subjectList}
                        cylinderCount={cylinderCount}
                        isOwn={true}
                      />
                    </Box>
                  )}
                </Box>
                <Box
                  sx={{
                    position: 'relative',
                    height: '100%',
                    borderLeft: '1px solid',
                    borderColor: '#E9EAEF',
                  }}
                >
                  <Collapse
                    orientation="horizontal"
                    in={showInfo}
                    sx={{
                      height: '100%',
                      overflowY: 'auto',
                    }}
                  >
                    <Information
                      imageSet={dataImageSet?.me?.aircraft[0]?.borescopeImageSets[0]}
                      includingBad={includingBad}
                      setShowRequest={setShowRequest}
                      sendAndBack={sendAndBack}
                      buttonLabel={pageIndex === 0 ? 'Complete Report' : 'Back'}
                      nextDisabled={
                        dataImageSet?.me?.aircraft[0]?.borescopeImageSets[0]?.status === BORESCOPE_SENT_STATUS && pageIndex === 0
                      }
                      aircraft={dataImageSet?.me?.aircraft[0]}
                      comments={comments}
                      setComments={setComments}
                    />
                  </Collapse>
                  <IconButton
                    sx={{
                      position: 'absolute',
                      left: 0,
                      top: '40%',
                      transform: 'translate(-50%, -50%)',
                      '&:hover': {
                        backgroundColor: 'background.default',
                      },
                      borderRadius: '6px',
                      boxShadow: '0px 1px 1px rgba(19, 30, 64, 0.05)',
                      border: '1px solid',
                      borderColor: 'grey.200',
                      backgroundColor: 'background.default',
                      zIndex: 999,
                    }}
                    onClick={() => {
                      setShowInfo(!showInfo);
                    }}
                  >
                    {showInfo ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                  </IconButton>
                </Box>
              </Box>
            </Box>
          </Box>
          {pageIndex === 1 && (
            <Box
              sx={{
                backgroundColor: 'background.default',
                borderTop: '1px solid',
                borderColor: '#E4E6EC',
                boxShadow: '0px 1px 15px 0px #2E313833',
                textAlign: 'center',
              }}
              py={2}
            >
              <Button
                variant="contained"
                sx={{
                  minWidth: '140px',
                }}
                onClick={() => {
                  setShowComplete(true);
                }}
                disabled={
                  loadingPreviewReport ||
                  loadingSendReport ||
                  dataImageSet?.me?.aircraft[0]?.borescopeImageSets[0]?.status === BORESCOPE_SENT_STATUS
                }
                data-testid="report_send"
              >
                Complete Report
              </Button>
            </Box>
          )}
        </Box>
      </ComponentLoading>
      <RequestImageDialog
        open={showRequest}
        setOpen={setShowRequest}
        propsPost={dataImageSet?.me?.aircraft[0]?.borescopeImageSets[0]?.badImageUpdateRequestPostDraft || ''}
        requestUpdatedImageAction={requestUpdatedImageAction}
        loading={loadingRequestUpdatedImages}
      />
      <WarningDialog open={showWarning} setOpen={setShowWarning} warningList={warningList} />
      <DeleteDialog
        open={showClear}
        setOpen={setShowClear}
        onSubmitAction={onClearTag}
        title="Clear tag?"
        text="Are you sure to clear all images tag?"
      />
      <DeleteDialog
        open={showComplete}
        setOpen={setShowComplete}
        onSubmitAction={onSubmit}
        title="Complete Report?"
        text="Continuing will make the report available and finalize this image set (no further tagging)."
      />
    </Box>
  );
};

export default BorescopeOwnAnalysisReports;
