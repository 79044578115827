import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, NavLink } from 'react-router-dom';
import { Dialog, Box, Typography, DialogContent, DialogTitle, TextField, Button, MenuItem, Grid } from '@mui/material';

import { useMutation } from '@apollo/client';
import { MUTATION_TRANSFER_SUBSCRIPTION_TO_OWNED_AIRCRAFT } from 'gql';

import { ComponentLoading } from 'components';
import { IAircraft } from 'types';
import { PATH_AIRCRAFT_CREATE } from 'const';
import { aircraftRegistrationMakeModel } from 'helpers';
import { setAlert } from 'state';

interface TransferPlanToAnotherAircraftProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  aircrafts: IAircraft[];
}

const TransferPlanToAnotherAircraft: React.FC<TransferPlanToAnotherAircraftProps> = (props) => {
  const { open, setOpen, aircrafts } = props;

  const { id } = useParams();

  const dispatch = useDispatch();

  const [transferToAircraft, { data, loading, error }] = useMutation(MUTATION_TRANSFER_SUBSCRIPTION_TO_OWNED_AIRCRAFT);

  const [selectedAircraftId, setSelectedAircraftId] = useState('');
  const [aircraftError, setAircraftError] = useState('');

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    if (!selectedAircraftId) {
      setAircraftError('Please select aircraft to transfer.');
      return;
    }

    await transferToAircraft({
      variables: {
        sourceAircraftId: id,
        targetAircraftId: selectedAircraftId,
      },
    });
  };

  useEffect(() => {
    if (error) {
      dispatch(setAlert('error', 'Unable to transfer plan to another aircraft'));
    } else if (data) {
      if (data.transferSubscriptionsToOwnedAircraft?.ok) {
        dispatch(setAlert('success', 'Transferred plan to another aircraft'));
        setOpen(false);
      } else {
        dispatch(setAlert('error', data.transferSubscriptionsToOwnedAircraft?.error || 'Unable to transfer plan to another aircraft'));
      }
    }
  }, [data, error, dispatch, setOpen]);

  return (
    <Dialog open={open} fullWidth onClose={handleClose}>
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontSize: '24px',
          fontWeight: 700,
        }}
      >
        Transfer plan to another aircraft
      </DialogTitle>
      <DialogContent>
        <ComponentLoading loading={loading}>
          <Box>
            <Typography variant="body1" align="center" gutterBottom>
              Transfer your plan to a different aircraft you own. Select the aircraft from the list below or{' '}
              <NavLink to={PATH_AIRCRAFT_CREATE}>add a new aircraft</NavLink>.
            </Typography>
            <TextField
              select
              label="Click here to select aircraft..."
              value={selectedAircraftId}
              onChange={(e) => {
                setSelectedAircraftId(e.target.value);
                setAircraftError('');
              }}
              fullWidth
              error={Boolean(aircraftError)}
              helperText={aircraftError}
              FormHelperTextProps={{
                sx: {
                  ml: 0,
                },
              }}
            >
              {aircrafts?.map((item: IAircraft, key: number) => {
                if (item.id === id) return null;
                return (
                  <MenuItem key={key} value={item.id} title={aircraftRegistrationMakeModel(item)}>
                    <Typography
                      sx={{
                        fontSize: '18px',
                        fontWeight: 'bold',
                        lineHeight: '25px',
                      }}
                    >
                      {aircraftRegistrationMakeModel(item)}
                    </Typography>
                  </MenuItem>
                );
              })}
            </TextField>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button color="secondary" variant="outlined" onClick={handleClose} fullWidth>
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  onSubmit();
                }}
                fullWidth
              >
                Transfer
              </Button>
            </Grid>
          </Grid>
        </ComponentLoading>
      </DialogContent>
    </Dialog>
  );
};

export default TransferPlanToAnotherAircraft;
