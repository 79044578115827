import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import { useLazyQuery } from '@apollo/client';
import { QUERY_SHARED_FLIGHT_BY_ID } from 'gql';
import { PageLoading } from 'components';
import { setAlert } from 'state';

import { SharedFlightChart, FlightInfo } from './components';
import { ModeManager } from './components/chartComponents';

const modeManager = new ModeManager();

const SharedFlightView: React.FC = () => {
  const [fetchFlightData, { data: dataFlight, loading: loadingFlight, error: errorFlight }] = useLazyQuery(QUERY_SHARED_FLIGHT_BY_ID, {
    errorPolicy: 'all',
  });

  const { id, uuid } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    if (id && uuid) {
      fetchFlightData({
        variables: {
          flightId: id,
          flightUuid: uuid,
        },
      });
    }
  }, [id, uuid, fetchFlightData]);

  useEffect(() => {
    if (errorFlight) {
      dispatch(setAlert('error', 'Unable to load flight data.'));
    } else if (dataFlight) {
      if (!dataFlight.sharedFlight?.id || !dataFlight.sharedFlight.publicUuid) {
        dispatch(setAlert('error', 'Unable to load flight data'));
      }
    }
  }, [errorFlight, dispatch, dataFlight]);

  const [diffData, setDiffData] = useState<any>({});

  const [analysisMode, setAnalysisMode] = useState('');

  const [chartNum, setChartNum] = useState(2);
  const [showInfo, setShowInfo] = useState(true);

  return (
    <Box>
      {loadingFlight && <PageLoading />}
      <Box
        flexGrow={1}
        width="100%"
        sx={{
          mb: 1,
        }}
      >
        <Box
          sx={{
            minHeight: {
              lg: 'calc(100% - 75px)',
              xs: 'inherit',
            },
            display: {
              lg: 'flex',
              xs: 'block',
            },
            p: {
              lg: 3,
              xs: 1,
            },
            overflowX: 'hidden',
          }}
        >
          {dataFlight && dataFlight.sharedFlight && (
            <SharedFlightChart
              flightData={dataFlight.sharedFlight}
              modeManager={modeManager}
              setDiffData={setDiffData}
              analysisMode={analysisMode}
              chartNum={chartNum}
              isShare={true}
              showInfo={showInfo}
              setShowInfo={setShowInfo}
              settings={undefined}
            />
          )}
          {dataFlight && dataFlight.sharedFlight && (
            <FlightInfo
              flightData={dataFlight.sharedFlight}
              modeManager={modeManager}
              diffData={diffData}
              analysisMode={analysisMode}
              setAnalysisMode={setAnalysisMode}
              chartNum={chartNum}
              setChartNum={setChartNum}
              isShare={true}
              showInfo={showInfo}
              settings={undefined}
              aircraftSettings={[]}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SharedFlightView;
