import React, { useState, useEffect, useCallback } from 'react';

import { Navigate, useParams } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { CREATE_ACCOUNT_ERROR, SET_SIGNUP_INFO } from 'state/types';

import { useMutation, useLazyQuery } from '@apollo/client';
import { MUTATION_CREATE_ACCOUNT, QUERY_ELIGIBLE_TYPE_SERVICE, QUERY_ELIGIBLE_MODEL_SERVICE } from 'gql';

import { Typography, Grid, Radio, RadioGroup, FormControl, FormControlLabel, FormHelperText, Box } from '@mui/material';

import { Subsection, ButtonSubmit, SignupEmail, SignupPassword } from 'components';

import { AircraftMakeModel } from './';

import { useAutocomplete, useService } from 'hooks';
import * as Yup from 'yup';
import { FormGroupInput } from 'components';
import { Formik, Form } from 'formik';

import { isEmpty, handleSendError, snakeToCamelCase } from 'helpers';

import { logAmplitudeEvent } from 'services';

import { SIGNUP_PAGE_LOADED_SAVVY, SIGNUP_CREATE_ACCOUNT_ERROR, SAVVY_MX, FORM_FIELDS_REQUIRED_ERORR } from 'const';

import { IModelAdditionalFields } from 'types';

const validationSchema = Yup.object({
  email: Yup.string()
    .email('Invalid email')
    .min(3, 'Too short: 3-50 characters required')
    .max(50, 'Too long: 3-50 characters required')
    .required('Required'),
  password: Yup.string().min(8, 'Too short: 8-30 characters required').max(30, 'Too long: 8-30 characters required').required('Required'),
  firstName: Yup.string().min(1, 'Too short: 1-20 characters required').max(20, 'Too long: 1-20 characters required').required('Required'),
  lastName: Yup.string().min(1, 'Too short: 1-20 characters required').max(20, 'Too long: 1-20 characters required').required('Required'),
  registrationNumber: Yup.string().max(20, 'Too long: Maximum 20 characters').required('Required'),
});

interface SignupFormValues {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  registrationNumber: string;
}

interface SignupServiceFormProps {
  setServicePlan?: (arg0: number) => void;
}

const SignupServiceForm: React.FC<SignupServiceFormProps> = (props) => {
  const { setServicePlan } = props;
  const dispatch = useDispatch();
  const { service } = useParams(); // Get service Id from url (Router slug - Router.tsx)
  const serviceObject = useService();
  const [prebuy, setPrebuy] = useState('0');
  const [prebuyError, setPrebuyError] = useState('');

  useEffect(() => {
    // Amplitude Page Loaded
    logAmplitudeEvent(`${SIGNUP_PAGE_LOADED_SAVVY}${service?.toUpperCase()}`);
  }, [service]);

  // Manufacturer + Add Aircraft Dialog
  const {
    value: manufacturer,
    setValue: setManufacturer,
    error: errorManufacturer,
    setError: setErrorManufacturer,
    onChange: onChangeManufacturer,
  } = useAutocomplete();
  const { value: model, setValue: setModel, error: errorModel, setError: setErrorModel, onChange: onChangeModel } = useAutocomplete();

  const [emailError, setEmailError] = useState('');
  const [submitParam, setSubmitParam] = useState<any>({});

  const [eligibleTypeForService, { loading: loadingEligibleTypeForService, data: dataEligibleTypeForService }] = useLazyQuery(
    QUERY_ELIGIBLE_TYPE_SERVICE,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [eligibleModelForService, { loading: loadingEligibleModelForService, data: dataEligibleModelForService }] = useLazyQuery(
    QUERY_ELIGIBLE_MODEL_SERVICE,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [additionalFields, setAdditionalFields] = useState<IModelAdditionalFields | null>(null);

  const [createAccount, { data: dataCreateAccount, loading: loadingCreateAccount, error: createAccountError }] =
    useMutation(MUTATION_CREATE_ACCOUNT);

  const [responseError, setResponseError] = useState<any[]>([]);

  const onSubmit = async (values: SignupFormValues) => {
    if (manufacturer === null || isEmpty(manufacturer.name)) {
      setErrorManufacturer('Required');
      return;
    }

    if (model === null || isEmpty(model.name)) {
      setErrorModel('Required');
      return;
    }

    if (service === SAVVY_MX && prebuy === '0') {
      setPrebuyError('Please select an option...');
      return;
    }

    setSubmitParam({
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      password: values.password,
      phone: 'last name',
      registration: values.registrationNumber,
      aircraft_manufacturer_id: parseInt(manufacturer?.id),
      aircraft_manufacturer_name: manufacturer?.id ? null : manufacturer.name,
      aircraft_model_id: parseInt(model?.id),
      aircraft_model_name: model?.id ? null : model.name,
      certification: model?.id ? null : additionalFields?.certification,
      propulsion: model?.id ? null : additionalFields?.propulsion,
      engineCount: model?.id ? null : additionalFields?.engine,
      prebuy: prebuy === '1' ? false : prebuy === '2' ? true : null,
    });

    if (model?.id) {
      await eligibleModelForService({
        variables: {
          serviceCode: service,
          aircraftModelId: model.id,
        },
      });
    } else {
      await eligibleTypeForService({
        variables: {
          propulsion: additionalFields?.propulsion,
          engineCount: additionalFields?.engine,
          serviceCode: service,
        },
      });
    }
  };

  const createAccountFunction = useCallback(() => {
    createAccount({
      variables: {
        ...submitParam,
      },
    });
  }, [createAccount, submitParam]);

  const displayTypeError = useCallback(() => {
    if (additionalFields) {
      setErrorModel(
        `Sorry, but this aircraft type (${additionalFields?.engine} ${additionalFields?.propulsion}) is not eligible for ${serviceObject?.name}.`,
      );
    } else {
      setErrorModel(`Sorry, but this aircraft is not eligible for ${serviceObject?.name}.`);
    }
  }, [serviceObject, additionalFields, setErrorModel]);

  useEffect(() => {
    if (additionalFields) {
      return;
    }

    if (loadingEligibleModelForService) {
      setErrorModel('');
      return;
    }

    if (!loadingEligibleModelForService && dataEligibleModelForService?.eligibleModelForService) {
      createAccountFunction();
    } else if (!loadingEligibleModelForService && dataEligibleModelForService && !dataEligibleModelForService.eligibleModelForService) {
      displayTypeError();
    }
  }, [
    dataEligibleModelForService,
    setErrorModel,
    loadingEligibleModelForService,
    createAccountFunction,
    displayTypeError,
    additionalFields,
  ]);

  useEffect(() => {
    if (!additionalFields) {
      return;
    }
    if (loadingEligibleTypeForService) {
      setErrorModel('');
      return;
    }

    if (dataEligibleTypeForService?.eligibleTypeForService) {
      createAccountFunction();
    } else if (dataEligibleTypeForService && !dataEligibleTypeForService.eligibleTypeForService) {
      displayTypeError();
    }
  }, [dataEligibleTypeForService, setErrorModel, loadingEligibleTypeForService, createAccountFunction, displayTypeError, additionalFields]);

  useEffect(() => {
    if (dataCreateAccount) {
      const { errors } = dataCreateAccount.createAccount;
      // Errors
      if (errors) {
        // Handle other Server Errors
        dispatch({ type: CREATE_ACCOUNT_ERROR });

        setResponseError(errors);

        // Amplitude
        logAmplitudeEvent(SIGNUP_CREATE_ACCOUNT_ERROR);
      } else {
        if (dataCreateAccount?.createAccount?.me?.aircraft[0]?.id) {
          dispatch({
            type: SET_SIGNUP_INFO,
            payload: {
              aircraftId: dataCreateAccount.createAccount.me.aircraft[0].id,
              subscriptionId: '',
            },
          });
        }

        setStepCompleted(true);
      }
    }
  }, [createAccount, dataCreateAccount, dispatch]);

  useEffect(() => {
    if (responseError.length > 0) {
      responseError.map((item: any) => {
        const fieldName = snakeToCamelCase(item.field);

        switch (fieldName) {
          case 'aircraftManufacturerId':
            setErrorManufacturer(item?.messages[0]);
            break;
          case 'aircraftManufacturerName':
            setErrorManufacturer(item?.messages[0]);
            break;
          case 'aircraftModelId':
            setErrorModel(item?.messages[0]);
            break;
          case 'aircraftModelName':
            setErrorModel(item?.messages[0]);
            break;
        }

        return fieldName;
      });
    } else {
      setErrorManufacturer('');
      setErrorModel('');
    }
  }, [responseError, setErrorManufacturer, setErrorModel]);

  const [stepCompleted, setStepCompleted] = useState(false);

  // Send Error
  createAccountError && handleSendError();

  // Redirect to Payment step
  if (stepCompleted)
    return (
      <Navigate
        to={`/signup/${service}/payment?category=${dataCreateAccount?.createAccount?.me?.aircraft[0]?.aircraftModel?.propulsion.toLocaleLowerCase()}-${dataCreateAccount?.createAccount?.me?.aircraft[0]?.aircraftModel?.enginesCount.toLocaleLowerCase()}`}
      />
    );

  // Default UI
  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        firstName: '',
        lastName: '',
        registrationNumber: '',
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ isSubmitting, handleChange, handleBlur, touched, errors }) => {
        if (emailError) {
          errors.email = emailError;
        }

        responseError &&
          responseError?.map((item: any) => {
            const fieldName = snakeToCamelCase(item.field);

            switch (fieldName) {
              case 'email':
                errors.email = item?.messages[0];
                break;
              case 'password':
                errors.password = item?.messages[0];
                break;
              case 'firstName':
                errors.firstName = item?.messages[0];
                break;
              case 'lastName':
                errors.lastName = item?.messages[0];
                break;
              case 'registration':
                errors.registrationNumber = item?.messages[0];
                break;
            }

            return fieldName;
          });

        return (
          <Form
            onChange={() => {
              setResponseError([]);
            }}
          >
            <Subsection>
              <Typography variant="h4" gutterBottom align="center">
                Account
              </Typography>
              <SignupEmail
                handleBlur={handleBlur}
                handleChange={handleChange}
                errors={errors}
                touched={touched}
                emailError={emailError}
                setEmailError={setEmailError}
              />

              <SignupPassword handleBlur={handleBlur} handleChange={handleChange} errors={errors} touched={touched} />

              <FormGroupInput
                name="firstName"
                label="First Name *"
                onBlur={handleBlur}
                onChange={handleChange}
                errors={errors}
                touched={touched}
                maxLength={20}
              />

              <FormGroupInput
                name="lastName"
                label="Last Name *"
                onBlur={handleBlur}
                onChange={handleChange}
                errors={errors}
                touched={touched}
                maxLength={20}
              />
            </Subsection>
            <Subsection>
              <Typography variant="h4" gutterBottom align="center">
                Aircraft
              </Typography>
              <FormGroupInput
                name="registrationNumber"
                label="Registration Number *"
                onBlur={handleBlur}
                onChange={(e) => {
                  handleChange(e);
                }}
                errors={errors}
                touched={touched}
                maxLength={20}
              />
              <AircraftMakeModel
                setServicePlan={setServicePlan}
                manufacturer={manufacturer}
                setManufacturer={setManufacturer}
                errorManufacturer={errorManufacturer}
                setErrorManufacturer={setErrorManufacturer}
                onChangeManufacturer={onChangeManufacturer}
                model={model}
                setModel={setModel}
                errorModel={errorModel}
                setErrorModel={setErrorModel}
                onChangeModel={onChangeModel}
                setAdditionalFields={setAdditionalFields}
                setResponseError={setResponseError}
              />
            </Subsection>
            {service === SAVVY_MX && (
              <Subsection>
                <FormControl
                  sx={{
                    p: 0,
                    display: 'block',
                    mb: 0,
                  }}
                  error={!!prebuyError}
                >
                  <Typography variant="h4" gutterBottom align="center">
                    Manage Prebuy?
                  </Typography>
                  <Box
                    sx={{
                      display: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={prebuy}
                      onChange={(e) => {
                        setPrebuy(e.target.value);
                        setPrebuyError('');
                      }}
                      sx={{
                        mb: 0,
                      }}
                    >
                      <FormControlLabel
                        value={'1'}
                        control={
                          <Radio
                            sx={{
                              color: '#BBC0D4',
                            }}
                          />
                        }
                        sx={{
                          color: 'grey.600',
                          display: 'inline-block',
                        }}
                        label="No: I already own this aircraft"
                      />
                      <FormControlLabel
                        value={'2'}
                        control={
                          <Radio
                            sx={{
                              color: '#BBC0D4',
                            }}
                          />
                        }
                        sx={{
                          color: 'grey.600',
                          display: 'inline-block',
                        }}
                        label="Yes: I am interested in buying this aircraft"
                      />
                    </RadioGroup>
                  </Box>
                  <FormHelperText
                    sx={{
                      textAlign: 'center',
                      p: 0,
                      m: 0,
                    }}
                  >
                    {prebuyError}
                  </FormHelperText>
                </FormControl>
              </Subsection>
            )}
            <Box mb={2.5}>
              <Grid container spacing={0} justifyContent="center">
                <Grid item xs={12} md={6}>
                  <ButtonSubmit
                    text="Next"
                    loading={isSubmitting || loadingCreateAccount}
                    disabled={isSubmitting || loadingCreateAccount}
                  />
                </Grid>
              </Grid>
            </Box>
            <Subsection>
              <Typography paragraph color="textSecondary" align="center">
                {FORM_FIELDS_REQUIRED_ERORR}
              </Typography>
            </Subsection>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SignupServiceForm;
