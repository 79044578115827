import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, IconButton, Popover, Typography, TextField, MenuItem, Divider, Button } from '@mui/material';
import {
  DataGridPro,
  GridRenderCellParams,
  GridRowParams,
  GridSelectionModel,
  GridFilterModel,
  GridSortModel,
  getGridDateOperators,
} from '@mui/x-data-grid-pro';

import { QUERY_USER_AIRCRAFTS_WITHOUT_SUBSCRIPTION, QUERY_USER_FLIGHTS_PER_AIRCRAFT } from 'gql';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { PageLoading, ComponentLoading, Checkbox, CustomGridToolbar, CustomGridSelectionFooter } from 'components';
import { setAlert, setFlightTableConfig } from 'state';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { MUTATION_DELETE_FLIGHTS } from 'gql';
import { PATH_FLIGHT_VIEW, PATH_FLIGHTS, PATH_AIRCRAFT_CREATE } from 'const';
import { IAircraft, IFlight } from 'types';
import { aircraftRegistrationMakeModel, ctrlCmdClick } from 'helpers';
import { useTableConfig } from 'hooks';

import { SET_TABLE_FLIGHT_FILTER, SET_TABLE_FLIGHT_SORT, SET_TABLE_FLIGHT_PAGE, SET_TABLE_FLIGHT_HIDE_SHORT } from 'state/types';

import { storeSelectedAircraft } from 'state';

const FlightsTable: React.FC = () => {
  const { data, loading, error } = useQuery(QUERY_USER_AIRCRAFTS_WITHOUT_SUBSCRIPTION);
  const [fetchFlights, { data: dataFlights, loading: loadingFlights, error: errorFlights }] = useLazyQuery(
    QUERY_USER_FLIGHTS_PER_AIRCRAFT,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { aircraftId } = useParams();

  const [flights, setFlights] = useState<any[]>([]);

  const [selectedFlight, setSelectedFlight] = useState<string[]>([]);
  const [selectedAircraftId, setSelectedAircraftId] = useState('');

  const { storedAircraftId } = useSelector((state: any) => state.auth);

  const [deleteFlights, { data: dataDeleteFlights, error: errorDeleteFlights, loading: loadingDeleteFlights }] =
    useMutation(MUTATION_DELETE_FLIGHTS);

  const { rowsPerPage: pageSize } = useSelector((state: any) => state.flight);
  const setPageSize = (rows: number) => {
    dispatch(
      setFlightTableConfig({
        rowsPerPage: rows,
      }),
    );
  };
  const { density, setDensity } = useTableConfig();
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const { flightFilter: filterModel, page, sortModel, hideShortFlights } = useSelector((state: any) => state.flight);
  const setFilterModel = (newFilterModel: GridFilterModel) => {
    dispatch({
      type: SET_TABLE_FLIGHT_FILTER,
      payload: {
        filterModel: newFilterModel,
      },
    });
  };
  const setPage = (pageNum: number) => {
    if (page !== pageNum) {
      dispatch({
        type: SET_TABLE_FLIGHT_PAGE,
        payload: {
          page: pageNum,
        },
      });
    }
  };
  const setSortModel = (newSortModel: GridSortModel) => {
    if (sortModel !== newSortModel) {
      dispatch({
        type: SET_TABLE_FLIGHT_SORT,
        payload: {
          sortModel: newSortModel,
        },
      });
    }
  };
  const setHideShortFlights = (value: boolean) => {
    if (value !== hideShortFlights) {
      dispatch({
        type: SET_TABLE_FLIGHT_HIDE_SHORT,
        payload: {
          hideShortFlights: value,
        },
      });
    }
  };
  const [filterButtonEl, setFilterButtonEl] = useState<HTMLButtonElement | null>(null);

  const deleteSelectedFlight = async (selectedFlights: GridSelectionModel) => {
    if (selectedFlights.length)
      await deleteFlights({
        variables: {
          flightIds: selectedFlights as string[],
        },
      });
  };

  useEffect(() => {
    if (error) {
      dispatch(setAlert('error', 'Unable to load your flights.'));
    }
  }, [error, dispatch]);

  useEffect(() => {
    if (aircraftId) {
      setSelectedAircraftId(aircraftId);
    } else {
      if (data?.me?.aircraft) {
        if (storedAircraftId) {
          navigate(`${PATH_FLIGHTS}/aircraft/${storedAircraftId}`);
        } else {
          data?.me?.aircraft[0]?.id && navigate(`${PATH_FLIGHTS}/aircraft/${data?.me?.aircraft[0]?.id}`);
        }
      }
    }
  }, [aircraftId, data, navigate, storedAircraftId]);

  useEffect(() => {
    if (selectedAircraftId) {
      fetchFlights({
        variables: {
          id: parseInt(selectedAircraftId),
          hideShortFlights,
        },
      });
    }
  }, [selectedAircraftId, fetchFlights, hideShortFlights]);

  useEffect(() => {
    if (errorFlights) {
      dispatch(setAlert('error', 'Unable to load your flights.'));
    } else if (dataFlights) {
      let tmp_flights: any[] = [];
      let tmp_flight: any = {};
      dataFlights.me?.aircraft[0]?.flights?.forEach((flight: any) => {
        tmp_flight = {
          ...flight,
          importFile: flight.importFile.name,
          duration: !flight.duration
            ? ''
            : `${Math.floor(flight.duration / 3600)}h ${(Math.floor((flight.duration % 3600) / 60) < 10 ? '0' : '') + Math.floor((flight.duration % 3600) / 60)}m`,
          chtTemperatureAlert: flight.chtTemperatureAlert ? 'HI CHT' : '',
        };
        tmp_flights.push(tmp_flight);
      });
      setFlights(tmp_flights);
    }
  }, [dataFlights, errorFlights, dispatch]);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteFlightFromRow = async (removeFlights: string[]) => {
    await deleteFlights({
      variables: {
        flightIds: [...removeFlights],
      },
    });

    const removeIndex = flights.findIndex((flight: any) => flight.id === removeFlights[0]);
    const tmp = [...flights];

    if (removeIndex !== undefined) {
      tmp.splice(removeIndex, 1);
      setFlights([...tmp]);
    }
  };

  const changeShortFlgihts = async () => {
    setHideShortFlights(!hideShortFlights);
  };

  useEffect(() => {
    if (errorDeleteFlights) {
      dispatch(setAlert('error', 'Unable to delete selected flights'));
    } else if (dataDeleteFlights) {
      if (dataDeleteFlights.deleteFlights?.ok) {
        dispatch(setAlert('success', 'Deleted selected flights'));
        if (selectedAircraftId) {
          fetchFlights({
            variables: {
              id: parseInt(selectedAircraftId),
              hideShortFlights,
            },
          });
        }
      } else {
        dispatch(setAlert('error', dataDeleteFlights.deleteFlights?.error || 'Unable to delete selected flights'));
      }
    }
  }, [errorDeleteFlights, dataDeleteFlights, dispatch, selectedAircraftId, fetchFlights, hideShortFlights]);

  return (
    <Box
      p={{
        md: 0,
        lg: 3.75,
        xl: 5,
      }}
    >
      {(loading || loadingDeleteFlights) && <PageLoading />}
      <ComponentLoading loading={loadingFlights}>
        <Box
          sx={{
            minHeight: '500px',
          }}
        >
          {data?.me?.aircraft && data?.me?.aircraft?.length !== 0 && (
            <Box
              py={2}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <TextField
                select
                value={selectedAircraftId}
                variant="outlined"
                sx={{
                  marginBottom: 0,
                  minWidth: '300px',
                }}
                onChange={(e: any) => {
                  dispatch(storeSelectedAircraft(e.target.value));
                  navigate(`${PATH_FLIGHTS}/aircraft/${e.target.value}`);
                }}
              >
                {data.me.aircraft.map((item: IAircraft, key: number) => {
                  return (
                    <MenuItem key={key} value={item.id} title={aircraftRegistrationMakeModel(item)}>
                      <Typography
                        sx={{
                          fontSize: '16px',
                          fontWeight: 'bold',
                          color: 'text.primary',
                          lineHeight: '27px',
                        }}
                      >
                        {aircraftRegistrationMakeModel(item)}
                      </Typography>
                    </MenuItem>
                  );
                })}
              </TextField>
              <Box
                sx={{
                  textAlign: 'right',
                }}
              >
                <Box
                  mb={1}
                  sx={{
                    display: 'inline-flex',
                  }}
                >
                  <Checkbox
                    value={hideShortFlights}
                    checked={hideShortFlights}
                    label={
                      <Typography
                        sx={{
                          display: 'inline-block',
                        }}
                      >
                        Hide short flights
                      </Typography>
                    }
                    onChange={() => {
                      changeShortFlgihts();
                    }}
                    noMargin
                  />
                </Box>
              </Box>
            </Box>
          )}
          {data?.me?.aircraft && data?.me?.aircraft?.length !== 0 && (
            <DataGridPro
              sx={{
                width: '100%',
                minWidth: '100px',
                '& .MuiDataGrid-columnSeparator': {
                  display: 'none',
                },
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-cell': {
                  padding: '10px',
                },
                '& .MuiDataGrid-row': {
                  cursor: 'pointer',
                  borderBottom: '1px solid',
                  borderColor: 'grey.200',
                },
                '& .MuiDataGrid-columnHeader:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-cellContent': {
                  whiteSpace: 'break-spaces',
                },
                '& .MuiDataGrid-toolbarContainer .MuiTextField-root': {
                  mb: 0,
                },
                border: 'none',
              }}
              autoHeight
              getRowHeight={() => 'auto'}
              disableColumnResize={true}
              checkboxSelection
              checkboxSelectionVisibleOnly
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              pageSize={pageSize}
              onPageSizeChange={(newPage) => setPageSize(newPage)}
              pagination
              page={page}
              onPageChange={(newPage) => setPage(newPage)}
              sortModel={sortModel}
              onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
              showColumnRightBorder={false}
              disableColumnSelector
              disableColumnMenu
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
              density={density}
              onStateChange={(params) => {
                if (params.density && params.density.value !== density) {
                  setDensity(params.density.value);
                }
              }}
              filterModel={filterModel}
              onFilterModelChange={(newFilterModel) => {
                setFilterModel(newFilterModel);
              }}
              selectionModel={selectionModel}
              rows={flights ? flights : []}
              columns={[
                {
                  field: 'date',
                  headerName: 'Date',
                  valueFormatter: (params) => {
                    return params.value ? params.value.toString().substring(0, 10) : '';
                  },
                  flex: 0.5,
                  filterOperators: getGridDateOperators(),
                },
                {
                  field: 'departureAirport',
                  headerName: 'Departure Airport',
                  flex: 1,
                },
                {
                  field: 'destinationAirport',
                  headerName: 'Destination Airport',
                  flex: 1,
                },
                {
                  field: 'importFile',
                  headerName: 'File',
                  flex: 1,
                },
                {
                  field: 'duration',
                  headerName: 'Duration',
                  flex: 0.5,
                },
                {
                  field: 'chtTemperatureAlert',
                  headerName: 'High CHT Alert',
                  valueFormatter: (params) => {
                    return params.value ? 'HI CHT' : '';
                  },
                  flex: 0.5,
                },
                {
                  field: 'id',
                  headerName: '',
                  renderCell: (params: GridRenderCellParams<string>) => {
                    return (
                      <IconButton
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                          e.preventDefault();
                          e.stopPropagation();
                          if (selectedFlight.length > 1) {
                            return false;
                          }
                          setAnchorEl(e.currentTarget);
                          setSelectedFlight([params?.value || '']);
                        }}
                        sx={{
                          padding: 0,
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    );
                  },
                  sortable: false,
                },
              ]}
              onRowClick={(params: GridRowParams, event?: any) => {
                const { row } = params;
                if (row.id) {
                  event?.preventDefault();
                  event?.stopPropagation();
                  ctrlCmdClick(event, `${PATH_FLIGHT_VIEW}/${row.id}`, navigate);
                } else {
                  return false;
                }
              }}
              components={{
                Toolbar: CustomGridToolbar,
                Footer: CustomGridSelectionFooter,
              }}
              componentsProps={{
                toolbar: {
                  setFilterButtonEl,
                },
                filterPanel: {
                  sx: {
                    '& .MuiDataGrid-filterForm': {
                      '& .MuiTextField-root': {
                        mb: 0,
                      },
                    },
                  },
                },
                panel: {
                  anchorEl: filterButtonEl,
                  placement: 'bottom-end',
                },
                footer: {
                  selection: selectionModel,
                  setSelection: setSelectionModel,
                  fullData: flights.map((item: IFlight) => item.id),
                  pageSize,
                  deleteAction: deleteSelectedFlight,
                },
              }}
            />
          )}
          {(!data?.me?.aircraft || data?.me?.aircraft?.length === 0) && (
            <Box>
              <Typography
                textAlign="center"
                sx={{
                  fontSize: '20px',
                }}
              >
                No aircraft found.{' '}
                <Button
                  variant="contained"
                  onClick={() => {
                    navigate(PATH_AIRCRAFT_CREATE);
                  }}
                >
                  Add Aircraft...
                </Button>
              </Typography>
            </Box>
          )}
        </Box>
      </ComponentLoading>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        <Typography
          paragraph
          sx={{
            padding: '10px',
            marginBlock: '0',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: 'grey.200',
            },
          }}
          onClick={() => {
            deleteFlightFromRow(selectedFlight);
            setAnchorEl(null);
          }}
        >
          Delete Flight
        </Typography>
        <Divider
          sx={{
            width: '100%',
          }}
        />
        <Typography
          paragraph
          sx={{
            padding: '10px',
            marginBlock: '0',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: 'grey.200',
            },
          }}
          onClick={() => {
            window.open(`${PATH_FLIGHT_VIEW}/${selectedFlight}`, '_blank');
            setAnchorEl(null);
          }}
        >
          Open in New Tab
        </Typography>
      </Popover>
    </Box>
  );
};

export default FlightsTable;
