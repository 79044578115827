import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Collapse, Typography, IconButton, Badge, Tooltip } from '@mui/material';
import { ISavvyTicket, IContactType } from 'types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { PersonIcon } from 'customIcons';
import HandymanIcon from '@mui/icons-material/Handyman';
import FlagIcon from '@mui/icons-material/Flag';
import { humanDate, includeTicketSubject } from 'helpers';
import { WatcherColor } from './TicketInfoComponents';

interface ITicketGroup {
  name: string;
  description: string;
  tickets: ISavvyTicket[];
}

interface SubCollapsedTicketsProps {
  group: ITicketGroup;
  clickTicket: (event: any, value: string) => void;
  subjectSearch: string;
  checkUnread: boolean;
}

const SubCollapsedTickets: React.FC<SubCollapsedTicketsProps> = (props) => {
  const { group, clickTicket, subjectSearch, checkUnread } = props;

  const [collpased, setCollpased] = useState(true);

  const [showWatcherColorAnchorEl, setShowWatcherColorAnchorEl] = useState<HTMLElement | null>(null);
  const [ticketId, setTicketId] = useState('');

  const { id } = useParams();

  const RenderLastPost = (date: Date, poster: IContactType) => {
    return (
      <Box
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
        }}
      >
        <Typography>{humanDate(date)} by</Typography>
        {poster?.role === 'M' && (
          <Box
            component="img"
            src="/images/logo.png"
            sx={{
              width: '14px',
              height: '14px',
              mx: 1,
            }}
          />
        )}
        {poster?.role === 'C' && (
          <PersonIcon
            sx={{
              color: 'warning.main',
              mx: 1,
              width: '14px',
              height: '14px',
            }}
          />
        )}
        {poster?.role === 'T' && (
          <HandymanIcon
            sx={{
              color: 'success.main',
              mx: 1,
              width: '20px',
              height: '20px',
            }}
          />
        )}
        <Typography>
          {poster?.firstName} {poster?.lastName}
        </Typography>
      </Box>
    );
  };

  const RenderFlag = (color: string, ticketId: string) => {
    return (
      <Box
        onClick={(e: React.MouseEvent<HTMLDivElement>) => {
          e.preventDefault();
          e.stopPropagation();
          setShowWatcherColorAnchorEl(e.currentTarget);
          setTicketId(ticketId);
        }}
      >
        <FlagIcon
          sx={{
            fill: color ? color : '#e5e5e5',
          }}
        />
      </Box>
    );
  };

  return (
    <Box>
      <Box
        sx={{
          py: 1,
          pl: {
            xs: 2,
            sm: 3,
            md: 4,
            lg: 5,
            xl: 7,
          },
          pr: 2,
          background: '#E8E9EF',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
        }}
        onClick={() => {
          setCollpased(!collpased);
        }}
      >
        <Typography
          paragraph
          color="textPrimary"
          sx={{
            marginBottom: 0,
            fontSize: '14px',
            lineHeight: '19px',
            fontWeight: 'bold',
          }}
        >
          {group.name}
        </Typography>
        <IconButton>{collpased ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</IconButton>
      </Box>
      <Collapse in={collpased}>
        <Box
          sx={{
            background: {
              xs: '#FFFFFF',
              lg: 'inherit',
            },
          }}
        >
          {[...group.tickets]
            ?.sort((ticket1, ticket2) => {
              return ticket1.firstUnreadDatetime > ticket2.firstUnreadDatetime ? 1 : -1;
            })
            ?.map((ticket: ISavvyTicket, key: number) => {
              if (ticket.state.toLowerCase() === 'c') return null;
              if (!!subjectSearch && !includeTicketSubject(ticket, subjectSearch)) {
                return null;
              }
              if (checkUnread && !ticket.hasUnread) {
                return null;
              }
              return (
                <Box
                  key={key}
                  py={2}
                  sx={{
                    cursor: 'pointer',
                    px: {
                      xs: 2,
                      sm: 3,
                      md: 4,
                      lg: 5,
                      xl: 7,
                    },
                    background: id && id === ticket.id ? '#FFFFFF' : 'none',
                  }}
                  onClick={(event) => {
                    clickTicket(event, ticket.id);
                  }}
                >
                  {ticket.hasUnread && !!ticket.unreadCount && (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {ticket.unreadCount && (
                          <Badge
                            badgeContent={ticket.unreadCount}
                            color="primary"
                            sx={{
                              mr: 3,
                              '& .MuiBadge-badge': {
                                transform: 'scale(1) translate(-30%, -50%)',
                                minWidth: '25px',
                                height: '25px',
                                borderRadius: '50%',
                              },
                            }}
                            anchorOrigin={{
                              horizontal: 'left',
                              vertical: 'top',
                            }}
                          ></Badge>
                        )}
                        <Typography
                          variant="body1"
                          sx={{
                            fontSize: '16px',
                            lineHeight: '22px',
                            color: 'text.secondary',
                          }}
                        >
                          {ticket.subject}
                        </Typography>
                      </Box>
                      {RenderFlag(ticket.color, ticket.id)}
                    </Box>
                  )}
                  {(!ticket.hasUnread || !ticket.unreadCount) && (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: '16px',
                          lineHeight: '22px',
                          color: 'text.secondary',
                        }}
                      >
                        {ticket.subject}
                      </Typography>
                      {RenderFlag(ticket.color, ticket.id)}
                    </Box>
                  )}
                  {ticket?.aircraft && (
                    <Typography
                      sx={{
                        fontSize: '12px',
                        fontWeight: 600,
                      }}
                    >
                      <span style={{ fontWeight: 400 }}>Client: </span>
                      {`${ticket?.aircraft?.registration} ${ticket?.aircraft?.owner?.firstName} ${ticket?.aircraft?.owner?.lastName}`}
                    </Typography>
                  )}
                  {Boolean(ticket?.firstUnreadDatetime) && (
                    <Tooltip title={ticket.updated.toString()} placement="right-start">
                      {RenderLastPost(ticket.firstUnreadDatetime, ticket?.lastPoster)}
                    </Tooltip>
                  )}
                  <Box></Box>
                </Box>
              );
            })}
        </Box>
      </Collapse>
      <WatcherColor setOpen={setShowWatcherColorAnchorEl} anchorEl={showWatcherColorAnchorEl} ticketId={ticketId} />
    </Box>
  );
};

export default SubCollapsedTickets;
