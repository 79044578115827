import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Divider, Button, Link } from '@mui/material';
import { IUpgradeCard } from 'types';

interface UpgradeCardProps {
  card: IUpgradeCard;
  aircraft: any;
}

const UpgradeCard: React.FC<UpgradeCardProps> = (props) => {
  const { card, aircraft } = props;
  const navigate = useNavigate();

  return (
    <Box
      p={4.75}
      m={2.5}
      sx={{
        width: '300px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '6px',
        backgroundColor: 'grey.200',
      }}
    >
      <Typography
        sx={{
          fontSize: '20px',
          color: card.titleColor,
          lineHeight: '28px',
        }}
        gutterBottom
      >
        {card.title}
      </Typography>
      <Typography
        paragraph
        sx={{
          lineHeight: '21px',
          height: '100%',
          textAlign: 'left',
        }}
      >
        {card.description}
      </Typography>
      <Divider
        sx={{
          width: '100%',
          mb: 2,
        }}
      />
      <Box
        px={2}
        sx={{
          width: '100%',
          minHeight: '80px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            '& p': {
              fontSize: '18px',
            },
            py: 0.5,
          }}
        >
          <Typography
            sx={{
              fontWeight: 'bold',
            }}
          >
            Price
          </Typography>
          <Typography>${card.price}</Typography>
        </Box>
        {card.credit !== 0 && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              '& p': {
                fontSize: '18px',
              },
              py: 0.5,
            }}
          >
            <Typography
              sx={{
                fontWeight: 'bold',
              }}
            >
              Credit
            </Typography>
            <Typography>(${card.credit})</Typography>
          </Box>
        )}
        {!!card.discount && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              '& p': {
                fontSize: '18px',
              },
              py: 0.5,
            }}
          >
            <Typography
              sx={{
                fontWeight: 'bold',
              }}
            >
              Discount
            </Typography>
            <Typography>(${card.discount})</Typography>
          </Box>
        )}
      </Box>
      <Box
        px={2}
        sx={{
          width: '100%',
        }}
      >
        <Divider
          sx={{
            width: '100%',
            my: 0.5,
          }}
        />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            '& p': {
              fontSize: '18px',
            },
            py: 0.5,
          }}
        >
          <Typography
            sx={{
              fontWeight: 'bold',
            }}
          >
            Total
          </Typography>
          <Typography>${card.total}</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          px: 2,
          width: '100%',
          py: 3,
        }}
      >
        <Button
          variant="contained"
          onClick={() => {
            if (card.actionLink) {
              navigate(
                card.actionLink +
                  `?category=${aircraft?.aircraftModel?.propulsion?.toLowerCase()}-${aircraft?.aircraftModel?.enginesCount?.toLowerCase()}`,
              );
            }
          }}
          fullWidth
        >
          {card.buttonText}
        </Button>
      </Box>
      <Link
        href={card.learnMoreLink}
        target="_blank"
        sx={{
          fontWeight: 'bold',
        }}
      >
        Learn More
      </Link>
    </Box>
  );
};

export default UpgradeCard;
