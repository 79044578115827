import { gql } from '@apollo/client';
import { FRAGMENT_TICKET_TAG } from 'gql';

export const MUTATION_ADD_POST_TO_TICKET = gql`
  ${FRAGMENT_TICKET_TAG}
  mutation AddPostToTicket(
    $body: String
    $ticketId: Int
    $visibility: String
    $documentIds: [ID]
    $existingAttachmentIds: [ID]
    $nothappy: Boolean
  ) {
    addPostToTicket(
      body: $body
      ticketId: $ticketId
      visibility: $visibility
      documentIds: $documentIds
      existingAttachmentIds: $existingAttachmentIds
      nothappy: $nothappy
    ) {
      ok
      me {
        tickets(ticketId: $ticketId) {
          id
          posts {
            id
            created
            body
            metadata
            poster {
              firstName
              lastName
              role
              id
            }
            attachments {
              id
              name
              isImage
              isPdf
            }
            visibility
            editable
          }
          notHappy
          canSetNotHappy
          canUnsetNotHappy
          sharingUuid
          ...TagType
          timesheets {
            id
            category {
              id
              name
            }
            minutes
          }
          draftPosts {
            id
          }
        }
      }
      error
    }
  }
`;

export const MUTATION_CLOSE_TICKET = gql`
  mutation CloseTicket($ticketId: Int) {
    closeTicket(ticketId: $ticketId) {
      ok
      ticket {
        id
        state
      }
      error
    }
  }
`;

export const MUTATION_REOPEN_TICKET = gql`
  mutation ReopenTicket($ticketId: Int) {
    reopenTicket(ticketId: $ticketId) {
      ok
      ticket {
        id
        state
      }
      error
    }
  }
`;

export const MUTATION_MARK_TICKET_AS_READ = gql`
  mutation MarkTicketAsRead($ticketId: Int) {
    markTicketAsRead(ticketId: $ticketId) {
      ok
      ticket {
        id
        hasUnread
      }
    }
  }
`;

export const MUTATION_UPDATE_POST = gql`
  mutation UpdatePost($postId: ID, $body: String, $visibility: String) {
    updatePost(postId: $postId, body: $body, visibility: $visibility) {
      ok
      post {
        id
        body
        visibility
      }
      error
    }
  }
`;

export const MUTATION_MOVE_COPY_POST_TO_TICKET = gql`
  mutation MoveCopyPostToTicket($copyMove: String, $postId: ID, $targetTicketId: ID) {
    moveCopyPostToTicket(copyMove: $copyMove, postId: $postId, targetTicketId: $targetTicketId) {
      ok
      ticket {
        id
        posts {
          id
        }
      }
      error
    }
  }
`;

export const MUTATION_CREATE_TICKET = gql`
  mutation CreateTicket($body: String, $subject: String) {
    createTicket(body: $body, subject: $subject) {
      ok
      ticket {
        id
      }
    }
  }
`;

export const MUTATION_CREATE_ACCOUNT_SUPPORT_TICKET = gql`
  mutation CreateAccountSupportTicket($aircraftId: ID, $body: String, $subject: String, $documentIds: [ID], $contactId: ID) {
    createAccountSupportTicket(aircraftId: $aircraftId, subject: $subject, body: $body, documentIds: $documentIds, contactId: $contactId) {
      ok
      ticket {
        id
      }
      error
    }
  }
`;

export const MUTATION_CREATE_TECHNICAL_SUPPORT_TICKET = gql`
  mutation CreateTechnicalSupportTicket($body: String, $subject: String, $documentIds: [ID], $contactId: ID) {
    createTechnicalSupportTicket(subject: $subject, body: $body, documentIds: $documentIds, contactId: $contactId) {
      ok
      ticket {
        id
      }
      error
    }
  }
`;

export const MUTATION_CREATE_PREBUY_TICKET = gql`
  mutation CreatePrebuyTicket($body: String, $aircraftId: ID, $subject: String, $subscriptionId: ID, $documentIds: [ID], $contactId: ID) {
    createPrebuyTicket(
      body: $body
      aircraftId: $aircraftId
      subject: $subject
      subscriptionId: $subscriptionId
      documentIds: $documentIds
      contactId: $contactId
    ) {
      ok
      ticket {
        id
      }
      error
    }
  }
`;

export const MUTATION_CREATE_ANNUAL_SERVICE_TICKET = gql`
  mutation CreateAnnualServiceTicket(
    $body: String
    $aircraftId: ID
    $subject: String
    $priority: String
    $subscriptionId: ID
    $documentIds: [ID]
    $contactId: ID
  ) {
    createAnnualServiceTicket(
      body: $body
      aircraftId: $aircraftId
      subject: $subject
      priority: $priority
      subscriptionId: $subscriptionId
      documentIds: $documentIds
      contactId: $contactId
    ) {
      ok
      ticket {
        id
      }
      error
    }
  }
`;

export const MUTATION_CREATE_SERVICE_TICKET = gql`
  mutation CreateServiceTicket(
    $body: String
    $aircraftId: ID
    $subject: String
    $priority: String
    $subscriptionId: ID
    $documentIds: [ID]
    $contactId: ID
  ) {
    createServiceTicket(
      body: $body
      aircraftId: $aircraftId
      subject: $subject
      priority: $priority
      subscriptionId: $subscriptionId
      documentIds: $documentIds
      contactId: $contactId
    ) {
      ok
      ticket {
        id
      }
      error
    }
  }
`;

export const MUTATION_CREATE_ANALYSIS_TICKET = gql`
  mutation CreateAnalysisTicket(
    $body: String
    $aircraftId: ID
    $subject: String
    $priority: String
    $flightId: ID
    $priorityExplanation: String
    $documentIds: [ID]
    $contactId: ID
  ) {
    createAnalysisTicket(
      body: $body
      aircraftId: $aircraftId
      subject: $subject
      priority: $priority
      flightId: $flightId
      priorityExplanation: $priorityExplanation
      documentIds: $documentIds
      contactId: $contactId
    ) {
      ok
      ticket {
        id
      }
      error
    }
  }
`;

export const MUTATION_CREATE_BORESCOPE_ANALYSIS_TICKET = gql`
  mutation CreateBorescopeAnalysisTicket(
    $body: String
    $aircraftId: ID
    $subject: String
    $priority: String
    $borescopeImageSetId: ID
    $priorityExplanation: String
    $documentIds: [ID]
    $contactId: ID
  ) {
    createBorescopeAnalysisTicket(
      body: $body
      aircraftId: $aircraftId
      subject: $subject
      priority: $priority
      borescopeImageSetId: $borescopeImageSetId
      priorityExplanation: $priorityExplanation
      documentIds: $documentIds
      contactId: $contactId
    ) {
      ok
      ticket {
        id
      }
      error
    }
  }
`;

export const MUTATION_CREATE_BREAKDOWN_TICKET = gql`
  mutation CreateBreakdownTicket(
    $body: String
    $aircraftId: ID
    $subject: String
    $subscriptionId: ID
    $telephone: String
    $documentIds: [ID]
    $contactId: ID
  ) {
    createBreakdownTicket(
      body: $body
      aircraftId: $aircraftId
      subject: $subject
      telephone: $telephone
      subscriptionId: $subscriptionId
      documentIds: $documentIds
      contactId: $contactId
    ) {
      ok
      ticket {
        id
      }
      error
    }
  }
`;

export const MUTATION_CREATE_PREBUY_INQUIRY_TICKET = gql`
  mutation CreatePrebuyInquiryTicket(
    $brokerInvolved: String
    $candidate1: String
    $candidate2: String
    $candidate3: String
    $candidateAirport: String
    $candidateChosen: String
    $candidateRegistration: String
    $commitment: String
    $firstTimeBuyer: String
    $homeAirportId: String
    $knowExactly: String
    $modelsConsidered: String
    $narrowedToAFew: String
    $narrowedToOne: String
    $notQuiteSure: String
    $scannedLogbooks: String
    $telephone: String
    $remarks: String
    $missionProfile: String
    $modelDecided: String
    $contactId: ID
  ) {
    createPrebuyInquiryTicket(
      brokerInvolved: $brokerInvolved
      candidate1: $candidate1
      candidate2: $candidate2
      candidate3: $candidate3
      candidateAirport: $candidateAirport
      candidateChosen: $candidateChosen
      candidateRegistration: $candidateRegistration
      commitment: $commitment
      firstTimeBuyer: $firstTimeBuyer
      homeAirportId: $homeAirportId
      knowExactly: $knowExactly
      modelsConsidered: $modelsConsidered
      narrowedToAFew: $narrowedToAFew
      narrowedToOne: $narrowedToOne
      notQuiteSure: $notQuiteSure
      scannedLogbooks: $scannedLogbooks
      telephone: $telephone
      remarks: $remarks
      missionProfile: $missionProfile
      modelDecided: $modelDecided
      contactId: $contactId
    ) {
      ok
      ticket {
        id
      }
      error
    }
  }
`;

export const MUTATION_UPDATE_TICKETRATING_STATUS = gql`
  mutation UpdateTicketRatingStatus($ticketRatingId: ID) {
    updateTicketRatingStatus(ticketRatingId: $ticketRatingId) {
      ok
      error
    }
  }
`;

export const MUTATION_CREATE_FEEDBACK_TICKET = gql`
  mutation CreateFeedbackTicket($ticketRatingId: ID, $body: String) {
    createFeedbackTicket(ticketRatingId: $ticketRatingId, body: $body) {
      ok
      rating {
        id
        feedbackTickets {
          id
        }
      }
      error
    }
  }
`;

export const MUTATION_SAVVY_TICKET_TEXT_ON_UPDATE = gql`
  mutation SetTextOnUpdate($ticketId: ID, $status: Boolean) {
    setTextOnUpdate(ticketId: $ticketId, status: $status) {
      ok
      ticketWatcher {
        id
        textOnUpdate
      }
      error
    }
  }
`;

export const MUTATION_SAVVY_TICKET_DONT_REQUEST_RATING = gql`
  mutation SetRequestRating($ticketId: ID, $status: Boolean) {
    setRequestRating(ticketId: $ticketId, status: $status) {
      ok
      ticket {
        id
        dontRequestRating
      }
      error
    }
  }
`;

export const MUTATION_MARK_TICKET_AS_RECENTLY_VIEWED = gql`
  mutation MarkTicketAsRecentlyViewed($ticketId: Int) {
    markTicketAsRecentlyViewed(ticketId: $ticketId) {
      ok
    }
  }
`;

export const MUTATION_ADD_WATCHER_TO_TICKET = gql`
  mutation AddWatcherToTicket($contactId: ID, $ticketId: ID) {
    addWatcherToTicket(contactId: $contactId, ticketId: $ticketId) {
      ok
      ticket {
        id
        watchers {
          id
          contact {
            id
            firstName
            lastName
            isSavvy
            company
            isTechnician
          }
          firstName
          lastName
          email
          isEmailWatcher
          role
          removable
          cannotRemoveExplanation
        }
      }
      error
    }
  }
`;

export const MUTATION_REASSIGN_TICKET = gql`
  mutation ReassignTicket($contactId: ID, $ticketId: ID) {
    reassignTicket(contactId: $contactId, ticketId: $ticketId) {
      ok
      ticket {
        id
        assignee {
          id
          firstName
          lastName
        }
        posts {
          id
          created
          body
          metadata
          poster {
            firstName
            lastName
            role
            id
            avatar
          }
          visibility
          editable
        }
      }
      error
    }
  }
`;

export const MUTATION_REMOVE_WATCHER_FROM_TICKET = gql`
  mutation RemoveWatcherFromTicket($watcherId: ID, $ticketId: ID) {
    removeWatcherFromTicket(watcherId: $watcherId, ticketId: $ticketId) {
      ok
      ticket {
        id
        watchers {
          id
          contact {
            id
            firstName
            lastName
            isSavvy
            company
            isTechnician
          }
          firstName
          lastName
          email
          isEmailWatcher
          role
          removable
          cannotRemoveExplanation
        }
      }
      error
    }
  }
`;

export const MUTATION_UPDATE_TICKET_SUBJECT = gql`
  mutation UpdateTicketSubject($ticketId: ID, $subject: String) {
    updateTicketSubject(ticketId: $ticketId, subject: $subject) {
      ok
      ticket {
        id
        subject
      }
      error
    }
  }
`;

export const MUTATION_UNSNOOZE_TICKET = gql`
  mutation UnsnoozeTicket($ticketId: ID) {
    unsnoozeTicket(ticketId: $ticketId) {
      ok
      ticket {
        id
        snoozedUntil
      }
      error
    }
  }
`;

export const MUTATION_UPDATE_TICKET_PRIORITY = gql`
  mutation UpdateTicketPriority($ticketId: ID, $priority: String) {
    updateTicketPriority(ticketId: $ticketId, priority: $priority) {
      ok
      ticket {
        id
        priority
      }
      error
    }
  }
`;

export const MUTATION_DELETE_TAG = gql`
  ${FRAGMENT_TICKET_TAG}
  mutation DeleteTicketTag($ticketTagId: ID, $tagType: String) {
    deleteTicketTag(ticketTagId: $ticketTagId, tagType: $tagType) {
      ok
      ticket {
        id
        ...TagType
      }
      error
    }
  }
`;

export const MUTATION_SNOOZE_TICKET = gql`
  mutation SnoozeTicket($snooze: String, $ticketId: ID) {
    snoozeTicket(snooze: $snooze, ticketId: $ticketId) {
      ok
      ticket {
        id
        notHappy
        canSetNotHappy
        canUnsetNotHappy
        sharingUuid
        timesheets {
          id
          category {
            id
            name
          }
          minutes
        }
        snoozedUntil
      }
      error
    }
  }
`;

export const MUTATION_CREATE_TIMESHEETS = gql`
  ${FRAGMENT_TICKET_TAG}
  mutation CreateTimesheet($timesheets: [TimesheetInputType], $ticketId: Int) {
    createTimesheet(timesheets: $timesheets, ticketId: $ticketId) {
      ok
      ticket {
        id
        notHappy
        canSetNotHappy
        canUnsetNotHappy
        sharingUuid
        ...TagType
        timesheets {
          id
          category {
            id
            name
          }
          minutes
        }
      }
      error
    }
  }
`;

export const MUTATION_CREATE_TICKET_TAG = gql`
  ${FRAGMENT_TICKET_TAG}
  mutation CreateTicketTag($ticketId: ID, $userTagTypeId: ID) {
    createTicketTag(ticketId: $ticketId, userTagTypeId: $userTagTypeId) {
      ok
      ticket {
        id
        notHappy
        canSetNotHappy
        canUnsetNotHappy
        sharingUuid
        ...TagType
        timesheets {
          id
          category {
            id
            name
          }
          minutes
        }
      }
      error
    }
  }
`;

export const MUTATION_MERGE_TICKETS = gql`
  mutation MergeTickets($sourceTicketId: ID, $destinationTicketId: ID, $subject: String) {
    mergeTickets(sourceTicketId: $sourceTicketId, destinationTicketId: $destinationTicketId, subject: $subject) {
      ok
      ticket {
        id
        subject
        state
        relatedTickets {
          id
          subject
          state
          type
          updated
        }
      }
      error
    }
  }
`;

export const MUTATION_CREATE_ANALYSIS_TAG = gql`
  ${FRAGMENT_TICKET_TAG}
  mutation CreateAnalysisTag($flightId: ID, $ticketId: ID) {
    createAnalysisTag(ticketId: $ticketId, flightId: $flightId) {
      ok
      ticket {
        id
        ...TagType
      }
      error
    }
  }
`;

export const MUTATION_CREATE_BREAKDOWN_TAG = gql`
  ${FRAGMENT_TICKET_TAG}
  mutation CreateBreakdownTag($ticketId: ID) {
    createBreakdownTag(ticketId: $ticketId) {
      ok
      ticket {
        id
        ...TagType
      }
    }
  }
`;

export const MUTATION_CREATE_FEVA_TAG = gql`
  ${FRAGMENT_TICKET_TAG}
  mutation CreateFevaTag($ticketId: ID, $alertId: ID) {
    createFevaTag(ticketId: $ticketId, alertId: $alertId) {
      ok
      ticket {
        id
        ...TagType
      }
    }
  }
`;

export const MUTATION_CREATE_PARSE_FAILURE_TAG = gql`
  ${FRAGMENT_TICKET_TAG}
  mutation CreateParseFailureTag($ticketId: ID, $fileId: ID) {
    createParseFailureTag(ticketId: $ticketId, fileId: $fileId) {
      ok
      ticket {
        id
        ...TagType
      }
    }
  }
`;

export const MUTATION_CREATE_PREBUY_INQUIRY_TAG = gql`
  ${FRAGMENT_TICKET_TAG}
  mutation CreatePrebuyInquiryTag($ticketId: ID, $prebuyId: ID) {
    createPrebuyInquiryTag(ticketId: $ticketId, prebuyId: $prebuyId) {
      ok
      ticket {
        id
        ...TagType
      }
    }
  }
`;

export const MUTATION_CREATE_PREBUY_TAG = gql`
  ${FRAGMENT_TICKET_TAG}
  mutation CreatePrebuyTag($ticketId: ID) {
    createPrebuyTag(ticketId: $ticketId) {
      ok
      ticket {
        id
        ...TagType
      }
    }
  }
`;

export const MUTATION_CREATE_SERVICE_ANNUAL_TAG = gql`
  ${FRAGMENT_TICKET_TAG}
  mutation CreateServiceAnnualTag($ticketId: ID) {
    createServiceAnnualTag(ticketId: $ticketId) {
      ok
      ticket {
        id
        ...TagType
      }
    }
  }
`;

export const MUTATION_CREATE_SERVICE_SIGNUP_TAG = gql`
  ${FRAGMENT_TICKET_TAG}
  mutation CreateServiceSignupTag($ticketId: ID, $serviceId: ID) {
    createServiceSignupTag(ticketId: $ticketId, serviceId: $serviceId) {
      ok
      ticket {
        id
        ...TagType
      }
    }
  }
`;

export const MUTATION_CREATE_SERVICE_TAG = gql`
  ${FRAGMENT_TICKET_TAG}
  mutation CreateServiceTag($ticketId: ID, $serviceId: ID) {
    createServiceTag(ticketId: $ticketId, serviceId: $serviceId) {
      ok
      ticket {
        id
        ...TagType
      }
    }
  }
`;

export const MUTATION_CREATE_TECHNICAL_SUPPORT_TAG = gql`
  ${FRAGMENT_TICKET_TAG}
  mutation CreateTechnicalSupportTag($ticketId: ID) {
    createTechnicalSupportTag(ticketId: $ticketId) {
      ok
      ticket {
        id
        ...TagType
      }
    }
  }
`;

export const MUTATION_CREATE_ACCOUNT_SUPPORT_TAG = gql`
  ${FRAGMENT_TICKET_TAG}
  mutation CreateAccountSupportTag($ticketId: ID, $aircraftId: ID) {
    createAccountSupportTag(ticketId: $ticketId, aircraftId: $aircraftId) {
      ok
      ticket {
        id
        ...TagType
      }
    }
  }
`;

export const MUTATION_CREATE_DRAFT_POST = gql`
  mutation CreateDraftPost($body: String, $ticketId: Int) {
    createDraftPost(body: $body, ticketId: $ticketId) {
      ok
      ticket {
        id
        draftPosts {
          id
          body
          created
        }
      }
      error
    }
  }
`;

export const MUTATION_RESET_UNHAPPY_STATUS = gql`
  mutation ResetUnhappyStatus($ticketId: Int) {
    resetUnhappyStatus(ticketId: $ticketId) {
      ok
      ticket {
        id
        notHappy
      }
      error
    }
  }
`;

export const MUTATION_SET_TICKET_WATCHER_COLOR = gql`
  mutation SetTicketWatcherColor($ticketId: ID, $color: String) {
    setTicketWatcherColor(ticketId: $ticketId, color: $color) {
      ok
      ticket {
        id
        color
      }
      error
    }
  }
`;

export const MUTATION_EXPORT_TICKET = gql`
  mutation ExportTicket($ticketId: ID) {
    exportTicket(ticketId: $ticketId) {
      ok
      error
    }
  }
`;

export const MUTATION_UPDATE_TICKET_NOTES = gql`
  mutation UpdateTicketNotes($ticketId: ID, $notes: String) {
    updateTicketNotes(ticketId: $ticketId, notes: $notes) {
      ok
      ticket {
        id
        notes
      }
      error
    }
  }
`;

export const MUTATION_CREATE_TIMESHEETS_FOR_DASHBOARD = gql`
  mutation CreateTimesheetForDashboard($timesheets: [TimesheetInputType]) {
    createTimesheet(timesheets: $timesheets) {
      ok
      error
    }
  }
`;

export const MUTATION_UPDATE_TIMESHEET = gql`
  mutation UpdateTimesheet($timesheetId: Int, $minutes: Int, $categoryId: Int, $remarks: String) {
    updateTimesheet(timesheetId: $timesheetId, minutes: $minutes, categoryId: $categoryId, remarks: $remarks) {
      ok
      error
    }
  }
`;

export const MUTATION_DELETE_TIMESHEET = gql`
  mutation DeleteTimesheet($timesheetId: Int) {
    deleteTimesheet(timesheetId: $timesheetId) {
      ok
      error
    }
  }
`;

export const MUTATION_DELETE_FEVA_ALERT = gql`
  mutation DeleteFevaAlert($fevaAlertId: Int) {
    deleteFevaAlert(fevaAlertId: $fevaAlertId) {
      ok
      error
    }
  }
`;

export const MUTATION_UPDATE_FEVA_ALERT = gql`
  mutation UpdateFevaAlert($chartVerificationStatus: String, $comment: String, $fevaAlertId: Int, $physicalVerificationStatus: String) {
    updateFevaAlert(
      chartVerificationStatus: $chartVerificationStatus
      comment: $comment
      fevaAlertId: $fevaAlertId
      physicalVerificationStatus: $physicalVerificationStatus
    ) {
      ok
      error
      fevaAlert {
        id
        chartVerificationStatus
        comment
        physicalVerificationStatus
      }
    }
  }
`;

export const MUTATION_CREATE_FEVA_ALERT = gql`
  mutation CreateFevaAlert(
    $chartVerificationStatus: ChartVerificationStatusEnum
    $comment: String
    $physicalVerificationStatus: PhysicalVerificationStatusEnum
    $cylinders: String
    $flightId: ID
    $notificationDate: DateTime
    $ticketId: ID
  ) {
    createFevaAlert(
      chartVerificationStatus: $chartVerificationStatus
      comment: $comment
      physicalVerificationStatus: $physicalVerificationStatus
      cylinders: $cylinders
      flightId: $flightId
      notificationDate: $notificationDate
      ticketId: $ticketId
    ) {
      ok
      error
    }
  }
`;

export const MUTATION_CREATE_FEVA_TICKET = gql`
  mutation CreateFevaTicket($fevaAlertId: ID, $subject: String, $body: String) {
    createFevaTicket(fevaAlertId: $fevaAlertId, body: $body, subject: $subject) {
      ok
      error
      ticket {
        id
        subject
      }
    }
  }
`;

export const MUTATION_MERGE_MULTIPLE_TICKETS = gql`
  mutation MergeMultipleTickets($newSubject: String, $ticketIds: [ID]) {
    mergeMultipleTickets(newSubject: $newSubject, ticketIds: $ticketIds) {
      ok
      error
    }
  }
`;

export const MUTATION_CREATE_TICKET_RATING = gql`
  mutation CreateTicketRating(
    $ticketRatingUuid: UUID
    $comment: String
    $contactRater: Boolean
    $rating: Int
    $savyRatingIds: [ID]
    $shopRatingIds: [ID]
  ) {
    createTicketRating(
      ticketRatingUuid: $ticketRatingUuid
      comment: $comment
      contactRater: $contactRater
      rating: $rating
      savyRatingIds: $savyRatingIds
      shopRatingIds: $shopRatingIds
    ) {
      ok
      error
      rating {
        id
        rating
      }
    }
  }
`;

export const MUTATION_CREATE_SHARE_MAINTENANCE_LOGS_TAG = gql`
  ${FRAGMENT_TICKET_TAG}
  mutation CreateShareMaintenanceLogsTag($aircraftId: Int, $logbookIds: [ID], $ticketId: ID) {
    createShareMaintenanceLogsTag(aircraftId: $aircraftId, logbookIds: $logbookIds, ticketId: $ticketId) {
      ok
      error
      ticket {
        id
        ...TagType
        posts {
          id
          created
          body
          metadata
          poster {
            firstName
            lastName
            role
            id
          }
          attachments {
            id
            name
            isImage
            isPdf
          }
          visibility
          editable
        }
      }
    }
  }
`;

export const MUTATION_CREATE_PREBUY_CONFLICT_TICKET = gql`
  mutation CreatePrebuyConflictTicket($aircraftId: ID, $body: String, $subject: String, $contactId: ID) {
    createPrebuyConflictTicket(aircraftId: $aircraftId, subject: $subject, body: $body, contactId: $contactId) {
      ok
      ticket {
        id
      }
      error
    }
  }
`;

export const MUTATION_CREATE_POST_ATTACHMENTS_ARCHIVE = gql`
  mutation CreatePostAttachmentsArchive($postId: Int) {
    createPostAttachmentsArchive(postId: $postId) {
      ok
      error
      workflowId
    }
  }
`;
