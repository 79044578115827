import React, { Fragment, useState, useEffect, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LOGOUT_SUCCESS, LOGOUT_ERROR } from 'state/types';

import { useMutation, useQuery } from '@apollo/client';
import { MUTATION_LOGOUT, QUERY_USER_DATA } from 'gql';

import { PATH_ACCOUNT, PATH_SAVVY_ACCOUNT, PATH_AIRCRAFT_LIST } from 'const';

import { Box, Button, List, ListItem, Avatar, MenuItem, Typography, Collapse } from '@mui/material';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import Skeleton from '@mui/material/Skeleton';

import { Dropdown, DropdownMenu } from 'components';
import { logAmplitudeEvent } from 'services';

import { handleSendError } from 'helpers';
import { useXlUp } from 'hooks';

interface UserMenuProps {
  role?: string;
}

const UserMenu: React.FC<UserMenuProps> = (props) => {
  const { role = '' } = props;

  const dispatch = useDispatch();
  const { isSavvy, isTechnician } = useSelector((state: any) => state.auth);

  const MAX_NAME_LENGTH = 13;

  // Dropdown User Menu
  const [open, setOpen] = useState(false);
  const toggleMenu = () => {
    setOpen(!open);
  };
  const closeMenu = () => {
    setOpen(false);
  };

  // Get user data
  const { loading: userDataLoading, data: userData } = useQuery(QUERY_USER_DATA); // GraphQL. Rename to userData to prevent naming conflicts
  const [displayName, setDisplayName] = useState('');

  // Initial
  let email = '';
  let firstName = '';
  let lastName = '';
  let nickname = '';
  let avatar = '';

  const isXlUp = useXlUp();

  // Response
  if (userData && userData?.me) {
    // Update user data
    email = userData.me.email;
    firstName = userData.me.firstName;
    lastName = userData.me.lastName;
    nickname = userData.me.nickname;
    avatar = userData.me.avatar;
  }

  // Define User Name to show in dropdown
  // Email as fallback
  let userName = email;

  // If first or last name specified - use them
  if (firstName?.length > 0 || lastName?.length > 0) {
    userName = `${firstName} ${lastName}`;
  }

  // If nickname specififed - use it
  if (nickname?.length > 0) {
    userName = nickname;
  }

  const navigate = useNavigate();

  // Logout
  const handleLogout = () => {
    closeMenu(); // Hide user menu
    logout(); // Call GraphQL
  };

  // Account
  const handleAccount = () => {
    logAmplitudeEvent('menu_account', {
      role,
    });
    navigate(isSavvy ? PATH_SAVVY_ACCOUNT : PATH_ACCOUNT);
  };

  const handleAircraft = () => {
    navigate(PATH_AIRCRAFT_LIST);
  };

  const getUserNameForSmallScreen = (firstName: string, lastName: string) => {
    const windowWidth = document.body.offsetWidth;

    if (windowWidth && windowWidth >= 1280 && windowWidth < 1400) {
      if (`${firstName} ${lastName}`.length >= MAX_NAME_LENGTH) {
        if (firstName.length >= MAX_NAME_LENGTH) {
          if (lastName.length >= MAX_NAME_LENGTH) {
            return `${firstName[0]}${lastName[0]}`;
          } else {
            return lastName;
          }
        } else {
          return firstName;
        }
      } else {
        return `${firstName} ${lastName}`;
      }
    } else {
      if (`${firstName} ${lastName}`.length >= 30) {
        return lastName;
      } else {
        return `${firstName} ${lastName}`;
      }
    }
  };

  const handleResize = useCallback(() => {
    setDisplayName(getUserNameForSmallScreen(firstName, lastName));
  }, [firstName, lastName]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  // GraphQL
  const [logout, { data: dataLogout, error }] = useMutation(MUTATION_LOGOUT); // Rename data to prevent naming conflicts

  // Send Error
  error && handleSendError();

  // useEffect to prevent re-renders limit
  useEffect(() => {
    // Response
    if (dataLogout) {
      const { ok } = dataLogout.logout;

      if (!ok) {
        // Error
        dispatch({ type: LOGOUT_ERROR });
      } else {
        // Success
        navigate('/login');
        dispatch({ type: LOGOUT_SUCCESS });
      }
    }
  }, [dataLogout, dispatch, navigate]);

  useEffect(() => {
    if (userData && !userData?.me) {
      closeMenu();
      dispatch({ type: LOGOUT_SUCCESS });
    }
  }, [userData, dispatch]);

  useEffect(() => {
    setDisplayName(getUserNameForSmallScreen(firstName, lastName));
  }, [firstName, lastName]);

  return (
    <Box
      height="100%"
      alignItems="center"
      sx={{
        display: {
          md: 'block',
          lg: 'inline-flex',
        },
      }}
    >
      {userDataLoading ? (
        <Fragment>
          <Box mr={2}>
            <Skeleton variant="circular" animation="wave" width={36} height={36} />
          </Box>
          <Skeleton variant="text" animation="wave" width={100} height={20} />
        </Fragment>
      ) : (
        <Fragment>
          {isXlUp && (
            <Box>
              <Dropdown onClickAway={closeMenu}>
                <Box display="flex" height="100%">
                  <Button color="inherit" endIcon={<ExpandMoreOutlinedIcon />} onClick={toggleMenu}>
                    <Box mr={1}>
                      <Avatar
                        src={avatar}
                        alt={userName}
                        sx={{
                          width: '36px',
                          height: '36px',
                        }}
                      />
                    </Box>
                    <Typography>{displayName}</Typography>
                  </Button>
                </Box>

                <DropdownMenu open={open}>
                  <List component="div">
                    <ListItem ContainerComponent="div" button onClick={handleAccount}>
                      Account
                    </ListItem>
                    {!isSavvy && !isTechnician && (
                      <ListItem ContainerComponent="div" button onClick={handleAircraft}>
                        Aircraft
                      </ListItem>
                    )}
                    <ListItem ContainerComponent="div" button onClick={handleLogout}>
                      Logout
                    </ListItem>
                  </List>
                </DropdownMenu>
              </Dropdown>
            </Box>
          )}
          {!isXlUp && (
            <Box>
              <MenuItem onClick={toggleMenu}>
                <Typography variant="body1">{userName}</Typography>
                {!open ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
              </MenuItem>
              {open && (
                <Collapse in={open} timeout="auto">
                  <Fragment>
                    <MenuItem onClick={handleAccount}>
                      <Typography variant="body1">Account</Typography>
                    </MenuItem>
                    {!isSavvy && !isTechnician && (
                      <MenuItem onClick={handleAircraft}>
                        <Typography variant="body1">Aircraft</Typography>
                      </MenuItem>
                    )}
                    <MenuItem onClick={handleLogout}>
                      <Typography variant="body1">Logout</Typography>
                    </MenuItem>
                  </Fragment>
                </Collapse>
              )}
            </Box>
          )}
        </Fragment>
      )}
    </Box>
  );
};

export default UserMenu;
