import React from 'react';
import { useNavigate } from 'react-router-dom';

import { LayoutDashboard, PageLoading, ButtonSubmit, MuiFormikInput } from 'components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { Typography, Grid, Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Classifications, ClassificationType } from './components';
import { useMutation, useQuery } from '@apollo/client';
import {
  QUERY_TECHNICIAN,
  MUTATION_UPDATE_TECHNICIAN_CONTACT,
  QUERY_TECHNICIAN_CLASSIFICATION_CATEGORIES,
  QUERY_TECHNICIAN_CLASSIFICATIONS,
} from 'gql';
import { PATH_CONTACT } from 'const';

import { Error } from '@mui/icons-material';

const TechnicianSchema = Yup.object().shape({
  firstName: Yup.string().max(50, 'Too long: Maximum 50 characters').required('Required'),
  lastName: Yup.string().max(50, 'Too long: Maximum 50 characters').required('Required'),
  nickname: Yup.string().max(50, 'Too long: Maximum 50 characters'),
  email: Yup.string().email('Invalid email').max(40, 'Too long: Maximum 50 characters').required('Required'),
  phoneMobile: Yup.string().max(20, 'Too long: Maximum 50 characters'),
});

const TechnicianPage: React.FC = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const {
    data: dataContact,
    loading: loadingContact,
    error: errorContact,
  } = useQuery(QUERY_TECHNICIAN, { variables: { technicianId: id } });

  const { data: dataCategories, loading: loadingCategories } = useQuery(QUERY_TECHNICIAN_CLASSIFICATION_CATEGORIES);

  const { data: dataClassifications, loading: loadingClassifications } = useQuery(QUERY_TECHNICIAN_CLASSIFICATIONS);

  // TODO: Should we be using "data", "loading", "error"?
  const [updateTechnician, { data, loading, error }] = useMutation(MUTATION_UPDATE_TECHNICIAN_CONTACT);

  React.useEffect(() => {
    if (!error && data) {
      if (data.updateTechnicianContact) {
        navigate(`${PATH_CONTACT}/${id}`);
      }
    }
  }, [data, error, id, navigate]);

  if (errorContact) {
    // TODO: Proper error
    return <Error>Did not work</Error>;
  }

  // TODO: Proper loader
  if (loadingContact) return <PageLoading />;

  const technician = dataContact.technicians[0];
  const categories = dataCategories?.technicianClassificationCategories;
  const classifications = dataClassifications?.technicianClassifications;

  return (
    <LayoutDashboard backgroundColor="#FFFFFF">
      <Box p={5}>
        <Typography variant="h4" gutterBottom>
          Edit Technician
        </Typography>
        <Formik
          initialValues={{
            firstName: technician?.firstName ? technician.firstName : '',
            lastName: technician?.lastName ? technician.lastName : '',
            email: technician?.email ? technician.email : '',
            nickname: technician?.nickname ? technician.nickname : '',
            phoneMobile: technician?.phoneMobile ? technician.phoneMobile : '',
            classifications: technician.classifications.map((classification: ClassificationType) => classification.id),
          }}
          onSubmit={async (values) => {
            await updateTechnician({ variables: { id: id, input: { ...values } } });
          }}
          validationSchema={TechnicianSchema}
        >
          {({ isSubmitting, handleChange, handleBlur, touched, errors }) => {
            return (
              <Form>
                <Grid container spacing={2}>
                  <Grid item sm={6}>
                    <MuiFormikInput
                      name="firstName"
                      label="First Name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                    <MuiFormikInput
                      name="lastName"
                      label="Last Name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                    <MuiFormikInput
                      name="email"
                      label="Email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                    <MuiFormikInput
                      name="nickname"
                      label="Nick Name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                    <MuiFormikInput
                      name="phoneMobile"
                      label="Phone"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </Grid>
                </Grid>
                {!loadingCategories && !loadingClassifications && (
                  <Classifications categories={categories} classifications={classifications} />
                )}
                <Grid container spacing={2}>
                  <Grid item xs={false} sm={3}></Grid>
                  <Grid item xs={12} sm={6}>
                    <ButtonSubmit text="Submit" loading={loading} disabled={isSubmitting} />
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </LayoutDashboard>
  );
};

export default TechnicianPage;
