import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dialog, Box, DialogTitle, DialogContent, TextField, MenuItem, DialogActions, Grid, Button, Typography, Link } from '@mui/material';

import { MUTATION_PARSE_FLIGHTS_FROM_DOCUMENT, QUERY_GET_TICKETS_AIRCRAFT } from 'gql';
import { useLazyQuery, useMutation } from '@apollo/client';
import { setAlert } from 'state';
import { aircraftRegistrationMakeModel } from 'helpers';
import { PATH_SAVVY_AIRCRAFT, PATH_FLIGHTS } from 'const';

import { ComponentLoading } from 'components';

interface ParseFlightsFromAttachmentProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  documentId: string;
  aircraftId: string;
  fileName: string;
  ticketId: string;
  registration: string;
}

const ParseFlightsFromAttachment: React.FC<ParseFlightsFromAttachmentProps> = (props) => {
  const { open, setOpen, documentId, aircraftId, fileName, ticketId, registration } = props;

  const [parseDocument, { data: dataParseDocument, loading: loadingParseDocument, error: errorParseDocument }] = useMutation(
    MUTATION_PARSE_FLIGHTS_FROM_DOCUMENT,
  );
  const [fetchAircraft, { data: dataAircraft, loading: loadingAircraft, error: errorAircraft }] = useLazyQuery(QUERY_GET_TICKETS_AIRCRAFT);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isSavvy } = useSelector((state: any) => state.auth);

  const [selectAircraftId, setSelectAircraftId] = useState('');
  const [aircraftError, setAircraftError] = useState('');

  const [isReadyLink, setIsReadyLink] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const getRegistrationFromSelect = (id: string) => {
    const matchedAircraft = dataAircraft?.me?.tickets[0]?.client?.contact?.aircraft?.filter((aircraft: any) => aircraft.id === id);
    if (matchedAircraft?.length) {
      return matchedAircraft[0].registration;
    }
    return '';
  };

  const onSubmit = async () => {
    if (!aircraftId && !selectAircraftId) {
      setAircraftError('Please select aircraft');
      return;
    }

    if (!documentId) {
      dispatch(setAlert('error', 'Please select correct attachment'));
      return;
    }

    await parseDocument({
      variables: {
        aircraftId: aircraftId ? aircraftId : selectAircraftId,
        documentId,
        stage: true,
      },
    });
  };

  useEffect(() => {
    if (errorParseDocument) {
      dispatch(setAlert('error', 'Unable to parse flights file'));
      setOpen(false);
    } else if (dataParseDocument) {
      if (dataParseDocument.parseFlightsFromDocument?.ok) {
        dispatch(setAlert('success', 'Parsed flights file'));
        setIsReadyLink(true);
      } else {
        dispatch(setAlert('error', 'Unable to parse flights file'));
      }
    }
  }, [errorParseDocument, dataParseDocument, dispatch, setOpen, navigate, aircraftId, selectAircraftId]);

  useEffect(() => {
    if (!open) {
      setSelectAircraftId('');
    } else {
      setIsReadyLink(false);
    }
  }, [open]);

  useEffect(() => {
    if (open && !aircraftId) {
      fetchAircraft({
        variables: {
          ticketId: parseInt(ticketId),
        },
      });
    }
  }, [open, aircraftId, ticketId, fetchAircraft]);

  useEffect(() => {
    if (errorAircraft) {
      dispatch(setAlert('error', "Unable to load ticket's aircraft"));
    }
  }, [dispatch, errorAircraft]);

  useEffect(() => {
    if (dataAircraft?.me?.tickets[0]?.client?.contact) {
      if (!dataAircraft?.me?.tickets[0]?.client.contact.aircraft?.length) {
        dispatch(setAlert('error', "This ticket doesn't have related aircraft."));
      }
    }
  }, [dispatch, dataAircraft]);

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: '400px',
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontSize: '24px',
          fontWeight: 700,
        }}
      >
        Upload {fileName}
        {registration ? ` to ${registration}?` : selectAircraftId ? ` to ${getRegistrationFromSelect(selectAircraftId)}?` : ''}
      </DialogTitle>
      <DialogContent>
        {!isReadyLink && (
          <ComponentLoading loading={loadingParseDocument || loadingAircraft}>
            {!aircraftId && !!dataAircraft?.me?.tickets[0]?.client?.contact?.aircraft?.length && (
              <Box py={1}>
                <TextField
                  select
                  label="Aircraft *"
                  name="aircraft"
                  value={selectAircraftId}
                  onChange={(e: any) => {
                    setSelectAircraftId(e.target.value);
                    setAircraftError('');
                  }}
                  error={!!aircraftError}
                  helperText={aircraftError}
                  FormHelperTextProps={{
                    sx: {
                      ml: 0,
                    },
                  }}
                  fullWidth
                >
                  {dataAircraft?.me?.tickets[0]?.client?.contact?.aircraft?.map((item: any, key: number) => {
                    return (
                      <MenuItem value={item.id} key={key}>
                        {aircraftRegistrationMakeModel(item)}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Box>
            )}
            {aircraftId && (
              <Typography
                variant="body1"
                gutterBottom
                align="center"
                sx={{
                  fontSize: '18px',
                }}
              >
                Are you sure to parse this file?
              </Typography>
            )}
          </ComponentLoading>
        )}
        {isReadyLink && (
          <Box>
            <Typography align="center">
              Please click this{' '}
              <Link
                href={
                  isSavvy
                    ? `${PATH_SAVVY_AIRCRAFT}/${aircraftId ? aircraftId : selectAircraftId}/flights`
                    : `${PATH_FLIGHTS}/aircraft/${aircraftId}`
                }
                target="_blank"
              >
                link
              </Link>{' '}
              to view parsed flights.
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {!isReadyLink && (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button variant="outlined" onClick={handleClose} disabled={loadingParseDocument || loadingAircraft} fullWidth>
                No
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button variant="contained" onClick={onSubmit} disabled={loadingParseDocument || loadingAircraft} fullWidth>
                Yes
              </Button>
            </Grid>
          </Grid>
        )}
        {isReadyLink && (
          <Grid container spacing={2}>
            <Grid item xs={3}></Grid>
            <Grid item xs={6}>
              <Button variant="outlined" onClick={handleClose} fullWidth>
                Close
              </Button>
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ParseFlightsFromAttachment;
