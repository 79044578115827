import { gql } from '@apollo/client';
import { FRAGMENT_TICKETS_DASHBOARD_ROW, FRAGMENT_TIMESHEET, FRAGMENT_FEVA_ALERT, FRAGMENT_TICKET_TAG } from 'gql';

export const QUERY_USER_TICKETS = gql`
  query MeTickets($subjectSearch: String, $last: Int) {
    me {
      id
      tickets(subjectSearch: $subjectSearch, last: $last) {
        id
        created
        updated
        state
        subject
        type
        creator {
          id
          firstName
          lastName
        }
        priority
        priorityExplanation
        hasUnread
        aircraft {
          id
          registration
          owner {
            id
            firstName
            lastName
          }
          serial
        }
      }
    }
  }
`;

export const QUERY_SAVVY_TICKETS = gql`
  query TicketsBySavvy($subjectSearch: String, $last: Int) {
    me {
      id
      tickets(subjectSearch: $subjectSearch, last: $last) {
        id
        created
        updated
        state
        subject
        type
        creator {
          firstName
          lastName
        }
        aircraft {
          registration
          owner {
            firstName
            lastName
          }
        }
        priority
        priorityExplanation
      }
    }
  }
`;

export const QUERY_USER_DETAIL_TICKET = gql`
  query MeDetailTicket($ticketId: Int!) {
    me {
      id
      tickets(ticketId: $ticketId) {
        id
        created
        updated
        state
        subject
        type
        client {
          id
          firstName
          lastName
        }
        creator {
          id
          firstName
          lastName
          email
          isEmailClient
          fullNameWithNick
        }
        priority
        priorityExplanation
        posts {
          id
          created
          body
          metadata
          poster {
            firstName
            lastName
            role
            id
            avatar
            email
            isEmailClient
          }
          attachments {
            id
            name
            isImage
            isPdf
            created
          }
          visibility
          editable
        }
        assignee {
          id
          firstName
          lastName
        }
        watchers {
          id
          contact {
            id
            firstName
            lastName
            isSavvy
          }
          firstName
          lastName
          email
          isEmailWatcher
          role
          removable
          cannotRemoveExplanation
          fullNameWithNick
        }
        relatedTickets {
          id
          subject
          state
          type
          updated
          created
        }
        tags {
          ... on AnalysisTagType {
            id
            name
            verboseName
            created
            metadata
            flight {
              id
              duration
              date
              departureAirport
              destinationAirport
            }
            report {
              id
            }
          }
          ... on ShareMaintenanceLogsTagType {
            id
            reportingTag {
              id
              name
            }
            name
            verboseName
            logs
            metadata
          }
        }
        hasUnread
        notHappy
        canSetNotHappy
        canUnsetNotHappy
        aircraft {
          id
          registration
          year
        }
        watchingServiceCenters
        sharingUuid
      }
    }
  }
`;

export const QUERY_USER_DETAIL_TICKET_FOR_TIMER = gql`
  query MeDetailTicketForTimer($ticketId: Int!) {
    me {
      id
      tickets(ticketId: $ticketId) {
        id
        created
        state
        subject
        type
        client {
          id
        }
        creator {
          id
        }
        priority
        priorityExplanation
        posts {
          id
          created
          body
          metadata
          poster {
            firstName
            lastName
            role
            id
            avatar
            email
            isEmailClient
          }
          attachments {
            id
            name
            isImage
            isPdf
            created
          }
          visibility
          editable
        }
        assignee {
          id
          firstName
          lastName
        }
        watchers {
          id
          contact {
            id
            firstName
            lastName
            isSavvy
          }
          firstName
          lastName
          email
          isEmailWatcher
          role
          removable
          cannotRemoveExplanation
          fullNameWithNick
        }
        relatedTickets {
          id
        }
        tags {
          ... on AnalysisTagType {
            id
            name
            verboseName
            created
            metadata
            flight {
              id
              duration
              date
              departureAirport
              destinationAirport
            }
            report {
              id
            }
          }
          ... on ShareMaintenanceLogsTagType {
            id
            reportingTag {
              id
              name
            }
            name
            verboseName
            logs
            metadata
          }
        }
        hasUnread
        notHappy
        canSetNotHappy
        canUnsetNotHappy
        aircraft {
          id
        }
        watchingServiceCenters
        sharingUuid
      }
    }
  }
`;

export const QUERY_USER_DETAIL_ATTACHMENT_DATA = gql`
  query MeAttachmentData($ticketId: Int, $postId: ID, $attachmentId: ID) {
    me {
      id
      tickets(ticketId: $ticketId) {
        id
        posts(postId: $postId) {
          id
          attachments(attachmentId: $attachmentId) {
            id
            name
            isImage
            isPdf
            previewUrl
            base64Data
          }
        }
      }
    }
  }
`;

export const QUERY_USER_DETAIL_ATTACHMENT_DOWNLOAD_URL = gql`
  query MeAttachmentDownload($ticketId: Int, $postId: ID, $attachmentId: ID) {
    me {
      id
      tickets(ticketId: $ticketId) {
        id
        posts(postId: $postId) {
          id
          attachments(attachmentId: $attachmentId) {
            id
            name
            downloadUrl
          }
        }
      }
    }
  }
`;

export const QUERY_SAVVY_TICKET_AIRCRAFT_ATTACHMENT = gql`
  query SavvyTicketAircraftAttachments($ticketId: Int) {
    me {
      id
      tickets(ticketId: $ticketId) {
        id
        aircraft {
          id
          attachments {
            id
            name
            isImage
            isPdf
            created
            description
            document {
              id
            }
          }
        }
      }
    }
  }
`;

export const QUERY_FETCH_SAVVY_RECOMMENDED_TIMESHEET_CATEGORY = gql`
  query SavvyRecommendedTimesheetCategory($ticketId: Int!) {
    me {
      id
      tickets(ticketId: $ticketId) {
        id
        recommendedTimesheetCategory {
          timesheetCategory {
            id
            name
          }
          reason
        }
      }
    }
  }
`;

export const QUERY_SAVVY_DETAILT_TICKET = gql`
  ${FRAGMENT_TICKET_TAG}
  query SavvyDetailTicket($ticketId: Int!) {
    me {
      id
      tickets(ticketId: $ticketId) {
        id
        created
        updated
        state
        subject
        type
        creator {
          id
          firstName
          lastName
          email
          isEmailClient
          contact {
            id
            comments {
              id
              comment
              date
              pinned
            }
            subscriptions(current: true) {
              id
              serviceName
              startDate
              endDate
            }
          }
          fullNameWithNick
        }
        client {
          id
        }
        priority
        priorityExplanation
        posts {
          id
          created
          body
          metadata
          poster {
            firstName
            lastName
            role
            id
            avatar
            email
            isEmailClient
          }
          attachments {
            id
            name
            isImage
            isPdf
            created
            description
            document {
              id
            }
          }
          visibility
          editable
        }
        assignee {
          id
          firstName
          lastName
          isAway
          fullNameWithNick
        }
        watchers {
          id
          contact {
            id
            firstName
            lastName
            isSavvy
            company
            isTechnician
          }
          firstName
          lastName
          email
          isEmailWatcher
          role
          removable
          cannotRemoveExplanation
          fullNameWithNick
          textOnUpdate
        }
        dontRequestRating
        ...TagType
        aircraft {
          id
          registration
          owner {
            id
            firstName
            lastName
            comments {
              id
              comment
              date
              pinned
            }
            coordinates {
              longitude
              latitude
            }
            airportId
            fullNameWithNick
          }
          serial
          year
          aircraftManufacturer {
            id
            name
          }
          aircraftModel {
            id
            name
          }
          engineManufacturer {
            name
          }
          engineModel {
            name
          }
          engineMonitorManufacturer {
            name
          }
          engineMonitorModel {
            name
          }
          comments {
            id
            comment
            date
            pinned
          }
          subscriptions(current: true) {
            id
            serviceName
            startDate
            endDate
          }
          accountManager {
            id
            firstName
            lastName
          }
          backupManager {
            id
            firstName
            lastName
          }
          preferredServiceCenter {
            id
            company
          }
          annualDue
          checkRcta
        }
        notHappy
        canSetNotHappy
        canUnsetNotHappy
        sharingUuid
        lastViewedBy {
          id
          firstName
          lastName
        }
        timesheets {
          id
          category {
            id
            name
          }
          minutes
          user {
            id
            firstName
            lastName
          }
          created
        }
        allowedPriorities {
          code
          description
        }
        hasUnread
        budget {
          name
          elapsedPct
          budgetConsumedPct
          budgetConsumedPctWidth
          budgetColor
          blink
        }
        canSnooze
        coveringFor
        firstUnread {
          id
          created
        }
        draftPosts {
          id
          created
          body
          poster {
            id
            firstName
            lastName
          }
        }
        showCoiWarning
        riskText
        color
        notes
        snoozedUntil
      }
    }
  }
`;

export const QUERY_SAVVY_RELATED_TICKETS_FOR_DETAIL = gql`
  query SavvyRelatedTicketsForDetail($ticketId: Int!) {
    me {
      id
      tickets(ticketId: $ticketId) {
        id
        relatedTickets {
          id
          subject
          state
          type
          updated
          created
        }
      }
    }
  }
`;

export const QUERY_SAVVY_DETAILT_TICKET_FOR_FETCH = gql`
  ${FRAGMENT_TICKET_TAG}
  query SavvyDetailTicketFetch($ticketId: Int!) {
    me {
      id
      tickets(ticketId: $ticketId) {
        id
        created
        updated
        state
        subject
        type
        creator {
          id
        }
        client {
          id
        }
        priority
        priorityExplanation
        posts {
          id
          created
          body
          metadata
          poster {
            firstName
            lastName
            role
            id
            avatar
            email
            isEmailClient
          }
          attachments {
            id
            name
            isImage
            isPdf
            created
            description
          }
          visibility
          editable
        }
        assignee {
          id
          firstName
          lastName
          isAway
          fullNameWithNick
        }
        watchers {
          id
          contact {
            id
            firstName
            lastName
            isSavvy
            company
            isTechnician
          }
          firstName
          lastName
          email
          isEmailWatcher
          role
          removable
          cannotRemoveExplanation
          fullNameWithNick
          textOnUpdate
        }
        dontRequestRating
        ...TagType
        aircraft {
          id
          registration
          owner {
            id
            firstName
            lastName
            comments {
              id
              comment
              date
              pinned
            }
            coordinates {
              longitude
              latitude
            }
            airportId
            fullNameWithNick
          }
          serial
          year
          aircraftManufacturer {
            name
          }
          aircraftModel {
            name
          }
          engineManufacturer {
            name
          }
          engineModel {
            name
          }
          engineMonitorManufacturer {
            name
          }
          engineMonitorModel {
            name
          }
          comments {
            id
            comment
            date
            pinned
          }
          subscriptions(current: true) {
            id
            serviceName
            startDate
            endDate
          }
          accountManager {
            id
            firstName
            lastName
          }
          backupManager {
            id
            firstName
            lastName
          }
          attachments {
            id
            name
            isImage
            isPdf
            created
            description
          }
          annualDue
        }
        notHappy
        canSetNotHappy
        canUnsetNotHappy
        sharingUuid
        lastViewedBy {
          id
          firstName
          lastName
        }
        timesheets {
          id
          category {
            id
            name
          }
          minutes
          user {
            id
            firstName
            lastName
          }
          created
        }
        allowedPriorities {
          code
          description
        }
        hasUnread
        budget {
          name
          elapsedPct
          budgetConsumedPct
          budgetConsumedPctWidth
          budgetColor
          blink
        }
        canSnooze
        coveringFor
        showCoiWarning
        notes
        snoozedUntil
      }
    }
  }
`;

export const QUERY_USER_TICKETS_BY_AIRCRAFT = gql`
  query MeTicketsByAircraft($aircraftId: Int) {
    me {
      id
      tickets(aircraftId: $aircraftId) {
        id
        created
        updated
        state
        subject
        type
        creator {
          id
          firstName
          lastName
        }
        priority
        priorityExplanation
        posts {
          id
          created
          body
          metadata
          poster {
            id
            firstName
            lastName
            role
            email
            isEmailClient
          }
        }
      }
    }
  }
`;

export const QUERY_TICKET_RATINGS = gql`
  query SavvyTicketRatings($startDate: Date, $endDate: Date) {
    savvy {
      id
      me {
        id
      }
      ticketRatings(startDate: $startDate, endDate: $endDate) {
        id
        uuid
        created
        updated
        ticket {
          id
          subject
          assignee {
            id
            firstName
            lastName
          }
        }
        rater {
          firstName
          lastName
          id
        }
        rating
        contactRater
        comment
        criteria {
          id
          score
          definition {
            id
            name
            displayNamePositive
            displayNameNegative
            applicableTo
          }
          rating {
            id
            rating
            comment
          }
        }
        status
        feedbackTickets {
          id
        }
      }
    }
  }
`;

export const QUERY_TICKET_RATINGS_RATER_INFO = gql`
  query SavvyTicketRaterInfo($ticketRatingId: ID) {
    savvy {
      id
      me {
        id
      }
      ticketRatings(ticketRatingId: $ticketRatingId) {
        id
        uuid
        rater {
          id
          firstName
          lastName
          phoneMobile
          email
          country
          state
          city
          street
          zip
          airportId
        }
      }
    }
  }
`;

export const QUERY_PERSONAL_TICKET_DASHBOARD = gql`
  query SavvyPersonalTicketDashboard {
    savvy {
      id
      me {
        id
      }
      personalTicketDashboard {
        groups {
          name
          description
          tickets {
            id
            subject
            updated
            created
            hasUnread
            state
            aircraft {
              id
              registration
              aircraftManufacturer {
                id
                name
              }
              aircraftModel {
                id
                name
              }
              owner {
                id
                firstName
                lastName
              }
            }
            creator {
              id
              firstName
              lastName
            }
            lastPoster {
              id
              firstName
              lastName
              role
            }
            client {
              id
              firstName
              lastName
              email
              isEmailClient
            }
            watchers {
              id
              contact {
                id
              }
              textOnUpdate
            }
            coveringFor
            firstUnreadDatetime
            unreadCount
            color
            snoozedUntil
          }
        }
      }
      textOnUpdate
    }
  }
`;

export const QUERY_PERSONAL_TICKET_DASHBOARD_CONFIG = gql`
  query SavvyPersonalTicketDashboardConfig {
    savvy {
      id
      me {
        id
      }
      textOnUpdate
      cannedResponses {
        title
        text
      }
      allowedAssignees {
        id
        firstName
        lastName
        fullNameWithNick
      }
      allowedTimesheetCategories {
        id
        name
      }
      lastTimesheetCategory {
        id
        name
      }
      showTimesheetForm
      permissions {
        ticket {
          canViewUnhappyState
          canResetUnhappy
          canUpdateTicketNotes
        }
      }
    }
  }
`;

export const QUERY_PERSONAL_TICKET_DASHBOARD_EVERY_MINUTE = gql`
  ${FRAGMENT_TICKET_TAG}
  query SavvyPersonalTicketDashboardEveryMinute($ticketId: Int) {
    savvy {
      id
      me {
        id
        tickets(ticketId: $ticketId) {
          id
          created
          updated
          state
          subject
          type
          creator {
            id
          }
          client {
            id
          }
          priority
          priorityExplanation
          posts {
            id
            created
            body
            metadata
            poster {
              firstName
              lastName
              role
              id
              avatar
              email
              isEmailClient
            }
            attachments {
              id
              name
              isImage
              isPdf
              created
              description
            }
            visibility
            editable
          }
          assignee {
            id
            firstName
            lastName
            isAway
            fullNameWithNick
          }
          watchers {
            id
            contact {
              id
              firstName
              lastName
              isSavvy
              company
              isTechnician
            }
            firstName
            lastName
            email
            isEmailWatcher
            role
            removable
            cannotRemoveExplanation
            fullNameWithNick
            textOnUpdate
          }
          dontRequestRating
          ...TagType
          aircraft {
            id
            registration
            owner {
              id
              firstName
              lastName
              comments {
                id
                comment
                date
                pinned
              }
              coordinates {
                longitude
                latitude
              }
              airportId
              fullNameWithNick
            }
            serial
            year
            aircraftManufacturer {
              name
            }
            aircraftModel {
              name
            }
            engineManufacturer {
              name
            }
            engineModel {
              name
            }
            engineMonitorManufacturer {
              name
            }
            engineMonitorModel {
              name
            }
            comments {
              id
              comment
              date
              pinned
            }
            subscriptions(current: true) {
              id
              serviceName
              startDate
              endDate
            }
            accountManager {
              id
              firstName
              lastName
            }
            backupManager {
              id
              firstName
              lastName
            }
            annualDue
          }
          notHappy
          canSetNotHappy
          canUnsetNotHappy
          sharingUuid
          lastViewedBy {
            id
            firstName
            lastName
          }
          timesheets {
            id
            category {
              id
              name
            }
            minutes
            user {
              id
              firstName
              lastName
            }
            created
          }
          allowedPriorities {
            code
            description
          }
          hasUnread
          budget {
            name
            elapsedPct
            budgetConsumedPct
            budgetConsumedPctWidth
            budgetColor
            blink
          }
          canSnooze
          coveringFor
          showCoiWarning
          notes
          snoozedUntil
        }
      }
      personalTicketDashboard {
        groups {
          name
          description
          tickets {
            id
            subject
            updated
            created
            hasUnread
            unreadCount
            state
            aircraft {
              id
              registration
              owner {
                id
                firstName
                lastName
              }
            }
            creator {
              id
              firstName
              lastName
            }
            lastPoster {
              id
              firstName
              lastName
              role
            }
            client {
              id
            }
            snoozedUntil
          }
        }
      }
    }
  }
`;

export const QUERY_SAVVY_TICKET_SEARCH = gql`
  query SavvyTicketSearch($search: String, $limit: Int, $offset: Int) {
    savvy {
      id
      search(search: $search, limit: $limit) {
        tickets(offset: $offset, limit: $limit) {
          count
          results {
            id
            created
            updated
            state
            subject
            type
            creator {
              id
              firstName
              lastName
            }
            aircraft {
              id
              registration
              serial
              aircraftManufacturer {
                name
              }
              aircraftModel {
                name
              }
              owner {
                id
                firstName
                lastName
              }
            }
            client {
              id
              firstName
              lastName
            }
            lastPoster {
              id
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`;

export const QUERY_TICKET_CLIENT_INFO = gql`
  query SavvyTicketClientInfo($contactId: ID) {
    savvy {
      id
      contacts(contactId: $contactId) {
        id
        aircraft {
          id
          subscriptions {
            id
            serviceName
          }
        }
      }
    }
  }
`;

export const QUERY_SAVVY_TICKET_ALLOWED_TAGS = gql`
  query SavvyTicketAllowedTags($ticketId: Int!) {
    me {
      id
      tickets(ticketId: $ticketId) {
        id
        allowedTags
      }
    }
  }
`;

export const QUERY_GET_USERS_TICKETS = gql`
  query GetUsersTickets($contactId: ID) {
    savvy {
      id
      contacts(contactId: $contactId) {
        id
        tickets {
          id
          created
          updated
          state
          subject
          type
          creator {
            id
            firstName
            lastName
          }
          priority
          priorityExplanation
          hasUnread
        }
      }
      me {
        id
      }
    }
  }
`;

export const QUERY_SAVVY_CONFIG_FOR_TICKET = gql`
  query SavvyConfigForTicket {
    savvy {
      id
      permissions {
        ticket {
          canViewUnhappyState
          canResetUnhappy
          canUpdateTicketNotes
        }
      }
      me {
        id
      }
      textOnUpdate
      cannedResponses {
        title
        text
      }
      allowedAssignees {
        id
        firstName
        lastName
        fullNameWithNick
      }
      allowedTimesheetCategories {
        id
        name
      }
      lastTimesheetCategory {
        id
        name
      }
      showTimesheetForm
    }
  }
`;

export const QUERY_CANNED_RESPONSES_FOR_TICKET = gql`
  query SavvyConfigForTicket {
    savvy {
      borescopeCannedResponses {
        title
        text
      }
    }
  }
`;

export const QUERY_TICKETS_DASHBOARD = gql`
  ${FRAGMENT_TICKETS_DASHBOARD_ROW}
  query TicketsDashboard($tagName: String) {
    ticketsDashboard(tagName: $tagName) {
      ...TicketDashboard
    }
  }
`;

export const QUERY_FILTERED_TICKET = gql`
  query FilteredTickets($tag: String, $category: String) {
    filteredTickets(tag: $tag, category: $category) {
      id
      created
      updated
      state
      subject
      type
      creator {
        id
        firstName
        lastName
      }
      priority
      priorityExplanation
      hasUnread
    }
  }
`;

export const QUERY_RENEWAL_TICKETS = gql`
  query RenewalTickets($serviceCode: String) {
    renewalTickets(serviceCode: $serviceCode) {
      id
      created
      updated
      state
      subject
      type
      creator {
        id
        firstName
        lastName
      }
      priority
      priorityExplanation
      hasUnread
    }
  }
`;

export const QUERY_UNASSIGNED_TICKETS = gql`
  query UnassignedTickets {
    unassignedTickets {
      id
      created
      updated
      state
      subject
      type
      creator {
        id
        firstName
        lastName
      }
      priority
      priorityExplanation
      hasUnread
    }
  }
`;

export const QUERY_GET_AIRCRAFT_TICKETS = gql`
  query GetAircraftTickets($contactId: ID, $aircraftId: Int) {
    savvy {
      id
      contacts(contactId: $contactId) {
        tickets(aircraftId: $aircraftId) {
          id
          created
          updated
          state
          subject
          type
          creator {
            id
            firstName
            lastName
          }
          priority
          priorityExplanation
          hasUnread
        }
      }
      me {
        id
      }
    }
  }
`;

export const QUERY_SERVICE_CENTER_TICKETS = gql`
  query ServiceCenterTickets($serviceCenterId: ID!) {
    serviceCenters(serviceCenterId: $serviceCenterId) {
      id
      tickets {
        id
        created
        updated
        state
        subject
        type
        creator {
          id
          firstName
          lastName
        }
        priority
        priorityExplanation
        hasUnread
        aircraft {
          id
          registration
          owner {
            id
            firstName
            lastName
          }
          serial
        }
      }
    }
  }
`;

export const QUERY_GET_TICKETS_AIRCRAFT = gql`
  query GetTicketsAircraft($ticketId: Int!) {
    me {
      id
      tickets(ticketId: $ticketId) {
        id
        client {
          id
          contact {
            id
            aircraft {
              id
              registration
              serial
              aircraftManufacturer {
                name
              }
              aircraftModel {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const QUERY_GET_SAVVY_TICKET_PERMISSIONS = gql`
  query GetSavvyTicketPermissions {
    savvy {
      id
      permissions {
        ticket {
          canExportTicket
        }
      }
    }
  }
`;

export const QUERY_TECHNICIAN_TICKETS = gql`
  query MeTechTickets {
    me {
      id
      tickets {
        id
        subject
        updated
        created
        hasUnread
        state
        aircraft {
          id
          registration
          aircraftManufacturer {
            id
            name
          }
          aircraftModel {
            id
            name
          }
          owner {
            id
            firstName
            lastName
          }
        }
        creator {
          id
          firstName
          lastName
        }
        lastPoster {
          id
          firstName
          lastName
          role
        }
        client {
          id
          firstName
          lastName
          email
          isEmailClient
        }
        firstUnreadDatetime
        unreadCount
        color
      }
    }
  }
`;

export const QUERY_TIMESHEETS = gql`
  ${FRAGMENT_TIMESHEET}
  query GetSavvyTimesheets($timesheetState: String, $fromDatetime: DateTime, $toDatetime: DateTime, $offset: Int, $limit: Int) {
    savvy {
      id
      timesheets(timesheetState: $timesheetState, fromDatetime: $fromDatetime, toDatetime: $toDatetime, offset: $offset, limit: $limit) {
        count
        totalMinutes
        timesheets {
          ...Timesheet
        }
      }
    }
  }
`;

export const QUERY_TIMESHEET_CATEGORY = gql`
  query getTimesheetCategory {
    savvy {
      id
      allowedTimesheetCategories {
        id
        name
      }
    }
  }
`;

export const QUERY_FEVA_ALERTS = gql`
  query FevaAlerts($alertsFilter: String) {
    fevaAlerts(alertsFilter: $alertsFilter) {
      id
      cylinders
      flight {
        id
        duration
        date
        aircraft {
          id
          registration
          owner {
            id
            firstName
            lastName
          }
        }
      }
      notificationDate
      chartVerificationStatus
      physicalVerificationStatus
      ticket {
        id
        subject
      }
      manuallyCreated
      comment
    }
  }
`;

export const QUERY_FEVA_ALERT_DETAIL = gql`
  ${FRAGMENT_FEVA_ALERT}
  query FevaAlertDetail($fevaAlertId: ID) {
    fevaAlerts(fevaAlertId: $fevaAlertId) {
      ...FevaAlert
      relatedFevaAlerts {
        ...FevaAlert
      }
    }
  }
`;

export const QUERY_ANALYSIS_DASHBOARD = gql`
  query AnalysisDashboard {
    analysisDashboard {
      analystsWithoutTickets {
        id
        firstName
        lastName
      }
      ticketsByAnalyst {
        analyst {
          id
          firstName
          lastName
        }
        tickets {
          id
          created
          updated
          state
          subject
          client {
            id
            firstName
            lastName
          }
          aircraft {
            id
            registration
            serial
            aircraftManufacturer {
              id
              name
            }
            aircraftModel {
              id
              name
            }
            subscriptions {
              id
              startDate
              endDate
              serviceName
              status
            }
          }
          lastPoster {
            id
            firstName
            lastName
          }
          snoozedUntil
          priority
          tags {
            ... on AnalysisTagType {
              id
              name
              verboseName
              created
              metadata
              flight {
                id
              }
              report {
                id
              }
            }
            ... on BorescopeAnalysisTagType {
              tagId
              name
              borescopeImageSet {
                id
                name
              }
            }
          }
        }
      }
      unassignedTickets {
        id
        created
        updated
        state
        subject
        client {
          id
          firstName
          lastName
        }
        aircraft {
          id
          registration
          serial
          aircraftManufacturer {
            id
            name
          }
          aircraftModel {
            id
            name
          }
          subscriptions {
            id
            startDate
            endDate
            serviceName
            status
          }
        }
        lastPoster {
          id
          firstName
          lastName
        }
        lastAssignee {
          id
          firstName
          lastName
        }
        snoozedUntil
        priority
        tags {
          ... on AnalysisTagType {
            id
            name
            verboseName
            created
            metadata
            flight {
              id
            }
            report {
              id
            }
          }
          ... on BorescopeAnalysisTagType {
            tagId
            name
            borescopeImageSet {
              id
              name
            }
          }
        }
      }
      analysts {
        id
        firstName
        lastName
      }
    }
  }
`;

export const QUERY_TIMESHEETS_CATEGORY = gql`
  query TimesheetCategories {
    timesheetCategories {
      id
      name
      rate
      active
      default
      quickenCategory
      overhead
    }
  }
`;

export const QUERY_TIMESHEET_CATEGORY_RATES = gql`
  query TimesheetCategoryRates {
    timesheetCategoryRates {
      id
      user {
        id
        firstName
        lastName
      }
      category {
        id
        name
      }
      rate
    }
  }
`;

export const QUERY_ANALYSIS_TICKET_WITH_TAG = gql`
  query AnalysisTicketTagById($tagId: ID) {
    analysisTicketTagById(tagId: $tagId) {
      id
      ticket {
        id
      }
      flight {
        id
        aircraft {
          id
          registration
          owner {
            id
            firstName
            lastName
          }
          aircraftModel {
            id
            enginesCount
          }
        }
      }
      created
      name
      clientComments
    }
  }
`;

export const QUERY_TICKET_RATING_BY_UUID = gql`
  query TicketRatingByUuid($ticketRatingUuid: String) {
    ticketRatingByUuid(ticketRatingUuid: $ticketRatingUuid) {
      id
      ticket {
        id
        subject
        serviceCenter {
          id
          company
        }
      }
      rating
    }
  }
`;

export const QUERY_RATING_DEFINITION = gql`
  query RatingDefinitions {
    ratingDefinitions {
      id
      name
      displayNamePositive
      displayNameNegative
      applicableTo
    }
  }
`;

export const QUERY_SAVVY_TICKET_SERVICE_CENTER = gql`
  query SavvyTicketServiceCenter($ticketId: Int!) {
    me {
      id
      tickets(ticketId: $ticketId) {
        id
        serviceCenter {
          id
          company
        }
      }
    }
  }
`;

export const QUERY_PREBUY_CONFLICT_BODY = gql`
  query PrebuyConflictTicketBody($aircraftId: ID) {
    prebuyConflictTicketBody(aircraftId: $aircraftId)
  }
`;

export const QUERY_DOWNLOAD_POST_ATTACHMENTS_S3_URL = gql`
  query DownloadPostAttachmentsS3Url($workflowId: String) {
    downloadPostAttachmentsS3Url(workflowId: $workflowId)
  }
`;

export const QUERY_SAVVY_TEXT_ON_UPDATE = gql`
  query SavvyTextOnUpdate {
    savvy {
      id
      textOnUpdate
    }
  }
`;
