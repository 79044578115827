import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box, Typography, Button, Link } from '@mui/material';
import { DataGridPro, GridValueGetterParams, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-pro';

import { LayoutDashboard, ComponentLoading, CustomGridToolbar } from 'components';

import { QUERY_COHORTS } from 'gql';
import { useLazyQuery } from '@apollo/client';
import { getTimesheetDateString, ctrlCmdClick, sortCohortsByAircraftName } from 'helpers';

import { PATH_SAVVY_COHORTS, PATH_SAVVY_ENGINE_PARAMETERS } from 'const';

import { CreateCohortDialog } from './components';

import { setAlert } from 'state';

const Cohorts: React.FC = () => {
  const [fetchCohorts, { data: dataCohorts, error: errorCohorts, loading: loadingCohorts }] = useLazyQuery(QUERY_COHORTS, {
    fetchPolicy: 'cache-and-network',
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [filterButtonEl, setFilterButtonEl] = useState<HTMLButtonElement | null>(null);

  const [openCreate, setOpenCreate] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (errorCohorts) {
      dispatch(setAlert('error', 'Unable to load cohorts'));
    }
  }, [errorCohorts, dispatch]);

  useEffect(() => {
    fetchCohorts();
  }, [fetchCohorts]);

  useEffect(() => {
    if (success) {
      fetchCohorts();
    }
  }, [success, fetchCohorts]);

  return (
    <LayoutDashboard backgroundColor="#fff">
      <Box p={4}>
        <Typography variant="h2" gutterBottom>
          Cohorts
        </Typography>
        <Box py={1}>
          <Button
            variant="contained"
            onClick={() => {
              setSuccess(false);
              setOpenCreate(true);
            }}
          >
            Create Cohort
          </Button>
        </Box>
        <Box py={1}>
          <ComponentLoading loading={loadingCohorts}>
            <Box py={1}>
              <DataGridPro
                sx={{
                  width: '100%',
                  minWidth: '100px',
                  '& .MuiDataGrid-cell:focus': {
                    outline: 'none',
                  },
                  '& .MuiDataGrid-row': {
                    cursor: 'pointer',
                    borderBottom: '1px solid',
                    borderColor: 'grey.200',
                  },
                  '& .MuiDataGrid-columnHeader:focus': {
                    outline: 'none',
                  },
                  '& .MuiDataGrid-toolbarContainer .MuiTextField-root': {
                    mb: 0,
                  },
                  border: 'none',
                }}
                autoHeight
                disableSelectionOnClick
                rowsPerPageOptions={
                  dataCohorts?.cohorts?.length && dataCohorts.cohorts.length > 100
                    ? [5, 10, 25, dataCohorts.cohorts.length]
                    : [5, 10, 25, 100]
                }
                autoPageSize
                showColumnRightBorder={false}
                disableColumnSelector
                disableColumnMenu
                rows={dataCohorts?.cohorts?.length ? sortCohortsByAircraftName(dataCohorts.cohorts) : []}
                columns={[
                  {
                    field: 'id',
                    headerName: 'ID',
                    flex: 0.3,
                  },
                  {
                    field: 'aircraftModel',
                    headerName: 'Aircraft',
                    valueGetter: (params: GridValueGetterParams) => {
                      if (!params?.value) return '';
                      return `${params.value.manufacturer.name} ${params.value.name}`;
                    },
                    flex: 1,
                  },
                  {
                    field: 'engineModel',
                    headerName: 'Engine',
                    valueGetter: (params: GridValueGetterParams) => {
                      if (!params?.value) return '';
                      return `${params.value.manufacturer.name} ${params.value.name}`;
                    },
                    flex: 1,
                  },
                  {
                    field: 'numberOfFlights',
                    headerName: 'Flights',
                    valueGetter: (params: GridValueGetterParams) => {
                      return params?.value ? params.value : 'None';
                    },
                    flex: 0.5,
                  },
                  {
                    field: 'parentCohort',
                    headerName: 'Parent Cohort',
                    renderCell: (params: GridRenderCellParams) => {
                      if (!params.row?.parentCohort) return '';

                      return (
                        <Link
                          href={`${PATH_SAVVY_COHORTS}/${params.row?.parentCohort.id}`}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            ctrlCmdClick(e, `${PATH_SAVVY_COHORTS}/${params.row?.parentCohort.id}`, navigate);
                          }}
                        >
                          Cohort #{params.row?.parentCohort.id}
                        </Link>
                      );
                    },
                    flex: 0.5,
                  },
                  {
                    field: 'engineParameters',
                    headerName: 'Power Model',
                    renderCell: (params: GridRenderCellParams) => {
                      if (!params.row?.engineParameters) return 'None';

                      return (
                        <Link
                          href={`${PATH_SAVVY_ENGINE_PARAMETERS}/${params.row?.engineParameters.id}`}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            ctrlCmdClick(e, `${PATH_SAVVY_ENGINE_PARAMETERS}/${params.row?.engineParameters.id}`, navigate);
                          }}
                        >
                          {params.row?.engineParameters.name}
                        </Link>
                      );
                    },
                    flex: 0.5,
                  },
                  {
                    field: 'active',
                    headerName: 'Active',
                    valueGetter: (params: GridValueGetterParams) => {
                      return params?.value ? 'Yes' : 'No';
                    },
                    flex: 0.5,
                  },
                  {
                    field: 'updated',
                    headerName: 'Last Updated',
                    valueGetter: (params: GridValueGetterParams) => {
                      return params?.value ? getTimesheetDateString(params.value) : 'None';
                    },
                    flex: 0.5,
                  },
                ]}
                onRowClick={(params: GridRowParams, event?: any) => {
                  const { row } = params;
                  if (row?.id) {
                    ctrlCmdClick(event, `${PATH_SAVVY_COHORTS}/${row.id}`, navigate);
                  }
                }}
                components={{ Toolbar: CustomGridToolbar }}
                componentsProps={{
                  toolbar: {
                    setFilterButtonEl,
                  },
                  filterPanel: {
                    sx: {
                      '& .MuiDataGrid-filterForm': {
                        '& .MuiTextField-root': {
                          mb: 0,
                        },
                      },
                    },
                  },
                  panel: {
                    anchorEl: filterButtonEl,
                    placement: 'bottom-end',
                  },
                }}
              />
            </Box>
          </ComponentLoading>
        </Box>
        <CreateCohortDialog open={openCreate} setOpen={setOpenCreate} setSuccess={setSuccess} parentCohorts={dataCohorts?.cohorts || []} />
      </Box>
    </LayoutDashboard>
  );
};

export default Cohorts;
