import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Link } from '@mui/material';
import { ComponentLoading } from 'components';

import { QUERY_SAVVY_SUBSCRIPTION_SEARCH } from 'gql';
import { useLazyQuery } from '@apollo/client';
import { SAVVY_SEARCH_LIMIT, PATH_SAVVY_AIRCRAFT, PATH_SAVVY_SEARCH_SUBSCRIPTION } from 'const';

import { ISubscription } from 'types';
import { ctrlCmdClick } from 'helpers';

interface SubscriptionSearchProps {
  searchQuery: string;
}

const SubscriptionSearch: React.FC<SubscriptionSearchProps> = (props) => {
  const { searchQuery } = props;

  const [fetchSavvySubscriptionSearch, { data: dataSubscription, loading: loadingSubscription }] =
    useLazyQuery(QUERY_SAVVY_SUBSCRIPTION_SEARCH);

  const [subscriptionList, setSubscriptionList] = useState<ISubscription[]>([]);

  const [count, setCount] = useState(0);

  const navigate = useNavigate();

  const loadMore = () => {
    navigate(`${PATH_SAVVY_SEARCH_SUBSCRIPTION}?query=${searchQuery}`);
  };

  useEffect(() => {
    if (!searchQuery || searchQuery.length < 2) {
      return;
    }

    setSubscriptionList([]);

    const timeout: NodeJS.Timeout = setTimeout(() => {
      fetchSavvySubscriptionSearch({
        variables: {
          search: searchQuery,
          limit: SAVVY_SEARCH_LIMIT,
          offset: 0,
        },
      });
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [searchQuery, fetchSavvySubscriptionSearch]);

  useEffect(() => {
    if (dataSubscription?.savvy?.search?.subscriptions?.results?.length) {
      setSubscriptionList((subscriptionList: ISubscription[]) => {
        const newArray: ISubscription[] = subscriptionList.concat(dataSubscription.savvy?.search?.subscriptions?.results);
        return [...newArray];
      });
    }

    if (dataSubscription?.savvy?.search?.subscriptions) {
      setCount(dataSubscription.savvy?.search?.subscriptions?.count);
    }
  }, [dataSubscription]);

  return (
    <Box>
      <ComponentLoading loading={loadingSubscription}>
        <Box>
          {!!searchQuery && (
            <Typography
              variant="h5"
              sx={{
                fontWeight: 'bold',
                py: 1,
              }}
            >
              Subscriptions {count ? `(${count})` : ''}
            </Typography>
          )}
          <Box pl={4}>
            {subscriptionList?.map((subscription: ISubscription, key: number) => {
              return (
                <Box
                  key={key}
                  py={1}
                  sx={{
                    cursor: 'pointer',
                  }}
                  onClick={(event: any) => {
                    ctrlCmdClick(event, `${PATH_SAVVY_AIRCRAFT}/${subscription?.aircraft?.id}/subscriptions/${subscription.id}`, navigate);
                  }}
                >
                  <Typography
                    sx={{
                      textDecoration: subscription.status === 'Active' ? 'none' : 'line-through',
                    }}
                  >
                    {subscription.aircraft?.registration} {subscription.aircraft?.owner?.firstName} {subscription.aircraft?.owner?.lastName}{' '}
                    {subscription.serviceName} ({subscription.status})
                  </Typography>
                </Box>
              );
            })}
            {!subscriptionList?.length && !!searchQuery && !loadingSubscription && <Box py={1}>No matched subscriptions</Box>}
          </Box>
          {count > subscriptionList.length && (
            <Link
              href="#"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                loadMore();
              }}
            >
              Show More
            </Link>
          )}
        </Box>
      </ComponentLoading>
    </Box>
  );
};

export default SubscriptionSearch;
