import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';

import { Typography, Box, TextField, MenuItem, Button } from '@mui/material';
import FlightIcon from '@mui/icons-material/Flight';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import { DataGridPro, GridRowParams } from '@mui/x-data-grid-pro';

import { useSmDown, useLgDown, useTableConfig } from 'hooks';
import { aircraftRegistrationMakeModel, getDefaultAircraft, ctrlCmdClick } from 'helpers';

import { useQuery, useLazyQuery } from '@apollo/client';
import { QUERY_USER_ONLY_AIRCRAFTS, QUERY_USER_FLIGHTS_PER_AIRCRAFT } from 'gql';
import { setAlert } from 'state';
import { ComponentLoading, CustomGridToolbar } from 'components';

import { ISubscription, IAircraft } from 'types';
import { PATH_FLIGHTS_UPLOAD, PATH_FLIGHTS, PATH_AIRCRAFT_CREATE, PATH_BETA_PLANS } from 'const';

import { storeSelectedAircraft } from 'state';

const Aircrafts: React.FC = () => {
  const { data, loading, error } = useQuery(QUERY_USER_ONLY_AIRCRAFTS);

  const [fetchFlights, { data: dataFlights, loading: loadingFlights, error: errorFlights }] = useLazyQuery(QUERY_USER_FLIGHTS_PER_AIRCRAFT);

  const [selectedAircraft, setSelectedAircraft] = useState<IAircraft | undefined>(undefined);

  const [firstSubscriptionIndex, setFirstSubscriptionIndex] = useState(0);

  const [pageSize, setPageSize] = useState(20);
  const [filterButtonEl, setFilterButtonEl] = useState<HTMLButtonElement | null>(null);

  const dispatch = useDispatch();
  const { storedAircraftId } = useSelector((state: any) => state.auth);

  const navigate = useNavigate();
  const { density, setDensity } = useTableConfig();

  useEffect(() => {
    if (data?.me?.aircraft) {
      if (storedAircraftId) {
        const filteredAircraft = data.me.aircraft.filter((aircraft: IAircraft) => aircraft.id === storedAircraftId);
        if (filteredAircraft) {
          setSelectedAircraft(filteredAircraft[0]);
        } else {
          setSelectedAircraft(getDefaultAircraft(data?.me?.aircraft));
        }
      } else {
        setSelectedAircraft(getDefaultAircraft(data?.me?.aircraft));
      }
    }
    if (error) {
      dispatch(setAlert('error', 'Unable to load your aircrafts.'));
    }
  }, [data, error, dispatch, storedAircraftId]);

  useEffect(() => {
    if (selectedAircraft && selectedAircraft.id) {
      fetchFlights({
        variables: {
          id: parseInt(selectedAircraft.id),
        },
      });

      if (selectedAircraft?.subscriptions?.length) {
        for (let i = 0; i < selectedAircraft?.subscriptions?.length; i++) {
          if (selectedAircraft.subscriptions[i].status === 'Active') {
            setFirstSubscriptionIndex(i);
            break;
          }
        }
      }
    }
  }, [selectedAircraft, fetchFlights]);

  useEffect(() => {
    if (!loadingFlights && errorFlights) {
      dispatch(setAlert('error', 'Unable to load flights data.'));
    }
  }, [errorFlights, loadingFlights, dispatch]);

  let aircrafts: IAircraft[] = [];

  if (data) aircrafts = data?.me?.aircraft;

  const isLgDown = useLgDown();
  const isSmDown = useSmDown();

  return (
    <Box
      sx={{
        border: '1px solid',
        borderColor: 'grey.200',
        backgroundColor: 'background.default',
      }}
    >
      <ComponentLoading loading={loading || loadingFlights}>
        {!!aircrafts?.length && (
          <Box
            px={2.5}
            py={2.25}
            sx={{
              display: isSmDown ? 'block' : 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderBottom: '1px solid',
              borderBottomColor: 'grey.200',
            }}
          >
            {!!aircrafts?.length && (
              <TextField
                select
                value={selectedAircraft ? selectedAircraft.id : ''}
                variant="outlined"
                sx={{
                  marginBottom: 0,
                  minWidth: '300px',
                }}
                onChange={(e: any) => {
                  const tmpAircraft = aircrafts.filter((aircraft: IAircraft) => aircraft.id === e.target.value);

                  setSelectedAircraft(tmpAircraft[0]);
                  dispatch(storeSelectedAircraft(e.target.value));
                }}
                fullWidth={isLgDown}
              >
                {aircrafts?.map((item: IAircraft, key: number) => {
                  return (
                    <MenuItem key={key} value={item.id} title={aircraftRegistrationMakeModel(item)}>
                      <Typography
                        sx={{
                          fontSize: '20px',
                          fontWeight: 'bold',
                          color: 'text.primary',
                          lineHeight: '27px',
                        }}
                      >
                        {aircraftRegistrationMakeModel(item, true, 25)}
                      </Typography>
                    </MenuItem>
                  );
                })}
              </TextField>
            )}
            <Box
              px={isSmDown ? 0 : 2.5}
              py={1}
              sx={{
                textAlign: isSmDown ? 'center' : 'right',
                display: {
                  sm: 'block',
                  md: 'flex',
                  lg: 'flex',
                  xl: 'flex',
                },
                flexDirection: {
                  md: 'row-reverse',
                  xl: 'row-reverse',
                },
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    marginRight: '20px',
                  }}
                  color="primary"
                  onClick={() => {
                    if (selectedAircraft && selectedAircraft.id) {
                      navigate(`${PATH_BETA_PLANS}/${selectedAircraft.id}`);
                    }
                  }}
                >
                  <MoreTimeIcon />
                  Manage Plans
                </Button>
                <NavLink to={selectedAircraft ? `${PATH_FLIGHTS_UPLOAD}/${selectedAircraft.id}` : PATH_FLIGHTS_UPLOAD}>
                  <Button variant="contained" color="primary">
                    <FlightIcon
                      sx={{
                        transform: 'rotate(45deg)',
                      }}
                    />
                    Upload Flight
                  </Button>
                </NavLink>
              </Box>
              <Box
                sx={{
                  marginRight: {
                    lg: 0,
                    xl: '16px',
                  },
                  paddingTop: isSmDown ? '10px' : 0,
                }}
              >
                <Typography
                  style={{
                    fontWeight: 700,
                    fontSize: '14px',
                    maxWidth: isLgDown ? 'inherit' : '200px',
                  }}
                >
                  {selectedAircraft?.subscriptions &&
                    selectedAircraft?.subscriptions.map((subscription: ISubscription, key: number) => {
                      if (subscription.status !== 'Active') return null;
                      if (key > firstSubscriptionIndex) {
                        return (
                          <React.Fragment key={key}>
                            , {subscription.serviceName}:{' '}
                            <Box
                              component="span"
                              sx={{
                                color: 'success.dark',
                              }}
                            >
                              {subscription.status}
                            </Box>
                          </React.Fragment>
                        );
                      } else {
                        return (
                          <React.Fragment key={key}>
                            {subscription.serviceName}:{' '}
                            <Box
                              component="span"
                              sx={{
                                color: 'success.dark',
                              }}
                            >
                              {subscription.status}
                            </Box>
                          </React.Fragment>
                        );
                      }
                    })}
                </Typography>
              </Box>
            </Box>
            <NavLink to={PATH_FLIGHTS}>
              <Typography
                paragraph
                color="textPrimary"
                sx={{
                  marginBottom: 0,
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                {isSmDown ? 'All' : 'View All Flights'}
              </Typography>
            </NavLink>
          </Box>
        )}
        {!aircrafts?.length && (
          <Box
            px={2.5}
            py={2.25}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderBottom: '1px solid',
              borderBottomColor: 'grey.200',
            }}
          >
            <Typography
              textAlign="center"
              sx={{
                fontSize: '20px',
              }}
            >
              No aircraft found.
              <Button
                variant="contained"
                onClick={() => {
                  navigate(PATH_AIRCRAFT_CREATE);
                }}
                sx={{
                  ml: 1,
                }}
              >
                Add Aircraft
              </Button>
            </Typography>
          </Box>
        )}
        <Box
          px={2}
          sx={{
            minHeight: '200px',
          }}
        >
          {selectedAircraft && !loadingFlights && (
            <Box py={2}>
              <Typography
                variant="h3"
                gutterBottom
                sx={{
                  fontWeight: 'bold',
                  color: 'text.secondary',
                }}
              >
                Latest Aircraft Activity
              </Typography>
              <DataGridPro
                sx={{
                  width: '100%',
                  minWidth: '100px',
                  '& .MuiDataGrid-columnSeparator': {
                    display: 'none',
                  },
                  '& .MuiDataGrid-cell:focus': {
                    outline: 'none',
                  },
                  '& .MuiDataGrid-row': {
                    cursor: 'pointer',
                    borderBottom: '1px solid',
                    borderColor: 'grey.200',
                  },
                  '& .MuiDataGrid-columnHeader:focus': {
                    outline: 'none',
                  },
                  '& .MuiDataGrid-cellContent': {
                    whiteSpace: 'break-spaces',
                  },
                  '& .MuiDataGrid-toolbarContainer .MuiTextField-root': {
                    mb: 0,
                  },
                  border: 'none',
                }}
                autoHeight
                disableColumnResize={true}
                rowsPerPageOptions={[5, 10, 20, 100]}
                pageSize={pageSize}
                onPageSizeChange={(newPage) => setPageSize(newPage)}
                pagination
                showColumnRightBorder={false}
                disableColumnSelector
                disableColumnMenu
                disableSelectionOnClick
                density={density}
                onStateChange={(params) => {
                  if (params.density && params.density.value !== density) {
                    setDensity(params.density.value);
                  }
                }}
                rows={dataFlights?.me?.aircraft[0]?.flights ? dataFlights.me.aircraft[0].flights : []}
                columns={[
                  {
                    field: 'date',
                    headerName: 'Date',
                    valueFormatter: (params) => {
                      return params.value ? params.value.toString().substring(0, 10) : '';
                    },
                    flex: 0.7,
                  },
                  {
                    field: 'departureAirport',
                    headerName: 'Departure Airport',
                    flex: 1,
                  },
                  {
                    field: 'destinationAirport',
                    headerName: 'Destination Airport',
                    flex: 1,
                  },
                  {
                    field: 'duration',
                    headerName: 'Duration',
                    valueFormatter: (params) => {
                      if (!params?.value) return '';
                      const hour = Math.floor(params.value / 3600);
                      const min = Math.floor((params.value % 3600) / 60);
                      return `${hour}h ${(min < 10 ? '0' : '') + min}m`;
                    },
                    flex: 0.7,
                  },
                ]}
                onRowClick={(params: GridRowParams, event?: any) => {
                  const { row } = params;
                  if (row.id) {
                    ctrlCmdClick(event, `${PATH_FLIGHTS}/${row.id}`, navigate);
                  } else {
                    return false;
                  }
                }}
                components={{ Toolbar: CustomGridToolbar }}
                componentsProps={{
                  toolbar: {
                    setFilterButtonEl,
                  },
                  filterPanel: {
                    sx: {
                      '& .MuiDataGrid-filterForm': {
                        '& .MuiTextField-root': {
                          mb: 0,
                        },
                      },
                    },
                  },
                  panel: {
                    anchorEl: filterButtonEl,
                    placement: 'bottom-end',
                  },
                }}
              />
            </Box>
          )}
        </Box>
      </ComponentLoading>
    </Box>
  );
};

export default Aircrafts;
