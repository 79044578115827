import { Navigate } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { PageLoading } from 'components';
import { NotFound } from 'pages';

import { PATH_LOGIN, PATH_SECURITY_CHECK } from 'const';

interface IPrivateRoute {
  element: any;
  path: string;
  exact?: boolean;
}

const PrivateSavvyRoute = (props: IPrivateRoute) => {
  const { element: Component } = props;
  const { loggedIn, incompleteAircraft, isSavvy, missingToken } = useSelector((state: any) => {
    return state.auth;
  });

  if (loggedIn === undefined) return <PageLoading />;

  if (missingToken) {
    return <Navigate to={PATH_SECURITY_CHECK} replace />;
  }

  if (loggedIn === false) {
    const next = window.location.pathname;
    const search = window.location.search;

    // Redirect to login
    return <Navigate to={`${PATH_LOGIN}?next=${encodeURIComponent(next + search)}`} />;
  }

  if (loggedIn && incompleteAircraft?.length > 0) {
    if (incompleteAircraft[0].subscriptions?.length > 0) {
      return <Navigate to={`/signup/${incompleteAircraft[0].subscriptions[0].serviceCode}/additional-info`} replace />;
    }
  }

  return isSavvy ? Component : <NotFound />;
};

export default PrivateSavvyRoute;
