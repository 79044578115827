import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Link, Hidden, IconButton } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { IReportContext } from 'types';
import { ComponentLoading } from 'components';
import { ReportContext } from './';
import { PATH_COHORTS, PATH_BETA_PLANS, BORESCOPE_ANALYSIS_REPORT_TYPE } from 'const';
import { ctrlCmdClick } from 'helpers';

interface ReportCardContextProps {
  aircraft:
    | {
        id: string;
        reportCardEligible: boolean;
        cohortExists: boolean;
        reportCards: string;
      }
    | undefined;
  reportContext: IReportContext | undefined;
  loadingContext: boolean;
  setSlideNum?: (value: number) => void;
  reportId: string;
  reportFilter: string;
  reportType: string | undefined;
  length: number;
  cardType?: string;
}

const NewReportCardContext: React.FC<ReportCardContextProps> = (props) => {
  const { aircraft, reportContext, loadingContext, setSlideNum, reportId, reportFilter, reportType, length, cardType } = props;
  const navigate = useNavigate();

  if (aircraft === undefined) {
    return (
      <Box
        sx={{
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '23px',
              color: 'text.primary',
              textAlign: 'center',
              mt: 5,
            }}
          >
            Please create a new report or select an existing one.
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
      }}
    >
      <ComponentLoading loading={loadingContext}>
        <Hidden xlUp>
          <Box py={1} px={2}>
            <IconButton
              sx={{
                '&:hover': {
                  borderRadius: '6px',
                },
                borderRadius: '6px',
                boxShadow: '0px 1px 1px rgba(19, 30, 64, 0.05)',
                border: '1px solid #E8E9EF',
                background: '#FFFFFF',
              }}
              onClick={() => {
                if (setSlideNum) {
                  setSlideNum(1);
                }
              }}
            >
              <ChevronLeftIcon />
            </IconButton>
          </Box>
        </Hidden>
        {reportFilter !== BORESCOPE_ANALYSIS_REPORT_TYPE && ((!reportType && !reportId) || !!reportId) && (
          <Box>
            {!aircraft.reportCardEligible && (
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: '16px',
                    lineHeight: '23px',
                    color: 'grey.600',
                    textAlign: 'center',
                    margin: '0 auto',
                    mt: 5,
                  }}
                >
                  Report Cards and Trend Analysis are available to aircraft enrolled in a&nbsp;
                  <b>SavvyBasics, SavvyAnalysis, SavvyQA and SavvyMx plan.</b>
                  <br />
                  <Link
                    href={`${PATH_BETA_PLANS}/${aircraft.id}`}
                    onClick={(e: any) => {
                      e.preventDefault();
                      e.stopPropagation();
                      ctrlCmdClick(e, `${PATH_BETA_PLANS}/${aircraft.id}`, navigate);
                    }}
                  >
                    View Available Plans
                  </Link>
                </Typography>
              </Box>
            )}
            {!aircraft.cohortExists && cardType === 'report_card' && !reportId && (
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: '16px',
                    lineHeight: '23px',
                    color: 'grey.600',
                    textAlign: 'center',
                    maxWidth: '600px',
                    margin: '0 auto',
                    mt: 5,
                  }}
                >
                  This aircraft is not in any of the currently supported cohorts.
                  <br />
                  Report Cards can only be created for aircraft in a supported cohort.{' '}
                  <b>However, you can generate Trend Analysis reports by changing the "Report Type" drop-down.</b>
                  <br />
                  Periodically we add support for new cohorts. If we begin providing support for your aircraft's cohort, we will notify you
                  via email.
                  <br />
                  See a list of{' '}
                  <Link
                    href={PATH_COHORTS}
                    onClick={(e: any) => {
                      e.preventDefault();
                      e.stopPropagation();
                      ctrlCmdClick(e, PATH_COHORTS, navigate);
                    }}
                  >
                    currently supported aircraft and engine cohorts
                  </Link>
                  .
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                pt: 5,
              }}
            >
              {!length &&
                aircraft.reportCardEligible &&
                (aircraft.cohortExists || (!aircraft.cohortExists && cardType !== 'report_card')) &&
                !reportId && (
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: '16px',
                      lineHeight: '23px',
                      color: 'text.primary',
                      textAlign: 'center',
                    }}
                  >
                    Please create a new report.
                  </Typography>
                )}
              {reportContext !== undefined && <ReportContext reportContext={reportContext} reportId={reportId} />}
            </Box>
          </Box>
        )}
      </ComponentLoading>
    </Box>
  );
};

export default NewReportCardContext;
