import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { PageLoading } from 'components';

import { useLazyQuery } from '@apollo/client';
import { QUERY_USER_TICKET_ELIGIBILITY } from 'gql';
import { setAlert } from 'state';

import { LayoutTicketCreate, PostTicket } from './components';
import { ticketTypeArrayByName } from 'api';
import { IAircraftEligibility } from 'types';

const TicketsCreate: React.FC = () => {
  const params = useParams();

  const [fetchAircraft, { data, loading, error }] = useLazyQuery(QUERY_USER_TICKET_ELIGIBILITY);
  const [prebuyAircraft, setPrebuyAircraft] = useState<IAircraftEligibility | undefined>(undefined);

  const [ticketType, setTicketType] = useState<
    | {
        field: string;
        label: string;
        urlType: string;
      }
    | undefined
  >(undefined);

  const isPrebuy = (value: any) => {
    if (value && !value.id && value.type === 'prebuy') return true;
    return false;
  };

  useEffect(() => {
    if (params && params.type) {
      for (let i = 0; i < ticketTypeArrayByName.length; i++) {
        if (ticketTypeArrayByName[i].urlType === params.type) {
          setTicketType(ticketTypeArrayByName[i]);
        }
      }
    }
    if (params && params.id) {
      fetchAircraft({
        variables: {
          id: parseInt(params.id),
        },
      });
    } else if (params && !params.id && params.type === 'prebuy') {
      fetchAircraft();
    }
  }, [params, fetchAircraft]);

  useEffect(() => {
    if (isPrebuy(params)) {
      if (data) {
        for (let i = 0; i < data?.me?.aircraft?.length; i++) {
          if (
            data.me.aircraft[i].ticketEligibility.prebuy.free ||
            (data.me.aircraft[i].ticketEligibility.prebuy.eligible &&
              data.me.aircraft[i].ticketEligibility.prebuy.currentlyEligibleVia.length)
          ) {
            setPrebuyAircraft(data.me.aircraft[i]);
          }
        }
      }
    }
  }, [params, data]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      dispatch(setAlert('error', 'Unable to load user data'));
    }
  }, [error, dispatch]);

  return (
    <React.Fragment>
      {loading && <PageLoading />}
      <LayoutTicketCreate
        activeStep={1}
        component={
          <PostTicket
            ticketAircraft={isPrebuy(params) ? prebuyAircraft : data?.me?.aircraft[0] ? data?.me?.aircraft[0] : undefined}
            ticketType={ticketType}
          />
        }
      />
    </React.Fragment>
  );
};

export default TicketsCreate;
