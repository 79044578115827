import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, TextField, MenuItem, Typography, Link } from '@mui/material';
import { aircraftRegistrationMakeModel } from 'helpers';
import TooltipForUpload from './TooltipForUpload';
import { LegacyUpload, WorkflowUpload, RecentEdfTable } from './fileUploadComponents';

import { PATH_FLIGHTS_UPLOAD, PATH_FAQ } from 'const';

import { storeSelectedAircraft } from 'state';
import { IAircraft } from 'types';

interface FileUploadWithAircraftProps {
  aircrafts: IAircraft[];
  fromTime: Date;
  setEngineDataFileId: (value: string) => void;
}

const FileUploadWithAircraft: React.FC<FileUploadWithAircraftProps> = (props) => {
  const { aircrafts: propsAircraft, fromTime, setEngineDataFileId } = props;

  const [selectedAircraft, setSelectedAircraft] = useState<IAircraft | undefined>(undefined);

  const dispatch = useDispatch();

  const { id } = useParams();

  const [aircrafts, setAircrafts] = useState<IAircraft[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (propsAircraft && id) {
      for (let i = 0; i < propsAircraft.length; i++) {
        if (propsAircraft[i].id === id) {
          setSelectedAircraft(propsAircraft[i]);
        }
      }
    } else {
      setSelectedAircraft(undefined);
    }
  }, [id, propsAircraft]);

  useEffect(() => {
    if (propsAircraft.length) {
      const tmp = [...propsAircraft];
      setAircrafts(
        tmp.sort((firstAircraft, secondAircraft) => {
          if (firstAircraft.registration > secondAircraft.registration) {
            return 1;
          } else {
            return -1;
          }
        }),
      );
    }
  }, [propsAircraft]);

  return (
    <Box
      p={{
        xl: 5,
        lg: 4,
        md: 3,
        xs: 2,
      }}
    >
      {aircrafts.length !== 0 && (
        <Typography
          sx={{
            fontSize: '18px',
            fontWeight: 700,
            color: 'text.secondary',
            lineHeight: '25px',
          }}
          gutterBottom
        >
          Uploading flights to:
        </Typography>
      )}
      {aircrafts.length !== 0 && (
        <Box
          mb={5}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <TextField
            select
            value={selectedAircraft ? selectedAircraft.id : ''}
            label="Choose your aircraft first"
            variant="outlined"
            sx={{
              mb: 0,
              minWidth: '300px',
              textAlign: 'left',
              mr: 2,
            }}
            onChange={(e: any) => {
              dispatch(storeSelectedAircraft(e.target.value));
              navigate(`${PATH_FLIGHTS_UPLOAD}/${e.target.value}`);
            }}
          >
            {!!aircrafts?.length &&
              aircrafts?.map((item: IAircraft, key: number) => {
                return (
                  <MenuItem key={key} value={item.id} title={aircraftRegistrationMakeModel(item)}>
                    <Typography
                      sx={{
                        fontSize: '20px',
                        fontWeight: 700,
                        color: 'text.primary',
                        lineHeight: '27px',
                        maxWidth: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {aircraftRegistrationMakeModel(item)}
                    </Typography>
                  </MenuItem>
                );
              })}
          </TextField>
          <TooltipForUpload />
        </Box>
      )}
      {!selectedAircraft?.directUpload && (
        <LegacyUpload aircrafts={aircrafts || []} selectedAircraft={selectedAircraft} fromTime={fromTime} />
      )}
      {selectedAircraft?.directUpload && (
        <WorkflowUpload aircrafts={aircrafts || []} selectedAircraft={selectedAircraft} fromTime={fromTime} />
      )}
      {selectedAircraft?.directUpload && <RecentEdfTable aircraftId={selectedAircraft.id} setEngineDataFileId={setEngineDataFileId} />}
      {!!aircrafts.length && (
        <Box>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 700,
              lineHeight: '22px',
              color: 'text.primary',
            }}
          >
            Valid files:
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              lineHeight: '22px',
              color: 'rgba(75, 84, 112, 0.5)',
            }}
          >
            - Original files, as downloaded from your engine monitor.
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              lineHeight: '22px',
              color: 'rgba(75, 84, 112, 0.5)',
            }}
          >
            - Common extensions: CSV, DAT, JPI, LOG, ALD, REC, TAB.
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              lineHeight: '22px',
              color: 'rgba(75, 84, 112, 0.5)',
            }}
          >
            - Zip files containing the above are OK.
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              lineHeight: '22px',
              color: 'rgba(75, 84, 112, 0.5)',
            }}
          >
            - Max file size 65MB.
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              lineHeight: '22px',
              color: 'rgba(75, 84, 112, 0.5)',
            }}
          >
            - EI UBG-16 and MGL users, read the{' '}
            <Link href={PATH_FAQ} target="_blank">
              FAQ
            </Link>
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default FileUploadWithAircraft;
