import { Navigate } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { PageLoading } from 'components';
import { PATH_SIGNUP_COMPLETE_ADDITIONAL_INFO } from 'const';

interface IRedirectLoggedIn {
  element: JSX.Element; // Use `element` instead of `component`
  path?: string;
  exact?: boolean;
}

// Redirect to dashboard for logged IN
const RedirectStepLoggedIn = (props: IRedirectLoggedIn) => {
  const { element: Element } = props; // Get Element user tries to access
  const { loggedIn, incompleteAircraft } = useSelector((state: any) => state.auth); // Get auth state (Redux)

  // If loading
  if (loggedIn === null) return <PageLoading />;

  if (loggedIn && incompleteAircraft?.length > 0) {
    if (incompleteAircraft[0]?.subscriptions?.length) {
      return <Navigate to={`/signup/${incompleteAircraft[0].subscriptions[0].serviceCode}/additional-info`} />;
    } else {
      return <Navigate to={PATH_SIGNUP_COMPLETE_ADDITIONAL_INFO} />;
    }
  } else if (loggedIn) {
    window.location.href = '/';
    return null;
  }

  // If logged OUT - proceed to page
  return Element; // Return the element directly
};

export default RedirectStepLoggedIn;
