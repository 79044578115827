import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Typography, Button, Hidden, IconButton, Link, FormControlLabel, Switch } from '@mui/material';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

import Highcharts from 'highcharts';

import { TicketIcon } from 'customIcons';

import { useLazyQuery, useMutation } from '@apollo/client';
import {
  QUERY_FLIGHT_CHART_DETAIL_BY_ID,
  MUTATION_SET_FLIGHT_INFO_PANEL_VISIBILITY,
  MUTATION_DELETE_FLIGHTS,
  QUERY_USER_ANALYSIS_TICKET_ELIGIBILITY,
  QUERY_GET_CLIENT_ANALYSIS_PACKS,
  QUERY_RESET_SERIES_SETTING,
  MUTATION_CHANGE_ADVANCED_SETTINGS,
} from 'gql';

import {
  humanDate,
  limitSerieSet,
  isGami,
  isMag,
  ctrlCmdClick,
  isFreeEligibility,
  hasAnalysisPack,
  isPresetToMode,
  presetSeries,
} from 'helpers';
import { IFlightData, ISerieSet, ISerieData, IChartVisibleSetting, IAircraftSetting, IFlightChartSetting, IUnitConversion } from 'types';
import {
  CHART_VISIBLE_NORMAL,
  PATH_TICKETS_CREATE,
  INFO_TAB_WIDTH,
  INFO_SAVVY_TAB_WIDTH,
  PATH_FLIGHTS,
  PATH_SAVVY_AIRCRAFT,
  PATH_UESR_TICKET_CREATE_TYPE,
  X_MODE,
  FLAG_TEST_DOWNSAMPLING,
} from 'const';
import { useLgUp, usePrevious } from 'hooks';
import { setAlert } from 'state';

import { ComponentLoading } from 'components';
import { ZoomSync, ModeManager, GamiManager } from './chartComponents';
import FlightChartView from './FlightChartView';
import DeletePopups from './DeletePopups';
import { QUERY_HAS_FEATURE_FLAG } from 'gql';
import { useQuery } from '@apollo/client';

interface FlightChartProps {
  flightData: IFlightData;
  modeManager: ModeManager;
  setDiffData: (value: any) => void;
  analysisMode: string;
  chartNum: number;
  isShare?: boolean;
  showInfo: boolean;
  setShowInfo: (value: boolean) => void;
  settings: IFlightChartSetting | undefined;
  conversions: IUnitConversion[];
  presetMode: string;
  setPresetMode?: (value: string) => void;
  scaleSettings: IAircraftSetting[];
}

interface ISerieChartData {
  [name: string]: number[];
}

const zoomSync = new ZoomSync();
const gamiManager = new GamiManager();

const FlightChart: React.FC<FlightChartProps> = (props) => {
  const {
    flightData,
    modeManager,
    setDiffData,
    analysisMode,
    chartNum,
    isShare = false,
    showInfo,
    setShowInfo,
    settings,
    conversions,
    presetMode,
    setPresetMode,
    scaleSettings,
  } = props;

  const { data: dataFlag, loading: loadingFlag } = useQuery(QUERY_HAS_FEATURE_FLAG, {
    variables: {
      flagName: FLAG_TEST_DOWNSAMPLING,
    },
  });

  useEffect(() => {
    if (dataFlag?.hasFeatureFlag) {
      zoomSync.setFeatureFlag(true);
    } else {
      zoomSync.setFeatureFlag(false);
    }
  }, [dataFlag]);

  const [fetchFlightData, { data: dataFetchFlightData, error: errorSeriesData }] = useLazyQuery(QUERY_FLIGHT_CHART_DETAIL_BY_ID, {
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });

  const [deleteFlight, { data: dataDeleteFlight, loading: loadingDeleteFlight, error: errorDeleteFlight }] =
    useMutation(MUTATION_DELETE_FLIGHTS);

  const [fetchAnalaysisTicketEligibility, { data: dataEligibility, loading: loadingEligibility }] = useLazyQuery(
    QUERY_USER_ANALYSIS_TICKET_ELIGIBILITY,
    {
      fetchPolicy: 'cache-and-network',
    },
  );
  const [fetchClientProPacks, { data: dataPacks, loading: loadingPacks }] = useLazyQuery(QUERY_GET_CLIENT_ANALYSIS_PACKS);
  const [resetSeriesSetting, { data: dataResetSeriesSetting }] = useLazyQuery(QUERY_RESET_SERIES_SETTING);
  const [updateSettings, { data: dataSettings, loading: loadingSettings, error: errorSettings }] =
    useMutation(MUTATION_CHANGE_ADVANCED_SETTINGS);

  const [alwaysResetSeriesWhenViewingFlights, setAlwaysResetSeriesWhenViewingFlights] = useState<boolean>(false);

  const { id } = useParams();

  const previousPresetMode = usePrevious(presetMode);

  const [setPanelVisibility] = useMutation(MUTATION_SET_FLIGHT_INFO_PANEL_VISIBILITY);

  const chartData = JSON.parse(flightData.data);

  const dispatch = useDispatch();
  const { isSavvy, isTechnician, showAnonymized } = useSelector((state: any) => state.auth);
  const { isAnalysisTab } = useSelector((state: any) => state.analysis);

  const [chartContainerWidth, setChartContainerWidth] = useState(
    window.innerWidth - (showInfo && window.innerWidth >= 1024 ? (isSavvy && isAnalysisTab ? INFO_SAVVY_TAB_WIDTH : INFO_TAB_WIDTH) : 0),
  );

  const [seriesChartData, setSeriesChartData] = useState<ISerieChartData>(chartData ? chartData.series_data : {});
  const SERIES_SETS = chartData?.series_sets
    ? chartData.series_sets
        .filter((serie_set: ISerieSet) => {
          const tmp_serie = {
            name: serie_set.name,
            series: [] as string[],
          };

          for (const serie of serie_set.series) {
            if (chartData.series_data[serie]?.length) {
              if (chartData.series_data[serie][0].toString().includes('0x')) continue;
              else {
                tmp_serie.series.push(serie);
              }
            } else {
              tmp_serie.series.push(serie);
            }
          }

          if (!tmp_serie.series.length && serie_set.name !== 'None') return false;
          return tmp_serie;
        })
        .map((serie_set: ISerieSet) => {
          const sorted = [...serie_set.series].sort((item1, item2) => {
            return item1.replaceAll(' ', '') > item2.replaceAll(' ', '') ? 1 : -1;
          });
          return {
            name: serie_set.name,
            series: sorted,
          };
        })
    : [];

  modeManager.setGamiManager(gamiManager);

  const isLgUp = useLgUp();

  const navigate = useNavigate();

  const deleteFlightById = async () => {
    await deleteFlight({
      variables: {
        flightIds: [flightData.id],
      },
    });
  };

  const findFirstOf = (availableSeriesSet: ISerieSet[], desiredSet: string) => {
    for (const serie of availableSeriesSet) {
      if (serie?.name && serie.name === desiredSet) {
        return serie;
      }
    }

    return {
      name: 'None',
      series: [],
    };
  };

  const getInitialSerie = (chartNumber: number, seriesSelector: string) => {
    const series_sets = JSON.parse(flightData.data).series_sets;

    for (let i = 0; i < flightData.initialSeries?.length; i++) {
      if (flightData.initialSeries[i].chartNumber === chartNumber && flightData.initialSeries[i].seriesSelector === seriesSelector) {
        for (let j = 0; j < flightData.initialSeries[i].seriesNames.length; j++) {
          for (const serie of series_sets) {
            if (serie && serie.name === flightData.initialSeries[i].seriesNames[j])
              // return serie;
              return limitSerieSet(findFirstOf(series_sets, serie.name), flightData.aircraft.cylinderCount);
          }
        }
      }
    }

    return {
      name: 'None',
      series: [],
    };
  };

  const [primarySerie, setPrimarySerie] = useState<ISerieSet | undefined>(
    chartData
      ? getInitialSerie(1, 'L')
      : {
          name: 'None',
          series: [],
        },
  );
  const primarySerieRef = useRef<ISerieSet | undefined>(undefined);
  const [secondarySerie, setSecondarySerie] = useState<ISerieSet | undefined>(
    chartData
      ? getInitialSerie(1, 'R')
      : {
          name: 'None',
          series: [],
        },
  );
  const secondarySerieRef = useRef<ISerieSet | undefined>(undefined);

  const [thirdSerie, setThirdSerie] = useState<ISerieSet | undefined>(
    chartData
      ? getInitialSerie(2, 'L')
      : {
          name: 'None',
          series: [],
        },
  );
  const thirdSerieRef = useRef<ISerieSet | undefined>(undefined);

  const [forthSerie, setForthSerie] = useState<ISerieSet | undefined>(
    chartData
      ? getInitialSerie(2, 'R')
      : {
          name: 'None',
          series: [],
        },
  );
  const forthSerieRef = useRef<ISerieSet | undefined>(undefined);

  const [chartSettings, setChartSettings] = useState<IChartVisibleSetting[]>([]);
  const [chartSecondarySettings, setChartSecondarySettings] = useState<IChartVisibleSetting[]>([]);
  const [chartThirdSettings, setChartThirdSettings] = useState<IChartVisibleSetting[]>([]);
  const [chartForthSettings, setChartForthSettings] = useState<IChartVisibleSetting[]>([]);

  const [primaryAdditionalSerie, setPrimaryAdditionalSerie] = useState<ISerieSet | undefined>(
    chartData
      ? getInitialSerie(3, 'L')
      : {
          name: 'None',
          series: [],
        },
  );
  const primaryAdditionalSerieRef = useRef<ISerieSet | undefined>(undefined);

  const [secondaryAdditionalSerie, setSecondaryAdditionalSerie] = useState<ISerieSet | undefined>(
    chartData
      ? getInitialSerie(3, 'R')
      : {
          name: 'None',
          series: [],
        },
  );
  const secondaryAdditionalSerieRef = useRef<ISerieSet | undefined>(undefined);

  const [thirdAdditionalSerie, setThirdAdditionalSerie] = useState<ISerieSet | undefined>(
    chartData
      ? getInitialSerie(4, 'L')
      : {
          name: 'None',
          series: [],
        },
  );
  const thirdAdditionalSerieRef = useRef<ISerieSet | undefined>(undefined);

  const [forthAdditionalSerie, setForthAdditionalSerie] = useState<ISerieSet | undefined>(
    chartData
      ? getInitialSerie(4, 'R')
      : {
          name: 'None',
          series: [],
        },
  );
  const forthAdditionalSerieRef = useRef<ISerieSet | undefined>(undefined);

  const [chartAdditionalSettings, setChartAdditionalSettings] = useState<IChartVisibleSetting[]>([]);
  const [chartAdditionalSecondarySettings, setChartAdditionalSecondarySettings] = useState<IChartVisibleSetting[]>([]);
  const [chartAdditionalThirdSettings, setChartAdditionalThirdSettings] = useState<IChartVisibleSetting[]>([]);
  const [chartAdditionalForthSettings, setChartAdditionalForthSettings] = useState<IChartVisibleSetting[]>([]);

  // const timeSeries = chartData?.series_data['TIME_UTC'] || chartData?.series_data['TIME_LOCAL'] || [];
  const [initialLoaded, setInitialLoaded] = useState(false);

  const fetchFlightDataRequest = useCallback(
    async (flightId: string, serieNames: string[]) => {
      await fetchFlightData({
        variables: {
          id: parseInt(flightId),
          serieNames,
        },
      });
    },
    [fetchFlightData],
  );

  const showVisibilitySet = () => {
    setPanelVisibility({
      variables: {
        isFlightPanelVisible: !showInfo,
      },
    });
    setShowInfo(!showInfo);
  };

  const handleWindowResize = useCallback(() => {
    setChartContainerWidth(
      window.innerWidth - (showInfo && window.innerWidth >= 1024 ? (isSavvy && isAnalysisTab ? INFO_SAVVY_TAB_WIDTH : INFO_TAB_WIDTH) : 0),
    );
  }, [showInfo, isSavvy, isAnalysisTab]);

  const handleChangeResetSeries = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setAlwaysResetSeriesWhenViewingFlights(event.target.checked);
    await updateSettings({
      variables: {
        alwaysResetSeriesWhenViewingFlights: event.target.checked,
      },
    });
  };

  useEffect(() => {
    if (isSavvy) {
      resetSeriesSetting();
    }
  }, [isSavvy, resetSeriesSetting]);

  useEffect(() => {
    if (dataResetSeriesSetting?.me) {
      setAlwaysResetSeriesWhenViewingFlights(dataResetSeriesSetting.me.settings.alwaysResetSeriesWhenViewingFlights);
    }
  }, [dataResetSeriesSetting]);

  useEffect(() => {
    if (errorSettings) {
      dispatch(setAlert('error', 'Unable to reset series setting'));
    } else if (dataSettings) {
      if (dataSettings?.changeAdvancedSettings?.ok) {
        dispatch(setAlert('success', 'Reset series setting'));
      } else {
        dispatch(setAlert('error', dataSettings?.changeAdvancedSettings?.error || 'Unable to reset series setting'));
      }
    }
  }, [dataSettings, errorSettings, dispatch]);

  useEffect(() => {
    const containerWidth =
      window.innerWidth - (showInfo && window.innerWidth >= 1024 ? (isSavvy && isAnalysisTab ? INFO_SAVVY_TAB_WIDTH : INFO_TAB_WIDTH) : 0);
    setChartContainerWidth(containerWidth);
    for (const chart of Highcharts.charts) {
      if (chart) {
        chart.update({
          chart: {
            width: containerWidth - (window.innerWidth >= 1024 ? 80 : 0),
          },
        });
      }
    }
  }, [showInfo, isSavvy, isAnalysisTab]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [handleWindowResize]);

  useEffect(() => {
    setInitialLoaded(false);
  }, [id]);

  useEffect(() => {
    if (
      primarySerie &&
      secondarySerie &&
      thirdSerie &&
      forthSerie &&
      primaryAdditionalSerie &&
      secondaryAdditionalSerie &&
      thirdAdditionalSerie &&
      forthAdditionalSerie &&
      !initialLoaded
    ) {
      const tmpSerieNames = primarySerie.series
        .concat(secondarySerie.series)
        .concat(thirdSerie.series)
        .concat(forthSerie.series)
        .concat(primaryAdditionalSerie.series)
        .concat(secondaryAdditionalSerie.series)
        .concat(thirdAdditionalSerie.series)
        .concat(forthAdditionalSerie.series)
        .concat(['TIME_UTC', 'TIME_LOCAL']);

      primarySerieRef.current = primarySerie;
      secondarySerieRef.current = secondarySerie;
      thirdSerieRef.current = thirdSerie;
      forthSerieRef.current = forthSerie;
      primaryAdditionalSerieRef.current = primaryAdditionalSerie;
      secondaryAdditionalSerieRef.current = secondaryAdditionalSerie;
      thirdAdditionalSerieRef.current = thirdAdditionalSerie;
      forthAdditionalSerieRef.current = forthAdditionalSerie;

      fetchFlightDataRequest(id || '', tmpSerieNames);
      setInitialLoaded(true);
    }
  }, [
    primarySerie,
    secondarySerie,
    thirdSerie,
    forthSerie,
    primaryAdditionalSerie,
    secondaryAdditionalSerie,
    thirdAdditionalSerie,
    forthAdditionalSerie,
    initialLoaded,
    id,
    fetchFlightDataRequest,
  ]);

  useEffect(() => {
    // setChartSettings([]);
    let tmp: IChartVisibleSetting[] = [];
    if (primarySerie && primarySerieRef?.current !== primarySerie) {
      tmp = primarySerie?.series.map((serie: string) => {
        return {
          name: serie,
          value: CHART_VISIBLE_NORMAL,
        };
      });

      if (primarySerie.series?.length && flightData && initialLoaded) {
        let flag = false;
        for (let i = 0; i < primarySerie.series.length; i++) {
          if (!seriesChartData[primarySerie.series[i]]) {
            flag = true;
            break;
          }
        }

        if (flag) {
          if (isMag(analysisMode)) {
            const chartData = JSON.parse(flightData.data);
            fetchFlightDataRequest(
              flightData.id,
              limitSerieSet(findFirstOf(chartData.series_sets, primarySerie.name), flightData.aircraft.cylinderCount).series,
            );
          } else if (!presetMode) {
            fetchFlightDataRequest(flightData.id, primarySerie.series);
          }
        }
      }

      primarySerieRef.current = primarySerie;
    }

    setChartSettings([...tmp]);
  }, [primarySerie, seriesChartData, flightData, initialLoaded, fetchFlightDataRequest, analysisMode, presetMode]);

  useEffect(() => {
    if (dataFetchFlightData?.me) {
      const { flight } = dataFetchFlightData.me;
      if (!flight[0]?.data) return;
      const series_data = JSON.parse(flight[0].data).series_data;
      setSeriesChartData((seriesChartData: ISerieChartData) => {
        Object.keys(series_data).map((key) => {
          seriesChartData[key] = series_data[key];
          return null;
        });

        return { ...seriesChartData };
      });
    }
  }, [dataFetchFlightData]);

  useEffect(() => {
    if (errorDeleteFlight) {
      dispatch(setAlert('error', 'Unable to delete flight.'));
    } else if (dataDeleteFlight) {
      if (dataDeleteFlight.deleteFlights?.ok) {
        dispatch(setAlert('success', 'Deleted flight'));
        navigate(PATH_FLIGHTS);
      } else {
        dispatch(setAlert('error', dataDeleteFlight.deleteFlights?.error || 'Unable to delete flight.'));
      }
    }
  }, [errorDeleteFlight, dataDeleteFlight, dispatch, navigate]);

  useEffect(() => {
    if (errorSeriesData) {
      dispatch(setAlert('error', 'Unable to load data for selected serie'));
    }
  }, [errorSeriesData, dispatch]);

  useEffect(() => {
    let tmp: IChartVisibleSetting[] = [];
    if (secondarySerie && secondarySerieRef.current !== secondarySerie) {
      tmp = secondarySerie?.series.map((serie: string) => {
        return {
          name: serie,
          value: CHART_VISIBLE_NORMAL,
        };
      });

      if (secondarySerie.series?.length && flightData && initialLoaded && !presetMode) {
        for (let i = 0; i < secondarySerie.series.length; i++) {
          if (!seriesChartData[secondarySerie.series[i]]) {
            fetchFlightDataRequest(flightData.id, secondarySerie.series);
            break;
          }
        }
      }

      secondarySerieRef.current = secondarySerie;
    }

    setChartSecondarySettings([...tmp]);
  }, [secondarySerie, seriesChartData, flightData, initialLoaded, fetchFlightDataRequest, presetMode]);

  useEffect(() => {
    if (analysisMode === '') {
      setDiffData({});
    }
  }, [analysisMode, setDiffData]);

  useEffect(() => {
    if (isGami(analysisMode) && primarySerie && secondarySerie) {
      const timeSeries = seriesChartData['TIME_UTC'] || seriesChartData['TIME_LOCAL'] || [];
      const seriesData = getChartSeries(primarySerie, seriesChartData);
      const secondarySeriesData = getChartSeries(secondarySerie, seriesChartData);

      if (seriesData && secondarySeriesData && timeSeries) {
        const tmp = seriesData.map((value: ISerieData) => {
          const tmpSeries: number[][] = [];

          for (let i = 0; i < value.series?.length; i++) {
            tmpSeries.push([(timeSeries[i] - timeSeries[0]) * 1000, value.series[i]]);
          }

          return {
            name: value.name,
            data: tmpSeries,
          };
        });

        const tmp1 = secondarySeriesData.map((value: ISerieData) => {
          const tmpSeries: number[][] = [];

          for (let i = 0; i < value.series?.length; i++) {
            tmpSeries.push([(timeSeries[i] - timeSeries[0]) * 1000, value.series[i]]);
          }
          return {
            name: value.name,
            data: tmpSeries,
          };
        });

        gamiManager.setOriginalData([...tmp, ...tmp1]);
      }
    }
  }, [primarySerie, secondarySerie, analysisMode, seriesChartData]);

  useEffect(() => {
    if (analysisMode && flightData) {
      let series: ISerieSet | undefined = undefined;

      const chartData = JSON.parse(flightData.data);

      if (analysisMode === 'mag') {
        series = limitSerieSet(findFirstOf(chartData.series_sets, 'EGT'), flightData.aircraft.cylinderCount);
      } else if (analysisMode === 'mag-left') {
        series = limitSerieSet(findFirstOf(chartData.series_sets, 'L-EGT'), flightData.aircraft.cylinderCount);
      } else if (analysisMode === 'mag-right') {
        series = limitSerieSet(findFirstOf(chartData.series_sets, 'R-EGT'), flightData.aircraft.cylinderCount);
      } else {
        if (analysisMode === 'gami') {
          setPrimarySerie(limitSerieSet(findFirstOf(chartData.series_sets, 'EGT'), flightData.aircraft.cylinderCount, true));
          setSecondarySerie(limitSerieSet(findFirstOf(chartData.series_sets, 'FF'), flightData.aircraft.cylinderCount));
          return;
        } else if (analysisMode === 'gami-left') {
          setPrimarySerie(limitSerieSet(findFirstOf(chartData.series_sets, 'L-EGT'), flightData.aircraft.cylinderCount, true));
          setSecondarySerie(limitSerieSet(findFirstOf(chartData.series_sets, 'L-FF'), flightData.aircraft.cylinderCount));
          return;
        } else if (analysisMode === 'gami-right') {
          setPrimarySerie(limitSerieSet(findFirstOf(chartData.series_sets, 'R-EGT'), flightData.aircraft.cylinderCount, true));
          setSecondarySerie(limitSerieSet(findFirstOf(chartData.series_sets, 'R-FF'), flightData.aircraft.cylinderCount));
          return;
        }
      }

      if (series) {
        const odd_egts = new RegExp(/(?:[LR]-)?(EGT)\s*[13579]/);
        const even_egts = new RegExp(/(?:[LR]-)?(EGT)\s*[2468]/);

        const topSeries: string[] = [];
        const bottomSeries: string[] = [];

        for (const serie of series.series) {
          if (odd_egts.exec(serie)) {
            topSeries.push(serie);
          } else if (even_egts.exec(serie)) {
            bottomSeries.push(serie);
          }
        }

        setPrimarySerie({
          name: series.name,
          series: [...topSeries],
        });

        setSecondarySerie({
          name: 'None',
          series: [],
        });

        setThirdSerie({
          name: series.name,
          series: [...bottomSeries],
        });

        setForthSerie({
          name: 'None',
          series: [],
        });
      }
    }
  }, [analysisMode, flightData]);

  useEffect(() => {
    if (flightData && presetMode) {
      const chartData = JSON.parse(flightData.data);
      let tmpFetchSeries: string[] = [];

      let primarySerie: ISerieSet | undefined;
      let secondarySerie: ISerieSet | undefined;
      let thirdSerie: ISerieSet | undefined;
      let forthSerie: ISerieSet | undefined;
      let primaryAdditionalSerie: ISerieSet | undefined;
      let secondaryAdditionalSerie: ISerieSet | undefined;
      let thirdAdditionalSerie: ISerieSet | undefined;
      let forthAdditionalSerie: ISerieSet | undefined;
      if (presetMode === X_MODE) {
        primarySerie = limitSerieSet(findFirstOf(chartData.series_sets, 'EGT'), flightData.aircraft.cylinderCount, true);
        setPrimarySerie(primarySerie);

        secondarySerie = limitSerieSet(findFirstOf(chartData.series_sets, 'MAP'), flightData.aircraft.cylinderCount);
        setSecondarySerie(secondarySerie);

        thirdSerie = limitSerieSet(findFirstOf(chartData.series_sets, 'CHT'), flightData.aircraft.cylinderCount, true);
        setThirdSerie(thirdSerie);

        forthSerie = limitSerieSet(findFirstOf(chartData.series_sets, 'VOLTS'), flightData.aircraft.cylinderCount);
        setForthSerie(forthSerie);

        primaryAdditionalSerie = limitSerieSet(findFirstOf(chartData.series_sets, 'FF'), flightData.aircraft.cylinderCount);
        setPrimaryAdditionalSerie(primaryAdditionalSerie);

        secondaryAdditionalSerie = limitSerieSet(findFirstOf(chartData.series_sets, 'RPM'), flightData.aircraft.cylinderCount);
        setSecondaryAdditionalSerie(secondaryAdditionalSerie);

        thirdAdditionalSerie = limitSerieSet(findFirstOf(chartData.series_sets, 'OIL_TEMP'), flightData.aircraft.cylinderCount);
        setThirdAdditionalSerie(thirdAdditionalSerie);

        forthAdditionalSerie = limitSerieSet(findFirstOf(chartData.series_sets, 'OIL_PRESS'), flightData.aircraft.cylinderCount);
        setForthAdditionalSerie(forthAdditionalSerie);
      } else if (isPresetToMode(presetMode)) {
        const toSeries = presetSeries(presetMode);
        primarySerie = limitSerieSet(findFirstOf(chartData.series_sets, toSeries[0]), flightData.aircraft.cylinderCount);
        setPrimarySerie(primarySerie);
        setSecondarySerie({
          name: 'None',
          series: [],
        });

        thirdSerie = limitSerieSet(findFirstOf(chartData.series_sets, toSeries[1]), flightData.aircraft.cylinderCount);
        setThirdSerie(thirdSerie);
        setForthSerie({
          name: 'None',
          series: [],
        });

        primaryAdditionalSerie = limitSerieSet(findFirstOf(chartData.series_sets, toSeries[2]), flightData.aircraft.cylinderCount);
        setPrimaryAdditionalSerie(primaryAdditionalSerie);
        setSecondaryAdditionalSerie({
          name: 'None',
          series: [],
        });
      }

      if (presetMode) {
        if (primarySerie) {
          for (let i = 0; i < primarySerie.series.length; i++) {
            if (!seriesChartData[primarySerie.series[i]]) {
              tmpFetchSeries = tmpFetchSeries.concat(primarySerie.series);
              break;
            }
          }
        }
        if (secondarySerie) {
          for (let i = 0; i < secondarySerie.series.length; i++) {
            if (!seriesChartData[secondarySerie.series[i]]) {
              tmpFetchSeries = tmpFetchSeries.concat(secondarySerie.series);
              break;
            }
          }
        }
        if (thirdSerie) {
          for (let i = 0; i < thirdSerie.series.length; i++) {
            if (!seriesChartData[thirdSerie.series[i]]) {
              tmpFetchSeries = tmpFetchSeries.concat(thirdSerie.series);
              break;
            }
          }
        }
        if (forthSerie) {
          for (let i = 0; i < forthSerie.series.length; i++) {
            if (!seriesChartData[forthSerie.series[i]]) {
              tmpFetchSeries = tmpFetchSeries.concat(forthSerie.series);
              break;
            }
          }
        }
        if (primaryAdditionalSerie) {
          for (let i = 0; i < primaryAdditionalSerie.series.length; i++) {
            if (!seriesChartData[primaryAdditionalSerie.series[i]]) {
              tmpFetchSeries = tmpFetchSeries.concat(primaryAdditionalSerie.series);
              break;
            }
          }
        }
        if (secondaryAdditionalSerie) {
          for (let i = 0; i < secondaryAdditionalSerie.series.length; i++) {
            if (!seriesChartData[secondaryAdditionalSerie.series[i]]) {
              tmpFetchSeries = tmpFetchSeries.concat(secondaryAdditionalSerie.series);
              break;
            }
          }
        }
        if (thirdAdditionalSerie) {
          for (let i = 0; i < thirdAdditionalSerie.series.length; i++) {
            if (!seriesChartData[thirdAdditionalSerie.series[i]]) {
              tmpFetchSeries = tmpFetchSeries.concat(thirdAdditionalSerie.series);
              break;
            }
          }
        }
        if (forthAdditionalSerie) {
          for (let i = 0; i < forthAdditionalSerie.series.length; i++) {
            if (!seriesChartData[forthAdditionalSerie.series[i]]) {
              tmpFetchSeries = tmpFetchSeries.concat(forthAdditionalSerie.series);
              break;
            }
          }
        }

        if (tmpFetchSeries.length) fetchFlightDataRequest(flightData.id, tmpFetchSeries);
      }
    }
  }, [presetMode, flightData, seriesChartData, fetchFlightDataRequest]);

  useEffect(() => {
    if (presetMode === '' && previousPresetMode === X_MODE) {
      const primarySerie = limitSerieSet(findFirstOf(chartData.series_sets, 'EGT'), flightData.aircraft.cylinderCount);
      setPrimarySerie(primarySerie);
      const thirdSerie = limitSerieSet(findFirstOf(chartData.series_sets, 'CHT'), flightData.aircraft.cylinderCount);
      setThirdSerie(thirdSerie);
    }
  }, [presetMode]);

  useEffect(() => {
    let tmp: IChartVisibleSetting[] = [];
    if (thirdSerie && thirdSerieRef.current !== thirdSerie) {
      tmp = thirdSerie?.series.map((serie: string) => {
        return {
          name: serie,
          value: CHART_VISIBLE_NORMAL,
        };
      });

      if (thirdSerie.series?.length && flightData && initialLoaded && !presetMode) {
        for (let i = 0; i < thirdSerie.series.length; i++) {
          if (!seriesChartData[thirdSerie.series[i]]) {
            if (!isMag(analysisMode)) fetchFlightDataRequest(flightData.id, thirdSerie.series);
            break;
          }
        }
      }

      thirdSerieRef.current = thirdSerie;
    }

    setChartThirdSettings([...tmp]);
  }, [thirdSerie, flightData, seriesChartData, initialLoaded, fetchFlightDataRequest, analysisMode, presetMode]);

  useEffect(() => {
    let tmp: IChartVisibleSetting[] = [];
    if (forthSerie && forthSerieRef.current !== forthSerie) {
      tmp = forthSerie?.series.map((serie: string) => {
        return {
          name: serie,
          value: CHART_VISIBLE_NORMAL,
        };
      });

      if (forthSerie.series?.length && flightData && initialLoaded && !presetMode) {
        for (let i = 0; i < forthSerie.series.length; i++) {
          if (!seriesChartData[forthSerie.series[i]]) {
            fetchFlightDataRequest(flightData.id, forthSerie.series);
            break;
          }
        }
      }

      forthSerieRef.current = forthSerie;
    }

    setChartForthSettings([...tmp]);
  }, [forthSerie, flightData, seriesChartData, initialLoaded, fetchFlightDataRequest, presetMode]);

  useEffect(() => {
    let tmp: IChartVisibleSetting[] = [];
    if (primaryAdditionalSerie && primaryAdditionalSerieRef.current !== primaryAdditionalSerie) {
      tmp = primaryAdditionalSerie?.series.map((serie: string) => {
        return {
          name: serie,
          value: CHART_VISIBLE_NORMAL,
        };
      });

      if (primaryAdditionalSerie.series?.length && flightData && initialLoaded && !presetMode) {
        for (let i = 0; i < primaryAdditionalSerie.series.length; i++) {
          if (!seriesChartData[primaryAdditionalSerie.series[i]]) {
            fetchFlightDataRequest(flightData.id, primaryAdditionalSerie.series);
            break;
          }
        }
      }

      primaryAdditionalSerieRef.current = primaryAdditionalSerie;
    }

    setChartAdditionalSettings([...tmp]);
  }, [primaryAdditionalSerie, flightData, seriesChartData, initialLoaded, fetchFlightDataRequest, presetMode]);

  useEffect(() => {
    let tmp: IChartVisibleSetting[] = [];
    if (secondaryAdditionalSerie && secondaryAdditionalSerieRef.current !== secondaryAdditionalSerie) {
      tmp = secondaryAdditionalSerie?.series.map((serie: string) => {
        return {
          name: serie,
          value: CHART_VISIBLE_NORMAL,
        };
      });

      if (secondaryAdditionalSerie.series?.length && flightData && initialLoaded && !presetMode) {
        for (let i = 0; i < secondaryAdditionalSerie.series.length; i++) {
          if (!seriesChartData[secondaryAdditionalSerie.series[i]]) {
            fetchFlightDataRequest(flightData.id, secondaryAdditionalSerie.series);
            break;
          }
        }
      }

      secondaryAdditionalSerieRef.current = secondaryAdditionalSerie;
    }

    setChartAdditionalSecondarySettings([...tmp]);
  }, [secondaryAdditionalSerie, flightData, seriesChartData, initialLoaded, fetchFlightDataRequest, presetMode]);

  useEffect(() => {
    let tmp: IChartVisibleSetting[] = [];
    if (thirdAdditionalSerie && thirdAdditionalSerieRef.current !== thirdAdditionalSerie) {
      tmp = thirdAdditionalSerie?.series.map((serie: string) => {
        return {
          name: serie,
          value: CHART_VISIBLE_NORMAL,
        };
      });

      if (thirdAdditionalSerie.series?.length && flightData && initialLoaded && !presetMode) {
        for (let i = 0; i < thirdAdditionalSerie.series.length; i++) {
          if (!seriesChartData[thirdAdditionalSerie.series[i]]) {
            fetchFlightDataRequest(flightData.id, thirdAdditionalSerie.series);
            break;
          }
        }
      }

      thirdAdditionalSerieRef.current = thirdAdditionalSerie;
    }

    setChartAdditionalThirdSettings([...tmp]);
  }, [thirdAdditionalSerie, flightData, seriesChartData, initialLoaded, fetchFlightDataRequest, presetMode]);

  useEffect(() => {
    let tmp: IChartVisibleSetting[] = [];
    if (forthAdditionalSerie && forthAdditionalSerieRef.current !== forthAdditionalSerie) {
      tmp = forthAdditionalSerie?.series.map((serie: string) => {
        return {
          name: serie,
          value: CHART_VISIBLE_NORMAL,
        };
      });

      if (forthAdditionalSerie.series?.length && flightData && initialLoaded && !presetMode) {
        for (let i = 0; i < forthAdditionalSerie.series.length; i++) {
          if (!seriesChartData[forthAdditionalSerie.series[i]]) {
            fetchFlightDataRequest(flightData.id, forthAdditionalSerie.series);
            break;
          }
        }
      }

      forthAdditionalSerieRef.current = forthAdditionalSerie;
    }

    setChartAdditionalForthSettings([...tmp]);
  }, [forthAdditionalSerie, flightData, seriesChartData, initialLoaded, fetchFlightDataRequest, presetMode]);

  useEffect(() => {
    if (isTechnician) {
      if (flightData?.aircraft) {
        fetchAnalaysisTicketEligibility({
          variables: {
            id: parseInt(flightData.aircraft.id),
          },
        });
      }
      fetchClientProPacks();
    }
  }, [isTechnician, flightData, fetchAnalaysisTicketEligibility, fetchClientProPacks]);

  const getChartSeries = (serie: ISerieSet, chartData: any) => {
    let tmp: ISerieData[] = [];

    if (serie && serie.series.length) {
      if (!chartData[serie.series[0]]) {
        return [];
      }
      tmp = serie.series.map((value: string) => {
        return {
          name: value,
          series: chartData[value],
        };
      });
    }

    return tmp;
  };

  const getAircraftSettings = (serie: ISerieSet | undefined, aircraftSettings: IAircraftSetting[]) => {
    if (!aircraftSettings) return undefined;
    for (const aircraftSetting of aircraftSettings) {
      if (aircraftSetting.seriesName === serie?.name && aircraftSetting.enabled) {
        return aircraftSetting;
      }
    }

    return undefined;
  };

  const requestAnalysis = () => {
    if (isSavvy) {
      navigate(
        `${PATH_UESR_TICKET_CREATE_TYPE}/analysis/${flightData.aircraft?.owner?.id}/aircraft/${flightData.aircraft.id}?flight=${flightData.id}`,
      );
    } else {
      navigate(`${PATH_TICKETS_CREATE}/analysis/${flightData.aircraft.id}?flight=${flightData.id}`);
    }
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        py: 3,
        maxWidth: isLgUp ? `${chartContainerWidth}px` : 'auto',
        '@media (min-width: 1280px)': {
          pl: 3,
          maxHeight: 'calc(100vh - 90px)',
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            width: '0.6em',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            borderRadius: 2,
          },
        },
      }}
    >
      <ComponentLoading loading={loadingEligibility || loadingPacks || loadingSettings || loadingFlag}>
        <Box
          sx={{
            px: 2,
            '@media (max-width: 768px)': {
              px: 0,
            },
          }}
        >
          <Box
            sx={{
              py: {
                md: 0,
                xs: 1,
              },
              display: 'flex',
              mb: {
                md: 2,
                xs: 0,
              },
              alignItems: 'center',
            }}
          >
            {!showAnonymized && (
              <Typography
                variant="h2"
                color="text.secondary"
                sx={{
                  textAlign: {
                    md: 'left',
                    xs: 'center',
                  },
                  mr: 2,
                }}
              >
                {isSavvy && !showAnonymized && flightData?.aircraft?.registration && (
                  <Link
                    href={`${PATH_SAVVY_AIRCRAFT}/${flightData?.aircraft?.id}`}
                    target="_blank"
                    sx={{
                      mr: 1,
                    }}
                  >
                    {flightData.aircraft.registration}
                  </Link>
                )}
                {humanDate(flightData.date)}
                <Box
                  component="span"
                  sx={{
                    color: 'grey.400',
                    fontSize: '20px',
                    ml: 3,
                    fontWeight: 400,
                  }}
                >
                  {flightData.date.toString().substring(11, 16)} UTC
                </Box>
              </Typography>
            )}
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <IconButton
                onClick={(e: any) => {
                  if (flightData.previousFlight) {
                    ctrlCmdClick(e, `${PATH_FLIGHTS}/${flightData.previousFlight?.id}`, navigate);
                  } else {
                    return;
                  }
                }}
                disabled={!flightData.previousFlight}
              >
                <ArrowBackIcon />
              </IconButton>
              {isSavvy && (
                <IconButton
                  sx={{
                    ml: 2,
                  }}
                  onClick={(e: any) => {
                    ctrlCmdClick(e, `${PATH_SAVVY_AIRCRAFT}/${flightData.aircraft.id}/flights`, navigate);
                  }}
                >
                  <ListAltIcon />
                </IconButton>
              )}
              <IconButton
                sx={{
                  ml: 2,
                }}
                onClick={(e: any) => {
                  if (flightData.nextFlight) {
                    ctrlCmdClick(e, `${PATH_FLIGHTS}/${flightData.nextFlight?.id}`, navigate);
                  } else {
                    return;
                  }
                }}
                disabled={!flightData.nextFlight}
              >
                <ArrowForwardIcon />
              </IconButton>
              {isSavvy && (
                <Box>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={alwaysResetSeriesWhenViewingFlights}
                        onChange={handleChangeResetSeries}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    sx={{
                      ml: 2,
                    }}
                    label="Reset series"
                  />
                </Box>
              )}
            </Box>
          </Box>
          <Box py={1}>
            <Box
              sx={{
                display: {
                  xl: 'flex',
                  lg: showInfo ? 'block' : 'flex',
                  md: 'block',
                },
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box
                sx={{
                  width: {
                    xl: '40%',
                    lg: showInfo ? '100%' : '40%',
                    md: '100%',
                  },
                }}
              >
                {!showAnonymized && (
                  <Box
                    sx={{
                      display: {
                        md: 'flex',
                        xs: 'block',
                      },
                      alignItems: 'center',
                      justifyContent: {
                        md: 'flex-start',
                        xs: 'center',
                      },
                      py: {
                        md: 0,
                        xs: 1,
                      },
                      height: '100%',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mr: {
                          md: 1,
                          xs: 0,
                        },
                      }}
                      title={flightData.departureAirport}
                    >
                      <FlightTakeoffIcon
                        sx={{
                          color: 'grey.400',
                          mr: 1,
                          cursor: 'pointer',
                        }}
                      />
                      <Typography
                        sx={{
                          fontWeight: 600,
                        }}
                      >
                        <Hidden mdUp>From:&nbsp;</Hidden>
                        {flightData.departureAirport ? flightData.departureAirport : ''}
                      </Typography>
                    </Box>
                    <Hidden mdDown>
                      <ArrowRightAltIcon
                        sx={{
                          mr: 1,
                        }}
                      />
                    </Hidden>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      title={flightData.destinationAirport}
                    >
                      <FlightLandIcon
                        sx={{
                          color: 'grey.400',
                          mr: 1,
                          cursor: 'pointer',
                        }}
                      />
                      <Typography
                        sx={{
                          fontWeight: 600,
                        }}
                      >
                        <Hidden mdUp>To:&nbsp;</Hidden>
                        {flightData.destinationAirport ? flightData.destinationAirport : ''}
                      </Typography>
                    </Box>
                  </Box>
                )}
                {showAnonymized && (
                  <Box
                    sx={{
                      py: {
                        md: 0,
                        xs: 1,
                      },
                      height: '100%',
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: '600',
                      }}
                    >
                      {flightData?.aircraft?.aircraftManufacturer?.name || ''}/{flightData?.aircraft?.aircraftModel?.name || ''} &nbsp;
                      {flightData?.aircraft?.engineManufacturer?.name || ''}/{flightData?.aircraft?.engineModel?.name || ''} &nbsp;
                      {flightData?.aircraft?.engineMonitorManufacturer?.name
                        ? `${flightData?.aircraft?.engineMonitorManufacturer?.name}/`
                        : ''}
                      {flightData?.aircraft?.engineMonitorModel?.name || ''}
                    </Typography>
                  </Box>
                )}
              </Box>
              <Box
                sx={{
                  py: {
                    xl: 0,
                    lg: showInfo ? 1 : 0,
                    md: 1,
                  },
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'center',
                    height: '100%',
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: 'grey.800',
                      height: '40px',
                      display: 'flex',
                      alignItems: 'center',
                      borderRadius: '6px',
                      width: '40px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      zoomSync.resetZoom();
                    }}
                  >
                    <RestartAltIcon
                      sx={{
                        margin: '0 auto',
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      backgroundColor: 'grey.800',
                      height: '40px',
                      display: 'flex',
                      alignItems: 'center',
                      borderRadius: '6px',
                      width: '40px',
                      ml: 1,
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      zoomSync.undoZoom();
                    }}
                  >
                    <ZoomOutIcon
                      sx={{
                        margin: '0 auto',
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              {!isShare && (
                <Box
                  sx={{
                    width: {
                      xl: '40%',
                      lg: showInfo ? '100%' : '40%',
                      md: '100%',
                    },
                  }}
                >
                  <Box
                    sx={{
                      py: {
                        md: 0,
                        xs: 1,
                      },
                      textAlign: {
                        xl: 'right',
                        lg: showInfo ? 'center' : 'right',
                        md: 'center',
                        xs: 'center',
                      },
                    }}
                  >
                    {(!isTechnician ||
                      (isTechnician &&
                        (isFreeEligibility('analysis', dataEligibility?.me?.aircraft[0]?.ticketEligibility?.analysis) ||
                          hasAnalysisPack(dataPacks?.me?.analysisPacks || [])))) && (
                      <Button
                        variant="contained"
                        onClick={requestAnalysis}
                        sx={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        <TicketIcon
                          sx={{
                            transform: 'rotate(90deg)',
                            mr: 1,
                          }}
                          fill="#ffffff"
                        />
                        Request Analysis
                      </Button>
                    )}
                    <Button
                      variant="outlined"
                      onClick={() => {
                        showVisibilitySet();
                      }}
                      sx={{
                        mx: 1,
                      }}
                    >
                      {showInfo ? 'Hide Details' : 'Show Details'}
                    </Button>
                    <DeletePopups deleteFlightById={deleteFlightById} />
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
          <ComponentLoading loading={loadingDeleteFlight}>
            {chartData && (
              <Box id="chart-container">
                <FlightChartView
                  seriesData={primarySerie ? getChartSeries(primarySerie, seriesChartData) : []}
                  chartSettings={chartSettings}
                  secondarySeriesData={secondarySerie ? getChartSeries(secondarySerie, seriesChartData) : []}
                  chartSecondarySettings={chartSecondarySettings}
                  timeSeries={seriesChartData['TIME_UTC'] || seriesChartData['TIME_LOCAL'] || []}
                  zoomSync={zoomSync}
                  modeManager={modeManager}
                  setDiffData={setDiffData}
                  gamiManager={gamiManager}
                  leftSerie={primarySerie}
                  setLeftSerie={setPrimarySerie}
                  seriesList={SERIES_SETS}
                  cylinderCount={flightData.aircraft.cylinderCount}
                  rightSerie={secondarySerie}
                  setRightSerie={setSecondarySerie}
                  setChartSettings={setChartSettings}
                  setChartSecondarySettings={setChartSecondarySettings}
                  zoomController={true}
                  aircraftId={flightData.aircraft?.id ? flightData.aircraft.id : ''}
                  chartNumber={1}
                  aircraftSetting={getAircraftSettings(primarySerie, scaleSettings)}
                  aircraftSecondarySetting={getAircraftSettings(secondarySerie, scaleSettings)}
                  settings={settings}
                  flightData={flightData}
                  isGami={isGami(analysisMode)}
                  isMag={isMag(analysisMode)}
                  conversions={conversions}
                  setPresetMode={setPresetMode}
                />
                {((!isGami(analysisMode) && chartNum !== 1) || isMag(analysisMode)) && (
                  <React.Fragment>
                    <FlightChartView
                      seriesData={thirdSerie ? getChartSeries(thirdSerie, seriesChartData) : []}
                      chartSettings={chartThirdSettings}
                      secondarySeriesData={forthSerie ? getChartSeries(forthSerie, seriesChartData) : []}
                      chartSecondarySettings={chartForthSettings}
                      timeSeries={seriesChartData['TIME_UTC'] || seriesChartData['TIME_LOCAL'] || []}
                      zoomSync={zoomSync}
                      modeManager={modeManager}
                      setDiffData={setDiffData}
                      leftSerie={thirdSerie}
                      setLeftSerie={setThirdSerie}
                      seriesList={SERIES_SETS}
                      cylinderCount={flightData.aircraft.cylinderCount}
                      rightSerie={forthSerie}
                      setRightSerie={setForthSerie}
                      setChartSettings={setChartThirdSettings}
                      setChartSecondarySettings={setChartForthSettings}
                      aircraftId={flightData.aircraft?.id ? flightData.aircraft.id : ''}
                      chartNumber={2}
                      aircraftSetting={getAircraftSettings(thirdSerie, scaleSettings)}
                      aircraftSecondarySetting={getAircraftSettings(forthSerie, scaleSettings)}
                      settings={settings}
                      flightData={flightData}
                      isMag={isMag(analysisMode)}
                      conversions={conversions}
                      setPresetMode={setPresetMode}
                    />
                  </React.Fragment>
                )}
                {!isGami(analysisMode) && !isMag(analysisMode) && chartNum > 2 && (
                  <FlightChartView
                    seriesData={primaryAdditionalSerie ? getChartSeries(primaryAdditionalSerie, seriesChartData) : []}
                    chartSettings={chartAdditionalSettings}
                    secondarySeriesData={secondaryAdditionalSerie ? getChartSeries(secondaryAdditionalSerie, seriesChartData) : []}
                    chartSecondarySettings={chartAdditionalSecondarySettings}
                    timeSeries={seriesChartData['TIME_UTC'] || seriesChartData['TIME_LOCAL'] || []}
                    zoomSync={zoomSync}
                    modeManager={modeManager}
                    setDiffData={setDiffData}
                    leftSerie={primaryAdditionalSerie}
                    setLeftSerie={setPrimaryAdditionalSerie}
                    seriesList={SERIES_SETS}
                    cylinderCount={flightData.aircraft.cylinderCount}
                    rightSerie={secondaryAdditionalSerie}
                    setRightSerie={setSecondaryAdditionalSerie}
                    setChartSettings={setChartAdditionalSettings}
                    setChartSecondarySettings={setChartAdditionalSecondarySettings}
                    aircraftId={flightData.aircraft?.id ? flightData.aircraft.id : ''}
                    chartNumber={3}
                    aircraftSetting={getAircraftSettings(primaryAdditionalSerie, scaleSettings)}
                    aircraftSecondarySetting={getAircraftSettings(secondaryAdditionalSerie, scaleSettings)}
                    settings={settings}
                    flightData={flightData}
                    conversions={conversions}
                    setPresetMode={setPresetMode}
                  />
                )}
                {!isGami(analysisMode) && !isMag(analysisMode) && chartNum === 4 && (
                  <FlightChartView
                    seriesData={thirdAdditionalSerie ? getChartSeries(thirdAdditionalSerie, seriesChartData) : []}
                    chartSettings={chartAdditionalThirdSettings}
                    secondarySeriesData={forthAdditionalSerie ? getChartSeries(forthAdditionalSerie, seriesChartData) : []}
                    chartSecondarySettings={chartAdditionalForthSettings}
                    timeSeries={seriesChartData['TIME_UTC'] || seriesChartData['TIME_LOCAL'] || []}
                    zoomSync={zoomSync}
                    modeManager={modeManager}
                    setDiffData={setDiffData}
                    leftSerie={thirdAdditionalSerie}
                    setLeftSerie={setThirdAdditionalSerie}
                    seriesList={SERIES_SETS}
                    cylinderCount={flightData.aircraft.cylinderCount}
                    rightSerie={forthAdditionalSerie}
                    setRightSerie={setForthAdditionalSerie}
                    setChartSettings={setChartAdditionalThirdSettings}
                    setChartSecondarySettings={setChartAdditionalForthSettings}
                    aircraftId={flightData.aircraft?.id ? flightData.aircraft.id : ''}
                    chartNumber={4}
                    aircraftSetting={getAircraftSettings(thirdAdditionalSerie, scaleSettings)}
                    aircraftSecondarySetting={getAircraftSettings(forthAdditionalSerie, scaleSettings)}
                    settings={settings}
                    flightData={flightData}
                    conversions={conversions}
                    setPresetMode={setPresetMode}
                  />
                )}
              </Box>
            )}
          </ComponentLoading>
        </Box>
      </ComponentLoading>
    </Box>
  );
};

export default FlightChart;
