import React from 'react';

import { Navigate } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { LayoutSignupService } from 'components';

import { SignupServiceAdditionalInfoForm } from './components';

import { PATH_NOT_FOUND } from 'const';

import { useService } from 'hooks';

const SignupService: React.FC = () => {
  const service = useService(); // Get service by id from url

  const price = useSelector((state: any) => {
    return state?.signup?.price ? state.signup.price : 0;
  });

  // If Service Not Found in Services array
  if (service === null) return <Navigate to={PATH_NOT_FOUND} />;

  return <LayoutSignupService service={service} form={<SignupServiceAdditionalInfoForm />} activeStep={2} servicePlan={price} />;
};

export default SignupService;
