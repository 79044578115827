import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dialog, Typography, DialogContent, DialogTitle, Button, DialogActions, Grid } from '@mui/material';

import { useMutation } from '@apollo/client';
import { MUTATION_TRANSFER_SUBSCRIPTION_TO_CONTACT } from 'gql';

import { Formik, Form } from 'formik';
import { MuiFormikInput, ButtonSubmit, Subsection, ComponentLoading } from 'components';
import * as Yup from 'yup';

import { setAlert } from 'state';

interface TransferPlanToContactProps {
  open: boolean;
  setOpen: (value: boolean) => void;
}

const validationFormSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
});

const TransferPlanToContact: React.FC<TransferPlanToContactProps> = (props) => {
  const { open, setOpen } = props;

  const dispatch = useDispatch();

  const { id } = useParams();

  const [transferToNewOwner, { data, loading, error }] = useMutation(MUTATION_TRANSFER_SUBSCRIPTION_TO_CONTACT);

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (values: any) => {
    if (!values.email) return;

    await transferToNewOwner({
      variables: {
        aircraftId: id,
        email: values.email,
      },
    });
  };

  useEffect(() => {
    if (error) {
      dispatch(setAlert('error', error.message));
    } else if (data) {
      if (data.transferSubscriptionsToContact?.ok) {
        dispatch(setAlert('success', 'Transferred plan to new owner'));
        setOpen(false);
      } else {
        dispatch(setAlert('error', data.transferSubscriptionsToContact?.error || 'Unable to transfer plan to new owner'));
      }
    }
  }, [data, error, dispatch, setOpen]);

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: '550px',
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontSize: '24px',
          fontWeight: 700,
        }}
      >
        Transfer plan to new owner
      </DialogTitle>
      <ComponentLoading loading={loading}>
        <Formik
          initialValues={{
            email: '',
          }}
          validationSchema={validationFormSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, handleChange, handleBlur, touched, errors }) => {
            return (
              <Form>
                <DialogContent>
                  <Subsection>
                    <Typography variant="h5" align="center">
                      You can transfer your plan to the new owner of your aircraft.
                      <br />
                      To do this the new owner will need to
                    </Typography>
                    <Typography paragraph px={6.5}>
                      - create an account with their own email address and
                      <br />- add the aircraft to this account
                    </Typography>
                  </Subsection>
                  <MuiFormikInput
                    name="email"
                    label="New Owner's Email *"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    noMargin
                  />
                </DialogContent>
                <DialogActions>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Button color="secondary" variant="outlined" onClick={handleClose} fullWidth>
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <ButtonSubmit text="Confirm" loading={isSubmitting || loading} />
                    </Grid>
                  </Grid>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </ComponentLoading>
    </Dialog>
  );
};

export default TransferPlanToContact;
