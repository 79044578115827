import React, { useState, useEffect } from 'react';
import { Box, Hidden, Slide } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { setAlert } from 'state';

import { CollapsedTickets, TicketContents, TicketInfo, TicketLoading } from './components';

import { useTimer } from 'hooks';
import { ctrlCmdClick } from 'helpers';

import {
  QUERY_USER_TICKETS,
  QUERY_USER_DETAIL_TICKET,
  QUERY_USER_DETAIL_TICKET_FOR_TIMER,
  MUTATION_CLOSE_TICKET,
  MUTATION_REOPEN_TICKET,
  MUTATION_MARK_TICKET_AS_READ,
  MUTATION_MARK_TICKET_AS_RECENTLY_VIEWED,
} from 'gql';
import { useLazyQuery, useMutation } from '@apollo/client';

import { PageLoading, ComponentLoading, LayoutDashboard } from 'components';
import { PATH_TICKETS_VIEW } from 'const';
import { ITicket } from 'types';

const Tickets: React.FC = () => {
  const FETCH_TIMEOUT = 60;

  const [fetchTickets, { data: dataTickets, loading: loadingTickets, error: errorTickets }] = useLazyQuery(QUERY_USER_TICKETS, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
  });

  const [timerTicketsList, { data: dataTimerTickets }] = useLazyQuery(QUERY_USER_TICKETS, {
    fetchPolicy: 'cache-and-network',
  }); // fetch only data in background

  const [fetchDetailTicket, { data: dataDetailTicket, loading: loadingDetailTicket, error: errorDetailTicket }] = useLazyQuery(
    QUERY_USER_DETAIL_TICKET,
    {
      errorPolicy: 'all',
      fetchPolicy: 'cache-and-network',
    },
  );

  const [fetchDetailTicketTimer] = useLazyQuery(QUERY_USER_DETAIL_TICKET_FOR_TIMER, {
    fetchPolicy: 'cache-and-network',
  });

  const [ticketList, setTicketList] = useState<ITicket[]>([]);

  const [subjectSearch, setSubjectSearch] = useState('');

  const [closeTicketMutation, { data: dataCloseTicket, loading: loadingCloseTicket, error: errorCloseTicket }] =
    useMutation(MUTATION_CLOSE_TICKET);
  const [reopenTicketMutation, { data: dataReopenTicket, loading: loadingReopenTicket, error: errorReopenTicket }] =
    useMutation(MUTATION_REOPEN_TICKET);
  const [markTicketAsRead] = useMutation(MUTATION_MARK_TICKET_AS_READ);
  const [setLastViewed] = useMutation(MUTATION_MARK_TICKET_AS_RECENTLY_VIEWED);

  const LAST_VIEWED_TIMEOUT = 300;

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { id } = useParams();

  const clickTicket = (event: any, ticketId: string) => {
    if (ticketId !== id) {
      ctrlCmdClick(event, `${PATH_TICKETS_VIEW}/${ticketId}`, navigate);
    } else {
      setSlideNum(2);
    }
  };

  const MARK_TIMEOUT = 3000;

  const [slideNum, setSlideNum] = useState(1);

  const timerAction = async () => {
    await timerTicketsList({
      variables: {
        subjectSearch: subjectSearch,
      },
    });
    if (id) {
      await fetchDetailTicketTimer({
        variables: {
          ticketId: parseInt(id),
        },
      });
    }
  };

  const { create: createTimer, clear: clearTimer } = useTimer({
    timeoutValue: FETCH_TIMEOUT,
    timerAction,
  });

  const LastViewedAction = async () => {
    if (id) {
      await setLastViewed({
        variables: {
          ticketId: parseInt(id),
        },
      });
    }
  };

  const { create: createLastViewedTimer, clear: clearLastViewedTimer } = useTimer({
    timeoutValue: LAST_VIEWED_TIMEOUT,
    timerAction: LastViewedAction,
  });

  useEffect(() => {
    fetchTickets({
      variables: {
        subjectSearch: subjectSearch,
      },
    });
  }, [subjectSearch, fetchTickets, id]);

  useEffect(() => {
    if (!loadingTickets && dataTickets) {
      if (dataTickets?.me?.tickets !== undefined) {
        setTicketList(dataTickets?.me?.tickets);
      }

      createTimer();
    }
  }, [dataTickets, loadingTickets, createTimer]);

  useEffect(() => {
    if (dataTimerTickets?.me?.tickets) {
      setTicketList(dataTimerTickets?.me?.tickets);
    }
  }, [dataTimerTickets]);

  useEffect(() => {
    if (errorTickets) {
      dispatch(setAlert('error', 'Unable to load your tickets.'));
    }
  }, [errorTickets, dispatch]);

  useEffect(() => {
    if (errorDetailTicket) {
      if (errorDetailTicket?.graphQLErrors && errorDetailTicket?.graphQLErrors[0]?.message) {
        dispatch(setAlert('error', errorDetailTicket?.graphQLErrors[0]?.message));
      } else {
        dispatch(setAlert('error', 'Unable to load info for selected ticket.'));
      }
    }
  }, [dispatch, errorDetailTicket]);

  useEffect(() => {
    if (id) {
      fetchDetailTicket({
        variables: {
          ticketId: parseInt(id),
        },
      });
    } else {
      setSlideNum(1);
    }
  }, [id, fetchDetailTicket]);

  useEffect(() => {
    if (id) {
      setLastViewed({
        variables: {
          ticketId: id,
        },
      });
    }
  }, [id, setLastViewed]);

  useEffect(() => {
    if (!loadingDetailTicket && dataDetailTicket?.me?.tickets?.length && dataDetailTicket?.me?.tickets[0]) {
      createLastViewedTimer();
    }
  }, [loadingDetailTicket, dataDetailTicket, createLastViewedTimer]);

  useEffect(() => {
    if (!loadingDetailTicket && dataDetailTicket && id) {
      setSlideNum(2);

      if (
        id &&
        !!dataDetailTicket?.me?.tickets?.length &&
        dataDetailTicket?.me?.tickets[0]?.id === id &&
        dataDetailTicket?.me?.tickets[0]?.hasUnread
      ) {
        setTimeout(async () => {
          await markTicketAsRead({
            variables: {
              ticketId: parseInt(id),
            },
          });
        }, MARK_TIMEOUT);
      }
    }
  }, [loadingDetailTicket, dataDetailTicket, id, markTicketAsRead]);

  const closeTicket = async (ticketId: number, close: boolean) => {
    if (close)
      await closeTicketMutation({
        variables: {
          ticketId,
        },
      });
    else
      await reopenTicketMutation({
        variables: {
          ticketId,
        },
      });
  };

  useEffect(() => {
    if (errorCloseTicket) {
      dispatch(setAlert('error', 'Unable to close selected ticket.'));
    } else if (dataCloseTicket) {
      if (dataCloseTicket.closeTicket?.ok) {
        dispatch(setAlert('success', 'Closed selected ticket.'));
      } else {
        dispatch(setAlert('error', dataCloseTicket.closeTicket?.error || 'Unable to close selected ticket.'));
      }
    }
  }, [dataCloseTicket, errorCloseTicket, dispatch]);

  useEffect(() => {
    if (errorReopenTicket) {
      dispatch(setAlert('error', 'Unable to reopen selected ticket.'));
    } else {
      if (dataReopenTicket && dataReopenTicket.reopenTicket?.ok) {
        dispatch(setAlert('success', 'Re-opened selected ticket.'));
      }
    }
  }, [dataReopenTicket, errorReopenTicket, dispatch]);

  useEffect(() => {
    if (dataDetailTicket?.me?.tickets?.length && dataDetailTicket?.me?.tickets[0]?.subject) {
      document.title = `Ticket: ${dataDetailTicket?.me?.tickets[0]?.subject}`;
    }
  }, [dataDetailTicket]);

  useEffect(() => {
    return () => {
      clearTimer();
      clearLastViewedTimer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <LayoutDashboard backgroundColor="#FFFFFF" flexGrow={1}>
      {(loadingTickets || loadingCloseTicket || loadingReopenTicket) && <PageLoading />}
      <Box
        flexGrow={1}
        width="100%"
        sx={{
          mb: 1,
          overflowX: 'hidden',
        }}
      >
        <Hidden xlDown>
          <Box
            sx={{
              minHeight: 'calc(100% - 75px)',
              display: 'flex',
            }}
          >
            <CollapsedTickets
              tickets={ticketList}
              subjectSearch={subjectSearch}
              setSubjectSearch={setSubjectSearch}
              clickTicket={clickTicket}
            />
            <Box
              sx={{
                display: 'flex',
                flexGrow: 1,
                position: 'relative',
                zIndex: 0,
              }}
            >
              <TicketLoading loading={loadingDetailTicket}>
                {!!dataDetailTicket?.me?.tickets?.length && <TicketContents ticket={id && dataDetailTicket?.me?.tickets[0]} />}
                {id && !!dataDetailTicket?.me?.tickets?.length && (
                  <TicketInfo ticket={dataDetailTicket?.me?.tickets[0]} closeTicket={closeTicket} />
                )}
              </TicketLoading>
            </Box>
          </Box>
        </Hidden>
        <Hidden xlUp>
          <ComponentLoading loading={loadingDetailTicket}>
            <Box
              sx={{
                overflowX: 'hidden',
              }}
            >
              <Slide
                direction="left"
                in={slideNum === 1}
                unmountOnExit
                timeout={{
                  exit: 0,
                  enter: 300,
                }}
              >
                <Box>
                  <CollapsedTickets
                    tickets={ticketList}
                    subjectSearch={subjectSearch}
                    setSubjectSearch={setSubjectSearch}
                    clickTicket={clickTicket}
                  />
                </Box>
              </Slide>
              {id && (
                <Slide
                  direction="left"
                  in={slideNum === 2}
                  mountOnEnter
                  unmountOnExit
                  timeout={{
                    exit: 0,
                    enter: 300,
                  }}
                >
                  <Box>
                    {!!dataDetailTicket?.me?.tickets?.length && (
                      <TicketContents ticket={id && dataDetailTicket?.me?.tickets[0]} setSlideNum={setSlideNum} closeTicket={closeTicket} />
                    )}
                  </Box>
                </Slide>
              )}
            </Box>
          </ComponentLoading>
        </Hidden>
      </Box>
    </LayoutDashboard>
  );
};

export default Tickets;
