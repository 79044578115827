import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Link } from '@mui/material';
import { ComponentLoading } from 'components';

import { QUERY_SAVVY_REPORT_SEARCH } from 'gql';
import { useLazyQuery } from '@apollo/client';
import { SAVVY_SEARCH_LIMIT, PATH_FLIGHT_REPORT, PATH_SAVVY_SEARCH_FLIGHT_REPORT } from 'const';
import { getFlightDescription, ctrlCmdClick } from 'helpers';

import { IFlightReport } from 'types';

interface FlightReportSearchProps {
  searchQuery: string;
}

const FlightReportSearch: React.FC<FlightReportSearchProps> = (props) => {
  const { searchQuery } = props;

  const [fetchSavvyFlightReports, { data: dataReports, loading }] = useLazyQuery(QUERY_SAVVY_REPORT_SEARCH);
  const navigate = useNavigate();

  const [reportList, setReportList] = useState<IFlightReport[]>([]);

  const [count, setCount] = useState(0);

  const loadMore = () => {
    navigate(`${PATH_SAVVY_SEARCH_FLIGHT_REPORT}?query=${searchQuery}`);
  };

  useEffect(() => {
    if (!searchQuery || searchQuery.length < 2) {
      return;
    }

    setReportList([]);

    const timeout: NodeJS.Timeout = setTimeout(() => {
      fetchSavvyFlightReports({
        variables: {
          search: searchQuery,
          limit: SAVVY_SEARCH_LIMIT,
          offset: 0,
        },
      });
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [searchQuery, fetchSavvyFlightReports]);

  useEffect(() => {
    if (dataReports?.savvy?.search?.flightReports?.results?.length) {
      setReportList((reportList: IFlightReport[]) => {
        const newArray: IFlightReport[] = reportList.concat(dataReports.savvy?.search?.flightReports?.results);
        return [...newArray];
      });
    }

    if (dataReports?.savvy?.search?.flightReports) {
      setCount(dataReports.savvy?.search?.flightReports?.count);
    }
  }, [dataReports]);

  return (
    <Box>
      <ComponentLoading loading={loading}>
        <Box>
          {!!searchQuery && (
            <Typography
              variant="h5"
              sx={{
                fontWeight: 'bold',
                py: 1,
              }}
            >
              Flight Reports {count ? `(${count})` : ''}
            </Typography>
          )}
          <Box pl={4}>
            {reportList?.map((report: IFlightReport, key: number) => {
              return (
                <Box
                  key={key}
                  py={1}
                  sx={{
                    cursor: 'pointer',
                  }}
                  onClick={(e: any) => {
                    ctrlCmdClick(e, `${PATH_FLIGHT_REPORT}/${report.flight.aircraft.id}/${report.id}`, undefined, true);
                  }}
                >
                  <Typography>{getFlightDescription(report.flight)}</Typography>
                </Box>
              );
            })}
            {!reportList?.length && !!searchQuery && !loading && <Box py={1}>No matched reports</Box>}
          </Box>
          {count > reportList.length && (
            <Link
              href="#"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                loadMore();
              }}
            >
              Show More
            </Link>
          )}
        </Box>
      </ComponentLoading>
    </Box>
  );
};

export default FlightReportSearch;
