import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, Typography, TextField, Switch, Collapse, IconButton, InputAdornment } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { IBorescopeImageSet, IAircraftDetail } from 'types';
import { getEngineNameFromBorescopeLabel } from 'helpers';
import { PATH_SAVVY_AIRCRAFT, PATH_CONTACT, PATH_CREATE_OWN_BORESCOPE_REPORT } from 'const';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import dayjs from 'dayjs';
import { ICannedResponse } from 'types';
import { useLazyQuery } from '@apollo/client';
import { QUERY_CANNED_RESPONSES_FOR_TICKET } from 'gql';
import { SET_PREVIW_FALG } from 'state/types';

interface InformationProps {
  imageSet: IBorescopeImageSet | undefined;
  includingBad: boolean;
  setShowRequest: (value: boolean) => void;
  sendAndBack: () => void;
  buttonLabel: string;
  aircraft: IAircraftDetail | undefined;
  comments: string;
  setComments: (value: string) => void;
  nextDisabled: boolean;
}

const Information: React.FC<InformationProps> = (props) => {
  const { aircraftId, borescopeImageSetId } = useParams();
  const { imageSet, includingBad, setShowRequest, sendAndBack, buttonLabel, aircraft, comments, setComments, nextDisabled } = props;
  const [fetchSavvy, { data: dataSavvy, loading: loadingSavvy }] = useLazyQuery(QUERY_CANNED_RESPONSES_FOR_TICKET);
  const { isSavvy } = useSelector((state: any) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (isSavvy) {
      fetchSavvy();
    }
  }, [isSavvy]);

  useEffect(() => {
    if (dataSavvy) {
      setCannedResponses(dataSavvy?.savvy?.borescopeCannedResponses || []);
    }
  }, [dataSavvy, loadingSavvy]);

  const [showComments, setShowComments] = useState(false);

  const [cannedResponses, setCannedResponses] = useState([] as ICannedResponse[]);

  const [showCannedResponsesList, setShowCannedResponsesList] = useState(false);

  const [searchCannedResponsesText, setSearchCannedResponsesText] = useState('');

  const setCannedResponseText = (value: string) => {
    setComments(comments + value + '\n');
  };

  const handleBackButton = () => {
    navigate(`${PATH_CREATE_OWN_BORESCOPE_REPORT}/${aircraftId}/${borescopeImageSetId}`);
  };

  const { previewFlag } = useSelector((state: any) => state.analysis);
  return (
    <Box>
      <Box
        sx={{
          width: '360px',
          py: 4,
        }}
      >
        <Box px={4}>
          {buttonLabel === 'Complete Report' ? (
            <Box>
              {previewFlag ? (
                <Button
                  variant="contained"
                  onClick={() => {
                    dispatch({ type: SET_PREVIW_FALG, payload: false });
                    handleBackButton();
                  }}
                  color="primary"
                  sx={{ my: '10px' }}
                  fullWidth
                >
                  Back to Edit
                </Button>
              ) : (
                <Button
                  variant="contained"
                  onClick={() => {
                    dispatch({ type: SET_PREVIW_FALG, payload: true });
                  }}
                  color="primary"
                  sx={{ my: '10px' }}
                  fullWidth
                >
                  Preview Report
                </Button>
              )}
              <Button
                variant="contained"
                onClick={() => {
                  sendAndBack();
                  dispatch({ type: SET_PREVIW_FALG, payload: false });
                }}
                disabled={nextDisabled}
                fullWidth
              >
                {buttonLabel}
              </Button>
            </Box>
          ) : (
            <Button
              variant="contained"
              onClick={() => {
                sendAndBack();
                dispatch({ type: SET_PREVIW_FALG, payload: false });
              }}
              disabled={nextDisabled}
              fullWidth
            >
              {buttonLabel}
            </Button>
          )}
          {includingBad && (
            <Button
              variant="outlined"
              color="warning"
              onClick={() => {
                setShowRequest(true);
              }}
              sx={{
                mt: '10px',
              }}
              fullWidth
            >
              Request Updated Images
            </Button>
          )}
          <Box py={2.5}>
            <Typography color="text.secondary">
              Borescope Image Set - {getEngineNameFromBorescopeLabel(imageSet?.engine || '')} Engine -{' '}
              {dayjs(imageSet?.created).format('MM/DD/YY')}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              px: 4,
              py: 2,
              backgroundColor: 'rgba(245, 246, 250, 0.5)',
              borderTop: '1px solid',
              borderBottom: '1px solid',
              borderColor: '#E9EAEF',
            }}
          >
            <Typography
              sx={{
                fontSize: '18px',
                fontWeight: 700,
                lineHeight: '25px',
              }}
            >
              Comments
            </Typography>
          </Box>
          {isSavvy && (
            <Box>
              <Box
                sx={{
                  py: 2,
                  width: '100%',
                  px: {
                    xs: 2,
                    sm: 3,
                    md: 4,
                    lg: 5,
                  },
                  background: 'rgba(245, 246, 250, 0.5)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setShowCannedResponsesList(!showCannedResponsesList);
                }}
              >
                <Typography
                  sx={{
                    color: 'text.secondary',
                    fontWeight: 700,
                    fontSize: '18px',
                    lineHeight: '25px',
                  }}
                >
                  Canned Responses
                  <Box
                    component={'span'}
                    sx={{
                      marginLeft: '10px',
                      backgroundColor: 'grey.400',
                      padding: '0 5px',
                      borderRadius: '4px',
                      color: 'background.default',
                      fontSize: '13px',
                      fontWeight: 600,
                      lineHeight: '18px',
                    }}
                  >
                    {cannedResponses.length}
                  </Box>
                </Typography>
                <IconButton>{showCannedResponsesList ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</IconButton>
              </Box>
              <Collapse in={showCannedResponsesList}>
                <Box
                  sx={{
                    width: '100%',
                    px: {
                      xs: 2,
                      sm: 3,
                      md: 4,
                      lg: 5,
                    },
                    py: 1,
                  }}
                >
                  <Box py={1}>
                    <TextField
                      label="Search"
                      name="search"
                      value={searchCannedResponsesText}
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setSearchCannedResponsesText(e.target.value);
                      }}
                      sx={{
                        marginBottom: 0,
                      }}
                    />
                  </Box>
                  {cannedResponses
                    .filter((cannedResponses: ICannedResponse) => {
                      if (searchCannedResponsesText === '') {
                        return cannedResponses;
                      } else if (cannedResponses.title.toLowerCase().includes(searchCannedResponsesText.toLowerCase())) {
                        return cannedResponses;
                      } else {
                        return null;
                      }
                    })
                    .map((cannedResponse: ICannedResponse, key: number) => {
                      return (
                        <Typography
                          sx={{
                            textDecoration: 'underline',
                            cursor: 'pointer',
                            fontWeight: 600,
                            py: 1,
                          }}
                          key={key}
                          onClick={() => {
                            setCannedResponseText(cannedResponse.text);
                            setShowCannedResponsesList(false);
                          }}
                        >
                          {cannedResponse.title.replace('Borescope Analysis - ', '')}
                        </Typography>
                      );
                    })}
                </Box>
              </Collapse>
            </Box>
          )}
          <Box p={4}>
            <TextField
              name="comments"
              label="Comments"
              placeholder="Enter Your Comments"
              value={comments}
              onChange={(e) => {
                setComments(e.target.value);
              }}
              disabled={imageSet?.status === 'REPORT_SENT'}
              sx={{
                mb: 0,
                maxWidth: '500px',
              }}
              multiline
              rows={10}
              fullWidth
            />
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              px: 4,
              py: 2,
              backgroundColor: 'rgba(245, 246, 250, 0.5)',
              borderTop: '1px solid',
              borderBottom: '1px solid',
              borderColor: '#E9EAEF',
            }}
          >
            <Typography
              sx={{
                fontSize: '18px',
                fontWeight: 700,
                lineHeight: '25px',
              }}
            >
              Set Information
            </Typography>
          </Box>
          <Box px={4} py={1.25}>
            <Box
              py={1.25}
              sx={{
                display: 'flex',
              }}
            >
              <Box
                sx={{
                  width: '40%',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '13px',
                    lineHeight: '18px',
                    color: 'grey.600',
                    fontWeight: 600,
                  }}
                >
                  Aircraft
                </Typography>
              </Box>
              <Box
                sx={{
                  width: '60%',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 600,
                    lineHeight: '19px',
                    color: 'text.secondary',
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  }}
                  onClick={() => {
                    if (isSavvy) {
                      window.open(`${PATH_SAVVY_AIRCRAFT}/${aircraft?.id}`, '_blank');
                    }
                  }}
                >
                  {`${aircraft?.aircraftManufacturer?.name || ''}/${aircraft?.aircraftModel?.name || ''} ${
                    aircraft?.engineManufacturer?.name || ''
                  }/${aircraft?.engineModel?.name || ''}`}
                </Typography>
              </Box>
            </Box>
            <Box
              py={1.25}
              sx={{
                display: 'flex',
              }}
            >
              <Box
                sx={{
                  width: '40%',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '13px',
                    lineHeight: '18px',
                    color: 'grey.600',
                    fontWeight: 600,
                  }}
                >
                  Owner
                </Typography>
              </Box>
              <Box
                sx={{
                  width: '60%',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 600,
                    lineHeight: '19px',
                    color: 'text.secondary',
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  }}
                  onClick={() => {
                    if (isSavvy) {
                      window.open(`${PATH_CONTACT}/${aircraft?.owner.id}`, '_blank');
                    }
                  }}
                >
                  {aircraft?.owner?.firstName} {aircraft?.owner?.lastName}
                </Typography>
              </Box>
            </Box>
            <Box
              py={1.25}
              sx={{
                display: 'flex',
              }}
            >
              <Box
                sx={{
                  width: '40%',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '13px',
                    lineHeight: '18px',
                    color: 'grey.600',
                    fontWeight: 600,
                  }}
                >
                  Year
                </Typography>
              </Box>
              <Box
                sx={{
                  width: '60%',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 600,
                    lineHeight: '19px',
                    color: 'text.secondary',
                  }}
                >
                  {aircraft?.year || ''}
                </Typography>
              </Box>
            </Box>
            <Box
              py={1.25}
              sx={{
                display: 'flex',
              }}
            >
              <Box
                sx={{
                  width: '40%',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '13px',
                    lineHeight: '18px',
                    color: 'grey.600',
                    fontWeight: 600,
                  }}
                >
                  Registration
                </Typography>
              </Box>
              <Box
                sx={{
                  width: '60%',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 600,
                    lineHeight: '19px',
                    color: 'text.secondary',
                  }}
                >
                  {aircraft?.registration || ''}
                </Typography>
              </Box>
            </Box>
            <Box
              py={1.25}
              sx={{
                display: 'flex',
              }}
            >
              <Box
                sx={{
                  width: '40%',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '13px',
                    lineHeight: '18px',
                    color: 'grey.600',
                    fontWeight: 600,
                  }}
                >
                  Serial
                </Typography>
              </Box>
              <Box
                sx={{
                  width: '60%',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 600,
                    lineHeight: '19px',
                    color: 'text.secondary',
                  }}
                >
                  {aircraft?.serial || ''}
                </Typography>
              </Box>
            </Box>
            {isSavvy && (
              <Box
                sx={{
                  display: 'flex',
                }}
              >
                <Box
                  sx={{
                    width: '40%',
                    py: 1.5,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '13px',
                      lineHeight: '18px',
                      color: 'grey.600',
                      fontWeight: 600,
                    }}
                  >
                    Pinned Comments
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: '60%',
                  }}
                >
                  {aircraft?.comments?.length ? (
                    <Switch
                      checked={showComments}
                      onChange={() => {
                        setShowComments(!showComments);
                      }}
                      name=""
                      color="primary"
                    />
                  ) : (
                    <Typography
                      sx={{
                        ml: 1,
                      }}
                    >
                      None
                    </Typography>
                  )}
                  {showComments && (
                    <Typography
                      dangerouslySetInnerHTML={{
                        __html:
                          aircraft?.comments
                            ?.filter((item: any) => item.pinned)
                            .map((item: any) => item.comment)
                            .join(', ') || '',
                      }}
                    />
                  )}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Information;
