import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Container, Box, Typography, Button } from '@mui/material';
import { DataGridPro, GridRowParams } from '@mui/x-data-grid-pro';

import { useQuery } from '@apollo/client';

import { setAlert } from 'state';
import { LayoutDashboard, PageLoading } from 'components';
import { QUERY_USER_ONLY_AIRCRAFTS } from 'gql';

import { PATH_AIRCRAFT_VIEW, PATH_AIRCRAFT_CREATE, MAX_AIRCRAFT_LIST } from 'const';
import { ctrlCmdClick } from 'helpers';
import { ISubscription } from 'types';

interface IAircraftTableItem {
  id: string;
  registration: string;
  year: string;
  make: string;
  model: string;
  serial: string;
  default: boolean;
  subscriptions: string;
}

const AircraftList: React.FC = () => {
  const {
    data: dataAircrafts,
    loading: loadingAircrafts,
    error: errorAircrafts,
  } = useQuery(QUERY_USER_ONLY_AIRCRAFTS, {
    fetchPolicy: 'cache-and-network',
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [aircraftList, setAircraftList] = useState<IAircraftTableItem[]>([]);

  const [pageSize, setPageSize] = useState(5);

  useEffect(() => {
    if (errorAircrafts) {
      dispatch(setAlert('error', 'Unable to load your aircraft'));
    }
  }, [errorAircrafts, dispatch]);

  useEffect(() => {
    if (dataAircrafts?.me?.aircraft?.length) {
      if (dataAircrafts.me.aircraft.length < MAX_AIRCRAFT_LIST) {
        for (let i = 0; i < dataAircrafts.me.aircraft.length; i++) {
          if (dataAircrafts.me.aircraft[i].default) {
            navigate(`${PATH_AIRCRAFT_VIEW}/${dataAircrafts.me.aircraft[i].id}`);
            return;
          }
        }

        navigate(`${PATH_AIRCRAFT_VIEW}/${dataAircrafts.me.aircraft[0].id}`);
      } else {
        const tmp = dataAircrafts.me.aircraft.map((aircraft: any) => {
          const subscriptions = aircraft.subscriptions
            .filter((subscription: ISubscription) => subscription.status.toLowerCase() === 'active')
            .map((item: ISubscription) => item.serviceName);
          let subscriptionString = '';
          if (subscriptions.length) {
            subscriptionString = subscriptions.join(', ');
          }

          return {
            id: aircraft.id,
            registration: aircraft.registration ? aircraft.registration : '',
            year: aircraft.year ? aircraft.year : '',
            make: aircraft.aircraftManufacturer ? aircraft.aircraftManufacturer.name : '',
            model: aircraft.aircraftModel ? aircraft.aircraftModel.name : '',
            serial: aircraft.serial ? aircraft.serial : '',
            default: aircraft.default,
            subscriptions: subscriptionString,
          };
        });

        setAircraftList([...tmp]);
      }
    }
  }, [dataAircrafts, navigate]);

  return (
    <LayoutDashboard>
      {loadingAircrafts && <PageLoading />}
      <Box
        p={{
          sm: 0,
          md: 2,
          lg: 3.75,
          xl: 5,
        }}
      >
        <Box
          pb={2}
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h2" align="center">
            Select aircraft to view detail
          </Typography>
          {!!aircraftList.length && (
            <Button
              variant="contained"
              onClick={() => {
                navigate(PATH_AIRCRAFT_CREATE);
              }}
              sx={{
                ml: 1,
              }}
            >
              Add Aircraft...
            </Button>
          )}
        </Box>
        <Container fixed maxWidth="xl">
          {!!aircraftList.length && (
            <DataGridPro
              sx={{
                width: '100%',
                minWidth: '100px',
                '& .MuiDataGrid-columnSeparator': {
                  display: 'none',
                },
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-row': {
                  cursor: 'pointer',
                  borderBottom: '1px solid',
                  borderColor: 'grey.200',
                },
                '& .MuiDataGrid-columnHeader:focus': {
                  outline: 'none',
                },
                border: 'none',
                backgroundColor: 'background.default',
              }}
              autoHeight
              disableColumnResize={true}
              disableSelectionOnClick={true}
              rowsPerPageOptions={[5, 10, 20, 100]}
              pagination
              pageSize={pageSize}
              onPageSizeChange={(newPage) => setPageSize(newPage)}
              showColumnRightBorder={false}
              rows={aircraftList}
              columns={[
                {
                  field: 'year',
                  headerName: 'Year',
                  flex: 1,
                },
                {
                  field: 'registration',
                  headerName: 'Registration',
                  flex: 1,
                },
                {
                  field: 'make',
                  headerName: 'Make',
                  flex: 1,
                },
                {
                  field: 'model',
                  headerName: 'Model',
                  flex: 1,
                },
                {
                  field: 'serial',
                  headerName: 'Serial',
                  flex: 1,
                },
                {
                  field: 'subscriptions',
                  headerName: 'Services',
                  flex: 1,
                },
              ]}
              onRowClick={(params: GridRowParams, event?: any) => {
                const { row } = params;
                if (row?.id) {
                  ctrlCmdClick(event, `${PATH_AIRCRAFT_VIEW}/${row?.id}`, navigate);
                } else {
                  return false;
                }
              }}
            />
          )}
          {!aircraftList.length && (
            <Box>
              <Typography
                textAlign="center"
                sx={{
                  fontSize: '20px',
                }}
              >
                No aircraft found.{' '}
                <Button
                  variant="contained"
                  onClick={() => {
                    navigate(PATH_AIRCRAFT_CREATE);
                  }}
                >
                  Add Aircraft...
                </Button>
              </Typography>
            </Box>
          )}
        </Container>
      </Box>
    </LayoutDashboard>
  );
};

export default AircraftList;
