import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box, Grid } from '@mui/material';
import * as Yup from 'yup';

import { Formik, Form } from 'formik';
import { MuiFormikInputWithWarning, ButtonSubmit, TicketTextareaWithFile, ComponentLoading } from 'components';

import { useMutation } from '@apollo/client';
import { MUTATION_CREATE_PREBUY_TICKET } from 'gql';
import { setAlert } from 'state';
import { IAircraftEligibility, ITicketEligibility, IUploadedFile } from 'types';
import { PATH_TICKETS_VIEW, MAX_SUBJECT_LENGTH, SHOW_SUBJECT_WARNING } from 'const';

const PostTicketSchema = Yup.object().shape({
  subject: Yup.string().max(MAX_SUBJECT_LENGTH, `Too long: 1-${MAX_SUBJECT_LENGTH} characters required`).required('Required'),
  body: Yup.string().max(10000, 'Too long: 1-10000 characters required').required('Required'),
});

interface submitValueProps {
  subject: string;
  body: string;
}

interface PrebuyFormComponentProps {
  ticketAircraft: IAircraftEligibility;
  eligibility: ITicketEligibility;
}

const PrebuyFormComponent: React.FC<PrebuyFormComponentProps> = (props) => {
  const { ticketAircraft, eligibility } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [createTicket, { data: dataCreateTicket, error: errorCreateTicket, loading: loadingCreateTicket }] = useMutation(
    MUTATION_CREATE_PREBUY_TICKET,
    {
      errorPolicy: 'all',
    },
  );

  const [isUploading, setIsUploading] = useState(false);
  const [uploadedFileList, setUploadedFileList] = useState<IUploadedFile[]>([]);

  useEffect(() => {
    if (errorCreateTicket) {
      dispatch(setAlert('error', 'Unable to create ticket'));
    } else if (dataCreateTicket) {
      if (dataCreateTicket.createPrebuyTicket?.ok) {
        dispatch(setAlert('success', 'Created new ticket'));
        navigate(`${PATH_TICKETS_VIEW}/${dataCreateTicket.createPrebuyTicket.ticket.id}`);
      } else {
        dispatch(setAlert('error', dataCreateTicket.createPrebuyTicket?.error || 'Unable to create ticket'));
      }
    }
  }, [errorCreateTicket, dispatch, dataCreateTicket, navigate]);

  const onSubmit = async (value: submitValueProps) => {
    const documentIds = uploadedFileList.map((file: IUploadedFile) => {
      return file.id;
    });

    await createTicket({
      variables: {
        body: value.body,
        subject: value.subject,
        aircraftId: ticketAircraft.id,
        subscriptionId: eligibility.currentlyEligibleViaSubscription[0],
        documentIds: documentIds ? documentIds : [],
      },
    });
  };

  if (!eligibility.free && !(eligibility.eligible && eligibility.currentlyEligibleVia.length)) return null;

  return (
    <ComponentLoading loading={loadingCreateTicket}>
      <Box py={3}>
        <Formik
          initialValues={{
            subject: '',
            body: '',
          }}
          validationSchema={PostTicketSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, handleChange, handleBlur, touched, errors }) => {
            return (
              <Form>
                <Box py={1}>
                  <MuiFormikInputWithWarning
                    name="subject"
                    label="Subject"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    maxLength={MAX_SUBJECT_LENGTH}
                    closeLength={SHOW_SUBJECT_WARNING}
                  />
                </Box>
                <Box pt={1} pb={3.5}>
                  <TicketTextareaWithFile
                    name="body"
                    label="Message"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    rows={5}
                    maxLength={10000}
                    maxLine={10}
                    isUploading={isUploading}
                    setIsUploading={setIsUploading}
                    uploadedFileList={uploadedFileList}
                    setUploadedFileList={setUploadedFileList}
                  />
                </Box>
                <Grid container spacing={2}>
                  <Grid item md={3} xs={false}></Grid>
                  <Grid item md={6} xs={12}>
                    <ButtonSubmit
                      text="Create Ticket"
                      loading={isSubmitting || loadingCreateTicket}
                      disabled={isSubmitting || loadingCreateTicket || isUploading}
                    />
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </ComponentLoading>
  );
};

export default PrebuyFormComponent;
