import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Button, Grid } from '@mui/material';
import { IBorescopeImageFileType, IBorescopeStatusChoice } from 'types';
import {
  QUERY_SAVVY_BORESCOPE_IMAGE_WITH_PREVIEW_URL,
  QUERY_SHARED_BORESCOPE_IMAGE_WITH_PREVIEW_URL,
  QUERY_ME_BORESCOPE_IMAGE_WITH_PREVIEW_URL,
  MUTATION_UPDATE_BORESCOPE_IMAGE_STATUS,
  MUTATION_UPDATE_BORESCOPE_IMAGE_SET_COMMENTS,
} from 'gql';
import { useLazyQuery, useMutation } from '@apollo/client';
import { ComponentLoading } from 'components';
import { apolloClient } from 'services';
import dayjs from 'dayjs';
import { setAlert } from 'state';
import { default as ImageZoomDialog } from './ImageZoomDialog';
import { IBorescopeImageSet } from 'types';

interface ViewImageProps {
  aircraftId?: string;
  imageSet?: IBorescopeImageSet;
  image: IBorescopeImageFileType | undefined;
  statuses: IBorescopeStatusChoice[];
  borescopeImageId: string;
  onClickNext: () => void;
  comments: string;
  isAvailableToTag: () => boolean;
  isOwn?: boolean;
}

const ViewImage: React.FC<ViewImageProps> = (props) => {
  const { aircraftId, imageSet, image, statuses, borescopeImageId, onClickNext, comments, isAvailableToTag, isOwn = false } = props;

  const [fetchBorescopeImagePreviewUrl, { data: dataPreviewUrl, loading: loadingPreviewUrl }] = useLazyQuery(
    QUERY_SAVVY_BORESCOPE_IMAGE_WITH_PREVIEW_URL,
    {
      fetchPolicy: 'network-only',
    },
  );
  const [fetchSharedBorescopeImagePreviewUrl, { data: dataSharedPreviewUrl, loading: loadingSharedPreviewUrl }] = useLazyQuery(
    QUERY_SHARED_BORESCOPE_IMAGE_WITH_PREVIEW_URL,
    {
      fetchPolicy: 'network-only',
    },
  );
  const [fetchOwnBorescopeImagePreviewUrl, { data: dataOwnPreviewUrl, loading: loadingOwnPreviewUrl }] = useLazyQuery(
    QUERY_ME_BORESCOPE_IMAGE_WITH_PREVIEW_URL,
    {
      fetchPolicy: 'network-only',
    },
  );
  const { isSavvy } = useSelector((state: any) => state.auth);
  const [updateComments, { error: errorComments }] = useMutation(MUTATION_UPDATE_BORESCOPE_IMAGE_SET_COMMENTS);
  const dispatch = useDispatch();

  const [loadingImage, setLoadingImage] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const [showZoom, setShowZoom] = useState(false);
  const [zoomSrc, setZoomSrc] = useState('');

  const [previousImages, setPreviousImages] = useState<IBorescopeImageFileType[]>([]);

  const setStatus = async (image: IBorescopeImageFileType, slug: string) => {
    if (!isAvailableToTag()) {
      return;
    }
    const status = [...image.status];
    const matchedIndex = status.findIndex((item) => item === slug);
    let clickedNext = false;
    if (matchedIndex > -1) {
      status.splice(matchedIndex, 1);
    } else {
      status.push(slug);
      if (slug === 'normal') {
        clickedNext = true;
      }
    }

    setIsUpdating(true);

    try {
      const { data } = await apolloClient.mutate({
        mutation: MUTATION_UPDATE_BORESCOPE_IMAGE_STATUS,
        variables: {
          borescopeImageId: image.id,
          statuses: status,
        },
      });

      await updateComments({
        variables: {
          imageSetId: imageSet?.id,
          comments,
        },
      });

      setIsUpdating(false);

      if (!data.updateBorescopeImageStatus?.ok) {
        dispatch(setAlert('error', data.updateBorescopeImageStatus.error || 'Unable to update status'));
        return;
      }
      if (clickedNext) {
        onClickNext();
      }
    } catch {
      dispatch(setAlert('error', 'Unable to update status'));
      setIsUpdating(false);
    }
  };

  useEffect(() => {
    if (aircraftId && imageSet?.id && borescopeImageId) {
      setLoadingImage(true);
      if (isSavvy) {
        fetchBorescopeImagePreviewUrl({
          variables: {
            aircraftId,
            borescopeImageSetId: imageSet.id,
            borescopeImageId,
          },
        });
      } else if (!isOwn) {
        fetchSharedBorescopeImagePreviewUrl({
          variables: {
            aircraftId,
            borescopeImageSetId: imageSet.id,
            borescopeImageId,
          },
        });
      } else {
        fetchOwnBorescopeImagePreviewUrl({
          variables: {
            aircraftId: parseInt(aircraftId),
            borescopeImageSetId: imageSet.id,
            borescopeImageId,
          },
        });
      }
    }
  }, [aircraftId, imageSet, borescopeImageId, isSavvy, isOwn]);

  useEffect(() => {
    if (errorComments) {
      dispatch(setAlert('error', 'Unable to save comments'));
    }
  }, [errorComments, dispatch]);

  useEffect(() => {
    if (borescopeImageId) {
      setPreviousImages([]);
    }
  }, [borescopeImageId]);

  useEffect(() => {
    if (dataPreviewUrl) {
      if (dataPreviewUrl?.savvy?.aircraft?.borescopeImageSets[0]?.images[0]?.previousImages?.length) {
        setPreviousImages([...dataPreviewUrl.savvy.aircraft.borescopeImageSets[0].images[0].previousImages]);
      }
    }
  }, [dataPreviewUrl]);

  useEffect(() => {
    if (dataSharedPreviewUrl) {
      if (dataSharedPreviewUrl?.sharedAircraftWithUploadBorescope[0]?.aircraft?.borescopeImageSets[0]?.images[0]?.previousImages?.length) {
        setPreviousImages([
          ...dataSharedPreviewUrl.sharedAircraftWithUploadBorescope[0].aircraft.borescopeImageSets[0].images[0].previousImages,
        ]);
      }
    }
  }, [dataSharedPreviewUrl]);

  useEffect(() => {
    if (dataOwnPreviewUrl) {
      if (dataOwnPreviewUrl?.me?.aircraft[0]?.borescopeImageSets[0]?.images[0]?.previousImages?.length) {
        setPreviousImages([...dataOwnPreviewUrl.me.aircraft[0].borescopeImageSets[0].images[0].previousImages]);
      }
    }
  }, [dataOwnPreviewUrl]);

  if (!image) {
    return null;
  }

  return (
    <Box
      sx={{
        minHeight: '500px',
      }}
      py={1.25}
    >
      <ComponentLoading loading={loadingPreviewUrl || loadingSharedPreviewUrl || loadingOwnPreviewUrl || isUpdating}>
        <Box mb={5}>
          <Box
            py={2}
            px={2.5}
            sx={{
              backgroundColor: 'rgba(245, 246, 250, 0.5)',
              borderTop: '1px solid',
              borderBottom: '1px solid',
              borderColor: '#E9EAEF',
            }}
          >
            {statuses.map((item: IBorescopeStatusChoice, key: number) => {
              return (
                <React.Fragment key={key}>
                  <Button
                    variant={image.status.findIndex((value) => value === item.slug) > -1 ? 'contained' : 'outlined'}
                    sx={{
                      ml: key === 0 ? 0 : 1.25,
                      my: '5px',
                      '&.MuiButton-outlinedPrimary:focus': {
                        backgroundColor: 'background.default',
                        color: 'primary.main',
                      },
                    }}
                    color="secondary"
                    onClick={() => {
                      setStatus(image, item.slug);
                    }}
                    disabled={isUpdating}
                  >
                    {item.name}
                  </Button>
                  {item.slug === 'normal' && (
                    <Typography
                      sx={{
                        ml: 1.25,
                        color: 'text.disabled',
                        display: 'inline-block',
                      }}
                    >
                      |
                    </Typography>
                  )}
                </React.Fragment>
              );
            })}
          </Box>
          <Box>
            <ComponentLoading loading={loadingImage}>
              <Grid container spacing={0}>
                <Grid item lg={0} xl={2} />
                <Grid item lg={12} xl={8}>
                  <Box
                    component={'img'}
                    src={
                      dataPreviewUrl?.savvy?.aircraft?.borescopeImageSets[0]?.images[0]?.previewUrl ||
                      dataSharedPreviewUrl?.sharedAircraftWithUploadBorescope[0]?.aircraft?.borescopeImageSets[0]?.images[0]?.previewUrl ||
                      dataOwnPreviewUrl?.me?.aircraft[0]?.borescopeImageSets[0]?.images[0]?.previewUrl ||
                      ''
                    }
                    sx={{
                      width: '100%',
                      height: '500px',
                      objectFit: 'contain',
                      cursor: 'zoom-in',
                      backgroundColor: 'grey.200',
                    }}
                    onLoad={() => {
                      setLoadingImage(false);
                    }}
                    onClick={() => {
                      setShowZoom(true);
                      if (isSavvy) {
                        setZoomSrc(dataPreviewUrl?.savvy?.aircraft?.borescopeImageSets[0]?.images[0]?.previewUrl || '');
                      } else if (!isOwn) {
                        setZoomSrc(
                          dataSharedPreviewUrl?.sharedAircraftWithUploadBorescope[0]?.aircraft?.borescopeImageSets[0]?.images[0]
                            ?.previewUrl || '',
                        );
                      } else {
                        setZoomSrc(dataOwnPreviewUrl?.me?.aircraft[0]?.borescopeImageSets[0]?.images[0]?.previewUrl || '');
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </ComponentLoading>
          </Box>
        </Box>
        <Box
          sx={{
            borderRadius: '5px',
          }}
        >
          <Typography
            sx={{
              color: 'text.secondary',
              fontWeight: 500,
              fontSize: '18px',
              lineHeight: '25px',
              mb: '20px',
              textAlign: 'center',
            }}
          >
            The previous version images
          </Typography>
          <Grid container spacing={2.5}>
            {previousImages?.map((image: any, key: number) => {
              return (
                <Grid item xs={6} key={key}>
                  <Box
                    sx={{
                      backgroundColor: 'grey.800',
                    }}
                  >
                    <Box py={1.5} px={2.5}>
                      <Typography
                        sx={{
                          color: 'text.disabled',
                        }}
                      >
                        Uploaded {dayjs(image.updated).format('MM/DD/YYYY')}
                      </Typography>
                    </Box>
                    <Box
                      component={'img'}
                      src={image.previewUrl}
                      sx={{
                        width: '100%',
                        height: '500px',
                        objectFit: 'cover',
                        cursor: 'zoom-in',
                      }}
                      onClick={() => {
                        setShowZoom(true);
                        setZoomSrc(image.previewUrl);
                      }}
                    />
                  </Box>
                </Grid>
              );
            })}
          </Grid>
          {!previousImages.length && (
            <Typography align="center" color="warning.main">
              No previous version images
            </Typography>
          )}
        </Box>
      </ComponentLoading>
      <ImageZoomDialog open={showZoom} setOpen={setShowZoom} src={zoomSrc} />
    </Box>
  );
};

export default ViewImage;
