import React, { useState, useEffect } from 'react';
import { useParams, NavLink } from 'react-router-dom';
import { Box, Typography, Grid, Button, Link } from '@mui/material';
import { LayoutDashboard, PageLoading } from 'components';

import { QUERY_USER_ONLY_AIRCRAFTS } from 'gql';
import { useLazyQuery } from '@apollo/client';

import { IAircraftDetail } from 'types';
import { getServiceName } from 'helpers';

import {
  SavvyBreakDownDescription,
  SavvyMxDescription,
  SavvyPrebuyDescription,
  SavvyProDescription,
  SavvyQaDescription,
  SavvyAnalysisDescription,
  SavvyBasicsDescription,
} from './components';
import { PATH_BETA_DASHBOARD } from 'const';

const WelcomePage: React.FC = () => {
  const [fetchAircraft, { data, loading }] = useLazyQuery(QUERY_USER_ONLY_AIRCRAFTS);
  const { service: serviceCode } = useParams();
  const { aircraftId } = useParams();

  const [aircraft, setAircraft] = useState<IAircraftDetail>();

  useEffect(() => {
    if (aircraftId) {
      fetchAircraft({
        variables: {
          id: parseInt(aircraftId),
        },
      });
    }
  }, [aircraftId, fetchAircraft]);

  useEffect(() => {
    if (data?.me?.aircraft?.length) {
      setAircraft(data?.me?.aircraft[0]);
    }
  }, [data]);

  return (
    <LayoutDashboard backgroundColor="#fff">
      {loading && <PageLoading />}
      <Box
        py={2}
        sx={{
          px: {
            xl: 4,
            md: 2,
            xs: 1,
          },
        }}
      >
        <Typography variant="h2">Welcome to {getServiceName(serviceCode || '')}</Typography>
      </Box>
      <Box
        sx={{
          minHeight: '500px',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundImage: {
            md: 'none',
            lg: "url('/images/moved-scaled.jpg')",
          },
        }}
      >
        <Grid container spacing={0}>
          <Grid item lg={5} md={12} xs={12}>
            <Box
              py={2}
              sx={{
                px: {
                  xl: 4,
                  md: 2,
                  xs: 1,
                },
              }}
            >
              {serviceCode === 'mx' && <SavvyMxDescription aircraft={aircraft} />}
              {serviceCode === 'breakdown' && <SavvyBreakDownDescription aircraft={aircraft} />}
              {serviceCode === 'prebuy' && <SavvyPrebuyDescription aircraft={aircraft} />}
              {serviceCode === 'pro' && <SavvyProDescription aircraft={aircraft} />}
              {serviceCode === 'qa' && <SavvyQaDescription aircraft={aircraft} />}
              {serviceCode === 'analysis' && <SavvyAnalysisDescription aircraft={aircraft} />}
              {serviceCode === 'basics' && <SavvyBasicsDescription aircraft={aircraft} />}
              <Box py={2}>
                {new Date().getFullYear() < 2024 && (
                  <Typography fontWeight={700} gutterBottom>
                    {
                      'On January 1, 2024 Savvy is reducing its six current subscription plans to four streamlined offerings to simplify and enhance our service. For some of our services we are also implementing the first price increases in the 15 year history of the company.'
                    }{' '}
                    <Link href="https://drive.google.com/file/d/1DRsgpCPhSYPwf9yh8-C7A1AzfFFKvM2L/view?pli=1" target="_blank">
                      A table summarizing the new prices and service structure can be found here
                    </Link>
                    , as well as a{' '}
                    <Link href="https://docs.google.com/document/d/1ZYsVxMVGCaDjCI9upgsw4i-IRVJ7B-oC1FfY6OItdJE/edit">FAQ</Link>.<br />
                  </Typography>
                )}
                <NavLink to={PATH_BETA_DASHBOARD}>
                  <Button variant="contained">Go to Dashboard</Button>
                </NavLink>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </LayoutDashboard>
  );
};

export default WelcomePage;
