import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box, IconButton, Button, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { DataGridPro, GridValueGetterParams, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-pro';
import dayjs from 'dayjs';

import { QUERY_BORESCOPE_IMAGE_SETS, MUTATION_DELETE_BORESCOPE_IMAGE_SET, QUERY_AIRCRAFT_ENGINES_COUNT } from 'gql';
import { useLazyQuery, useMutation } from '@apollo/client';

import { setAlert } from 'state';

import { ComponentLoading } from 'components';
import { IBorescopeImageSet } from 'types';
import { getEngineNameFromBorescopeLabel, ctrlCmdClick } from 'helpers';

import { UpdateBorescopeImageSetDialog } from '../../components/Dialog';
import { AddBorescopeImageSetDialog } from 'pages/BorescopeUpload/components';
import { DeleteDialog } from 'components';
import { BORESCOPE_ANALYSIS_TICKET_URL_QUERY, PATH_BORESCOPE_UPLOAD } from 'const';

interface BorescopeImageSetsProps {
  aircraftId: string;
  isLoad: boolean;
  cylinderCount: number;
}

const BorescopeImageSets: React.FC<BorescopeImageSetsProps> = (props) => {
  const { aircraftId, isLoad, cylinderCount } = props;

  const [borescopeImageSets, setBorescopeImageSets] = useState<IBorescopeImageSet[]>([]);

  const [fetchImageSets, { data: dataSets, loading: loadingSets, error: errorSets }] = useLazyQuery(QUERY_BORESCOPE_IMAGE_SETS);
  const [fetchEnginesCount, { data: dataEnginesCount, loading: loadingEnginesCount }] = useLazyQuery(QUERY_AIRCRAFT_ENGINES_COUNT);
  const [deleteImageSet, { data: dataDeleteSet, loading: loadingDeleteSet, error: errorDeleteSet }] =
    useMutation(MUTATION_DELETE_BORESCOPE_IMAGE_SET);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showAddModal, setShowAddModal] = useState(false);
  const [success, setSuccess] = useState(false);

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedBorescopeImageSet, setSelectedBorescopeImageSet] = useState<IBorescopeImageSet>();

  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [deleteName, setDeleteName] = useState('');

  const [pageSize, setPageSize] = useState(5);
  const [page, setPage] = useState(0);

  const deleteBorescopeImageSet = async () => {
    if (!deleteId) {
      return;
    }
    await deleteImageSet({
      variables: {
        borescopeImageSetId: deleteId,
      },
    });
  };

  useEffect(() => {
    if (isLoad && aircraftId) {
      fetchImageSets({
        variables: {
          aircraftId: parseInt(aircraftId),
        },
        fetchPolicy: 'cache-and-network',
      });
      fetchEnginesCount({
        variables: {
          aircraftId: parseInt(aircraftId),
        },
      });
    }
  }, [isLoad, aircraftId, fetchImageSets]);

  useEffect(() => {
    if (errorDeleteSet) {
      dispatch(setAlert('error', 'Unable to delete selected borescope image set'));
    } else if (dataDeleteSet) {
      const { ok, error } = dataDeleteSet.deleteBorescopeImageSet;
      if (ok) {
        dispatch(setAlert('success', 'Deleted borescope image set'));
        setSuccess(true);
      } else {
        dispatch(setAlert('error', error));
      }
    }
  }, [dispatch, errorDeleteSet, dataDeleteSet]);

  useEffect(() => {
    if (errorSets) {
      dispatch(setAlert('error', 'Unable to load borescope image sets.'));
    }
  }, [dispatch, errorSets]);

  useEffect(() => {
    if (dataSets?.me?.aircraft[0]?.borescopeImageSets) {
      setBorescopeImageSets([...dataSets.me.aircraft[0].borescopeImageSets]);
    }
  }, [dataSets]);

  useEffect(() => {
    if (success) {
      fetchImageSets({
        variables: {
          aircraftId: parseInt(aircraftId),
        },
        fetchPolicy: 'network-only',
      });
    }
  }, [success]);

  return (
    <Box
      sx={{
        p: {
          xs: 2,
          sm: 3,
          md: 4,
          lg: 5,
        },
      }}
    >
      <ComponentLoading loading={loadingSets || loadingEnginesCount || loadingDeleteSet}>
        <Box py={1}>
          <Button
            variant="contained"
            onClick={() => {
              setSuccess(false);
              setShowAddModal(true);
            }}
          >
            + Add Borescope Image Set
          </Button>
        </Box>
        <Box
          py={1}
          sx={{
            width: '100%',
          }}
        >
          {isLoad && (
            <DataGridPro
              sx={{
                width: '100%',
                minWidth: '100px',
                '& .MuiDataGrid-columnSeparator': {
                  display: 'none',
                },
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-row': {
                  cursor: 'pointer',
                  borderBottom: '1px solid',
                  borderColor: 'grey.200',
                },
                '& .MuiDataGrid-columnHeader:focus': {
                  outline: 'none',
                },
                border: 'none',
              }}
              autoHeight
              disableColumnResize={true}
              disableSelectionOnClick={true}
              rowsPerPageOptions={[5, 10, 20, 100]}
              pageSize={pageSize}
              onPageSizeChange={(newPage) => setPageSize(newPage)}
              pagination
              showColumnRightBorder={false}
              disableColumnMenu={true}
              page={page}
              onPageChange={(newPage) => setPage(newPage)}
              rows={
                borescopeImageSets
                  ? [...borescopeImageSets].sort((item1: IBorescopeImageSet, item2: IBorescopeImageSet) =>
                      item1.created > item2.created ? -1 : 1,
                    )
                  : []
              }
              columnVisibilityModel={{
                engine: dataEnginesCount?.me?.aircraft[0]?.aircraftModel?.enginesCount !== 'SINGLE',
              }}
              columns={[
                {
                  field: 'name',
                  headerName: 'Name',
                  flex: 1,
                },
                {
                  field: 'created',
                  headerName: 'Created',
                  valueGetter: (params: GridValueGetterParams) => {
                    return params.value ? dayjs(params.value).format('YYYY-MM-DD') : '';
                  },
                  flex: 1,
                },
                {
                  field: 'engine',
                  headerName: 'Engine',
                  valueGetter: (params: GridValueGetterParams) => {
                    return params.value ? getEngineNameFromBorescopeLabel(params.value) : '';
                  },
                  flex: 1,
                },
                {
                  field: 'status',
                  headerName: 'Status',
                  renderCell: (params: GridRenderCellParams<string>) => {
                    const status = params.value;
                    return (
                      <Box
                        sx={{
                          p: '5px 10px',
                          borderRadius: '6px',
                          backgroundColor: status === 'IN_PROGRESS' ? '#F7E8D3' : status === 'REPORT_SENT' ? '#F5F6FA' : '#D1F2D8',
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 700,
                            color: status === 'IN_PROGRESS' ? '#D68E21' : status === 'REPORT_SENT' ? 'text.primary' : '#4C8F5B',
                          }}
                        >
                          {status?.replace('_', ' ')}
                        </Typography>
                      </Box>
                    );
                  },
                  flex: 1,
                },
                {
                  field: 'id',
                  headerName: 'Action',
                  renderCell: (params: GridRenderCellParams<string>) => {
                    return (
                      <Box>
                        <IconButton
                          onClick={() => {
                            const selectedImageSet = borescopeImageSets.find((item) => item.id === params.value);
                            if (selectedImageSet) {
                              setShowUpdateModal(true);
                              setSelectedBorescopeImageSet(selectedImageSet);
                            }
                          }}
                          sx={{
                            mr: 1,
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          onClick={(e: any) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setDeleteId(params.value || '');
                            setDeleteName(params.row?.name || '');
                            setShowDelete(true);
                            setSuccess(false);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    );
                  },
                  flex: 0.5,
                },
              ]}
              onRowClick={(params: GridRowParams, e) => {
                if (params.row?.status === 'REPORT_SENT') {
                  dispatch(
                    setAlert(
                      'warning',
                      'A report for this image set has already been created.  To request additional analysis, please create a new image set.',
                    ),
                  );
                  return;
                }
                ctrlCmdClick(
                  e,
                  `${PATH_BORESCOPE_UPLOAD}/${aircraftId}?${BORESCOPE_ANALYSIS_TICKET_URL_QUERY}=${params.row?.id}`,
                  navigate,
                );
              }}
            />
          )}
        </Box>
      </ComponentLoading>
      <AddBorescopeImageSetDialog
        open={showAddModal}
        setOpen={setShowAddModal}
        setSuccess={setSuccess}
        aircraftId={aircraftId}
        isSingle={dataEnginesCount?.me?.aircraft[0]?.aircraftModel?.enginesCount === 'SINGLE'}
        cylinderCount={cylinderCount}
      />
      {selectedBorescopeImageSet && (
        <UpdateBorescopeImageSetDialog
          open={showUpdateModal}
          setOpen={setShowUpdateModal}
          selectedImageSet={selectedBorescopeImageSet}
          isSingle={dataEnginesCount?.me?.aircraft[0]?.aircraftModel?.enginesCount === 'SINGLE'}
        />
      )}
      <DeleteDialog
        open={showDelete}
        setOpen={setShowDelete}
        onSubmitAction={deleteBorescopeImageSet}
        title={`Delete "${deleteName}"?`}
        text="Are you sure?"
      />
    </Box>
  );
};

export default BorescopeImageSets;
