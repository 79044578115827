import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { MUTATION_VERIFY_EMAIL } from 'gql';
import { useMutation } from '@apollo/client';

import { LayoutLoggedOut, PageLoading } from 'components';

import { PATH_BETA_DASHBOARD, VERIFY_EMAIL_SUCCESS } from 'const';
import { setAlert } from 'state';
import { AUTH_LOG_IN } from 'state/types';

const VerifyEmail: React.FC = () => {
  const { uuid } = useParams();

  const [VerifyEmail, { data, loading, error }] = useMutation(MUTATION_VERIFY_EMAIL);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (uuid) {
      VerifyEmail({
        variables: {
          verificationCode: uuid,
        },
      });
    }
  }, [uuid, VerifyEmail]);

  useEffect(() => {
    if (error) {
      dispatch(setAlert('error', 'Unable to verify email. Please try the process again. You may be clicking on an older link.'));
    } else if (data) {
      const { ok, me, error } = data.verifyEmail;

      if (ok) {
        dispatch(setAlert('success', VERIFY_EMAIL_SUCCESS));
        dispatch({
          type: AUTH_LOG_IN,
          payload: {
            ...me,
          },
        });
        navigate(PATH_BETA_DASHBOARD);
      } else {
        dispatch(setAlert('error', error || 'Unable to verify email. Please try the process again. You may be clicking on an older link.'));
      }
    }
  }, [error, data, dispatch, navigate]);

  return <LayoutLoggedOut>{loading && <PageLoading />}</LayoutLoggedOut>;
};

export default VerifyEmail;
