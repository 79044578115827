import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Tooltip, Link } from '@mui/material';
import { ComponentLoading } from 'components';

import { QUERY_SAVVY_AIRCRAFT_SEARCH } from 'gql';
import { useLazyQuery } from '@apollo/client';
import { SAVVY_SEARCH_LIMIT, PATH_SAVVY_AIRCRAFT, PATH_SAVVY_SEARCH_AIRCRAFT } from 'const';
import { aircraftRegistrationMakeModel, ctrlCmdClick } from 'helpers';

import { IAircraft } from 'types';

interface AircraftSearchProps {
  searchQuery: string;
}

const AircraftSearch: React.FC<AircraftSearchProps> = (props) => {
  const { searchQuery } = props;

  const navigate = useNavigate();

  const [fetchSavvyAircraftSearch, { data: dataAircraft, loading: loadingAircraft }] = useLazyQuery(QUERY_SAVVY_AIRCRAFT_SEARCH);

  const [aircraftList, setAircraftList] = useState<IAircraft[]>([]);

  const [count, setCount] = useState(0);

  const loadMore = () => {
    navigate(`${PATH_SAVVY_SEARCH_AIRCRAFT}?query=${searchQuery}`);
  };

  useEffect(() => {
    if (!searchQuery || searchQuery.length < 2) {
      return;
    }

    setAircraftList([]);

    const timeout: NodeJS.Timeout = setTimeout(() => {
      fetchSavvyAircraftSearch({
        variables: {
          search: searchQuery,
          limit: SAVVY_SEARCH_LIMIT,
          offset: 0,
        },
      });
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [searchQuery, fetchSavvyAircraftSearch]);

  useEffect(() => {
    if (dataAircraft?.savvy?.search?.aircrafts?.results?.length) {
      setAircraftList((aircraftList: IAircraft[]) => {
        const newArray: IAircraft[] = aircraftList.concat(dataAircraft.savvy?.search?.aircrafts?.results);
        return [...newArray];
      });
    }

    if (dataAircraft?.savvy?.search?.aircrafts) {
      setCount(dataAircraft.savvy?.search?.aircrafts?.count);
    }
  }, [dataAircraft]);

  return (
    <Box>
      <ComponentLoading loading={loadingAircraft}>
        <Box>
          {!!searchQuery && (
            <Typography
              variant="h5"
              sx={{
                fontWeight: 'bold',
                py: 1,
              }}
            >
              Aircraft {count ? `(${count})` : ''}
            </Typography>
          )}
          <Box pl={4}>
            {dataAircraft?.savvy?.search?.aircrafts?.results?.map((aircraft: any, key: number) => {
              return (
                <Box
                  key={key}
                  py={1}
                  sx={{
                    cursor: 'pointer',
                  }}
                  onClick={(event: any) => {
                    if (aircraft.owner?.id) {
                      ctrlCmdClick(event, `${PATH_SAVVY_AIRCRAFT}/${aircraft.id}`, navigate);
                    }
                  }}
                >
                  <Tooltip
                    title={
                      <Typography
                        sx={{
                          fontSize: '16px',
                          lineHeight: '24px',
                        }}
                      >
                        {aircraft.owner?.email || 'No owner'}
                      </Typography>
                    }
                    componentsProps={{
                      tooltip: {
                        sx: {
                          backgroundColor: '#000',
                          color: 'background.default',
                        },
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        textDecoration: aircraft.hidden ? 'line-through' : 'none',
                      }}
                    >
                      {aircraftRegistrationMakeModel(aircraft)}
                      {aircraft.owner?.firstName ? `  (${aircraft.owner.firstName} ${aircraft.owner.lastName})` : ''}
                    </Typography>
                  </Tooltip>
                </Box>
              );
            })}
            {!aircraftList.length && !!searchQuery && !loadingAircraft && <Box py={1}>No matched aircraft</Box>}
          </Box>
          {count > aircraftList.length && (
            <Link
              href="#"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                loadMore();
              }}
            >
              Show More
            </Link>
          )}
        </Box>
      </ComponentLoading>
    </Box>
  );
};

export default AircraftSearch;
