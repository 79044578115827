import { Navigate } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { PageLoading } from 'components';
import { PATH_BETA_DASHBOARD } from 'const';

interface IRedirectLoggedIn {
  element: JSX.Element;
  path?: string;
  exact?: boolean;
}

const RedirectLoggedIn = (props: IRedirectLoggedIn) => {
  const { loggedIn } = useSelector((state: any) => state.auth); // Get auth state (Redux)

  // If loading
  if (loggedIn === null) return <PageLoading />;

  // Already logged in
  if (loggedIn !== null && loggedIn) {
    // Redirect to root and selector will do the right thing from there.
    return <Navigate to={PATH_BETA_DASHBOARD} />;
  }

  // If logged OUT - proceed to page
  return props.element;
};

export default RedirectLoggedIn;
