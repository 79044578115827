import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import { QUERY_SHARE_FLIGHTS_BY_CONTACT } from 'gql';
import { useLazyQuery } from '@apollo/client';

import { PageLoading, CustomGridToolbar } from 'components';
import { aircraftRegistrationMakeModel, ctrlCmdClick } from 'helpers';

import { PATH_SHARED_FLIGHT_VIEW } from 'const';

import { DataGridPro, GridRowParams, GridValueGetterParams } from '@mui/x-data-grid-pro';

const SharedFlightsByContact: React.FC = () => {
  const { uuid: contactUuid } = useParams();

  const [fetchSharedFlights, { data: dataFlights, loading: loadingFlights }] = useLazyQuery(QUERY_SHARE_FLIGHTS_BY_CONTACT);
  const navigate = useNavigate();

  const [pageSize, setPageSize] = useState(20);
  const [filterButtonEl, setFilterButtonEl] = useState<HTMLButtonElement | null>(null);

  const sortList = (data: undefined | any[]) => {
    if (!data) {
      return [];
    }

    return [...data].sort((item1: any, item2: any) => (item1.date > item2.date ? -1 : 1));
  };

  useEffect(() => {
    if (contactUuid) {
      fetchSharedFlights({
        variables: {
          contactUuid,
        },
      });
    }
  }, [contactUuid, fetchSharedFlights]);

  return (
    <Box>
      {loadingFlights && <PageLoading />}
      <Box p={4}>
        <Typography variant="h2" align="center" gutterBottom>
          Shared Flights
        </Typography>
        <Box>
          <DataGridPro
            sx={{
              width: '100%',
              minWidth: '100px',
              '& .MuiDataGrid-columnSeparator': {
                display: 'none',
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
              '& .MuiDataGrid-row': {
                cursor: 'pointer',
                borderBottom: '1px solid',
                borderColor: 'grey.200',
              },
              '& .MuiDataGrid-columnHeader:focus': {
                outline: 'none',
              },
              '& .MuiDataGrid-cellContent': {
                whiteSpace: 'break-spaces',
              },
              '& .MuiDataGrid-toolbarContainer .MuiTextField-root': {
                mb: 0,
              },
              border: 'none',
            }}
            autoHeight
            disableColumnResize={true}
            rowsPerPageOptions={[5, 10, 20, 100]}
            pageSize={pageSize}
            onPageSizeChange={(newPage) => setPageSize(newPage)}
            pagination
            showColumnRightBorder={false}
            disableColumnSelector
            disableColumnMenu
            disableSelectionOnClick
            rows={sortList(dataFlights?.shareFlightsByContact)}
            columns={[
              {
                field: 'aircraft',
                headerName: 'Aircraft',
                valueGetter: (params: GridValueGetterParams) => {
                  return params.value ? aircraftRegistrationMakeModel(params.value) : '';
                },
                flex: 1,
              },
              {
                field: 'date',
                headerName: 'Date',
                valueGetter: (params: GridValueGetterParams) => {
                  return params.value ? params.value.toString().substring(0, 10) : '';
                },
                flex: 0.7,
              },
              {
                field: 'departureAirport',
                headerName: 'Departure Airport',
                flex: 1,
              },
              {
                field: 'destinationAirport',
                headerName: 'Destination Airport',
                flex: 1,
              },
              {
                field: 'duration',
                headerName: 'Duration',
                valueGetter: (params: GridValueGetterParams) => {
                  if (!params?.value) return '';
                  const hour = Math.floor(params.value / 3600);
                  const min = Math.floor((params.value % 3600) / 60);
                  return `${hour}h ${(min < 10 ? '0' : '') + min}m`;
                },
                flex: 0.7,
              },
            ]}
            onRowClick={(params: GridRowParams, event?: any) => {
              const { row } = params;
              if (row.publicUuid) {
                ctrlCmdClick(event, `${PATH_SHARED_FLIGHT_VIEW}/${row.id}/${row.publicUuid}`, navigate);
              } else {
                return false;
              }
            }}
            components={{ Toolbar: CustomGridToolbar }}
            componentsProps={{
              toolbar: {
                setFilterButtonEl,
              },
              filterPanel: {
                sx: {
                  '& .MuiDataGrid-filterForm': {
                    '& .MuiTextField-root': {
                      mb: 0,
                    },
                  },
                },
              },
              panel: {
                anchorEl: filterButtonEl,
                placement: 'bottom-end',
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default SharedFlightsByContact;
