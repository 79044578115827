import React, { useState, useEffect, useCallback } from 'react';
import { Box, Button, TextField, Grid, Slide, Hidden, CircularProgress, Autocomplete, Typography, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { useDispatch } from 'react-redux';
import { setAlert } from 'state';
import { IServiceCenter } from 'types';

import { useLazyQuery, useQuery } from '@apollo/client';
import {
  QUERY_ALL_SERVICES,
  QUERY_SERVICE_CENTER_CLASSIFICATIONS,
  QUERY_SERVICE_CENTER_DETAIL,
  QUERY_SAVVY_SERVICE_CENTER_SEARCH,
  QUERY_AIRPORT_COORDINATES,
} from 'gql';

import { default as ContainedPoints } from './ContainedPoints';
import { default as ServiceCenterInfo } from './ServiceCenterInfo';
import { default as Map } from './Map';
import { IClassification } from 'types';
import { ComponentLoading } from 'components';
import { useLgDown } from 'hooks';
import { getContactAddress, copyClipboardTextArea } from 'helpers';
import { SAVVY_SEARCH_LIMIT } from 'const';

const ServiceCenterMap: React.FC = () => {
  const dispatch = useDispatch();

  const [selectedColor, setSelectedColor] = useState('all');

  const [fetchAllService, { data: dataAllServices, error: errorAllServices, loading: loadingAllServices }] =
    useLazyQuery(QUERY_ALL_SERVICES);

  const [fetchServiceCenter, { data: dataServiceCenter, loading: loadingServiceCenter }] = useLazyQuery(QUERY_SERVICE_CENTER_DETAIL);

  const [fetchServiceCenterSearch, { data: dataSearch, loading: loadingSearch }] = useLazyQuery(QUERY_SAVVY_SERVICE_CENTER_SEARCH);
  const [searchText, setSearchText] = useState('');
  const [searchedServiceCenter, setSearchedServiceCenter] = useState<IServiceCenter>();
  const [searchLoading, setSearchLoading] = useState(false);

  const [fetchAirport, { data: dataAirport, loading: loadingAirport, error: errorAirport }] = useLazyQuery(QUERY_AIRPORT_COORDINATES);
  const [airportId, setAirportId] = useState('');

  const [shortList, setShortList] = useState<IServiceCenter[]>([]);
  const [copiedShortList, setCopiedShortList] = useState(false);

  const [slideNum, setSlideNum] = useState(1);
  const isLgDown = useLgDown();

  const WARNING_MESSAGE = 'You have short list not copied';

  useEffect(() => {
    fetchAllService({
      variables: {
        mapView: true,
      },
    });
  }, [fetchAllService]);

  const {
    data: dataClassifications,
    loading: loadingClassfications,
    error: errorClassifications,
  } = useQuery(QUERY_SERVICE_CENTER_CLASSIFICATIONS);

  const [classificationFilter, setClassificationFilter] = useState<IClassification[]>([]);

  const goToAirport = async (airportId: string) => {
    await fetchAirport({
      variables: {
        airportId,
      },
    });
  };

  useEffect(() => {
    if (errorClassifications) {
      dispatch(setAlert('error', 'Failed to load service center classifications'));
    }
  }, [loadingClassfications, errorClassifications, dispatch]);

  useEffect(() => {
    if (errorAllServices) dispatch(setAlert('error', 'Failed to load service centers'));
  }, [errorAllServices, dispatch]);

  const colorButtons = [
    {
      id: 'all',
      label: 'All Service Centers',
    },
    {
      id: 'redyellowgreen',
      label: 'Red, Yellow, Green',
    },
    {
      id: 'yellowgreen',
      label: 'Yellow, Green',
    },
    {
      id: 'green',
      label: 'Green',
    },
  ];

  const handleClickPoint = async (serviceCenterId: string) => {
    await fetchServiceCenter({
      variables: {
        serviceCenterId,
      },
    });
    if (isLgDown) {
      setSlideNum(2);
    }
  };

  const searchAirport = async () => {
    if (airportId) {
      await fetchAirport({
        variables: {
          airportId,
        },
      });
    }
  };

  useEffect(() => {
    if (classificationFilter.length) {
      const idArray = classificationFilter.map((item: IClassification) => {
        return item.id;
      });

      fetchAllService({
        variables: {
          classifications: idArray,
          mapView: true,
        },
      });
    } else {
      fetchAllService({
        variables: {
          mapView: true,
        },
      });
    }
  }, [classificationFilter, fetchAllService]);

  const [containedPoints, setContainedPoints] = useState<IServiceCenter[]>([]);

  const addShortList = (item: IServiceCenter) => {
    const filterSc = shortList.filter((list: IServiceCenter) => list.id === item.id);
    if (filterSc.length) {
      return;
    }

    shortList.push(item);
    setShortList([...shortList]);
    setCopiedShortList(false);
  };

  const removeShortList = (index: number) => {
    shortList.splice(index, 1);
    setShortList([...shortList]);
  };

  const copyShortList = () => {
    if (!shortList.length) return;
    const shortListArray = shortList.map((item: IServiceCenter) => {
      return (
        item.company +
        '\n' +
        (item.street ? item.street + '\n' : '') +
        (item.city ? `${item.city}, ${item.state}, ${item.zip}\n` : '') +
        (item.country && item.country !== 'US' ? item.country + '\n' : '') +
        (item.airportId ? 'Airport: ' + item.airportId + '\n' : '') +
        item.phoneWork
      );
    });
    copyClipboardTextArea(shortListArray.join('\n\n'));
    dispatch(setAlert('success', 'Copied short list'));
    setCopiedShortList(true);
  };

  const renderClassificationSearchComponent = () => {
    return (
      <Box
        pt={1}
        pb={2}
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: '100%',
          }}
        >
          <Autocomplete
            options={dataClassifications.serviceCenterClassifications}
            getOptionLabel={(option: IClassification) => option?.name}
            renderInput={(param) => {
              return (
                <TextField
                  {...param}
                  label="Classification Name"
                  variant="outlined"
                  sx={{
                    mb: 0,
                  }}
                />
              );
            }}
            groupBy={(option: IClassification) => option.category}
            multiple
            value={classificationFilter}
            onChange={(e: any, value: any) => {
              setClassificationFilter([...value]);
            }}
          />
        </Box>
      </Box>
    );
  };

  const renderSearchComponent = () => {
    return (
      <Box
        pt={1}
        pb={2}
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: '100%',
          }}
        >
          <Autocomplete
            options={dataSearch?.savvy?.search?.serviceCenters?.results ? dataSearch?.savvy?.search?.serviceCenters?.results : []}
            getOptionLabel={(option: IServiceCenter) =>
              option?.id ? `${option.company} ${getContactAddress(option) ? '(' + getContactAddress(option) + ')' : ''}` : ''
            }
            value={searchedServiceCenter ? searchedServiceCenter : undefined}
            onChange={(event: any, value: any) => {
              if (value && value.id) {
                setSearchedServiceCenter(value);
              }
            }}
            loading={searchLoading}
            renderInput={(params) => (
              <TextField
                {...params}
                label={'Service Center Name'}
                placeholder="Type service center"
                value={searchText}
                onChange={(e: any) => {
                  setSearchText(e.target.value);
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {searchLoading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
                sx={{
                  mb: 0,
                }}
                fullWidth
              />
            )}
            renderOption={(props: any, option: IServiceCenter) => {
              return option?.id ? (
                <li {...props} key={option.id}>{`${option.company} ${
                  getContactAddress(option) ? '(' + getContactAddress(option) + ')' : ''
                }`}</li>
              ) : null;
            }}
            disableClearable={true}
          />
        </Box>
      </Box>
    );
  };

  const renderSearchAirport = () => {
    return (
      <Box
        pt={1}
        pb={2}
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: '100%',
          }}
        >
          <TextField
            label="Airport ID"
            placeholder="Type airport ID and Enter"
            value={airportId}
            onChange={(e: any) => {
              setAirportId(e.target.value);
            }}
            onKeyDown={(e: any) => {
              if (e.keyCode === 13) {
                searchAirport();
              }
            }}
            InputProps={{
              endAdornment: <React.Fragment>{loadingAirport ? <CircularProgress color="inherit" size={20} /> : null}</React.Fragment>,
            }}
            fullWidth
          />
        </Box>
      </Box>
    );
  };

  const renderShortList = () => {
    return (
      <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h5">Copy shortlist to clipboard</Typography>
          <IconButton onClick={copyShortList}>
            <ContentCopyIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            maxHeight: '200px',
            overflowY: 'auto',
          }}
        >
          {shortList.map((item: IServiceCenter, key: number) => {
            return (
              <Box
                key={key}
                sx={{
                  py: 1,
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  borderBottom: '1px solid',
                  borderColor: 'grey.200',
                }}
              >
                <Typography>
                  {item.company}
                  {getContactAddress(item) ? `, ${getContactAddress(item)}` : ''}
                  {item.phoneWork ? `, ${item.phoneWork}` : ''}
                </Typography>
                <IconButton
                  onClick={() => {
                    removeShortList(key);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            );
          })}
        </Box>
      </Box>
    );
  };

  const alertUser = useCallback(
    (event: any) => {
      if (!copiedShortList && !!shortList.length) {
        event.preventDefault();
        (event || window.event).returnValue = WARNING_MESSAGE;
        return WARNING_MESSAGE;
      } else {
        return undefined;
      }
    },
    [copiedShortList, shortList],
  );

  useEffect(() => {
    if (!searchText || searchText?.length < 2) {
      setSearchLoading(false);
      return;
    }

    setSearchLoading(true);
    const timeout: NodeJS.Timeout = setTimeout(() => {
      fetchServiceCenterSearch({
        variables: {
          search: searchText,
          limit: SAVVY_SEARCH_LIMIT,
          offset: 0,
        },
      });
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [searchText, fetchServiceCenterSearch]);

  useEffect(() => {
    if (!loadingSearch && dataSearch?.savvy?.search) {
      setSearchLoading(false);
    }
  }, [dataSearch, loadingSearch]);

  useEffect(() => {
    if (errorAirport) {
      dispatch(setAlert('error', 'Unable to find airport id'));
    }
  }, [errorAirport, dispatch]);

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, [alertUser]);

  return (
    <Box>
      <ComponentLoading loading={loadingAllServices}>
        <Hidden lgDown>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Box>
                {colorButtons.map((colorButton, key) => {
                  return (
                    <Button
                      variant="contained"
                      onClick={() => {
                        setSelectedColor(colorButton.id);
                      }}
                      color={selectedColor === colorButton.id ? 'primary' : 'inherit'}
                      sx={{
                        mr: '10px',
                        mb: {
                          xs: '10px',
                        },
                      }}
                      key={key}
                    >
                      {colorButton.label}
                    </Button>
                  );
                })}
              </Box>
              {dataClassifications && renderClassificationSearchComponent()}
              {renderSearchComponent()}
              {renderSearchAirport()}
            </Grid>
            <Grid item md={6}>
              {renderShortList()}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item lg={6} md={12} xs={12}>
              <Map
                serviceCenters={dataAllServices?.serviceCenters ? dataAllServices?.serviceCenters : []}
                setContainedPoints={setContainedPoints}
                handleClickPoint={handleClickPoint}
                selectedColor={selectedColor}
                searchedCenter={searchedServiceCenter}
                selectedSC={dataServiceCenter?.serviceCenters[0] ? dataServiceCenter?.serviceCenters[0] : undefined}
                airportCoordinates={dataAirport?.airportCoordinates ? dataAirport?.airportCoordinates : undefined}
                goToAirport={goToAirport}
              />
            </Grid>
            <Grid item lg={3} md={12} xs={12}>
              <ContainedPoints
                points={containedPoints}
                handleClickPoint={handleClickPoint}
                selectedSC={dataServiceCenter?.serviceCenters[0] ? dataServiceCenter?.serviceCenters[0] : undefined}
                selectedColor={selectedColor}
              />
            </Grid>
            <Grid item lg={3} md={12}>
              <ServiceCenterInfo
                selectedSC={dataServiceCenter?.serviceCenters[0] ? dataServiceCenter?.serviceCenters[0] : undefined}
                loading={loadingServiceCenter}
                addShortList={addShortList}
              />
            </Grid>
          </Grid>
        </Hidden>
        <Hidden lgUp>
          <Slide
            direction="left"
            in={slideNum === 1}
            timeout={{
              exit: 0,
              enter: 300,
            }}
          >
            <Box
              sx={{
                display: slideNum === 1 ? 'block' : 'none',
              }}
            >
              <Box>
                {colorButtons.map((colorButton, key) => {
                  return (
                    <Button
                      variant="contained"
                      onClick={() => {
                        setSelectedColor(colorButton.id);
                      }}
                      color={selectedColor === colorButton.id ? 'primary' : 'inherit'}
                      sx={{
                        mr: '10px',
                        mb: {
                          xs: '10px',
                        },
                      }}
                      key={key}
                    >
                      {colorButton.label}
                    </Button>
                  );
                })}
              </Box>
              {dataClassifications && renderClassificationSearchComponent()}
              {renderSearchComponent()}
              {renderSearchAirport()}
              <Box py={1}>{renderShortList()}</Box>
              <Grid container spacing={2}>
                <Grid item lg={6} md={12} xs={12}>
                  <Map
                    serviceCenters={dataAllServices?.serviceCenters ? dataAllServices?.serviceCenters : []}
                    setContainedPoints={setContainedPoints}
                    handleClickPoint={handleClickPoint}
                    selectedColor={selectedColor}
                    searchedCenter={searchedServiceCenter}
                    selectedSC={dataServiceCenter?.serviceCenters[0] ? dataServiceCenter?.serviceCenters[0] : undefined}
                    airportCoordinates={dataAirport?.airportCoordinates ? dataAirport?.airportCoordinates : undefined}
                    goToAirport={goToAirport}
                  />
                </Grid>
                <Grid item lg={3} md={12} xs={12}>
                  <ContainedPoints
                    points={containedPoints}
                    handleClickPoint={handleClickPoint}
                    selectedSC={dataServiceCenter?.serviceCenters[0] ? dataServiceCenter?.serviceCenters[0] : undefined}
                    selectedColor={selectedColor}
                  />
                </Grid>
              </Grid>
            </Box>
          </Slide>
          <Slide
            direction="left"
            in={slideNum === 2}
            unmountOnExit
            timeout={{
              exit: 0,
              enter: 300,
            }}
          >
            <Box>
              <ServiceCenterInfo
                selectedSC={dataServiceCenter?.serviceCenters[0] ? dataServiceCenter?.serviceCenters[0] : undefined}
                loading={loadingServiceCenter}
                setSlideNum={setSlideNum}
                addShortList={addShortList}
              />
            </Box>
          </Slide>
        </Hidden>
      </ComponentLoading>
    </Box>
  );
};

export default ServiceCenterMap;
