import React, { useEffect } from 'react';

import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';

import { LayoutSignupService } from 'components';

import { SignupServiceForm } from './components';

import { SET_PRICE_FOR_SERVICE } from 'state/types';

import { PATH_NOT_FOUND } from 'const';

import { useService } from 'hooks';

import { turnService, isFeatureService } from 'helpers';

const SignupService: React.FC = () => {
  const service = useService(); // Get service by id from url

  const [price, setPrice] = React.useState(0);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const setServicePlan = (value: number) => {
    setPrice(value);
  };

  useEffect(() => {
    dispatch({
      type: SET_PRICE_FOR_SERVICE,
      payload: {
        price: price,
      },
    });
  }, [price, dispatch]);

  useEffect(() => {
    if (service && isFeatureService(service?.id)) {
      navigate(location.pathname.replace(service?.id, turnService(service?.id)));
    }
  }, [location, service]);

  // If Service Not Found in Services array
  if (service === null) return <Navigate to={PATH_NOT_FOUND} />;

  return (
    <React.Fragment>
      <LayoutSignupService
        service={service}
        form={<SignupServiceForm setServicePlan={setServicePlan} />}
        activeStep={0}
        servicePlan={price}
      />
    </React.Fragment>
  );
};

export default SignupService;
