import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import DownloadIcon from '@mui/icons-material/Download';
import { DataGridPro, GridValueGetterParams, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-pro';

import {
  MUTATION_DELETE_ENGINE_DATA_FILE,
  QUERY_GET_ENGINE_DATA_FILES_FOR_AIRCRAFT,
  QUERY_ME_AIRCRAFT_ENGINE_DATA_FILE_DOWNLOAD,
} from 'gql';
import { useMutation, useLazyQuery } from '@apollo/client';

import { setAlert } from 'state';

import { ComponentLoading } from 'components';
import { ViewLogModal } from '../../components/Dialog';
import { PATH_FLIGHTS } from 'const';
import { ctrlCmdClick, downloadFileInNewTab } from 'helpers';
import { apolloClient } from 'services';

interface FilesProps {
  aircraftId: string;
  isLoad: boolean;
}

const Files: React.FC<FilesProps> = (props) => {
  const { aircraftId, isLoad } = props;

  const [engineDataFiles, setEngineDataFiles] = useState<
    {
      id: string;
      name: string;
      uploadDate: Date;
      flightsCount: number;
      parseLogs: string;
    }[]
  >([]);

  const [fetchFiles, { data: dataFiles, loading: loadingFiles, error: errorFiles }] = useLazyQuery(
    QUERY_GET_ENGINE_DATA_FILES_FOR_AIRCRAFT,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  const [deleteFile, { data: dataDeleteFile, loading: loadingDeleteFile, error: errorDeleteFile }] = useMutation(
    MUTATION_DELETE_ENGINE_DATA_FILE,
    {
      errorPolicy: 'all',
    },
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [fileId, setFileId] = useState('');
  const [showLogModal, setShowLogModal] = useState(false);

  const [pageSize, setPageSize] = useState(5);
  const [page, setPage] = useState(0);

  const [loadingDownloadFile, setLoadingDownloadFile] = useState(false);

  const deleteFileAction = async (id: string) => {
    await deleteFile({
      variables: {
        fileId: id,
      },
    });

    const tmp = engineDataFiles.filter((engineDataFile) => {
      return engineDataFile.id !== id;
    });

    setEngineDataFiles([...tmp]);
  };

  const downloadFile = async (engineDataFileId: string) => {
    setLoadingDownloadFile(true);
    const { data: dataDownloadUrl } = await apolloClient.query({
      query: QUERY_ME_AIRCRAFT_ENGINE_DATA_FILE_DOWNLOAD,
      variables: {
        id: parseInt(aircraftId),
        engineDataFileId,
      },
      fetchPolicy: 'no-cache',
    });

    setLoadingDownloadFile(false);

    if (dataDownloadUrl) {
      if (dataDownloadUrl?.me?.aircraft[0]?.engineDataFiles[0]?.resultFileS3Url) {
        downloadFileInNewTab(dataDownloadUrl.me.aircraft[0].engineDataFiles[0].resultFileS3Url);
      }
    }
  };

  useEffect(() => {
    if (isLoad && aircraftId) {
      fetchFiles({
        variables: {
          id: parseInt(aircraftId),
        },
      });
    }
  }, [isLoad, aircraftId, fetchFiles]);

  useEffect(() => {
    if (errorDeleteFile) {
      dispatch(setAlert('error', 'Unable to delete selected engine data file.'));
    } else if (dataDeleteFile) {
      const { ok, error } = dataDeleteFile.deleteEngineDataFile;
      if (ok) {
        dispatch(setAlert('success', 'Deleted engine data file'));
        fetchFiles({
          variables: {
            id: parseInt(aircraftId),
          },
        });
      } else {
        dispatch(setAlert('error', error));
      }
    }
  }, [dispatch, errorDeleteFile, dataDeleteFile]);

  useEffect(() => {
    if (errorFiles) {
      dispatch(setAlert('error', 'Unable to load engine data files.'));
    }
  }, [dispatch, errorFiles]);

  useEffect(() => {
    if (dataFiles?.me?.aircraft[0]?.engineDataFiles) setEngineDataFiles([...dataFiles.me.aircraft[0].engineDataFiles]);
  }, [dataFiles]);

  return (
    <Box
      sx={{
        p: {
          xs: 2,
          sm: 3,
          md: 4,
          lg: 5,
        },
      }}
    >
      <ComponentLoading loading={loadingDeleteFile || loadingFiles || loadingDownloadFile}>
        <Box
          py={1}
          sx={{
            width: '100%',
          }}
        >
          {isLoad && (
            <DataGridPro
              sx={{
                width: '100%',
                minWidth: '100px',
                '& .MuiDataGrid-columnSeparator': {
                  display: 'none',
                },
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-row': {
                  cursor: 'pointer',
                  borderBottom: '1px solid',
                  borderColor: 'grey.200',
                },
                '& .MuiDataGrid-columnHeader:focus': {
                  outline: 'none',
                },
                border: 'none',
              }}
              autoHeight
              disableColumnResize={true}
              disableSelectionOnClick={true}
              rowsPerPageOptions={[5, 10, 20, 100]}
              pageSize={pageSize}
              onPageSizeChange={(newPage) => setPageSize(newPage)}
              pagination
              showColumnRightBorder={false}
              disableColumnMenu={true}
              page={page}
              onPageChange={(newPage) => setPage(newPage)}
              rows={engineDataFiles ? engineDataFiles : []}
              columns={[
                {
                  field: 'name',
                  headerName: 'Name',
                  flex: 1,
                },
                {
                  field: 'uploadDate',
                  headerName: 'Upload Date',
                  valueGetter: (value: GridValueGetterParams) => {
                    return value.row?.uploadDate ? value.row?.uploadDate.toString().substring(0, 10) : '';
                  },
                  flex: 0.5,
                },
                {
                  field: 'flightsCount',
                  headerName: 'Number of Flights',
                  valueGetter: (params: GridValueGetterParams) => {
                    return params.value ? params.value : 0;
                  },
                  flex: 0.8,
                },
                {
                  field: 'parseLogs',
                  headerName: 'View Logs',
                  renderCell: (params: GridRenderCellParams<string>) => {
                    return (
                      <IconButton
                        onClick={() => {
                          setFileId(params.row?.id || '');
                          setShowLogModal(true);
                        }}
                      >
                        <TextSnippetIcon />
                      </IconButton>
                    );
                  },
                },
                {
                  field: 'id',
                  headerName: 'Action',
                  renderCell: (params: GridRenderCellParams<string>) => {
                    return (
                      <Box>
                        <IconButton
                          sx={{
                            mr: 1,
                            display: 'none',
                          }}
                          onClick={(e: any) => {
                            e.preventDefault();
                            e.stopPropagation();
                            downloadFile(params.value || '');
                          }}
                        >
                          <DownloadIcon />
                        </IconButton>
                        <IconButton
                          onClick={(e: any) => {
                            e.preventDefault();
                            e.stopPropagation();
                            deleteFileAction(params.value || '');
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    );
                  },
                  flex: 0.5,
                },
              ]}
              onRowClick={(params: GridRowParams, event?: any) => {
                const { row } = params;
                if (row.id) {
                  event?.preventDefault();
                  event?.stopPropagation();
                  ctrlCmdClick(event, `${PATH_FLIGHTS}/aircraft/${aircraftId}/files/${row.id}`, navigate);
                } else {
                  return false;
                }
              }}
            />
          )}
        </Box>
      </ComponentLoading>
      <ViewLogModal open={showLogModal} setOpen={setShowLogModal} fileId={fileId} aircraftId={aircraftId} />
    </Box>
  );
};

export default Files;
