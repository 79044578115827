import React, { useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Grid, Box, TextField, MenuItem, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { useQuery, useLazyQuery } from '@apollo/client';

import { setAlert } from 'state';
import { aircraftRegistrationMakeModel } from 'helpers';
import { LayoutDashboard, PageLoading } from 'components';
import { MainAircraftInfo, Subscription } from './components';
import { QUERY_USER_ONLY_AIRCRAFTS, QUERY_GET_DETAIL_AIRCRAFT } from 'gql';
import { PATH_AIRCRAFT_VIEW, PATH_AIRCRAFT_CREATE } from 'const';

const Aircrafts: React.FC = () => {
  const {
    data: dataAircrafts,
    loading: loadingAircrafts,
    error: errorAircrafts,
  } = useQuery(QUERY_USER_ONLY_AIRCRAFTS, {
    fetchPolicy: 'cache-and-network',
  });

  const [fetchDetailAircraft, { data: dataDetailAircraft, loading: loadingDetailAircraft, error: errorDetailAircraft }] =
    useLazyQuery(QUERY_GET_DETAIL_AIRCRAFT);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const { isTechnician } = useSelector((state: any) => state.auth);

  useEffect(() => {
    if (errorAircrafts) {
      dispatch(setAlert('error', 'Unable to load your aircrafts.'));
    }

    if (errorDetailAircraft) {
      dispatch(setAlert('error', 'Unable to load selected aircraft.'));
    }
  }, [errorAircrafts, dispatch, errorDetailAircraft]);

  useEffect(() => {
    if (id)
      fetchDetailAircraft({
        variables: {
          id: id,
        },
      });
  }, [fetchDetailAircraft, id]);

  return (
    <LayoutDashboard flexGrow={1}>
      {(loadingAircrafts || loadingDetailAircraft) && <PageLoading />}
      <Box
        p={{
          sm: 0,
          md: 2,
          lg: 3.75,
          xl: 5,
        }}
      >
        <Box py={2.25}>
          <Box
            sx={{
              width: '100%',
              display: {
                md: 'flex',
                xs: 'block',
              },
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {!!dataAircrafts?.me?.aircraft?.length && (
              <Box
                sx={{
                  width: {
                    md: '500px',
                    xs: '100%',
                  },
                  mb: {
                    md: 0,
                    xs: 1.5,
                  },
                }}
              >
                <TextField
                  select
                  variant="outlined"
                  value={id}
                  onChange={(e: any) => {
                    if (e.target.value) navigate(`${PATH_AIRCRAFT_VIEW}/${e.target.value}`);
                  }}
                  sx={{
                    mb: 0,
                    backgroundColor: 'background.default',
                  }}
                  inputProps={{
                    sx: {
                      fontWeight: 700,
                      py: 2,
                      pl: 2.5,
                    },
                  }}
                  fullWidth
                >
                  {dataAircrafts?.me?.aircraft?.map((aircraft: any, key: number) => {
                    return (
                      <MenuItem value={aircraft.id} key={key} title={aircraftRegistrationMakeModel(aircraft)}>
                        {aircraftRegistrationMakeModel(aircraft, true, 40)}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Box>
            )}
            <NavLink to={PATH_AIRCRAFT_CREATE}>
              <Button color="primary" variant="contained">
                <AddIcon /> Add Aircraft
              </Button>
            </NavLink>
          </Box>
        </Box>
        <Grid
          container
          spacing={{
            sm: 0,
            md: 2,
            lg: 3.75,
            xl: 5,
          }}
        >
          <Grid item xl={8} lg={12} md={12} xs={12}>
            <MainAircraftInfo aircraftDetail={dataDetailAircraft?.me?.aircraft[0]} />
          </Grid>
          {!isTechnician && (
            <Grid item xl={4} lg={12} md={12} xs={12}>
              <Grid
                container
                spacing={{
                  xl: 5,
                  lg: 3.75,
                  md: 2,
                  sm: 0,
                }}
              >
                <Grid item xl={12} lg={6} md={6} xs={12}>
                  <Subscription aircraftDetail={dataDetailAircraft?.me?.aircraft[0]} />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
    </LayoutDashboard>
  );
};

export default Aircrafts;
