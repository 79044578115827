import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IAircraftDetail } from 'types';

import { Typography, Box, Button, Hidden, Badge, styled } from '@mui/material';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import { humanDate } from 'helpers';
import { PATH_BETA_PLANS } from 'const';

interface SubscriptionProps {
  aircraftDetail?: IAircraftDetail;
}

const classes = {
  badge: 'badge',
};

const StyledDiv = styled('div')({
  [`& .${classes.badge}`]: {
    '& .MuiBadge-badge': {
      height: 'auto',
      bottom: '-20px',
      left: '40px',
      '@media (max-width: 768px)': {
        left: '0',
      },
      transform: 'inherit',
      transformOrigin: 'inherit',
    },
  },
});

const Subscription: React.FC<SubscriptionProps> = (props) => {
  const { aircraftDetail = undefined } = props;
  const navigate = useNavigate();

  if (!aircraftDetail) return null;
  return (
    <StyledDiv
      sx={{
        border: '1px solid #E8E9EF',
        background: '#FFFFFF',
      }}
    >
      <Hidden smDown>
        <Badge
          overlap="circular"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          className={classes.badge}
          badgeContent={
            <Box
              sx={{
                display: 'block',
                '@media (max-width: 1440px)': {
                  display: 'none',
                },
              }}
            ></Box>
          }
          sx={{
            width: '100%',
          }}
        >
          <Box
            component={'img'}
            src={'/images/aircraft.webp'}
            sx={{
              width: '100%',
              objectFit: 'cover',
              maxHeight: '250px',
              objectPosition: '50% 70%',
            }}
          />
        </Badge>
      </Hidden>
      <Box
        py={1.5}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: '1px solid #E8E9EF',
          pl: 2,
        }}
      >
        <Typography
          variant="h3"
          gutterBottom
          style={{
            marginBottom: 0,
            lineHeight: '25px',
            color: 'primaryDark',
            fontWeight: 700,
          }}
        >
          Paid Plans
        </Typography>
        <Button
          variant="contained"
          style={{
            marginRight: '20px',
          }}
          color="primary"
          onClick={() => {
            if (aircraftDetail?.id) {
              navigate(
                `${PATH_BETA_PLANS}/${aircraftDetail.id}?category=${aircraftDetail.aircraftModel?.propulsion?.toLowerCase()}-${aircraftDetail.aircraftModel?.enginesCount?.toLowerCase()}`,
              );
            }
          }}
        >
          <MoreTimeIcon />
          Manage Plans
        </Button>
      </Box>
      <Box
        sx={{
          py: 8,
          borderBottom: '1px solid #E8E9EF',
        }}
      >
        <Typography
          sx={{
            color: 'text.secondary',
            fontWeight: 'bold',
            fontSize: '30px',
            lineHeight: '40px',
            textAlign: 'center',
          }}
        >
          {aircraftDetail?.subscriptions[0]?.serviceName}
        </Typography>
        {aircraftDetail?.subscriptions[0] && (
          <Typography
            sx={{
              textAlign: 'center',
            }}
          >
            $
            {aircraftDetail?.subscriptions[0]?.status === 'Active'
              ? aircraftDetail?.subscriptions[0]?.amountPaid
              : aircraftDetail?.subscriptions[0]?.amountRefunded}
            /year
          </Typography>
        )}
        {!aircraftDetail?.subscriptions[0] && (
          <Typography
            sx={{
              textAlign: 'center',
              color: 'error.dark',
              fontWeight: 'bold',
              fontSize: '24px',
              lineHeight: '40px',
            }}
          >
            No active plans
          </Typography>
        )}
      </Box>
      {aircraftDetail?.subscriptions[0] && (
        <Box
          sx={{
            py: 2,
            pl: 2,
          }}
        >
          <Typography
            sx={{
              color: 'rgba(19, 30, 64, 0.5)',
            }}
          >
            Status:{' '}
            <Box
              component="span"
              sx={{
                fontWeight: 'bold',
                color: aircraftDetail?.subscriptions[0]?.status === 'Active' ? 'success.dark' : 'error.dark',
              }}
            >
              {aircraftDetail?.subscriptions[0] ? aircraftDetail?.subscriptions[0]?.status : ''}
            </Box>{' '}
            {aircraftDetail?.subscriptions[0] ? '/ ' : ''}
            {humanDate(aircraftDetail?.subscriptions[0]?.creationDate)}
          </Typography>
        </Box>
      )}
    </StyledDiv>
  );
};

export default Subscription;
