import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box, Container } from '@mui/material';
import { DataGridPro, GridValueGetterParams, GridRowParams } from '@mui/x-data-grid-pro';
import { QUERY_SAVVY_AIRCRAFT_FLIGHT_REPORTS } from 'gql';
import { useLazyQuery } from '@apollo/client';

import { ComponentLoading, CustomGridToolbar } from 'components';
import { getTimesheetDateString, ctrlCmdClick } from 'helpers';

import { setAlert } from 'state';
import { useTableConfig } from 'hooks';
import { PATH_FLIGHT_REPORT } from 'const';

interface AnalysisReportsProps {
  aircraftId: string;
  isLoad: boolean;
}

const AnalysisReports: React.FC<AnalysisReportsProps> = (props) => {
  const { aircraftId, isLoad } = props;

  const [fetchFlightReports, { data: dataReports, loading: loadingReports, error: errorReports }] =
    useLazyQuery(QUERY_SAVVY_AIRCRAFT_FLIGHT_REPORTS);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [pageSize, setPageSize] = useState(10);
  const [filterButtonEl, setFilterButtonEl] = useState<HTMLButtonElement | null>(null);

  const { density, setDensity } = useTableConfig();

  useEffect(() => {
    if (isLoad && aircraftId) {
      fetchFlightReports({
        variables: {
          aircraftId,
        },
        fetchPolicy: 'cache-and-network',
      });
    }
  }, [aircraftId, isLoad, fetchFlightReports]);

  useEffect(() => {
    if (errorReports) {
      dispatch(setAlert('error', 'Unable to load reports'));
    }
  }, [errorReports, dispatch]);

  useEffect(() => {
    if (dataReports?.savvy?.aircraft?.flightReports?.length) {
      setPageSize(dataReports.savvy.aircraft.flightReports.length);
    }
  }, [dataReports]);

  return (
    <Container fixed maxWidth="xl">
      <Box>
        <ComponentLoading loading={loadingReports}>
          <Box
            py={1}
            sx={{
              width: '100%',
            }}
          >
            {isLoad && (
              <DataGridPro
                sx={{
                  width: '100%',
                  minWidth: '100px',
                  '& .MuiDataGrid-columnSeparator': {
                    display: 'none',
                  },
                  '& .MuiDataGrid-cell:focus': {
                    outline: 'none',
                  },
                  '& .MuiDataGrid-row': {
                    cursor: 'pointer',
                    borderBottom: '1px solid',
                    borderColor: 'grey.200',
                  },
                  '& .MuiDataGrid-columnHeader:focus': {
                    outline: 'none',
                  },
                  '& .MuiDataGrid-cellContent': {
                    whiteSpace: 'break-spaces',
                  },
                  '& .MuiDataGrid-toolbarContainer .MuiTextField-root': {
                    mb: 0,
                  },
                  border: 'none',
                }}
                autoHeight
                disableColumnResize={true}
                disableSelectionOnClick={true}
                rowsPerPageOptions={
                  dataReports?.savvy?.aircraft?.flightReports?.length
                    ? [5, 10, 25, dataReports?.savvy?.aircraft?.flightReports.length]
                    : [5, 10, 25, 100]
                }
                pageSize={pageSize}
                onPageSizeChange={(newPage) => setPageSize(newPage)}
                pagination
                showColumnRightBorder={false}
                disableColumnSelector
                density={density}
                onStateChange={(params) => {
                  if (params.density && params.density.value !== density) {
                    setDensity(params.density.value);
                  }
                }}
                rows={dataReports?.savvy?.aircraft?.flightReports ? dataReports?.savvy?.aircraft?.flightReports : []}
                columns={[
                  {
                    field: 'ticket',
                    headerName: 'Ticket ID',
                    valueFormatter: (params) => {
                      return params.value ? params.value.id : '';
                    },
                    flex: 0.3,
                  },
                  {
                    field: 'flight',
                    headerName: 'Departure Airport',
                    valueGetter: (params: GridValueGetterParams) => {
                      return params.row?.flight ? `${params.row.flight.departureAirport}` : '';
                    },
                    flex: 0.7,
                  },
                  {
                    field: 'destination',
                    headerName: 'Destination Airport',
                    flex: 0.7,
                    valueGetter: (params: GridValueGetterParams) => {
                      return params.row?.flight ? params.row.flight.destinationAirport : '';
                    },
                  },
                  {
                    field: 'date',
                    headerName: 'Date',
                    valueGetter: (params: GridValueGetterParams) => {
                      return params.row?.flight ? getTimesheetDateString(params.row.flight.date) : '';
                    },
                    flex: 0.5,
                  },
                  {
                    field: 'createdOn',
                    headerName: 'Created',
                    valueGetter: (params: GridValueGetterParams) => {
                      return params.value ? getTimesheetDateString(params.value) : '';
                    },
                    flex: 0.5,
                  },
                  {
                    field: 'lastUpdateOn',
                    headerName: 'Updated',
                    valueGetter: (params: GridValueGetterParams) => {
                      return params.value ? getTimesheetDateString(params.value) : '';
                    },
                    flex: 0.5,
                  },
                ]}
                onRowClick={(params: GridRowParams, event?: any) => {
                  const { row } = params;
                  if (row.id) {
                    ctrlCmdClick(event, `${PATH_FLIGHT_REPORT}/${aircraftId}/${row.id}`, navigate);
                  }
                }}
                components={{ Toolbar: CustomGridToolbar }}
                componentsProps={{
                  toolbar: {
                    setFilterButtonEl,
                  },
                  filterPanel: {
                    sx: {
                      '& .MuiDataGrid-filterForm': {
                        '& .MuiTextField-root': {
                          mb: 0,
                        },
                      },
                    },
                  },
                  panel: {
                    anchorEl: filterButtonEl,
                    placement: 'bottom-end',
                  },
                }}
              />
            )}
          </Box>
        </ComponentLoading>
      </Box>
    </Container>
  );
};

export default AnalysisReports;
