import React, { Fragment } from 'react';
import { BrowserRouter, Route, Navigate, Routes } from 'react-router-dom';
import { isIE, isChrome, isSafari, isEdge, isFirefox, isOpera, browserVersion } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { setAlert } from 'state';

import {
  SignupFree,
  SignupService,
  SignupServicePayment,
  SignupServiceAdditionalInfo,
  Login,
  NotFound,
  Dashboard,
  ResetPassword,
  Flights,
  Account,
  Enroll,
  TechnicianPage,
  ServiceCenterMapPage,
  SignupCompleteAdditionalInfo,
  Aircrafts,
  TicketsCreate,
  SelectTicketType,
  FileUploads,
  AircraftCreate,
  Reports,
  Plans,
  FlightView,
  SharedFlightView,
  Tickets,
  LoginWithEmail,
  LoginWithCode,
  AircraftList,
  UpgradePlan,
  WelcomePage,
  VerifyEmail,
  ForgotPassword,
  TechDashboard,
  Legal,
  TicketRate,
  SharedFlightsByAircraft,
  SharedFlightsByContact,
  SharedLogs,
  UpgradeAnalysisPro,
  UpgradeQa,
  SecurityCheck,
  SetCookieFromToken,
  ClientCohorts,
  ViewStagedEngineDataFile,
  CreateAppToken,
  FlightsByFile,
  VerifyServiceCenter,
  UpdateServiceCenter,
  UpdateTechnician,
  VerifyTechnician,
  BorescopeUpload,
  CreateBaReportsWithPermission,
  CreateOwnBorescopeAnalysisReports,
} from 'pages';

import { Auth, Alerts } from 'components';

import {
  PrivateRoute,
  RedirectLoggedIn,
  RedirectStepLoggedIn,
  PrivateTicket,
  PrivateSavvyRoute,
  PrivateTechRoute,
  BetaSwitchRoute,
} from './components';

import {
  PATH_ENROLL,
  PATH_SIGNUP_FREE,
  PATH_SIGNUP_SERVICE,
  PATH_SIGNUP_SERVICE_LEGACY,
  PATH_SIGNUP_SERVICE_PAYMENT,
  PATH_LOGIN,
  PATH_RESET_PASSWORD,
  PATH_FLIGHTS,
  PATH_ACCOUNT,
  PATH_SIGNUP_SERVICE_ADDITIONAL_INFO,
  PATH_INTERNAL_TECHNICIAN,
  PATH_INTERNAL_SERVICE_CENTER,
  PATH_INTERNAL_TICKETRATINGS,
  PATH_SERVICE_CENTER_MAP,
  PATH_SIGNUP_COMPLETE_ADDITIONAL_INFO,
  PATH_INTERNAL_SERVICE_CENTER_RATING,
  PATH_TICKETS_VIEW,
  PATH_TICKETS_CREATE,
  PATH_AIRCRAFT_VIEW,
  PATH_BETA_DASHBOARD,
  PATH_FLIGHTS_UPLOAD,
  PATH_AIRCRAFT_CREATE,
  PATH_REPORTS_BETA,
  PATH_BETA_PLANS,
  PATH_FLIGHT_VIEW,
  PATH_SHARED_FLIGHT_VIEW,
  PATH_INTERNAL_DASHBOARD,
  PATH_SAVVY_TICKETS,
  PATH_SAVVY_TICKETS_VIEW,
  PATH_PERSONAL_TICKET_DASHBOARD,
  PATH_LOGIN_WITH_EMAIL,
  PATH_LOGIN_WITH_CODE,
  PATH_SERVICE_CENTER,
  PATH_CONTACT,
  PATH_SAVVY_AIRCRAFT_FLIGHTS,
  PATH_TICKETS_DASHBOARD,
  PATH_FILTERED_TICKETS,
  PATH_RENEWAL_TICKETS,
  PATH_UESR_TICKET_CREATE,
  PATH_UESR_TICKET_CREATE_TYPE,
  PATH_SAVVY_AIRCRAFT,
  PATH_UNASSIGNED_TICKETS,
  PATH_SERVICE_CENTER_CREATE,
  PATH_SERVICE_CENTER_MANUAL_RATING,
  PATH_SAVVY_ACCOUNT,
  PATH_SAVVY_SEARCH,
  PATH_SAVVY_LOG_BACKFILL,
  PATH_AIRCRAFT_LIST,
  PATH_SAVVY_COI_MANAGEMENT,
  PATH_BETA_UPGRADE_PLAN,
  PATH_WELCOME,
  PATH_VERIFY_EMAIL,
  PATH_FORGOT_PASSWORD,
  PATH_SC_DASHBOARD,
  PATH_SC_FLIGHTS,
  PATH_MANAGER_CONFLICTS,
  PATH_SAVVY_COHORT_REPORTS,
  PATH_SAVVY_TIMESHEETS_DASHBOARD,
  PATH_SAVVY_TICKETS_UNWATCH,
  PATH_CREATE_TECHNICIAN,
  PATH_SAVVY_AIRCRAFT_CREATE,
  PATH_PREBUY_INQUIRY_SIGNUP,
  PATH_ACTIVE_PREBUY,
  PATH_FEVA_ALERTS,
  PATH_ANALYSIS_DASHBOARD,
  PATH_FLIGHT_REPORT,
  PATH_SAVVY_SEARCH_TICKET,
  PATH_SAVVY_SERACH_CONTACT,
  PATH_SAVVY_SEARCH_AIRCRAFT,
  PATH_SAVVY_SEARCH_SUBSCRIPTION,
  PATH_SAVVY_SEARCH_SERVICE_CENTER,
  PATH_SAVVY_SEARCH_FLIGHT_REPORT,
  PATH_SAVVY_SEARCH_PREBUY_INQUIRY,
  PATH_SAVVY_TIMESHEETS_RATE,
  PATH_FEVA_VIEW,
  PATH_SAVVY_OOP,
  PATH_SAVVY_KEY_RESULTS_REPORT,
  PATH_SAVVY_CANNED_RESPONSES,
  PATH_SAVVY_FUTURE_COHORTS,
  PATH_LEGAL,
  PATH_SAVVY_PREBUY_INQUIRY,
  PATH_SAVVY_COHORTS,
  PATH_SAVVY_SUBMITTED_AIRCRAFT_DATA,
  PATH_SAVVY_ENGINE_PARAMETERS,
  PATH_SAVVY_FINANCIALS,
  PATH_TICKET_RATE,
  PATH_SHARE_ALL_FLIGHTS,
  PATH_SHARE_ALL_FLIGHTS_BY_CONTACT,
  PATH_SHARE_LOGS,
  PATH_ADD_ANALYSIS_PRO_PLAN,
  PATH_ADD_QA_PLAN,
  PATH_UPGRADE_QA_PLAN,
  PATH_SECURITY_CHECK,
  PATH_CREATE_PREBUY_CONFLICT_TICKET,
  PATH_VERIFY_SECURITY_TOKEN,
  PATH_COHORTS,
  PATH_STAGED_ENGINE_DATA_FILES,
  PATH_REQUEST_TOKEN,
  PATH_VERIFY_SERVICE_CENTER_FOR_TECH,
  PATH_UPDATE_SERVICE_CENTER_FOR_TECH,
  PATH_UPDATE_TECHNICIAN,
  PATH_TICKETS,
  PATH_VERIFY_TECHNICIAN,
  PATH_SAVVY_TECHS_WITH_UPDATE_REQUEST,
  PATH_BORESCOPE_UPLOAD,
  PATH_SAVVY_BORESCOPE_ANALYSIS_REPORT_GENERATE,
  PATH_SAVVY_BORESCOPE_ANALYSIS_REPORT_VIEW,
  PATH_SAVVY_SEARCH_AIRCRAFT_TYPE,
  PATH_CREATE_BORESCOPE_REPORT,
  PATH_CREATE_OWN_BORESCOPE_REPORT,
  PATH_MAKE_PAYMENTS,
  PATH_POSTMARK_TEMPLATES,
  PATH_API_TEMPLATES,
} from 'const';

import {
  LazySavvyTickets,
  LazyServiceCenterPage,
  LazyInternalDashboard,
  LazyPersonalTicketDashboard,
  LazyServiceCentersList,
  LazyEditContact,
  LazyContactFlight,
  LazySavvySubscriptions,
  LazyTicketsDashboard,
  LazyFilteredTicketsPage,
  LazyRenewalDashboard,
  LazyRenewalTicketsPage,
  LazySavvyFileUploads,
  LazySelectUserTicketType,
  LazyUserTicketCreate,
  LazySavvyAircraft,
  LazyUnassignedTicketsPage,
  LazyServiceCenterView,
  LazyServiceCenterManualRatingPage,
  LazySavvyAccount,
  LazyAdvancedSearch,
  LazySavvyBackfillLogPage,
  LazyManagementCoiPage,
  LazyManagerConflicts,
  LazyCohortReports,
  LazyTechnicianCreate,
  LazyTimesheetOverview,
  LazySavvyAircraftCreate,
  LazyActivePrebuy,
  LazyFevaAlerts,
  LazyFevaAlertView,
  LazyAnalysisDashboard,
  LazyCreateAnalysisReport,
  LazyAnalysisReports,
  LazyTimesheetRates,
  LazyTicketSearch,
  LazyContactSearch,
  LazyAircraftSearch,
  LazySubscriptionSearch,
  LazyServiceCenterSearch,
  LazyFlightReportSearch,
  LazyPrebuyInquirySearch,
  LazyOopList,
  LazyKeyResultsReport,
  LazyCannedResponses,
  LazyFutureCohorts,
  LazyPrebuyInquiry,
  LazyCohorts,
  LazyViewCohort,
  LazyFinancials,
  LazyAircraftSubmittedData,
  LazyViewSubmittedData,
  LazyPowerModelList,
  LazyCreatePowerModel,
  LazyViewPowerModel,
  LazyEditPowerModel,
  LazyCreatePrebuyConflictTicket,
  LazyServiceCenterRatingPage,
  LazyTicketRatingPage,
  LazyTechsWithUpdateRequest,
  LazyCreateBorescopeAnalysisReport,
  LazySavvyBorescopeReport,
  LazySearchAircraftByType,
  LazyMakePayments,
  LazyPostmark,
  LazyAPItemplate,
} from './LazyLoader';

import { PageLoading } from 'components';

// Advice: better route be a page, not component. It gives better organizing and fast finding and changing of components
const Router: React.FC = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (isIE) {
      dispatch(
        setAlert('warning', 'Internet Explorer is not supported. We support recent versions of Safari, Firefox, Edge, Opera and Chrome.'),
      );
    }
    if (isChrome && parseFloat(browserVersion) < 70) {
      dispatch(setAlert('warning', `Chrome v${browserVersion} is not supported. Please upgrade to version 70 or higher.`)); // Chrome v70 released on Oct 16, 2018
    }
    if (isSafari && parseFloat(browserVersion) < 11) {
      dispatch(setAlert('warning', `Safari v${browserVersion} is not supported. Please upgrade to version 11 or higher.`)); //safari 11 released on Sep 19, 2017
    }
    if (isFirefox && parseFloat(browserVersion) < 60) {
      dispatch(setAlert('warning', `Firefox v${browserVersion} is not supported. Please upgrade to version 60 or higher.`)); // Firefox v60 released on May 9, 2018
    }
    if (isEdge && parseFloat(browserVersion) < 79) {
      dispatch(setAlert('warning', `Edge v${browserVersion} is not supported. Please upgrade to version 79 or higher.`)); // Edge used new engine from v79. Jan 15, 2020
    }
    if (isOpera && parseFloat(browserVersion) < 50) {
      dispatch(setAlert('warning', `Opera v${browserVersion} is not supported. Please upgrade to version 50 or higher.`)); // Opera v50 released on Jan 4, 2018
    }
  }, [dispatch]);

  return (
    <Fragment>
      {/* Check is the user loggedIn on each page reload */}
      <Auth />

      <BrowserRouter>
        {/* App alerts - success, error, etc. Should be inside BrowserRouter to use react router Link */}
        <Alerts />
        <React.Suspense fallback={<PageLoading />}>
          <Routes>
            {/* Entry page */}
            {/*<RedirectLoggedIn exact path={PATH_HOME} component={SignupFree} />*/}
            {/* Test page */}
            <Route path="/test" element={<LazyPersonalTicketDashboard />} />
            {/* Enroll */}
            <Route path={PATH_ENROLL} element={<Enroll />} />
            <Route path={'/beta/*'} element={<BetaSwitchRoute path={'/beta'} />} />
            <Route path={'/shared/*'} element={<BetaSwitchRoute path={'/shared'} />} />
            <Route path={'/profile/*'} element={<BetaSwitchRoute path={'/profile'} />} />
            <Route path={'/beta'} element={<Navigate to={window.location.pathname.replace('/beta', '')} />} />

            {/* Signup Free */}
            <Route path={PATH_SIGNUP_FREE} element={<RedirectLoggedIn element={<SignupFree />} />} />
            <Route path={PATH_PREBUY_INQUIRY_SIGNUP} element={<RedirectLoggedIn element={<SignupFree />} />} />

            {/* Signup Service */}
            <Route path={PATH_SIGNUP_SERVICE} element={<RedirectLoggedIn element={<SignupService />} />} />
            <Route path={PATH_SIGNUP_SERVICE_LEGACY} element={<SignupService />} />
            <Route path={PATH_SIGNUP_SERVICE_PAYMENT} element={<SignupServicePayment />} />
            <Route path={PATH_SIGNUP_SERVICE_ADDITIONAL_INFO} element={<SignupServiceAdditionalInfo />} />
            <Route path={`${PATH_VERIFY_EMAIL}/:uuid`} element={<VerifyEmail />} />
            <Route path={`${PATH_FORGOT_PASSWORD}/:token`} element={<ForgotPassword />} />
            <Route path={PATH_LEGAL} element={<Legal />} />
            <Route path={PATH_SECURITY_CHECK} element={<SecurityCheck />} />
            <Route path={PATH_VERIFY_SECURITY_TOKEN} element={<SetCookieFromToken />} />
            <Route path={PATH_SIGNUP_COMPLETE_ADDITIONAL_INFO} element={<PrivateRoute element={<SignupCompleteAdditionalInfo />} />} />

            {/* Login */}
            <Route path={PATH_LOGIN} element={<RedirectStepLoggedIn element={<Login />} />} />
            <Route path={PATH_RESET_PASSWORD} element={<RedirectLoggedIn element={<ResetPassword />} />} />
            <Route path={PATH_LOGIN_WITH_EMAIL} element={<RedirectLoggedIn element={<LoginWithEmail />} />} />
            <Route path={`${PATH_LOGIN_WITH_CODE}/:uuid`} element={<RedirectLoggedIn element={<LoginWithCode />} />} />
            <Route path={PATH_REQUEST_TOKEN} element={<PrivateRoute element={<CreateAppToken />} />} />

            {/* Internal */}
            {/* TODO: Shouldn't exact be defaulting to False? */}
            <Route
              path={`${PATH_CREATE_TECHNICIAN}/:id`}
              element={<PrivateSavvyRoute path={`${PATH_CREATE_TECHNICIAN}/:id`} element={<LazyTechnicianCreate />} />}
            />
            <Route
              path={PATH_INTERNAL_TECHNICIAN}
              element={<PrivateSavvyRoute path={PATH_INTERNAL_TECHNICIAN} element={<TechnicianPage />} />}
            />
            <Route
              path={PATH_SERVICE_CENTER_MAP}
              element={<PrivateSavvyRoute path={PATH_SERVICE_CENTER_MAP} element={<ServiceCenterMapPage />} />}
            />

            {/* Temp Route */}
            {/* Dashboard */}

            <Route path={PATH_BETA_DASHBOARD} element={<PrivateRoute element={<Dashboard />} />} />

            {/* Flights */}
            <Route path={`${PATH_SHARE_LOGS}/:id`} element={<PrivateRoute element={<SharedLogs />} />} />
            <Route path={`${PATH_SHARED_FLIGHT_VIEW}/:id/:uuid`} element={<SharedFlightView />} />
            <Route path={`${PATH_SHARE_ALL_FLIGHTS}/:uuid`} element={<SharedFlightsByAircraft />} />
            <Route path={`${PATH_SHARE_ALL_FLIGHTS_BY_CONTACT}/:uuid`} element={<SharedFlightsByContact />} />
            <Route path={`${PATH_STAGED_ENGINE_DATA_FILES}`} element={<PrivateRoute element={<ViewStagedEngineDataFile />} />} />
            <Route path={`${PATH_STAGED_ENGINE_DATA_FILES}/:id`} element={<PrivateRoute element={<ViewStagedEngineDataFile />} />} />
            <Route path={PATH_COHORTS} element={<PrivateRoute element={<ClientCohorts />} />} />
            <Route path={`${PATH_REPORTS_BETA}`} element={<PrivateRoute element={<Reports />} />} />
            <Route path={`${PATH_REPORTS_BETA}/:id`} element={<PrivateRoute element={<Reports />} />} />
            <Route path={`${PATH_REPORTS_BETA}/:id/:reportType/:reportId`} element={<PrivateRoute element={<Reports />} />} />
            <Route path={PATH_FLIGHTS} element={<PrivateRoute element={<Flights />} />} />
            <Route path={`${PATH_FLIGHTS}/aircraft/:aircraftId`} element={<PrivateRoute element={<Flights />} />} />
            <Route
              path={`${PATH_FLIGHTS}/aircraft/:aircraftId/files/:engineDataFileId`}
              element={<PrivateRoute element={<FlightsByFile />} />}
            />
            <Route path={`${PATH_FLIGHT_VIEW}/:id`} element={<PrivateRoute element={<FlightView />} />} />

            {/* Tickets */}
            <Route path={`${PATH_TICKET_RATE}/:uuid`} element={<PrivateRoute element={<TicketRate />} />} />
            <Route path={PATH_TICKETS} element={<PrivateTicket element={<Tickets />} path={PATH_TICKETS} />} />
            <Route path={`${PATH_TICKETS_VIEW}/:id`} element={<PrivateTicket element={<Tickets />} path={`${PATH_TICKETS_VIEW}/:id`} />} />
            <Route path={PATH_SAVVY_TICKETS} element={<PrivateTicket element={<LazySavvyTickets />} path={PATH_SAVVY_TICKETS} />} />
            <Route
              path={`${PATH_SAVVY_TICKETS_VIEW}/:id`}
              element={<PrivateTicket element={<LazySavvyTickets />} path={`${PATH_SAVVY_TICKETS_VIEW}/:id`} />}
            />
            <Route
              path={`${PATH_SAVVY_TICKETS_UNWATCH}/:id`}
              element={<PrivateTicket element={<LazySavvyTickets />} path={`${PATH_SAVVY_TICKETS_UNWATCH}/:id`} />}
            />
            <Route path={PATH_TICKETS_CREATE} element={<PrivateRoute element={<SelectTicketType />} />} />
            <Route path={`${PATH_TICKETS_CREATE}/:type`} element={<PrivateRoute element={<TicketsCreate />} />} />
            <Route path={`${PATH_TICKETS_CREATE}/:type/:id`} element={<PrivateRoute element={<TicketsCreate />} />} />

            <Route path={PATH_AIRCRAFT_LIST} element={<PrivateRoute element={<AircraftList />} />} />

            {/* Client aircraft tabs */}
            <Route path={`${PATH_AIRCRAFT_VIEW}/:id`} element={<PrivateRoute element={<Aircrafts />} />} />
            <Route path={`${PATH_AIRCRAFT_VIEW}/:id/unit-conversions`} element={<PrivateRoute element={<Aircrafts />} />} />
            <Route path={`${PATH_AIRCRAFT_VIEW}/:id/mdr`} element={<PrivateRoute element={<Aircrafts />} />} />
            <Route path={`${PATH_AIRCRAFT_VIEW}/:id/coi`} element={<PrivateRoute element={<Aircrafts />} />} />
            <Route path={`${PATH_AIRCRAFT_VIEW}/:id/files`} element={<PrivateRoute element={<Aircrafts />} />} />
            <Route path={`${PATH_AIRCRAFT_VIEW}/:id/ubg`} element={<PrivateRoute element={<Aircrafts />} />} />
            <Route path={`${PATH_AIRCRAFT_VIEW}/:id/mgl`} element={<PrivateRoute element={<Aircrafts />} />} />
            <Route path={`${PATH_AIRCRAFT_VIEW}/:id/borescope-image-sets`} element={<PrivateRoute element={<Aircrafts />} />} />
            <Route path={`${PATH_AIRCRAFT_VIEW}/:id/aircraft-access`} element={<PrivateRoute element={<Aircrafts />} />} />
            <Route path={`${PATH_AIRCRAFT_CREATE}`} element={<PrivateRoute element={<AircraftCreate />} />} />

            {/* Flights Upload */}
            <Route path={`${PATH_FLIGHTS_UPLOAD}`} element={<PrivateRoute element={<FileUploads />} />} />
            <Route path={`${PATH_FLIGHTS_UPLOAD}/:id`} element={<PrivateRoute element={<FileUploads />} />} />

            {/* Borescope Upload */}
            <Route path={`${PATH_BORESCOPE_UPLOAD}`} element={<PrivateRoute element={<BorescopeUpload />} />} />
            <Route path={`${PATH_BORESCOPE_UPLOAD}/:id`} element={<PrivateRoute element={<BorescopeUpload />} />} />

            {/* Account */}
            <Route path={PATH_ACCOUNT} element={<PrivateRoute element={<Account />} />} />
            <Route path={`${PATH_BETA_PLANS}`} element={<PrivateRoute element={<Plans />} />} />
            <Route path={`${PATH_BETA_PLANS}/:id`} element={<PrivateRoute element={<Plans />} />} />
            <Route path={PATH_BETA_UPGRADE_PLAN} element={<PrivateRoute element={<UpgradePlan />} />} />
            <Route path={PATH_ADD_ANALYSIS_PRO_PLAN} element={<PrivateRoute element={<UpgradeAnalysisPro />} />} />
            <Route path={PATH_ADD_QA_PLAN} element={<PrivateRoute element={<UpgradeQa />} />} />
            <Route path={PATH_UPGRADE_QA_PLAN} element={<PrivateRoute element={<UpgradeQa />} />} />
            <Route path={`${PATH_WELCOME}/:aircraftId/:service`} element={<PrivateRoute element={<WelcomePage />} />} />
            <Route
              path={`${PATH_CREATE_BORESCOPE_REPORT}/:aircraftId/:borescopeImageSetId`}
              element={<PrivateRoute element={<CreateBaReportsWithPermission />} />}
            />
            <Route
              path={`${PATH_CREATE_OWN_BORESCOPE_REPORT}/:aircraftId/:borescopeImageSetId`}
              element={<PrivateRoute element={<CreateOwnBorescopeAnalysisReports />} />}
            />

            <Route
              path={PATH_SERVICE_CENTER_CREATE}
              element={<PrivateSavvyRoute path={PATH_SERVICE_CENTER_CREATE} element={<LazyServiceCenterPage />} />}
            />
            {/* Internal dashboard for savvy user*/}
            <Route
              path={PATH_INTERNAL_DASHBOARD}
              element={<PrivateSavvyRoute path={PATH_INTERNAL_DASHBOARD} element={<LazyInternalDashboard />} />}
            />
            <Route
              path={PATH_PERSONAL_TICKET_DASHBOARD}
              element={<PrivateSavvyRoute path={PATH_PERSONAL_TICKET_DASHBOARD} element={<LazyPersonalTicketDashboard />} />}
            />
            <Route
              path={PATH_SERVICE_CENTER}
              element={<PrivateSavvyRoute path={PATH_SERVICE_CENTER} element={<LazyServiceCentersList />} />}
            />

            {/* Savvy contact tabs */}
            <Route
              path={`${PATH_CONTACT}/:contactId/aircraft`}
              element={<PrivateSavvyRoute path={`${PATH_CONTACT}/:contactId/aircraft`} element={<LazyEditContact />} />}
            />
            <Route
              path={`${PATH_CONTACT}/:contactId/tickets`}
              element={<PrivateSavvyRoute path={`${PATH_CONTACT}/:contactId/tickets`} element={<LazyEditContact />} />}
            />
            <Route
              path={`${PATH_CONTACT}/:contactId/tickets/:id`}
              element={<PrivateSavvyRoute path={`${PATH_CONTACT}/:contactId/tickets/:id`} element={<LazyEditContact />} />}
            />
            <Route
              path={`${PATH_CONTACT}/:contactId/classifications`}
              element={<PrivateSavvyRoute path={`${PATH_CONTACT}/:contactId/classifications`} element={<LazyEditContact />} />}
            />
            <Route
              path={`${PATH_CONTACT}/:contactId/comments`}
              element={<PrivateSavvyRoute path={`${PATH_CONTACT}/:contactId/comments`} element={<LazyEditContact />} />}
            />
            <Route
              path={`${PATH_CONTACT}/:contactId/transactions`}
              element={<PrivateSavvyRoute path={`${PATH_CONTACT}/:contactId/transactions`} element={<LazyEditContact />} />}
            />
            <Route
              path={`${PATH_CONTACT}/:contactId/emailcc`}
              element={<PrivateSavvyRoute path={`${PATH_CONTACT}/:contactId/emailcc`} element={<LazyEditContact />} />}
            />
            <Route
              path={`${PATH_CONTACT}/:contactId/assigned`}
              element={<PrivateSavvyRoute path={`${PATH_CONTACT}/:contactId/assigned`} element={<LazyEditContact />} />}
            />
            <Route
              path={`${PATH_CONTACT}/:contactId`}
              element={<PrivateSavvyRoute path={`${PATH_CONTACT}/:contactId`} element={<LazyEditContact />} />}
            />

            <Route
              path={`${PATH_SAVVY_AIRCRAFT_FLIGHTS}/:id`}
              element={<PrivateSavvyRoute path={`${PATH_SAVVY_AIRCRAFT_FLIGHTS}/:id`} element={<LazyContactFlight />} />}
            />
            <Route
              path={`${PATH_CONTACT}/:contactId/subscription/:id`}
              element={<PrivateSavvyRoute path={`${PATH_CONTACT}/:contactId/subscription/:id`} element={<LazySavvySubscriptions />} />}
            />
            <Route
              path={PATH_TICKETS_DASHBOARD}
              element={<PrivateSavvyRoute path={PATH_TICKETS_DASHBOARD} element={<LazyTicketsDashboard />} />}
            />
            <Route
              path={`${PATH_FILTERED_TICKETS}/:tag/:category`}
              element={<PrivateSavvyRoute path={`${PATH_FILTERED_TICKETS}/:tag/:category`} element={<LazyFilteredTicketsPage />} />}
            />
            <Route
              path={`${PATH_FILTERED_TICKETS}/:tag/:category/:id`}
              element={<PrivateSavvyRoute path={`${PATH_FILTERED_TICKETS}/:tag/:category/:id`} element={<LazyFilteredTicketsPage />} />}
            />
            <Route
              path={PATH_RENEWAL_TICKETS}
              element={<PrivateSavvyRoute path={PATH_RENEWAL_TICKETS} element={<LazyRenewalDashboard />} />}
            />
            <Route
              path={`${PATH_RENEWAL_TICKETS}/:serviceCode`}
              element={<PrivateSavvyRoute path={`${PATH_RENEWAL_TICKETS}/:serviceCode`} element={<LazyRenewalTicketsPage />} />}
            />
            <Route
              path={`${PATH_RENEWAL_TICKETS}/:serviceCode/:id`}
              element={<PrivateSavvyRoute path={`${PATH_RENEWAL_TICKETS}/:serviceCode/:id`} element={<LazyRenewalTicketsPage />} />}
            />
            <Route
              path={`${PATH_CONTACT}/:contactId/aircraft/:id/file/upload`}
              element={
                <PrivateSavvyRoute path={`${PATH_CONTACT}/:contactId/aircraft/:id/file/upload`} element={<LazySavvyFileUploads />} />
              }
            />
            <Route
              path={PATH_UESR_TICKET_CREATE}
              element={<PrivateSavvyRoute path={PATH_UESR_TICKET_CREATE} element={<LazySelectUserTicketType />} />}
            />
            <Route
              path={`${PATH_UESR_TICKET_CREATE}/contacts/:contactId`}
              element={<PrivateSavvyRoute path={`${PATH_UESR_TICKET_CREATE}/contacts/:contactId`} element={<LazySelectUserTicketType />} />}
            />
            <Route
              path={`${PATH_UESR_TICKET_CREATE}/contacts/:contactId/aircraft/:aircraftId`}
              element={
                <PrivateSavvyRoute
                  path={`${PATH_UESR_TICKET_CREATE}/contacts/:contactId/aircraft/:aircraftId`}
                  element={<LazySelectUserTicketType />}
                />
              }
            />
            <Route
              path={`${PATH_UESR_TICKET_CREATE_TYPE}/:type/:contactId`}
              element={<PrivateSavvyRoute path={`${PATH_UESR_TICKET_CREATE_TYPE}/:type/:contactId`} element={<LazyUserTicketCreate />} />}
            />
            <Route
              path={`${PATH_UESR_TICKET_CREATE_TYPE}/:type/:contactId/aircraft/:id`}
              element={
                <PrivateSavvyRoute
                  path={`${PATH_UESR_TICKET_CREATE_TYPE}/:type/:contactId/aircraft/:id`}
                  element={<LazyUserTicketCreate />}
                />
              }
            />

            {/* Savvy aircraft tabs */}
            <Route
              path={`${PATH_SAVVY_AIRCRAFT}/:aircraftId`}
              element={<PrivateSavvyRoute path={`${PATH_SAVVY_AIRCRAFT}/:aircraftId`} element={<LazySavvyAircraft />} />}
            />
            <Route
              path={`${PATH_SAVVY_AIRCRAFT}/:aircraftId/owner`}
              element={<PrivateSavvyRoute path={`${PATH_SAVVY_AIRCRAFT}/:aircraftId/owner`} element={<LazySavvyAircraft />} />}
            />
            <Route
              path={`${PATH_SAVVY_AIRCRAFT}/:aircraftId/comments`}
              element={<PrivateSavvyRoute path={`${PATH_SAVVY_AIRCRAFT}/:aircraftId/comments`} element={<LazySavvyAircraft />} />}
            />
            <Route
              path={`${PATH_SAVVY_AIRCRAFT}/:aircraftId/managers`}
              element={<PrivateSavvyRoute path={`${PATH_SAVVY_AIRCRAFT}/:aircraftId/managers`} element={<LazySavvyAircraft />} />}
            />
            <Route
              path={`${PATH_SAVVY_AIRCRAFT}/:aircraftId/flights`}
              element={<PrivateSavvyRoute path={`${PATH_SAVVY_AIRCRAFT}/:aircraftId/flights`} element={<LazySavvyAircraft />} />}
            />
            <Route
              path={`${PATH_SAVVY_AIRCRAFT}/:aircraftId/tickets`}
              element={<PrivateSavvyRoute path={`${PATH_SAVVY_AIRCRAFT}/:aircraftId/tickets`} element={<LazySavvyAircraft />} />}
            />
            <Route
              path={`${PATH_SAVVY_AIRCRAFT}/:aircraftId/tickets/:id`}
              element={<PrivateSavvyRoute path={`${PATH_SAVVY_AIRCRAFT}/:aircraftId/tickets/:id`} element={<LazySavvyAircraft />} />}
            />
            <Route
              path={`${PATH_SAVVY_AIRCRAFT}/:aircraftId/subscriptions`}
              element={<PrivateSavvyRoute path={`${PATH_SAVVY_AIRCRAFT}/:aircraftId/subscriptions`} element={<LazySavvyAircraft />} />}
            />
            <Route
              path={`${PATH_SAVVY_AIRCRAFT}/:aircraftId/subscriptions/:subscriptionId`}
              element={
                <PrivateSavvyRoute
                  path={`${PATH_SAVVY_AIRCRAFT}/:aircraftId/subscriptions/:subscriptionId`}
                  element={<LazySavvyAircraft />}
                />
              }
            />
            <Route
              path={`${PATH_SAVVY_AIRCRAFT}/:aircraftId/documents`}
              element={<PrivateSavvyRoute path={`${PATH_SAVVY_AIRCRAFT}/:aircraftId/documents`} element={<LazySavvyAircraft />} />}
            />
            <Route
              path={`${PATH_SAVVY_AIRCRAFT}/:aircraftId/edf`}
              element={<PrivateSavvyRoute path={`${PATH_SAVVY_AIRCRAFT}/:aircraftId/edf`} element={<LazySavvyAircraft />} />}
            />
            <Route
              path={`${PATH_SAVVY_AIRCRAFT}/:aircraftId/coi`}
              element={<PrivateSavvyRoute path={`${PATH_SAVVY_AIRCRAFT}/:aircraftId/coi`} element={<LazySavvyAircraft />} />}
            />
            <Route
              path={`${PATH_SAVVY_AIRCRAFT}/:aircraftId/mdr`}
              element={<PrivateSavvyRoute path={`${PATH_SAVVY_AIRCRAFT}/:aircraftId/mdr`} element={<LazySavvyAircraft />} />}
            />
            <Route
              path={`${PATH_SAVVY_AIRCRAFT}/:aircraftId/analysis-reports`}
              element={<PrivateSavvyRoute path={`${PATH_SAVVY_AIRCRAFT}/:aircraftId/analysis-reports`} element={<LazySavvyAircraft />} />}
            />
            <Route
              path={`${PATH_SAVVY_AIRCRAFT}/:aircraftId/reports`}
              element={<PrivateSavvyRoute path={`${PATH_SAVVY_AIRCRAFT}/:aircraftId/reports`} element={<LazySavvyAircraft />} />}
            />
            <Route
              path={`${PATH_SAVVY_AIRCRAFT}/:aircraftId/borescope-image-sets`}
              element={
                <PrivateSavvyRoute path={`${PATH_SAVVY_AIRCRAFT}/:aircraftId/borescope-image-sets`} element={<LazySavvyAircraft />} />
              }
            />

            <Route
              path={`${PATH_UNASSIGNED_TICKETS}`}
              element={<PrivateSavvyRoute path={`${PATH_UNASSIGNED_TICKETS}`} element={<LazyUnassignedTicketsPage />} />}
            />
            <Route
              path={`${PATH_UNASSIGNED_TICKETS}/:id`}
              element={<PrivateSavvyRoute path={`${PATH_UNASSIGNED_TICKETS}/:id`} element={<LazyUnassignedTicketsPage />} />}
            />
            <Route
              path={PATH_INTERNAL_SERVICE_CENTER}
              element={<PrivateSavvyRoute path={PATH_INTERNAL_SERVICE_CENTER} element={<LazyServiceCenterView />} />}
            />
            <Route
              path={`${PATH_INTERNAL_SERVICE_CENTER}/tickets/:ticketId`}
              element={<PrivateSavvyRoute path={`${PATH_INTERNAL_SERVICE_CENTER}/tickets/:ticketId`} element={<LazyServiceCenterView />} />}
            />
            <Route
              path={`${PATH_SERVICE_CENTER_MANUAL_RATING}/:id`}
              element={
                <PrivateSavvyRoute path={`${PATH_SERVICE_CENTER_MANUAL_RATING}/:id`} element={<LazyServiceCenterManualRatingPage />} />
              }
            />
            <Route path={PATH_SAVVY_ACCOUNT} element={<PrivateSavvyRoute path={PATH_SAVVY_ACCOUNT} element={<LazySavvyAccount />} />} />
            <Route path={PATH_SAVVY_SEARCH} element={<PrivateSavvyRoute path={PATH_SAVVY_SEARCH} element={<LazyAdvancedSearch />} />} />
            <Route
              path={PATH_SAVVY_SEARCH_AIRCRAFT_TYPE}
              element={<PrivateSavvyRoute path={PATH_SAVVY_SEARCH_AIRCRAFT_TYPE} element={<LazySearchAircraftByType />} />}
            />
            <Route
              path={PATH_SAVVY_LOG_BACKFILL}
              element={<PrivateSavvyRoute path={PATH_SAVVY_LOG_BACKFILL} element={<LazySavvyBackfillLogPage />} />}
            />
            <Route
              path={PATH_SAVVY_COI_MANAGEMENT}
              element={<PrivateSavvyRoute path={PATH_SAVVY_COI_MANAGEMENT} element={<LazyManagementCoiPage />} />}
            />
            <Route
              path={PATH_MANAGER_CONFLICTS}
              element={<PrivateSavvyRoute path={PATH_MANAGER_CONFLICTS} element={<LazyManagerConflicts />} />}
            />
            <Route
              path={PATH_SAVVY_COHORT_REPORTS}
              element={<PrivateSavvyRoute path={PATH_SAVVY_COHORT_REPORTS} element={<LazyCohortReports />} />}
            />
            <Route
              path={`${PATH_SAVVY_COHORT_REPORTS}/:id`}
              element={<PrivateSavvyRoute path={`${PATH_SAVVY_COHORT_REPORTS}/:id`} element={<LazyCohortReports />} />}
            />
            <Route
              path={PATH_SAVVY_TIMESHEETS_DASHBOARD}
              element={<PrivateSavvyRoute path={PATH_SAVVY_TIMESHEETS_DASHBOARD} element={<LazyTimesheetOverview />} />}
            />
            <Route
              path={`${PATH_SAVVY_AIRCRAFT_CREATE}/:id`}
              element={<PrivateSavvyRoute path={`${PATH_SAVVY_AIRCRAFT_CREATE}/:id`} element={<LazySavvyAircraftCreate />} />}
            />
            <Route path={PATH_ACTIVE_PREBUY} element={<PrivateSavvyRoute path={PATH_ACTIVE_PREBUY} element={<LazyActivePrebuy />} />} />
            <Route path={PATH_FEVA_ALERTS} element={<PrivateSavvyRoute path={PATH_FEVA_ALERTS} element={<LazyFevaAlerts />} />} />
            <Route
              path={`${PATH_FEVA_VIEW}/:id`}
              element={<PrivateSavvyRoute path={`${PATH_FEVA_VIEW}/:id`} element={<LazyFevaAlertView />} />}
            />
            <Route
              path={PATH_ANALYSIS_DASHBOARD}
              element={<PrivateSavvyRoute path={PATH_ANALYSIS_DASHBOARD} element={<LazyAnalysisDashboard />} />}
            />
            <Route
              path={`${PATH_FLIGHT_REPORT}/generate/:tagId`}
              element={<PrivateSavvyRoute path={`${PATH_FLIGHT_REPORT}/generate/:tagId`} element={<LazyCreateAnalysisReport />} />}
            />
            <Route
              path={`${PATH_FLIGHT_REPORT}/:aircraftId/:reportId`}
              element={<PrivateSavvyRoute path={`${PATH_FLIGHT_REPORT}/:aircraftId/:reportId`} element={<LazyAnalysisReports />} />}
            />
            <Route
              path={PATH_SAVVY_TIMESHEETS_RATE}
              element={<PrivateSavvyRoute path={PATH_SAVVY_TIMESHEETS_RATE} element={<LazyTimesheetRates />} />}
            />

            <Route
              path={PATH_SAVVY_SEARCH_TICKET}
              element={<PrivateSavvyRoute path={PATH_SAVVY_SEARCH_TICKET} element={<LazyTicketSearch />} />}
            />
            <Route
              path={PATH_SAVVY_SERACH_CONTACT}
              element={<PrivateSavvyRoute path={PATH_SAVVY_SERACH_CONTACT} element={<LazyContactSearch />} />}
            />
            <Route
              path={PATH_SAVVY_SEARCH_AIRCRAFT}
              element={<PrivateSavvyRoute path={PATH_SAVVY_SEARCH_AIRCRAFT} element={<LazyAircraftSearch />} />}
            />
            <Route
              path={PATH_SAVVY_SEARCH_SUBSCRIPTION}
              element={<PrivateSavvyRoute path={PATH_SAVVY_SEARCH_SUBSCRIPTION} element={<LazySubscriptionSearch />} />}
            />
            <Route
              path={PATH_SAVVY_SEARCH_SERVICE_CENTER}
              element={<PrivateSavvyRoute path={PATH_SAVVY_SEARCH_SERVICE_CENTER} element={<LazyServiceCenterSearch />} />}
            />
            <Route
              path={PATH_SAVVY_SEARCH_FLIGHT_REPORT}
              element={<PrivateSavvyRoute path={PATH_SAVVY_SEARCH_FLIGHT_REPORT} element={<LazyFlightReportSearch />} />}
            />
            <Route
              path={PATH_SAVVY_SEARCH_PREBUY_INQUIRY}
              element={<PrivateSavvyRoute path={PATH_SAVVY_SEARCH_PREBUY_INQUIRY} element={<LazyPrebuyInquirySearch />} />}
            />

            <Route path={PATH_SAVVY_OOP} element={<PrivateSavvyRoute path={PATH_SAVVY_OOP} element={<LazyOopList />} />} />
            <Route
              path={PATH_SAVVY_KEY_RESULTS_REPORT}
              element={<PrivateSavvyRoute path={PATH_SAVVY_KEY_RESULTS_REPORT} element={<LazyKeyResultsReport />} />}
            />
            <Route
              path={`${PATH_SAVVY_KEY_RESULTS_REPORT}/:year/:month`}
              element={<PrivateSavvyRoute path={`${PATH_SAVVY_KEY_RESULTS_REPORT}/:year/:month`} element={<LazyKeyResultsReport />} />}
            />
            <Route
              path={PATH_SAVVY_CANNED_RESPONSES}
              element={<PrivateSavvyRoute path={PATH_SAVVY_CANNED_RESPONSES} element={<LazyCannedResponses />} />}
            />

            <Route
              path={PATH_SAVVY_FUTURE_COHORTS}
              element={<PrivateSavvyRoute path={PATH_SAVVY_FUTURE_COHORTS} element={<LazyFutureCohorts />} />}
            />
            <Route
              path={`${PATH_SAVVY_PREBUY_INQUIRY}/:id`}
              element={<PrivateSavvyRoute path={`${PATH_SAVVY_PREBUY_INQUIRY}/:id`} element={<LazyPrebuyInquiry />} />}
            />
            <Route path={PATH_SAVVY_COHORTS} element={<PrivateSavvyRoute path={PATH_SAVVY_COHORTS} element={<LazyCohorts />} />} />
            <Route
              path={`${PATH_SAVVY_COHORTS}/:id`}
              element={<PrivateSavvyRoute path={`${PATH_SAVVY_COHORTS}/:id`} element={<LazyViewCohort />} />}
            />
            <Route path={PATH_SAVVY_FINANCIALS} element={<PrivateSavvyRoute path={PATH_SAVVY_FINANCIALS} element={<LazyFinancials />} />} />

            <Route
              path={PATH_SAVVY_SUBMITTED_AIRCRAFT_DATA}
              element={<PrivateSavvyRoute path={PATH_SAVVY_SUBMITTED_AIRCRAFT_DATA} element={<LazyAircraftSubmittedData />} />}
            />
            <Route
              path={`${PATH_SAVVY_SUBMITTED_AIRCRAFT_DATA}/:type`}
              element={<PrivateSavvyRoute path={`${PATH_SAVVY_SUBMITTED_AIRCRAFT_DATA}/:type`} element={<LazyViewSubmittedData />} />}
            />

            <Route
              path={PATH_SAVVY_ENGINE_PARAMETERS}
              element={<PrivateSavvyRoute path={PATH_SAVVY_ENGINE_PARAMETERS} element={<LazyPowerModelList />} />}
            />
            <Route
              path={`${PATH_SAVVY_ENGINE_PARAMETERS}/create`}
              element={<PrivateSavvyRoute path={`${PATH_SAVVY_ENGINE_PARAMETERS}/create`} element={<LazyCreatePowerModel />} />}
            />
            <Route
              path={`${PATH_SAVVY_ENGINE_PARAMETERS}/:id`}
              element={<PrivateSavvyRoute path={`${PATH_SAVVY_ENGINE_PARAMETERS}/:id`} element={<LazyViewPowerModel />} />}
            />
            <Route
              path={`${PATH_SAVVY_ENGINE_PARAMETERS}/:id/edit`}
              element={<PrivateSavvyRoute path={`${PATH_SAVVY_ENGINE_PARAMETERS}/:id/edit`} element={<LazyEditPowerModel />} />}
            />
            <Route
              path={`${PATH_CREATE_PREBUY_CONFLICT_TICKET}/:aircraftId`}
              element={
                <PrivateSavvyRoute
                  path={`${PATH_CREATE_PREBUY_CONFLICT_TICKET}/:aircraftId`}
                  element={<LazyCreatePrebuyConflictTicket />}
                />
              }
            />
            <Route
              path={PATH_INTERNAL_SERVICE_CENTER_RATING}
              element={<PrivateSavvyRoute path={PATH_INTERNAL_SERVICE_CENTER_RATING} element={<LazyServiceCenterRatingPage />} />}
            />
            <Route
              path={PATH_INTERNAL_TICKETRATINGS}
              element={<PrivateSavvyRoute path={PATH_INTERNAL_TICKETRATINGS} element={<LazyTicketRatingPage />} />}
            />
            <Route
              path={PATH_SAVVY_TECHS_WITH_UPDATE_REQUEST}
              element={<PrivateSavvyRoute path={PATH_SAVVY_TECHS_WITH_UPDATE_REQUEST} element={<LazyTechsWithUpdateRequest />} />}
            />
            <Route path={PATH_MAKE_PAYMENTS} element={<PrivateSavvyRoute path={PATH_MAKE_PAYMENTS} element={<LazyMakePayments />} />} />

            <Route
              path={`${PATH_SAVVY_BORESCOPE_ANALYSIS_REPORT_GENERATE}/:aircraftId/:borescopeImageSetId`}
              element={
                <PrivateSavvyRoute
                  path={`${PATH_SAVVY_BORESCOPE_ANALYSIS_REPORT_GENERATE}/:aircraftId/:borescopeImageSetId`}
                  element={<LazyCreateBorescopeAnalysisReport />}
                />
              }
            />

            <Route
              path={`${PATH_SAVVY_BORESCOPE_ANALYSIS_REPORT_VIEW}/:aircraftId/:borescopeImageSetId`}
              element={
                <PrivateSavvyRoute
                  path={`${PATH_SAVVY_BORESCOPE_ANALYSIS_REPORT_VIEW}/:aircraftId/:borescopeImageSetId`}
                  element={<LazySavvyBorescopeReport />}
                />
              }
            />

            <Route path={PATH_SC_DASHBOARD} element={<PrivateTechRoute path={PATH_SC_DASHBOARD} element={<TechDashboard />} />} />
            <Route path={PATH_SC_FLIGHTS} element={<PrivateTechRoute path={PATH_SC_FLIGHTS} element={<Flights />} />} />
            <Route
              path={`${PATH_SC_FLIGHTS}/aircraft/:aircraftId`}
              element={<PrivateTechRoute path={`${PATH_SC_FLIGHTS}/aircraft/:aircraftId`} element={<Flights />} />}
            />
            <Route
              path={`${PATH_VERIFY_SERVICE_CENTER_FOR_TECH}/:id`}
              element={<PrivateTechRoute path={`${PATH_VERIFY_SERVICE_CENTER_FOR_TECH}/:id`} element={<VerifyServiceCenter />} />}
            />
            <Route
              path={`${PATH_UPDATE_SERVICE_CENTER_FOR_TECH}/:id`}
              element={<PrivateTechRoute path={`${PATH_UPDATE_SERVICE_CENTER_FOR_TECH}/:id`} element={<UpdateServiceCenter />} />}
            />
            <Route
              path={PATH_UPDATE_TECHNICIAN}
              element={<PrivateTechRoute path={PATH_UPDATE_TECHNICIAN} element={<UpdateTechnician />} />}
            />
            <Route
              path={PATH_VERIFY_TECHNICIAN}
              element={<PrivateTechRoute path={PATH_VERIFY_TECHNICIAN} element={<VerifyTechnician />} />}
            />

            <Route path={PATH_POSTMARK_TEMPLATES} element={<PrivateSavvyRoute path={PATH_POSTMARK_TEMPLATES} element={LazyPostmark} />} />
            <Route path={PATH_API_TEMPLATES} element={<PrivateSavvyRoute path={PATH_API_TEMPLATES} element={LazyAPItemplate} />} />

            {/* 404. Not found */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </React.Suspense>
      </BrowserRouter>
    </Fragment>
  );
};

export default Router;
