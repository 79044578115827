import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { useMutation } from '@apollo/client';
import { MUTATION_FORGOT_PASSWORD } from 'gql';

import { Grid, Typography, InputAdornment, IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { Formik, Form } from 'formik';

import { LayoutLoggedOut, Subsection, ButtonSubmit, FormGroupInput, ComponentLoading } from 'components';

import * as Yup from 'yup';
import { setAlert } from 'state';
import { PATH_LOGIN } from 'const';

interface ForgotPasswordFormValues {
  password: string;
  confirmPassword: string;
}

const validFormSchema = Yup.object().shape({
  password: Yup.string().min(8, 'Too short: 8-30 characters required').max(30, 'Too long: 8-30 characters required').required('Required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Required'),
});

// Main export
const ForgotPassword: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useParams();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const onSubmit = async (values: ForgotPasswordFormValues) => {
    await forgotPassword({
      variables: {
        password1: values.password,
        password2: values.confirmPassword,
        token,
      },
    });
  };

  // GraphQL
  const [forgotPassword, { loading, data, error }] = useMutation(MUTATION_FORGOT_PASSWORD, {
    errorPolicy: 'all',
  });

  // Send Error
  useEffect(() => {
    if (error) {
      dispatch(setAlert('error', 'Unable to set new password'));
    } else if (data) {
      if (data.forgotPassword?.ok) {
        dispatch(setAlert('success', 'Set new password'));
        setTimeout(() => {
          navigate(PATH_LOGIN);
        }, 300);
      } else {
        // Success
        dispatch(setAlert('error', data.forgotPassword?.error || 'Unable to set new password'));
      }
    }
  }, [error, data, dispatch, navigate]);

  // Default return - Form
  return (
    <LayoutLoggedOut>
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} sm={10} md={6} lg={5}>
          <ComponentLoading loading={loading}>
            <Subsection>
              <Typography variant="h1" align="center" gutterBottom>
                Set New Password
              </Typography>
            </Subsection>

            <Formik
              initialValues={{
                password: '',
                confirmPassword: '',
              }}
              onSubmit={onSubmit}
              validationSchema={validFormSchema}
            >
              {({ isSubmitting, handleChange, handleBlur, touched, errors }) => {
                return (
                  <Form>
                    <Subsection>
                      <FormGroupInput
                        name="password"
                        label="New Password *"
                        type={showPassword ? 'text' : 'password'}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={() => setShowPassword(!showPassword)}>
                                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <FormGroupInput
                        name="confirmPassword"
                        label="Confirm Password *"
                        type={showConfirmPassword ? 'text' : 'password'}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                {showConfirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Subsection>
                    <Subsection>
                      <ButtonSubmit text="Submit" loading={isSubmitting || loading} />
                    </Subsection>
                  </Form>
                );
              }}
            </Formik>
          </ComponentLoading>
        </Grid>
      </Grid>
    </LayoutLoggedOut>
  );
};

export default ForgotPassword;
