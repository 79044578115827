import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Button } from '@mui/material';
import { IAircraftEligibility, ITicketEligibility } from 'types';
import { aircraftRegistrationMakeModel } from 'helpers';
import { getServiceName, joinOrString } from './utils';
import { PATH_BETA_PLANS } from 'const';

interface RequirePurchaseProps {
  ticketAircraft: IAircraftEligibility | undefined;
  eligibility: ITicketEligibility | undefined;
  serviceName?: string;
}

const RequirePurchase: React.FC<RequirePurchaseProps> = (props) => {
  const { ticketAircraft, eligibility, serviceName = '' } = props;
  const navigate = useNavigate();

  if (eligibility?.free) return null;

  if (eligibility?.eligible && eligibility?.currentlyEligibleVia?.length) return null;

  return (
    <Box py={4}>
      <Typography
        variant="h2"
        color={serviceName === 'SavvyPrebuy' ? 'normal' : 'error'}
        textAlign="center"
        gutterBottom
        sx={{
          fontSize: '20px',
          fontWeight: 600,
        }}
      >
        {serviceName === 'SavvyPrebuy'
          ? 'If you are considering purchasing a new aircraft and you would like to signup for SavvyPrebuy, please click on the button below.'
          : `${eligibility ? joinOrString(eligibility.eligibleViaPurchase) : serviceName} required for this ticket.`}
      </Typography>
      {ticketAircraft && eligibility && (
        <Box
          mb={3}
          sx={{
            textAlign: 'center',
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              if (ticketAircraft) {
                navigate(`${PATH_BETA_PLANS}/${ticketAircraft?.id}`);
              }
            }}
          >
            Manage Plans for {aircraftRegistrationMakeModel(ticketAircraft)}
          </Button>
        </Box>
      )}
      <Typography
        variant="h2"
        color="error"
        textAlign="center"
        gutterBottom
        sx={{
          fontSize: '20px',
          fontWeight: 600,
        }}
      >
        You can buy one for a new aircraft by the link:
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mb: 3,
        }}
      >
        {eligibility &&
          eligibility.eligibleViaPurchase.map((service: string, key: number) => {
            return (
              <Box key={key} px={0.5}>
                <Button
                  variant="contained"
                  onClick={() => {
                    if (ticketAircraft) {
                      navigate(`${PATH_BETA_PLANS}/${ticketAircraft?.id}/purchase-new/${service}`);
                    }
                  }}
                >
                  Buy {getServiceName(service)}
                </Button>
              </Box>
            );
          })}
        {!eligibility && serviceName === 'SavvyPrebuy' && (
          <Box px={0.5}>
            <Button
              variant="contained"
              onClick={() => {
                if (ticketAircraft) {
                  navigate(`${PATH_BETA_PLANS}/${ticketAircraft.id}/purchase-new/prebuy`);
                } else {
                  navigate(PATH_BETA_PLANS);
                }
              }}
            >
              Buy {serviceName}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default RequirePurchase;
