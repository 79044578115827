import Highcharts from 'highcharts';
import { binarySearchInexact } from 'helpers';

const MAX_POINTS_PER_SERIE = 500;

class ZoomSync {
  history_disabled: boolean;
  zoom_disabled: boolean;
  zoomStack: any;
  series: {
    name: string;
    data: number[][];
  }[];
  min_x: number;
  max_x: number;
  xAxis_data: number[];
  point_start: number | undefined;
  appliedSeries: any;
  feature_flag: boolean;

  constructor() {
    this.history_disabled = false;
    this.zoomStack = {};
    this.zoom_disabled = false;
    this.series = [];
    this.min_x = -1;
    this.max_x = Number.MAX_SAFE_INTEGER;
    this.xAxis_data = [];
    this.point_start = undefined;
    this.appliedSeries = {};
    this.feature_flag = false;
  }

  setFeatureFlag(this: ZoomSync, flag: boolean) {
    this.feature_flag = flag;
  }

  _isZoomed(this: ZoomSync, chart: Highcharts.Chart | undefined) {
    if (!chart) return false;

    if (this.zoomStack[chart.container.id] === undefined) return false;

    return this.zoomStack[chart.container.id].length > 1;
  }

  isZoomed = this._isZoomed.bind(this);

  addSeries(this: ZoomSync, name: string, data: number[][]) {
    for (let i = 0; i < this.series.length; i++) {
      if (this.series[i].name === name) {
        this.series[i].data = data;
        return;
      }
    }

    this.series.push({
      name,
      data,
    });
    return;
  }

  filter(this: ZoomSync, data: number[][]) {
    let minIndex = -1;
    let maxIndex = -1;
    if (this.feature_flag) {
      if (this.min_x === -1) {
        minIndex = 0;
      } else {
        minIndex = binarySearchInexact(this.xAxis_data, this.min_x);
      }

      if (this.max_x === Number.MAX_SAFE_INTEGER) {
        maxIndex = this.xAxis_data.length - 1;
      } else {
        maxIndex = binarySearchInexact(this.xAxis_data, this.max_x);
      }

      this.point_start = this.xAxis_data[minIndex];

      const dataLen = maxIndex - minIndex;
      let skipFactor = dataLen / MAX_POINTS_PER_SERIE;
      if (skipFactor <= 1) {
        skipFactor = 1;
      }

      if (skipFactor <= 1) {
        return {
          data,
          skipFactor,
        };
      }

      var sampled = [],
        sampled_index = 0;
      var a: any = 0,
        max_area_point,
        max_area,
        area,
        next_a;

      sampled[sampled_index++] = data[a];
      let i = 0;
      for (i = 0; i < Math.floor(this.xAxis_data.length / skipFactor); i++) {
        var avg_x = 0,
          avg_y = 0,
          avg_range_start = Math.floor((i + 1) * skipFactor) + 1,
          avg_range_end = Math.floor((i + 2) * skipFactor) + 1;
        avg_range_end = avg_range_end < this.xAxis_data.length ? avg_range_end : this.xAxis_data.length;
        var avg_range_length = avg_range_end - avg_range_start;

        for (; avg_range_start < avg_range_end; avg_range_start++) {
          avg_x += data[avg_range_start][0] * 1;
          avg_y += data[avg_range_start][1] * 1;
        }
        avg_x /= avg_range_length;
        avg_y /= avg_range_length;
        var range_offs = Math.floor((i + 0) * skipFactor) + 1,
          range_to = Math.floor((i + 1) * skipFactor) + 1;
        var point_a_x = data[a][0] * 1,
          point_a_y = data[a][1] * 1;
        max_area = area = -1;
        for (; range_offs < range_to; range_offs++) {
          area =
            Math.abs((point_a_x - avg_x) * (data[range_offs][1] - point_a_y) - (point_a_x - data[range_offs][0]) * (avg_y - point_a_y)) *
            0.5;
          if (area > max_area) {
            max_area = area;
            max_area_point = [avg_x, data[range_offs][1]];
            next_a = range_offs;
          }
        }
        sampled[sampled_index++] = max_area_point;
        a = next_a;
      }
      if (Math.floor(this.xAxis_data.length / skipFactor) < this.xAxis_data.length / skipFactor) {
        sampled[sampled_index++] = data[this.xAxis_data.length - 1];
      }
      return {
        data: [...sampled],
        skipFactor: skipFactor,
      };
    }
    if (this.min_x === -1) {
      minIndex = 0;
    } else {
      minIndex = binarySearchInexact(this.xAxis_data, this.min_x);
    }

    if (this.max_x === Number.MAX_SAFE_INTEGER) {
      maxIndex = this.xAxis_data.length - 1;
    } else {
      maxIndex = binarySearchInexact(this.xAxis_data, this.max_x);
    }

    this.point_start = this.xAxis_data[minIndex];

    const dataLen = maxIndex - minIndex;
    let skipFactor = Math.floor(dataLen / MAX_POINTS_PER_SERIE);

    if (skipFactor <= 1) {
      skipFactor = 1;
    }

    if (skipFactor <= 1) {
      return {
        data,
        skipFactor,
      };
    }

    const newData: number[][] = [];

    let i = 0;

    for (i = 0; i < this.xAxis_data.length; i += skipFactor) {
      newData.push(data[i]);
    }

    if (i - skipFactor !== this.xAxis_data.length - 1) {
      newData.push(data[this.xAxis_data.length - 1]);
    }

    return {
      data: [...newData],
      skipFactor: skipFactor,
    };
  }

  downSample(this: ZoomSync, name: string) {
    for (const serie of this.series) {
      if (serie.name === name) return this.filter(serie.data);
    }

    return {
      data: [],
      skipFactor: 0,
    };
  }

  downSampleAllSeries(this: ZoomSync) {
    for (const chart of Highcharts.charts) {
      if (!chart) continue;

      for (const serie of chart.series) {
        this.appliedSeries[serie.name] = this.downSample(serie.name).data;
      }
    }
  }

  resetSeriesData(this: ZoomSync) {
    for (const chart of Highcharts.charts) {
      if (!chart) continue;

      for (const serie of chart.series as any) {
        if (serie.pointInterval === undefined) {
          serie.pointStart = this.point_start;
        }

        // serie.setData(this.appliedSeries)
        if (this.appliedSeries[serie.name]) {
          serie.setData(this.appliedSeries[serie.name], false, false, false);
        }
      }

      // chart.redraw();
    }
  }

  // setSerieData(this: ZoomSync) {
  //   for (const chart of Highcharts.charts) {
  //     if (!chart)
  //       continue;

  //     for (const serie of chart.series) {
  //       if (!serie)
  //         continue;

  //       let { data } = this.downSample(serie.name);
  //       if (serie.name === "CHT1") {
  //         console.log([...data]);
  //       }
  //       serie.setData(data, false);
  //     }
  //   }
  // }

  noteZoomLevels(this: ZoomSync, chart: Highcharts.Chart | undefined) {
    if (!chart) return;

    if (this.history_disabled) return;

    const xextremes = chart.xAxis[0].getExtremes();
    const y0extremes = chart.yAxis[0].getExtremes();
    const y1extremes = chart.yAxis[1].getExtremes();

    if (this.zoomStack[chart.container.id] === undefined) {
      this.zoomStack[chart.container.id] = [];
    }

    if (this.zoomStack[chart.container.id].length > 0) {
      const last = this.zoomStack[chart.container.id][this.zoomStack[chart.container.id].length - 1];
      if (
        last['xmin'] === xextremes.min &&
        last['xmax'] === xextremes.max &&
        last['y0min'] === y0extremes.min &&
        last['y0max'] === y0extremes.max &&
        last['y1min'] === y1extremes.min &&
        last['y1max'] === y1extremes.max
      ) {
        return;
      }
    }

    this.zoomStack[chart.container.id].push({
      xmin: xextremes.min,
      xmax: xextremes.max,
      y0min: y0extremes.min,
      y0max: y0extremes.max,
      y1min: y1extremes.min,
      y1max: y1extremes.max,
    });
  }

  minZoom(this: ZoomSync, chart: Highcharts.Chart | undefined) {
    if (!chart) return null;

    if (this.zoomStack[chart.container.id]) {
      const level = this.zoomStack[chart.container.id][0];

      if (level) return { min: level.xmin, max: level.xmax };
    }

    return null;
  }

  chartZoomed(this: ZoomSync, chart: Highcharts.Chart | undefined, min: number, max: number) {
    if (!chart) return;

    if (this.zoom_disabled) return;

    this.min_x = min;
    this.max_x = max;

    this.downSampleAllSeries();
    this.resetSeriesData();

    for (let i = 0; i < Highcharts.charts.length; i++) {
      if (!Highcharts.charts[i]) continue;

      if (chart.container.id !== Highcharts.charts[i]?.container.id) {
        this.zoom_disabled = true;
        Highcharts.charts[i]?.xAxis[0].setExtremes(min, max, true);
        this.zoom_disabled = false;
      }
    }
  }

  resetZoom(this: ZoomSync) {
    this.zoomStack = {};
    this.zoom_disabled = true;
    this.min_x = -1;
    this.max_x = Number.MAX_SAFE_INTEGER;

    // this.setSerieData();
    this.downSampleAllSeries();
    this.resetSeriesData();

    for (let i = 0; i < Highcharts.charts.length; i++) {
      if (!Highcharts.charts[i]) continue;

      Highcharts.charts[i]?.xAxis[0].setExtremes();
      Highcharts.charts[i]?.yAxis[0].setExtremes();
      Highcharts.charts[i]?.yAxis[1].setExtremes();
      // break;
    }

    this.zoom_disabled = false;
  }

  undoZoom(this: ZoomSync) {
    this.zoom_disabled = true;

    let firstTime = true;

    for (const chart of Highcharts.charts) {
      if (!chart) continue;

      if (this.zoomStack[chart.container.id].length <= 1) {
        this.zoom_disabled = true;
        return;
      }

      this.zoomStack[chart.container.id].pop();
      const last = this.zoomStack[chart.container.id].pop();

      if (last === undefined) {
        this.zoom_disabled = true;
        return;
      }

      if (firstTime) {
        this.min_x = last['xmin'];
        this.max_x = last['xmax'];

        // this.setSerieData();
        this.downSampleAllSeries();
        this.resetSeriesData();
        firstTime = false;
      }

      if (this.zoomStack[chart.container.id].length === 0) {
        this.zoom_disabled = true;
      }

      chart.xAxis[0].setExtremes(last['xmin'], last['xmax'], false);
      if (!isNaN(last['y0min']) && !isNaN(last['y0max'])) chart.yAxis[0].setExtremes(last['y0min'], last['y0max'], false);
      if (!isNaN(last['y1min']) && !isNaN(last['y1max'])) chart.yAxis[1].setExtremes(last['y1min'], last['y1max'], false);
      // Redraw only once
      chart.redraw();
    }

    this.zoom_disabled = false;
  }

  isAbleUndoZoom(this: ZoomSync) {
    let ableUndoZoom = false;

    for (const chart of Highcharts.charts) {
      if (!chart) continue;

      if (this.zoomStack[chart.container.id].length > 1) {
        ableUndoZoom = true;
      }
    }

    if (ableUndoZoom) return true;

    return false;
  }
}

export default ZoomSync;
