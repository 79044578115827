import { gql } from '@apollo/client';
import {
  FRAGMENT_DOCUMENT,
  FRAGMENT_ELIGIBILITY_DETAILS,
  FRAGMENT_COI,
  FRAGMENT_MAINTENANCE_ENTRY,
  FRAGMENT_AIRCRAFT_UPGRADE_CONTEXT,
  FRAGMENT_AIRCRAFT_LOGBOOK,
  FRAGMENT_FLIGHT_REPORT,
  FRAGMENT_ENGINE_PARAMETERS,
  FRAGEMENT_SERVICE_CENTER,
  FRAGMENT_BORESCOPE_IMAGE_SET,
} from 'gql';

export const QUERY_USER_ONLY_AIRCRAFTS = gql`
  query MeAircraftsOnly($id: Int) {
    me {
      id
      aircraft(id: $id) {
        id
        aircraftManufacturer {
          id
          name
        }
        aircraftModel {
          id
          name
          propulsion
          enginesCount
        }
        registration
        subscriptions {
          id
          serviceName
          serviceCode
          status
        }
        year
        serial
        default
        managePlansLink
      }
    }
  }
`;

export const QUERY_USER_AIRCRAFTS_WITHOUT_SUBSCRIPTION = gql`
  query MeAircraft {
    me {
      id
      aircraft {
        id
        aircraftManufacturer {
          id
          name
        }
        aircraftModel {
          id
          name
          propulsion
          enginesCount
        }
        registration
        default
        directUpload
      }
    }
  }
`;

export const QUERY_AIRCRAFT_MANUFACTURERS = gql`
  query AircraftManufacturer {
    aircraftManufacturer {
      id
      name
      aircraftmodelSet {
        id
        name
      }
    }
  }
`;

export const QUERY_ALL_AIRCRAFT_MODEL = gql`
  query AllAircraftModel {
    aircraftModel {
      id
      name
    }
  }
`;

// Fuzzy Aircraft Manufacturer
export const QUERY_FUZZY_AIRCRAFT_MANUFACTURERS = gql`
  query FuzzyAircraftManufacturers($search: String!) {
    fuzzyAircraftManufacturers(search: $search) {
      id
      name
      aircraftmodelSet {
        id
        name
      }
    }
  }
`;

// Fuzzy Aircraft Model
export const QUERY_FUZZY_AIRCRAFT_MODELS = gql`
  query FuzzyAircraftModels($search: String!, $manufacturerId: ID) {
    fuzzyAircraftModels(search: $search, manufacturerId: $manufacturerId) {
      id
      name
    }
  }
`;

// Fuzzy Aircraft Engine Manufacturer
export const QUERY_FUZZY_AIRCRAFT_ENGINE_MANUFACTURERS = gql`
  query FuzzyEngineManufacturers($search: String!) {
    fuzzyEngineManufacturers(search: $search) {
      id
      name
      enginemodelSet {
        id
        name
      }
    }
  }
`;

// Fuzzy Aircraft Engine Model
export const QUERY_FUZZY_AIRCRAFT_ENGINE_MODELS = gql`
  query FuzzyEngineModels($search: String!, $manufacturerId: ID) {
    fuzzyEngineModels(search: $search, manufacturerId: $manufacturerId) {
      id
      name
    }
  }
`;

// Fuzzy Aircraft Engine Monitor Manufacturers
export const QUERY_FUZZY_AIRCRAFT_ENGINE_MONITOR_MANUFACTURERS = gql`
  query FuzzyEnginemonitorManufacturers($search: String!) {
    fuzzyEnginemonitorManufacturers(search: $search) {
      id
      name
    }
  }
`;

// Fuzzy Aircraft Engine Monitor Models
export const QUERY_FUZZY_AIRCRAFT_ENGINE_MONITOR_MODELS = gql`
  query FuzzyEnginemonitorModels($search: String!, $manufacturerId: ID) {
    fuzzyEnginemonitorModels(search: $search, manufacturerId: $manufacturerId) {
      id
      name
    }
  }
`;

export const QUERY_AIRCRAFT_MODELS = gql`
  query AircraftModel($name: String, $manufacturerId: ID) {
    aircraftModel(name: $name, manufacturerId: $manufacturerId) {
      id
      name
      eligibleServices {
        serviceName
        serviceCode
        price
      }
    }
  }
`;

export const QUERY_AIRCRAFT_MODEL = gql`
  query AircraftModel($name: String!) {
    aircraftManufacturer(name: $name) {
      aircraftmodelSet {
        id
        name
      }
    }
  }
`;

export const QUERY_ENGINE_MANUFACTURER = gql`
  query EngineManufacturer {
    engineManufacturer {
      id
      name
      enginemodelSet {
        id
        name
      }
    }
  }
`;

export const QUERY_ENGINE_MODEL = gql`
  query EngineModel {
    engineModel {
      id
      name
    }
  }
`;

export const QUERY_ENGINE_MONITOR_MANUFACTURER = gql`
  query EngineMonitorManufacturer {
    engineMonitorManufacturer {
      id
      name
      enginemonitormodelSet {
        id
        name
      }
    }
  }
`;

export const QUERY_ENGINE_MONITOR_MODEL = gql`
  query EngineMonitorModel {
    engineMonitorModel {
      id
      name
    }
  }
`;

export const QUERY_ELIGIBLE_TYPE_SERVICE = gql`
  query EligibleTypeForService($propulsion: String, $engineCount: String, $serviceCode: String) {
    eligibleTypeForService(propulsion: $propulsion, engineCount: $engineCount, serviceCode: $serviceCode)
  }
`;

export const QUERY_ELIGIBLE_MODEL_SERVICE = gql`
  query EligibleModelForService($aircraftModelId: ID, $serviceCode: String) {
    eligibleModelForService(aircraftModelId: $aircraftModelId, serviceCode: $serviceCode)
  }
`;

export const QUERY_GET_DETAIL_AIRCRAFT = gql`
  ${FRAGEMENT_SERVICE_CENTER}
  query MeDetailAircraft($id: Int!) {
    me {
      id
      aircraft(id: $id) {
        id
        created
        updated
        registration
        serial
        year
        aircraftManufacturer {
          id
          name
          aircraftmodelSet {
            id
            name
          }
        }
        aircraftModel {
          id
          name
          propulsion
          enginesCount
        }
        engineManufacturer {
          id
          name
          enginemodelSet {
            id
            name
          }
        }
        engineModel {
          id
          name
        }
        engineMonitorManufacturer {
          id
          name
          enginemonitormodelSet {
            id
            name
          }
        }
        engineMonitorModel {
          id
          name
        }
        monitorConfig
        showMglSettings
        showUbg16Settings
        aircraftHasNoMonitor
        cylinderCount
        chtWarningTemperature
        flights {
          id
          importFile {
            id
            name
            uploadDate
          }
          date
          departureName
          destinationName
          duration
          departureAirport
          destinationAirport
        }
        subscriptions {
          creationDate
          serviceName
          serviceCode
          status
          amountPaid
          amountRefunded
        }
        canUpdateAircraftMakeModel
        default
        annualDue
        leftIgnition
        rightIgnition
        managePlansLink
        preferredServiceCenter {
          ...ServiceCenter
        }
      }
      airportId
      country
      phoneMobile
    }
  }
`;

export const QUERY_GET_ENGINE_DATA_FILES_FOR_AIRCRAFT = gql`
  query MeAircraftFiles($id: Int) {
    me {
      id
      aircraft(id: $id) {
        id
        engineDataFiles {
          id
          name
          uploadDate
          flightsCount
        }
      }
    }
  }
`;

export const QUERY_GET_ENGINE_DATA_FILE_LOG = gql`
  query MeEngineDataFileLog($id: Int, $engineDataFileId: ID) {
    me {
      id
      aircraft(id: $id) {
        id
        engineDataFiles(engineDataFileId: $engineDataFileId) {
          id
          name
          uploadDate
          flightsCount
          parseLogs
        }
      }
    }
  }
`;

export const QUERY_GET_SAVVY_ENGINE_DATA_FILE_LOG = gql`
  query SavvyEngineDataFileLog($aircraftId: ID, $engineDataFileId: ID) {
    savvy {
      id
      aircraft(aircraftId: $aircraftId) {
        id
        engineDataFiles(engineDataFileId: $engineDataFileId) {
          id
          name
          uploadDate
          flightsCount
          parseLogs
        }
      }
    }
  }
`;

export const QUERY_USER_TICKET_ELIGIBILITY = gql`
  query MeTicketEligibility($id: Int) {
    me {
      id
      aircraft(id: $id) {
        id
        registration
        aircraftManufacturer {
          id
          name
        }
        aircraftModel {
          id
          name
        }
        ticketEligibility {
          analysis {
            eligible
            free
            currentlyEligibleVia
            currentlyEligibleViaSubscription
            eligibleViaPurchase
          }
          service {
            eligible
            free
            currentlyEligibleVia
            currentlyEligibleViaSubscription
            eligibleViaPurchase
          }
          serviceAnnual {
            eligible
            free
            currentlyEligibleVia
            currentlyEligibleViaSubscription
            eligibleViaPurchase
          }
          breakdown {
            eligible
            free
            currentlyEligibleVia
            currentlyEligibleViaSubscription
            eligibleViaPurchase
          }
          techSupport {
            eligible
            free
            currentlyEligibleVia
            currentlyEligibleViaSubscription
            eligibleViaPurchase
          }
          accountSupport {
            eligible
            free
            currentlyEligibleVia
            currentlyEligibleViaSubscription
            eligibleViaPurchase
          }
          prebuy {
            eligible
            free
            currentlyEligibleVia
            currentlyEligibleViaSubscription
            eligibleViaPurchase
          }
          preliminaryLogbookReview {
            eligible
            free
            currentlyEligibleVia
            currentlyEligibleViaSubscription
            eligibleViaPurchase
          }
          borescopeAnalysis {
            eligible
            free
            currentlyEligibleVia
            currentlyEligibleViaSubscription
            eligibleViaPurchase
          }
        }
      }
    }
  }
`;

export const QUERY_USER_ANALYSIS_TICKET_ELIGIBILITY = gql`
  query MeAnalysisTicketEligibility($id: Int) {
    me {
      id
      aircraft(id: $id) {
        id
        ticketEligibility {
          analysis {
            eligible
            free
            currentlyEligibleVia
            currentlyEligibleViaSubscription
            eligibleViaPurchase
          }
        }
      }
    }
  }
`;

export const QUERY_STAGED_ENGINE_DATA_FILE = gql`
  query MeStagedEngineDataFile($id: Int) {
    me {
      id
      aircraft(id: $id) {
        id
        stagedEngineDataFiles {
          id
          name
          uploadDate
          fileSize
          flights {
            id
            date
            departureAirport
            destinationAirport
            duration
            aircraftDuplicate
          }
          stagedFlights {
            id
            date
            departureAirport
            destinationAirport
            duration
            aircraftDuplicate
          }
          uploadStatus {
            message
            status
          }
        }
      }
    }
  }
`;

export const QUERY_REPORT_CARDS_BY_AIRCRAFT = gql`
  query MeReportCardsByAircraft($id: Int) {
    me {
      id
      aircraft(id: $id) {
        id
        cohortExists
        reportCardEligible
        reportCards {
          id
          created
          kind
          dateStart
          dateEnd
          flightCount
          delivery
        }
      }
    }
  }
`;

export const QUERY_REPORT_DETAIL = gql`
  query MeReportDetail($aircraftId: Int, $reportCardId: ID) {
    me {
      id
      aircraft(id: $aircraftId) {
        id
        reportCards(reportCardId: $reportCardId) {
          id
          created
          kind
          dateStart
          dateEnd
          flightCount
          delivery
          context {
            aircraft {
              registration
              aircraftModel {
                name
              }
              aircraftManufacturer {
                name
              }
              engineModel {
                name
              }
              engineManufacturer {
                name
              }
            }
            startDate
            endDate
            numberOfFlights
            cohortSizeAircraft
            cohortSizeFlights
            header
            footer
            elements {
              heading
              uri
              comment
              description
            }
          }
        }
      }
    }
  }
`;

export const QUERY_AIRCRAFT_SUBSCRIPTIONS = gql`
  query MeSubscriptions($id: Int) {
    me {
      id
      aircraft(id: $id) {
        id
        aircraftManufacturer {
          id
          name
          aircraftmodelSet {
            id
            name
          }
        }
        aircraftModel {
          id
          name
        }
        registration
        subscriptions {
          id
          serviceName
          serviceCode
          status
          endDate
        }
      }
    }
  }
`;

export const QUERY_AIRCRAFT_RENEWALS = gql`
  ${FRAGMENT_AIRCRAFT_UPGRADE_CONTEXT}
  query MeAircraftRenewals($id: Int) {
    me {
      id
      aircraft(id: $id) {
        id
        registration
        ...upgradeContext
        aircraftManufacturer {
          id
          name
        }
        aircraftModel {
          id
          name
          enginesCount
          propulsion
        }
        profileComplete
      }
    }
  }
`;

export const QUERY_GET_USER_AIRCRAFTS = gql`
  query GetUserAircrafts($contactId: ID, $aircraftId: Int) {
    savvy {
      id
      contacts(contactId: $contactId) {
        id
        aircraft(id: $aircraftId) {
          id
          serial
          registration
          aircraftManufacturer {
            name
          }
          aircraftModel {
            name
          }
          year
          default
          subscriptions(current: true) {
            id
            serviceName
          }
          hidden
        }
      }
    }
  }
`;

export const QUERY_GET_CONTACT_AIRCRAFT_DETAIL = gql`
  query GetContactAircraftDetail($contactId: ID, $aircraftId: Int) {
    savvy {
      id
      contacts(contactId: $contactId) {
        id
        aircraft(id: $aircraftId) {
          id
          created
          updated
          registration
          serial
          year
          aircraftManufacturer {
            id
            name
            aircraftmodelSet {
              id
              name
            }
          }
          aircraftModel {
            id
            name
          }
          engineManufacturer {
            id
            name
            enginemodelSet {
              id
              name
            }
          }
          engineModel {
            id
            name
          }
          engineMonitorManufacturer {
            id
            name
            enginemonitormodelSet {
              id
              name
            }
          }
          engineMonitorModel {
            id
            name
          }
          aircraftHasNoMonitor
          cylinderCount
          chtWarningTemperature
          engineDataFiles {
            id
            name
            uploadDate
            flightsCount
          }
          canUpdateAircraftMakeModel
          default
          comments {
            id
            pinned
            comment
            poster {
              firstName
              lastName
            }
            date
          }
          subscriptions {
            id
            serviceName
            startDate
            endDate
            terminationCode
          }
        }
      }
    }
  }
`;

export const QUERY_GET_USER_AIRCRAFT_COMMENTS = gql`
  query GetUserAircraftComments($contactId: ID, $aircraftId: Int) {
    savvy {
      id
      contacts(contactId: $contactId) {
        id
        aircraft(id: $aircraftId) {
          id
          comments {
            id
            pinned
            comment
            poster {
              firstName
              lastName
            }
            date
          }
        }
      }
    }
  }
`;

export const QUERY_AIRCRAFT_CONVERSIONS = gql`
  query MeAircraftConversions($aircraftId: Int) {
    me {
      id
      aircraft(id: $aircraftId) {
        id
        aircraftConversions {
          id
          seriesName
          unitconversion {
            id
            fromName
            toName
            groupName
            aParam
            bParam
          }
        }
      }
    }
  }
`;

export const QUERY_AIRCRAFT_RECENT_FLIGHT_SERIES = gql`
  query MeAircraftRecentFlightSeries($aircraftId: Int) {
    me {
      id
      aircraft(id: $aircraftId) {
        id
        recentFlightSeries
      }
    }
  }
`;

export const QUERY_UNIT_CONVERSIONS = gql`
  query UnitConversions {
    unitConversions {
      id
      fromName
      toName
      groupName
      aParam
      bParam
    }
  }
`;

export const QUERY_SAVVY_AIRCRAFT_SEARCH = gql`
  query SavvyAircraftSearch($search: String, $limit: Int, $offset: Int) {
    savvy {
      id
      search(search: $search, limit: $limit) {
        aircrafts(offset: $offset, limit: $limit) {
          count
          results {
            id
            registration
            serial
            aircraftManufacturer {
              name
            }
            aircraftModel {
              name
            }
            owner {
              id
              firstName
              lastName
              email
            }
            hidden
            year
            created
          }
        }
      }
    }
  }
`;

export const QUERY_SAVVY_AIRCRAFT_ELIGIBILITY_SEARCH = gql`
  ${FRAGMENT_ELIGIBILITY_DETAILS}
  query SavvyAircraftEligibilitySearch($search: String, $limit: Int, $offset: Int) {
    savvy {
      search(search: $search, limit: $limit) {
        aircrafts(offset: $offset, limit: $limit) {
          count
          results {
            id
            registration
            serial
            aircraftManufacturer {
              name
            }
            aircraftModel {
              name
            }
            owner {
              id
              firstName
              lastName
              email
            }
            ticketEligibility {
              analysis {
                ...EligibilityDetails
              }
              service {
                ...EligibilityDetails
              }
              serviceAnnual {
                ...EligibilityDetails
              }
              breakdown {
                ...EligibilityDetails
              }
              techSupport {
                ...EligibilityDetails
              }
              accountSupport {
                ...EligibilityDetails
              }
              prebuy {
                ...EligibilityDetails
              }
              preliminaryLogbookReview {
                ...EligibilityDetails
              }
            }
          }
        }
      }
    }
  }
`;

export const QUERY_TICKET_AIRCRAFT_INFO = gql`
  query SavvyTicketAircraftInfo($contactId: ID, $aircraftId: Int) {
    savvy {
      id
      contacts(contactId: $contactId) {
        id
        aircraft(id: $aircraftId) {
          id
          aircraftManufacturer {
            name
          }
          aircraftModel {
            name
          }
          serial
          registration
          flights {
            id
            date
            departureAirport
            destinationAirport
            duration
            importFile {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const QUERY_STAGED_ENGINE_DATA_FILE_FOR_SAVVY = gql`
  query StagedEngineDataFileForSavvy($contactId: ID, $aircraftId: Int) {
    savvy {
      id
      contacts(contactId: $contactId) {
        id
        aircraft(id: $aircraftId) {
          id
          engineDataFiles {
            id
            name
            uploadDate
            flights {
              id
              date
              departureAirport
              destinationAirport
              duration
              aircraftDuplicate
            }
            stagedFlights {
              id
              date
              departureAirport
              destinationAirport
              duration
              aircraftDuplicate
            }
          }
          registration
          serial
          aircraftManufacturer {
            name
          }
          aircraftModel {
            name
          }
          directUpload
        }
      }
    }
  }
`;

export const QUERY_STAGED_ENGINE_DATA_FILE_FOR_SAVVY_AIRCRAFT = gql`
  query StagedEngineDataFileForSavvy($aircraftId: ID) {
    savvy {
      id
      aircraft(aircraftId: $aircraftId) {
        id
        stagedEngineDataFiles {
          id
          name
          uploadDate
          flights {
            id
            date
            departureAirport
            destinationAirport
            duration
            aircraftDuplicate
          }
          stagedFlights {
            id
            date
            departureAirport
            destinationAirport
            duration
            aircraftDuplicate
          }
        }
        engineDataFiles {
          id
          name
          uploadDate
          flights {
            id
            date
            departureAirport
            destinationAirport
            duration
            aircraftDuplicate
          }
          stagedFlights {
            id
            date
            departureAirport
            destinationAirport
            duration
            aircraftDuplicate
          }
        }
        registration
        serial
        aircraftManufacturer {
          name
        }
        aircraftModel {
          name
        }
        directUpload
      }
    }
  }
`;

export const QUERY_SAVVY_CONTACT_AIRCRAFT = gql`
  ${FRAGMENT_ELIGIBILITY_DETAILS}
  query GetContactAircraft($contactId: ID, $aircraftId: Int) {
    savvy {
      id
      contacts(contactId: $contactId) {
        id
        aircraft(id: $aircraftId) {
          id
          serial
          registration
          aircraftManufacturer {
            name
          }
          aircraftModel {
            name
          }
          ticketEligibility {
            analysis {
              ...EligibilityDetails
            }
            service {
              ...EligibilityDetails
            }
            serviceAnnual {
              ...EligibilityDetails
            }
            breakdown {
              ...EligibilityDetails
            }
            techSupport {
              ...EligibilityDetails
            }
            accountSupport {
              ...EligibilityDetails
            }
            prebuy {
              ...EligibilityDetails
            }
            preliminaryLogbookReview {
              ...EligibilityDetails
            }
          }
        }
      }
    }
  }
`;

export const QUERY_SAVVY_AIRCRAFT = gql`
  ${FRAGEMENT_SERVICE_CENTER}
  query SavvyAircraft($aircraftId: ID) {
    savvy {
      id
      permissions {
        aircraft {
          canEditAircraftMakeModelForPaidAircraft
        }
      }
      aircraft(aircraftId: $aircraftId) {
        id
        created
        updated
        registration
        serial
        year
        owner {
          id
          firstName
          lastName
          email
          phoneMobile
          zip
          street
          city
          state
          country
          coordinates {
            latitude
            longitude
          }
          airportId
        }
        aircraftManufacturer {
          id
          name
          aircraftmodelSet {
            id
            name
          }
        }
        aircraftModel {
          id
          name
        }
        engineManufacturer {
          id
          name
          enginemodelSet {
            id
            name
          }
        }
        engineModel {
          id
          name
        }
        engineMonitorManufacturer {
          id
          name
          enginemonitormodelSet {
            id
            name
          }
        }
        engineMonitorModel {
          id
          name
        }
        aircraftHasNoMonitor
        cylinderCount
        chtWarningTemperature
        engineDataFiles {
          id
          name
          uploadDate
        }
        canUpdateAircraftMakeModel
        default
        canDelete
        annualDue
        leftIgnition
        rightIgnition
        hidden
        preferredServiceCenter {
          ...ServiceCenter
        }
        subscriptions {
          id
          serviceCode
          status
        }
        prebuy
      }
    }
  }
`;

export const QUERY_GET_AIRCRAFT_COMMENTS = gql`
  query GetAircraftComments($aircraftId: ID) {
    savvy {
      id
      aircraft(aircraftId: $aircraftId) {
        id
        comments {
          id
          pinned
          comment
          poster {
            firstName
            lastName
          }
          date
        }
      }
    }
  }
`;

export const QUERY_GET_AIRCRAFT_MANAGER = gql`
  query GetAircraftManager($aircraftId: ID) {
    savvy {
      id
      aircraft(aircraftId: $aircraftId) {
        id
        accountManager {
          id
          firstName
          lastName
        }
        backupManager {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const QUERY_AIRCRAFT_DOCUMENTS = gql`
  ${FRAGMENT_DOCUMENT}
  query GetAircraftDocuments($aircraftId: ID) {
    savvy {
      id
      aircraft(aircraftId: $aircraftId) {
        id
        registration
        documents {
          ...Document
        }
      }
      permissions {
        aircraft {
          canCreateMaintenanceEntry
        }
      }
    }
  }
`;

export const QUERY_AIRCRAFT_DOCUMENTS_WITHOUT_PERMISSION = gql`
  ${FRAGMENT_DOCUMENT}
  query GetAircraftDocumentsWithoutPermission($aircraftId: ID) {
    savvy {
      id
      aircraft(aircraftId: $aircraftId) {
        id
        documents {
          ...Document
        }
      }
    }
  }
`;

export const QUERY_SAVVY_AIRCRAFT_COIS = gql`
  ${FRAGMENT_COI}
  query GetAircraftCoi($aircraftId: ID) {
    savvy {
      id
      aircraft(aircraftId: $aircraftId) {
        id
        registration
        coiDate
        coiUnderwriter
        coiAgency
        coiExpiration
        certificatesOfInsurance {
          ...AircraftCOI
        }
        directUpload
      }
      permissions {
        aircraft {
          canManageCois
        }
      }
    }
  }
`;

export const QUERY_AIRCRAFT_DOCUMENT_DATA = gql`
  query GetAircraftDocumentData($aircraftId: ID, $documentId: ID) {
    savvy {
      id
      aircraft(aircraftId: $aircraftId) {
        id
        documents(documentId: $documentId) {
          id
          previewUrl
          fileName
          isImage
          isPdf
          fileType
          base64Data
        }
      }
    }
  }
`;

export const QUERY_AIRCRAFT_DOCUMENT_DOWNLOAD_URL = gql`
  query GetAircraftDocumentDownloadUrl($aircraftId: ID, $documentId: ID) {
    savvy {
      id
      aircraft(aircraftId: $aircraftId) {
        id
        documents(documentId: $documentId) {
          id
          downloadUrl
        }
      }
    }
  }
`;

export const QUERY_AIRCRAFT_FLIGHT_LIST = gql`
  query GetAircraftFlightsList($aircraftId: ID, $hideShortFlights: Boolean) {
    savvy {
      id
      aircraft(aircraftId: $aircraftId) {
        id
        flights(hideShortFlights: $hideShortFlights) {
          id
          date
          duration
          publicUuid
          departureId
          destinationId
          departureName
          destinationName
          userDepartureName
          userDestinationName
          maxChtTemperature
          departureAirport
          destinationAirport
          importFile {
            id
            name
            uploadDate
          }
          chtTemperatureAlert
        }
      }
    }
  }
`;

export const QUERY_ME_AIRCRAFT_COI = gql`
  ${FRAGMENT_COI}
  query MeAircraftCOI($id: Int) {
    me {
      id
      aircraft(id: $id) {
        id
        coiDate
        coiUnderwriter
        coiAgency
        coiExpiration
        certificatesOfInsurance {
          ...AircraftCOI
        }
        directUpload
      }
    }
  }
`;

export const QUERY_ME_LOAD_ATTACHMENT = gql`
  query MeLoadAttachment($id: Int, $attachmentId: ID) {
    me {
      id
      aircraft(id: $id) {
        id
        attachments(attachmentId: $attachmentId) {
          id
          name
          isImage
          isPdf
          previewUrl
          downloadUrl
        }
      }
    }
  }
`;

export const QUERY_SAVVY_AIRCRAFT_COIS_PREVIEW = gql`
  query GetAircraftCoiPreview($aircraftId: ID, $certificateOfInsuranceId: ID) {
    savvy {
      id
      aircraft(aircraftId: $aircraftId) {
        id
        certificatesOfInsurance(certificateOfInsuranceId: $certificateOfInsuranceId) {
          id
          document {
            id
            fileName
            isPdf
            isImage
            previewUrl
            base64Data
          }
        }
      }
    }
  }
`;

export const QUERY_SAVVY_AIRCRAFT_COIS_DOWNLOAD = gql`
  query GetAircraftCoiDownload($aircraftId: ID, $certificateOfInsuranceId: ID) {
    savvy {
      id
      aircraft(aircraftId: $aircraftId) {
        id
        certificatesOfInsurance(certificateOfInsuranceId: $certificateOfInsuranceId) {
          id
          document {
            id
            downloadUrl
          }
        }
      }
    }
  }
`;

export const QUERY_ME_AIRCRAFT_COI_PREVIEW = gql`
  query GetMeAircraftCoiPreview($aircraftId: Int, $certificateOfInsuranceId: ID) {
    me {
      aircraft(id: $aircraftId) {
        id
        certificatesOfInsurance(certificateOfInsuranceId: $certificateOfInsuranceId) {
          id
          document {
            id
            fileName
            isPdf
            isImage
            previewUrl
            base64Data
            fileType
          }
        }
      }
    }
  }
`;

export const QUERY_ME_AIRCRAFT_COI_DOWNLOAD = gql`
  query GetMeAircraftCoiDownload($aircraftId: Int, $certificateOfInsuranceId: ID) {
    me {
      aircraft(id: $aircraftId) {
        id
        certificatesOfInsurance(certificateOfInsuranceId: $certificateOfInsuranceId) {
          id
          document {
            id
            downloadUrl
          }
        }
      }
    }
  }
`;

export const QUERY_SAVVY_AIRCRAFT_MAINTENANCE_ENTRY = gql`
  query GetSavvyAircraftMaintenanceEntry($aircraftId: ID) {
    savvy {
      id
      aircraft(aircraftId: $aircraftId) {
        id
        maintenanceEntries {
          id
          body
          entryDate
          maintenanceEntryCategory {
            id
            name
          }
          document {
            id
            fileName
          }
          isCombo
        }
      }
    }
  }
`;

export const QUERY_ME_AIRCRAFT_LOGBOOKS = gql`
  ${FRAGMENT_AIRCRAFT_LOGBOOK}
  query MeAircraftLogbooks($id: Int) {
    me {
      id
      aircraft(id: $id) {
        id
        logbooks {
          ...Logbook
        }
      }
    }
  }
`;

export const QUERY_ME_AIRCRAFT_LOGS = gql`
  ${FRAGMENT_MAINTENANCE_ENTRY}
  query MeAircraftLogs($id: Int) {
    me {
      id
      aircraft(id: $id) {
        id
        maintenanceEntries {
          ...MaintenanceEntry
        }
      }
    }
  }
`;

export const QUERY_ME_AIRCRAFT_LOG_BASE64 = gql`
  query MeAircraftLogBase64($id: Int, $maintenanceEntryId: ID) {
    me {
      id
      aircraft(id: $id) {
        id
        maintenanceEntries(maintenanceEntryId: $maintenanceEntryId) {
          id
          document {
            id
            fileName
            isImage
            isPdf
            previewUrl
            base64Data
          }
        }
      }
    }
  }
`;

export const QUERY_ME_AIRCRAFT_LOG_DOWNLOAD = gql`
  query MeAircraftLogDownload($id: Int, $maintenanceEntryId: ID) {
    me {
      id
      aircraft(id: $id) {
        id
        maintenanceEntries(maintenanceEntryId: $maintenanceEntryId) {
          id
          document {
            id
            downloadUrl
          }
        }
      }
    }
  }
`;

export const QUERY_SAVVY_AIRCRAFT_LOGBOOKS = gql`
  ${FRAGMENT_AIRCRAFT_LOGBOOK}
  query SavvyAircraftLogBooks($aircraftId: ID) {
    savvy {
      id
      aircraft(aircraftId: $aircraftId) {
        id
        logbooks {
          ...Logbook
        }
      }
      permissions {
        aircraft {
          canCreateMaintenanceEntry
        }
      }
    }
  }
`;

export const QUERY_SAVVY_AIRCRAFT_LOGS = gql`
  ${FRAGMENT_MAINTENANCE_ENTRY}
  query SavvyAircraftLogs($aircraftId: ID) {
    savvy {
      id
      aircraft(aircraftId: $aircraftId) {
        id
        maintenanceEntries {
          ...MaintenanceEntry
        }
      }
    }
  }
`;

export const QUERY_SAVVY_AIRCRAFT_LOG_BASE64 = gql`
  query SavvyAircraftLogBase64($aircraftId: ID, $maintenanceEntryId: ID) {
    savvy {
      id
      aircraft(aircraftId: $aircraftId) {
        id
        maintenanceEntries(maintenanceEntryId: $maintenanceEntryId) {
          id
          document {
            id
            fileName
            previewUrl
            isImage
            isPdf
            base64Data
          }
        }
      }
    }
  }
`;

export const QUERY_SAVVY_AIRCRAFT_LOG_DOWNLOAD = gql`
  query SavvyAircraftLogDownload($aircraftId: ID, $maintenanceEntryId: ID) {
    savvy {
      id
      aircraft(aircraftId: $aircraftId) {
        id
        maintenanceEntries(maintenanceEntryId: $maintenanceEntryId) {
          id
          document {
            id
            downloadUrl
          }
        }
      }
    }
  }
`;

export const QUERY_AIRCRAFT_WITHOUT_LOG = gql`
  query AircraftWithoutLogs($serviceCode: String) {
    aircraftWithoutLogs(serviceCode: $serviceCode) {
      id
      registration
      aircraftManufacturer {
        id
        name
      }
      aircraftModel {
        id
        name
      }
      documents {
        id
      }
    }
  }
`;

export const QUERY_MAINTENANCE_RECORD_CATEGORIES = gql`
  query MaintenanceEntryCategories {
    maintenanceEntryCategories {
      id
      name
    }
  }
`;

export const QUERY_AIRCRAFT_WITHOUT_COI = gql`
  query AircraftWithoutCurrentCoi {
    aircraftWithoutCurrentCoi {
      id
      registration
      owner {
        id
        firstName
        lastName
      }
      coiDate
      coiExpiration
      serial
    }
  }
`;

export const QUERY_AIRCRAFT_WITHOUT_COI_NOTITIFCATIONS = gql`
  query AircraftWithoutMissingCoiNotification {
    aircraftWithoutMissingCoiNotification {
      id
      registration
      owner {
        id
        firstName
        lastName
      }
      coiDate
      coiExpiration
      serial
    }
  }
`;

export const QUERY_REQUEST_COI_TEMPLATE_TEXT = gql`
  query RequestCoiTemplateText($aircraftId: ID) {
    requestCoiTemplateText(aircraftId: $aircraftId)
  }
`;

export const QUERY_AIRCRAFT_UPGRADE_PLAN_INFO = gql`
  ${FRAGMENT_AIRCRAFT_UPGRADE_CONTEXT}
  query AircraftUpgradePlanInfo($id: Int) {
    me {
      id
      aircraft(id: $id) {
        id
        registration
        year
        serial
        aircraftManufacturer {
          id
          name
        }
        aircraftModel {
          id
          name
          eligibleServices {
            serviceCode
            price
          }
          propulsion
          enginesCount
        }
        subscriptions {
          id
          serviceCode
          serviceName
          startDate
          endDate
          status
        }
        ...upgradeContext
      }
      creditCard {
        cardType
        last4
        expirationMonth
        expirationYear
      }
    }
  }
`;

export const QUERY_UNAPPROVED_COI = gql`
  ${FRAGMENT_COI}
  query UnapprovedCertificateOfInsurance {
    unapprovedCertificateOfInsurance {
      ...AircraftCOI
      aircraft {
        id
        registration
      }
    }
  }
`;

export const QUERY_SAVVY_AIRCRAFT_FLIGHT_REPORTS = gql`
  query SavvyAircraftFlightReports($aircraftId: ID) {
    savvy {
      id
      aircraft(aircraftId: $aircraftId) {
        id
        flightReports {
          id
          ticket {
            id
            subject
          }
          engine
          createdOn
          lastUpdateOn
          flight {
            id
            date
            duration
            destinationAirport
            departureAirport
          }
        }
      }
    }
  }
`;

export const QUERY_SAVVY_FLIGHT_REPORT_DETAIL = gql`
  ${FRAGMENT_FLIGHT_REPORT}
  query SavvyAircraftFlightReportDetail($aircraftId: ID, $reportId: ID) {
    savvy {
      id
      aircraft(aircraftId: $aircraftId) {
        id
        flightReports(reportId: $reportId) {
          ...FlightReport
        }
      }
    }
  }
`;

export const QUERY_SAVVY_FLIGHT_REPORT_DOWNLOAD_URL = gql`
  query SavvyAircraftFlightReportDownloadUrl($aircraftId: ID, $reportId: ID) {
    savvy {
      id
      aircraft(aircraftId: $aircraftId) {
        id
        flightReports(reportId: $reportId) {
          id
          downloadUrl
        }
      }
    }
  }
`;

export const QUERY_SAVVY_FLIGHT_REPORT_PREVIEW_URL = gql`
  query SavvyAircraftFlightReportPreviewUrl($aircraftId: ID, $reportId: ID) {
    savvy {
      id
      aircraft(aircraftId: $aircraftId) {
        id
        flightReports(reportId: $reportId) {
          id
          previewUrl
        }
      }
    }
  }
`;

export const QUERY_SAVVY_AIRCRAFT_REPORT_CARDS = gql`
  query SavvyAircraftReportCards($aircraftId: ID) {
    savvy {
      id
      aircraft(aircraftId: $aircraftId) {
        id
        reportCards {
          id
          created
          created
          kind
          dateStart
          dateEnd
          flightCount
          delivery
        }
      }
    }
  }
`;

export const QUERY_SAVVY_REPORT_CARD_DETAIL = gql`
  query SavvyReportCardDetail($aircraftId: ID, $reportCardId: ID) {
    savvy {
      id
      aircraft(aircraftId: $aircraftId) {
        id
        reportCards(reportCardId: $reportCardId) {
          id
          created
          kind
          dateStart
          dateEnd
          flightCount
          delivery
          context {
            aircraft {
              registration
              aircraftModel {
                name
              }
              aircraftManufacturer {
                name
              }
              engineModel {
                name
              }
              engineManufacturer {
                name
              }
            }
            startDate
            endDate
            numberOfFlights
            cohortSizeAircraft
            cohortSizeFlights
            header
            footer
            elements {
              heading
              uri
              comment
              description
            }
          }
        }
      }
    }
  }
`;

export const QUERY_ENGINE_PARAMETERS = gql`
  ${FRAGMENT_ENGINE_PARAMETERS}
  query AllEngineParameters($engineParametersId: ID) {
    engineParameters(engineParametersId: $engineParametersId) {
      ...EngineParameters
    }
  }
`;

export const QUERY_AIRCRAFT_MANUFACTURERS_SUBMITTED_DATA = gql`
  query AircraftManufacturerSubmittedData {
    aircraftManufacturerSubmittedData {
      publicData {
        id
        name
        usageCount
        firstUser {
          id
          firstName
          lastName
          email
        }
      }
      userData {
        id
        name
        usageCount
        firstUser {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`;

export const QUERY_AIRCRAFT_MODELS_SUBMITTED_DATA = gql`
  query AircraftModelSubmittedData {
    aircraftModelSubmittedData {
      publicData {
        id
        name
        usageCount
        manufacturer {
          id
          name
        }
        firstUser {
          id
          firstName
          lastName
          email
        }
      }
      userData {
        id
        name
        usageCount
        manufacturer {
          id
          name
        }
        firstUser {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`;

export const QUERY_ENGINE_MANUFACTURERS_SUBMITTED_DATA = gql`
  query EngineManufacturerSubmittedData {
    engineManufacturerSubmittedData {
      publicData {
        id
        name
        usageCount
        firstUser {
          id
          firstName
          lastName
          email
        }
      }
      userData {
        id
        name
        usageCount
        firstUser {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`;

export const QUERY_ENGINE_MODELS_SUBMITTED_DATA = gql`
  query EngineModelSubmittedData {
    engineModelSubmittedData {
      publicData {
        id
        name
        usageCount
        manufacturer {
          id
          name
        }
        firstUser {
          id
          firstName
          lastName
          email
        }
      }
      userData {
        id
        name
        usageCount
        manufacturer {
          id
          name
        }
        firstUser {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`;

export const QUERY_ENGINE_MONITOR_MANUFACTURERS_SUBMITTED_DATA = gql`
  query EngineMonitorManufacturerSubmittedData {
    engineMonitorManufacturerSubmittedData {
      publicData {
        id
        name
        usageCount
        firstUser {
          id
          firstName
          lastName
          email
        }
      }
      userData {
        id
        name
        usageCount
        firstUser {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`;

export const QUERY_ENGINE_MONITOR_MODELS_SUBMITTED_DATA = gql`
  query EngineMonitorModelSubmittedData {
    engineMonitorModelSubmittedData {
      publicData {
        id
        name
        usageCount
        manufacturer {
          id
          name
        }
        firstUser {
          id
          firstName
          lastName
          email
        }
      }
      userData {
        id
        name
        usageCount
        manufacturer {
          id
          name
        }
        firstUser {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`;

export const QUERY_DUPLICATE_CLOSEST_MATCH = gql`
  query DuplicateClosestMatch($name: String, $dataType: String) {
    duplicateClosestMatch(name: $name, dataType: $dataType) {
      id
      name
    }
  }
`;

export const QUERY_FLIGHT_REPORT_CLIPBOARD_ENTRIES = gql`
  query FlightReportClipboardEntries {
    flightReportClipboardEntries {
      id
      report {
        id
        ticket {
          id
        }
        flight {
          id
        }
      }
    }
  }
`;

export const QUERY_SHARED_LOGS = gql`
  query SharedLogs($ticketId: Int) {
    me {
      id
      tickets(ticketId: $ticketId) {
        id
        subject
        tags {
          ... on ShareMaintenanceLogsTagType {
            id
          }
        }
        sharedLogs {
          aircraft {
            id
            registration
            aircraftManufacturer {
              id
              name
            }
            aircraftModel {
              id
              name
            }
          }
          maintenanceLogbooks {
            id
            name
            created
            updated
            deleted
            maintenanceEntries {
              id
              body
              maintenanceEntryCategory {
                id
                name
              }
              entryDate
              isPerformedInspection
              isPerformedMaintenance
              signerFirstName
              signerLastName
              document {
                id
                fileName
                isImage
                isPdf
                attachment {
                  id
                  description
                }
              }
              logbook {
                id
              }
              created
              updated
              deleted
              isCombo
            }
            entriesCount
          }
        }
      }
    }
  }
`;

export const QUERY_SHARED_LOG_DOCUMENT_DOWNLOAD_URL = gql`
  query SharedLogDocumentDownloadUrl($ticketId: Int, $logbookId: Int, $maintenanceEntryId: Int) {
    me {
      id
      tickets(ticketId: $ticketId) {
        id
        sharedLogs {
          maintenanceLogbooks(logbookId: $logbookId) {
            id
            maintenanceEntries(maintenanceEntryId: $maintenanceEntryId) {
              id
              document {
                id
                downloadUrl
              }
            }
          }
        }
      }
    }
  }
`;

export const QUERY_SHARED_LOG_DOCUMENT_PREVIEW_URL = gql`
  query SharedLogDocumentPreviewUrl($ticketId: Int, $logbookId: Int, $maintenanceEntryId: Int) {
    me {
      id
      tickets(ticketId: $ticketId) {
        id
        sharedLogs {
          maintenanceLogbooks(logbookId: $logbookId) {
            id
            maintenanceEntries(maintenanceEntryId: $maintenanceEntryId) {
              id
              document {
                id
                previewUrl
                fileName
              }
            }
          }
        }
      }
    }
  }
`;

export const QUERY_SAVVY_AIRCRAFT_SIMPLE = gql`
  query SavvyAircraftSimple($aircraftId: ID) {
    savvy {
      id
      aircraft(aircraftId: $aircraftId) {
        id
        created
        updated
        registration
        serial
        year
        owner {
          id
          firstName
          lastName
        }
        aircraftManufacturer {
          id
          name
        }
        aircraftModel {
          id
          name
        }
      }
    }
  }
`;

export const QUERY_AIRCRAFT_RECENT_UPLOADS = gql`
  query AircraftRecentUploads($id: Int, $fromTime: DateTime) {
    me {
      id
      aircraft(id: $id) {
        id
        recentUploads(fromTime: $fromTime) {
          accepted {
            id
            date
            departureAirport
            destinationAirport
            duration
          }
          rejected {
            id
            date
            departureAirport
            destinationAirport
            duration
          }
        }
      }
    }
  }
`;

export const QUERY_SAVVY_AIRCRAFT_RECENT_UPLOADS = gql`
  query SavvyAircraftRecentUploads($aircraftId: ID, $fromTime: DateTime) {
    savvy {
      id
      aircraft(aircraftId: $aircraftId) {
        id
        recentUploads(fromTime: $fromTime) {
          accepted {
            id
            date
            departureAirport
            destinationAirport
            duration
          }
          rejected {
            id
            date
            departureAirport
            destinationAirport
            duration
          }
        }
      }
    }
  }
`;

export const QUERY_DOWNLOAD_EDF_ARCHIVE_URL = gql`
  query DownloadEdfArchiveUrl($workflowId: String) {
    downloadEdfArchiveUrl(workflowId: $workflowId)
  }
`;

export const QUERY_DOWNLOAD_REPORT_CARD_URL = gql`
  query DownloadReportCardUrl($reportId: Int) {
    downloadReportCardUrl(reportId: $reportId)
  }
`;

export const QUERY_ASSIGNED_AIRCRAFT_PRIMARY = gql`
  query AssignedAircraftPrimary($contactId: ID) {
    savvy {
      id
      contacts(contactId: $contactId) {
        id
        assignedAircraft {
          primary {
            id
            registration
            owner {
              id
              firstName
              lastName
            }
            aircraftManufacturer {
              id
              name
            }
            aircraftModel {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const QUERY_ASSIGNED_AIRCRAFT_BACKUP = gql`
  query AssignedAircraftBackup($contactId: ID) {
    savvy {
      id
      contacts(contactId: $contactId) {
        id
        assignedAircraft {
          backup {
            id
            registration
            owner {
              id
              firstName
              lastName
            }
            aircraftManufacturer {
              id
              name
            }
            aircraftModel {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const QUERY_SAVVY_AIRCRAFT_ENGINE_DATA_FILES = gql`
  query SavvyAircraftEngineDataFiles($aircraftId: ID) {
    savvy {
      id
      aircraft(aircraftId: $aircraftId) {
        id
        engineDataFiles {
          id
          name
          uploadDate
          flightsCount
        }
      }
    }
  }
`;

export const QUERY_SAVVY_AIRCRAFT_ENGINE_DATA_FILE_DOWNLOAD = gql`
  query SavvyAircraftEngineDataFiles($aircraftId: ID, $engineDataFileId: ID) {
    savvy {
      id
      aircraft(aircraftId: $aircraftId) {
        id
        engineDataFiles(engineDataFileId: $engineDataFileId) {
          id
          resultFileS3Url
        }
      }
    }
  }
`;

export const QUERY_ME_AIRCRAFT_ENGINE_DATA_FILE_DOWNLOAD = gql`
  query MeAircraftEngineDataFiles($id: Int, $engineDataFileId: ID) {
    me {
      id
      aircraft(id: $id) {
        id
        engineDataFiles(engineDataFileId: $engineDataFileId) {
          id
          resultFileS3Url
        }
      }
    }
  }
`;

export const QUERY_ME_RECENT_EDFS = gql`
  query MeGetRecentEdf($id: Int, $fromTime: DateTime) {
    me {
      id
      aircraft(id: $id) {
        id
        recentEngineDataFiles(fromTime: $fromTime) {
          id
          name
          uploadDate
          fileSize
          flightsCount
          uploadStatus {
            status
            message
          }
          workflowStatus
        }
      }
    }
  }
`;

export const QUERY_SAVVY_RECENT_EDFS = gql`
  query SavvyGetRecentEdf($aircraftId: ID, $fromTime: DateTime) {
    savvy {
      id
      aircraft(aircraftId: $aircraftId) {
        id
        recentEngineDataFiles(fromTime: $fromTime) {
          id
          name
          uploadDate
          fileSize
          flightsCount
          uploadStatus {
            status
            message
          }
          workflowStatus
        }
      }
    }
  }
`;

export const QUERY_ME_ENGINE_DATA_FILE_FLIGHTS = gql`
  query MeEngineDataFileFligghts($id: Int, $engineDataFileId: ID) {
    me {
      id
      aircraft(id: $id) {
        id
        engineDataFiles(engineDataFileId: $engineDataFileId) {
          id
          name
          flights {
            id
            date
            departureAirport
            destinationAirport
            duration
          }
        }
      }
    }
  }
`;

export const QUERY_SAVVY_ENGINE_DATA_FILE_FLIGHTS = gql`
  query SavvyEngineDataFileFligghts($aircraftId: ID, $engineDataFileId: ID) {
    savvy {
      id
      aircraft(aircraftId: $aircraftId) {
        id
        engineDataFiles(engineDataFileId: $engineDataFileId) {
          id
          name
          flights {
            id
            date
            departureAirport
            destinationAirport
            duration
          }
        }
      }
    }
  }
`;

export const QUERY_DEBUG_FLIGHT_COUNT_FOR_RCTA = gql`
  query DebugFlightCountForRCTA($aircraftId: ID, $startDate: Date, $endDate: Date) {
    debugFlightCountForRcTa(aircraftId: $aircraftId, startDate: $startDate, endDate: $endDate) {
      flightCount
      flights {
        id
        rcStatus
      }
    }
  }
`;

export const QUERY_BORESCOPE_IMAGE_SETS = gql`
  ${FRAGMENT_BORESCOPE_IMAGE_SET}
  query BorescopeImageSets($aircraftId: Int) {
    me {
      id
      aircraft(id: $aircraftId) {
        id
        borescopeImageSets {
          ...BorescopeImageSet
        }
      }
    }
  }
`;

export const QUERY_BORESCOPE_IMAGE_SETS_WITH_IMAGE_LENGTH = gql`
  query BorescopeImageSetsWithImageLength($id: Int) {
    me {
      id
      aircraft(id: $id) {
        id
        borescopeImageSets {
          id
          created
          updated
          name
          engine
          clientComments
          images(completedOnly: true) {
            id
          }
          status
        }
      }
    }
  }
`;

export const QUERY_AIRCRAFT_ENGINES_COUNT = gql`
  query AircraftEnginesCount($aircraftId: Int) {
    me {
      id
      aircraft(id: $aircraftId) {
        id
        aircraftModel {
          id
          enginesCount
        }
      }
    }
  }
`;

export const QUERY_ME_AIRCRAFT_CYLINDER_COUNT = gql`
  query AircraftCylinderCount($id: Int) {
    me {
      id
      aircraft(id: $id) {
        id
        cylinderCount
      }
    }
  }
`;

export const QUERY_ME_AIRCRAFT_BORESCOPE_IMAGE_SET_DETAIL = gql`
  query BorescopeImageSetDtail($aircraftId: Int, $borescopeImageSetId: ID, $completedOnly: Boolean) {
    me {
      id
      aircraft(id: $aircraftId) {
        id
        borescopeImageSets(borescopeImageSetId: $borescopeImageSetId) {
          id
          images(completedOnly: $completedOnly) {
            id
            filename
            uploadingStatus
            cylinder
            subject
            previewUrl
            isBadImage
          }
        }
      }
    }
  }
`;

export const QUERY_ME_AIRCRAFT_BORESCOPE_IMAGE_SET_DETAIL_WITH_IMAGE_URL = gql`
  query BorescopeImageSetDtailWithUrl($aircraftId: Int, $borescopeImageSetId: ID, $completedOnly: Boolean) {
    me {
      id
      aircraft(id: $aircraftId) {
        id
        borescopeImageSets(borescopeImageSetId: $borescopeImageSetId) {
          id
          images(completedOnly: $completedOnly) {
            id
            filename
            uploadingStatus
            cylinder
            subject
            previewUrl
          }
        }
      }
    }
  }
`;

export const QUERY_BORESCOPE_IMAGE_SUBJECTS_LIST = gql`
  query BorescopeImageSubjectsList {
    borescopeImageSubjectsList
  }
`;

export const QUERY_SAVVY_BORESCOPE_IMAGE_SETS = gql`
  query SavvyBorescopeImageSets($aircraftId: ID) {
    savvy {
      id
      aircraft(aircraftId: $aircraftId) {
        id
        borescopeImageSets {
          id
          name
          clientComments
          created
          updated
          engine
          status
        }
        cylinderCount
      }
    }
  }
`;

export const QUERY_SAVVY_AIRCRAFT_ENGINES_COUNT = gql`
  query SavvyAircraftEnginesCount($aircraftId: ID) {
    savvy {
      id
      aircraft(aircraftId: $aircraftId) {
        id
        aircraftModel {
          id
          enginesCount
        }
      }
    }
  }
`;

export const QUERY_SAVVY_AIRCRAFT_BORESCOPE_IMAGES = gql`
  query SavvyAircraftBorescopeImages($aircraftId: ID, $borescopeImageSetId: ID) {
    savvy {
      id
      aircraft(aircraftId: $aircraftId) {
        id
        borescopeImageSets(borescopeImageSetId: $borescopeImageSetId) {
          id
          images {
            id
            filename
            uploadingStatus
            cylinder
            subject
          }
        }
      }
    }
  }
`;

export const QUERY_SAVVY_BORESCOPE_IMAGE_PREVIEW_URL = gql`
  query SavvyBorescopeImagePreviewUrl($aircraftId: ID, $borescopeImageSetId: ID, $borescopeImageId: ID) {
    savvy {
      id
      aircraft(aircraftId: $aircraftId) {
        id
        borescopeImageSets(borescopeImageSetId: $borescopeImageSetId) {
          id
          images(borescopeImageId: $borescopeImageId) {
            id
            filename
            previewUrl
          }
        }
      }
    }
  }
`;

export const QUERY_SAVVY_AIRCRAFT_BORESCOPE_IMAGES_WITH_DETAIL = gql`
  query SavvyAircraftBorescopeImagesWithDetail($aircraftId: ID, $borescopeImageSetId: ID) {
    savvy {
      id
      aircraft(aircraftId: $aircraftId) {
        id
        cylinderCount
        registration
        owner {
          id
          firstName
          lastName
        }
        year
        serial
        comments {
          id
          pinned
          comment
        }
        aircraftManufacturer {
          id
          name
        }
        aircraftModel {
          id
          name
        }
        engineManufacturer {
          id
          name
        }
        engineModel {
          id
          name
        }
        borescopeImageSets(borescopeImageSetId: $borescopeImageSetId) {
          id
          name
          engine
          images(completedOnly: true) {
            id
            filename
            uploadingStatus
            cylinder
            subject
            status
            previousImages {
              id
              created
              imageSet {
                id
                name
              }
            }
            updated
            textualStatus
          }
          created
          status
          comments
          reportCommentsDraft
          badImageUpdateRequestPostDraft
          reportCreator {
            id
            firstName
            lastName
            isSavvy
          }
        }
      }
    }
  }
`;

export const QUERY_SAVVY_AIRCRAFT_BORESCOPE_IMAGES_WITH_PREVIEW_URL = gql`
  query SavvyBorescopeImageSetsWithPreviewUrl($aircraftId: ID, $borescopeImageSetId: ID) {
    savvy {
      id
      aircraft(aircraftId: $aircraftId) {
        id
        owner {
          firstName
          lastName
        }
        registration
        aircraftManufacturer {
          id
          name
        }
        aircraftModel {
          id
          name
        }
        engineManufacturer {
          id
          name
        }
        engineModel {
          id
          name
        }
        borescopeImageSets(borescopeImageSetId: $borescopeImageSetId) {
          id
          clientComments
          comments
          engine
          name
          images(completedOnly: true) {
            id
            filename
            uploadingStatus
            cylinder
            subject
            status
            previewUrl
            textualStatus
            imageAnalysisState
          }
          updated
          reportCreator {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const QUERY_SAVVY_BORESCOPE_IMAGE_WITH_PREVIEW_URL = gql`
  query SavvyBorescopeImageWithPreviewUrl($aircraftId: ID, $borescopeImageSetId: ID, $borescopeImageId: ID) {
    savvy {
      id
      aircraft(aircraftId: $aircraftId) {
        id
        borescopeImageSets(borescopeImageSetId: $borescopeImageSetId) {
          id
          images(borescopeImageId: $borescopeImageId) {
            id
            previewUrl
            previousImages {
              id
              previewUrl
              updated
            }
          }
        }
      }
    }
  }
`;

export const QUERY_BORESCOPE_STATUS = gql`
  query BorescopeStatus {
    borescopeStatus {
      subject
      slug
      choices {
        name
        slug
      }
    }
  }
`;

export const QUERY_BORESCOPE_REPORT_FOR_CLIENT = gql`
  query BorescopeReportForClient($id: Int, $borescopeImageSetId: ID) {
    me {
      id
      aircraft(id: $id) {
        id
        cylinderCount
        registration
        owner {
          id
          firstName
          lastName
        }
        aircraftManufacturer {
          id
          name
        }
        aircraftModel {
          id
          name
        }
        engineManufacturer {
          id
          name
        }
        engineModel {
          id
          name
        }
        engineMonitorManufacturer {
          id
          name
        }
        engineMonitorModel {
          id
          name
        }
        borescopeImageSets(borescopeImageSetId: $borescopeImageSetId) {
          id
          engine
          name
          clientComments
          images(completedOnly: true) {
            id
            cylinder
            subject
            previewUrl
            textualStatus
            imageAnalysisState
          }
          updated
          comments
          reportCreator {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const QUERY_BORESCOPE_REPORT_PDF_DOWNLOAD = gql`
  query BorescopeReportPdfDownload($id: Int, $borescopeImageSetId: ID) {
    me {
      id
      aircraft(id: $id) {
        id
        borescopeImageSets(borescopeImageSetId: $borescopeImageSetId) {
          id
          downloadUrl
        }
      }
    }
  }
`;

export const QUERY_BORESCOPE_REPORT_PDF_DOWNLOAD_FOR_SAVVY = gql`
  query BorescopeReportPdfDownloadForSavvy($id: ID, $borescopeImageSetId: ID) {
    savvy {
      id
      aircraft(aircraftId: $id) {
        id
        borescopeImageSets(borescopeImageSetId: $borescopeImageSetId) {
          id
          downloadUrl
          previewUrl
        }
      }
    }
  }
`;

export const QUERY_COMBO_LOGS_PDF_DOWNLOAD_URL = gql`
  query ComboLogsPdfDownloadUrl($workflowId: String) {
    comboLogsPdfDownloadUrl(workflowId: $workflowId)
  }
`;

export const QUERY_COMBO_LOGS_PDF_STATUS = gql`
  query ComboLogsPdfGenerationStatus($workflowId: String) {
    comboLogsPdfGenerationStatus(workflowId: $workflowId)
  }
`;

export const QUERY_ELIGIBLE_BORESCOPE = gql`
  query AircraftEligibleBorescope($id: Int) {
    me {
      id
      aircraft(id: $id) {
        id
        registration
        aircraftManufacturer {
          id
          name
        }
        aircraftModel {
          id
          name
        }
        ticketEligibility {
          borescopeAnalysis {
            eligible
            currentlyEligibleVia
            eligibleViaPurchase
          }
        }
      }
    }
  }
`;

export const QUERY_BORESCOPE_REPORT_FOR_SAVVY = gql`
  query BorescopeReportForSavvy($aircraftId: ID, $borescopeImageSetId: ID) {
    savvy {
      id
      aircraft(aircraftId: $aircraftId) {
        id
        cylinderCount
        registration
        owner {
          id
          firstName
          lastName
        }
        aircraftManufacturer {
          id
          name
        }
        aircraftModel {
          id
          name
        }
        engineManufacturer {
          id
          name
        }
        engineModel {
          id
          name
        }
        engineMonitorManufacturer {
          id
          name
        }
        engineMonitorModel {
          id
          name
        }
        borescopeImageSets(borescopeImageSetId: $borescopeImageSetId) {
          id
          engine
          name
          clientComments
          images(completedOnly: true) {
            id
            cylinder
            subject
            previewUrl
            textualStatus
            imageAnalysisState
          }
          updated
          comments
          reportCreator {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const QUERY_SEARCH_AIRCRAFT_TYPE = gql`
  query SearchAircraftType(
    $aircraftManufacturerId: ID
    $aircraftModelId: ID
    $engineManufacturerId: ID
    $engineModelId: ID
    $offset: Int
    $limit: Int
  ) {
    searchAircraftByType(
      aircraftManufacturerId: $aircraftManufacturerId
      aircraftModelId: $aircraftModelId
      engineManufacturerId: $engineManufacturerId
      engineModelId: $engineModelId
      offset: $offset
      limit: $limit
    ) {
      count
      results {
        id
        registration
        aircraftManufacturer {
          id
          name
        }
        aircraftModel {
          id
          name
        }
        engineManufacturer {
          id
          name
        }
        engineModel {
          id
          name
        }
        engineMonitorManufacturer {
          id
          name
        }
        engineMonitorModel {
          id
          name
        }
        flightCount
      }
    }
  }
`;

export const QUERY_AIRCRAFT_ACCESS_PERMISSIONS = gql`
  query AircraftAccessPermissions($id: Int) {
    me {
      id
      aircraft(id: $id) {
        id
        accessPermissions {
          id
          contact {
            id
            email
            firstName
            lastName
            role
          }
          permissions
          created
        }
      }
    }
  }
`;

export const QUERY_AVAILABLE_ACCESS_PERMISSIONS = gql`
  query AvailableAccessPermissions {
    availableAccessPermissions {
      name
      slug
    }
  }
`;

export const QUERY_SHARED_AIRCRAFT_WITH_UPLOAD_BORESCOPE_ACCESS = gql`
  query SharedAircraftWithUploadBorescope($aircraftId: ID) {
    sharedAircraftWithUploadBorescope(aircraftId: $aircraftId) {
      id
      aircraft {
        id
        aircraftManufacturer {
          id
          name
        }
        aircraftModel {
          id
          name
        }
        registration
      }
      permissions
    }
  }
`;

export const QUERY_SHARED_AIRCRAFT_BORESCOPE_IMAGE_SETS = gql`
  ${FRAGMENT_BORESCOPE_IMAGE_SET}
  query SharedAircraftBorescopeImageSets($aircraftId: ID) {
    sharedAircraftWithUploadBorescope(aircraftId: $aircraftId) {
      id
      aircraft {
        id
        borescopeImageSets {
          ...BorescopeImageSet
        }
      }
    }
  }
`;

export const QUERY_SHARED_AIRCRAFT_ENGINES_COUNT = gql`
  query SharedAircraftEnginesCount($aircraftId: ID) {
    sharedAircraftWithUploadBorescope(aircraftId: $aircraftId) {
      id
      aircraft {
        id
        aircraftModel {
          id
          enginesCount
        }
      }
    }
  }
`;

export const QUERY_SHARED_AIRCRAFT_CYLINDER_COUNT = gql`
  query SharedAircraftCylinderCount($aircraftId: ID) {
    sharedAircraftWithUploadBorescope(aircraftId: $aircraftId) {
      id
      aircraft {
        id
        cylinderCount
      }
    }
  }
`;

export const QUERY_SHARED_AIRCRAFT_BORESCOPE_IMAGE_SET_DETAIL = gql`
  query SharedBorescopeImageSetDtail($aircraftId: ID, $borescopeImageSetId: ID, $completedOnly: Boolean) {
    sharedAircraftWithUploadBorescope(aircraftId: $aircraftId) {
      id
      aircraft {
        id
        borescopeImageSets(borescopeImageSetId: $borescopeImageSetId) {
          id
          images(completedOnly: $completedOnly) {
            id
            filename
            uploadingStatus
            cylinder
            subject
            previewUrl
            isBadImage
          }
        }
      }
    }
  }
`;

export const QUERY_SHARED_AIRCRAFT_BORESCOPE_IMAGES_WITH_DETAIL = gql`
  query SharedAircraftBorescopeImagesWithDetail($aircraftId: ID, $borescopeImageSetId: ID) {
    sharedAircraftWithUploadBorescope(aircraftId: $aircraftId) {
      id
      aircraft {
        id
        cylinderCount
        registration
        owner {
          id
          firstName
          lastName
        }
        year
        serial
        comments {
          id
          pinned
          comment
        }
        aircraftManufacturer {
          id
          name
        }
        aircraftModel {
          id
          name
        }
        engineManufacturer {
          id
          name
        }
        engineModel {
          id
          name
        }
        borescopeImageSets(borescopeImageSetId: $borescopeImageSetId) {
          id
          name
          engine
          images(completedOnly: true) {
            id
            filename
            uploadingStatus
            cylinder
            subject
            status
            previousImages {
              id
              created
              imageSet {
                id
                name
              }
            }
            updated
            textualStatus
          }
          created
          status
          comments
          reportCommentsDraft
          badImageUpdateRequestPostDraft
          reportCreator {
            id
            firstName
            lastName
            isSavvy
          }
        }
      }
    }
  }
`;

export const QUERY_SHARED_BORESCOPE_IMAGE_WITH_PREVIEW_URL = gql`
  query SharedBorescopeImageWithPreviewUrl($aircraftId: ID, $borescopeImageSetId: ID, $borescopeImageId: ID) {
    sharedAircraftWithUploadBorescope(aircraftId: $aircraftId) {
      id
      aircraft {
        id
        borescopeImageSets(borescopeImageSetId: $borescopeImageSetId) {
          id
          images(borescopeImageId: $borescopeImageId) {
            id
            previewUrl
            previousImages {
              id
              previewUrl
              updated
            }
          }
        }
      }
    }
  }
`;

export const QUERY_SHARED_AIRCRAFT_BORESCOPE_IMAGES_WITH_PREVIEW_URL = gql`
  query SharedBorescopeImageSetsWithPreviewUrl($aircraftId: ID, $borescopeImageSetId: ID) {
    sharedAircraftWithUploadBorescope(aircraftId: $aircraftId) {
      id
      aircraft {
        id
        borescopeImageSets(borescopeImageSetId: $borescopeImageSetId) {
          id
          images(completedOnly: true) {
            id
            filename
            uploadingStatus
            cylinder
            subject
            status
            previewUrl
            textualStatus
          }
        }
      }
    }
  }
`;

export const QUERY_ME_AIRCRAFT_BORESCOPE_IMAGES_WITH_DETAIL = gql`
  query MeAircraftBorescopeImagesWithDetail($aircraftId: Int, $borescopeImageSetId: ID) {
    me {
      id
      aircraft(id: $aircraftId) {
        id
        cylinderCount
        registration
        owner {
          id
          firstName
          lastName
        }
        year
        serial
        comments {
          id
          pinned
          comment
        }
        aircraftManufacturer {
          id
          name
        }
        aircraftModel {
          id
          name
        }
        engineManufacturer {
          id
          name
        }
        engineModel {
          id
          name
        }
        borescopeImageSets(borescopeImageSetId: $borescopeImageSetId) {
          id
          name
          engine
          images(completedOnly: true) {
            id
            filename
            uploadingStatus
            cylinder
            subject
            status
            imageAnalysisState
            previewUrl
            previousImages {
              id
              created
              imageSet {
                id
                name
              }
            }
            updated
            textualStatus
          }
          created
          status
          comments
          reportCommentsDraft
          badImageUpdateRequestPostDraft
          reportCreator {
            id
            firstName
            lastName
            isSavvy
          }
        }
      }
    }
  }
`;

export const QUERY_ME_BORESCOPE_IMAGE_WITH_PREVIEW_URL = gql`
  query MeBorescopeImageWithPreviewUrl($aircraftId: Int, $borescopeImageSetId: ID, $borescopeImageId: ID) {
    me {
      id
      aircraft(id: $aircraftId) {
        id
        borescopeImageSets(borescopeImageSetId: $borescopeImageSetId) {
          id
          images(borescopeImageId: $borescopeImageId) {
            id
            previewUrl
            previousImages {
              id
              previewUrl
              updated
            }
          }
        }
      }
    }
  }
`;

export const QUERY_ME_AIRCRAFT_BORESCOPE_IMAGES_WITH_PREVIEW_URL = gql`
  query MeBorescopeImageSetsWithPreviewUrl($aircraftId: Int, $borescopeImageSetId: ID) {
    me {
      id
      aircraft(id: $aircraftId) {
        id
        borescopeImageSets(borescopeImageSetId: $borescopeImageSetId) {
          id
          images(completedOnly: true) {
            id
            filename
            uploadingStatus
            cylinder
            subject
            status
            previewUrl
            textualStatus
          }
        }
      }
    }
  }
`;
