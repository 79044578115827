import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Tooltip, Collapse, IconButton, Hidden } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { ITicket } from 'types';
import { humanDate } from 'helpers';
import { BadgeIcon } from 'customIcons';

interface CollapsedTicketsProps {
  tickets?: ITicket[];
  clickTicket: (value: string) => void;
  usedTicketId?: boolean;
}

const CollapsedTickets: React.FC<CollapsedTicketsProps> = (props) => {
  const { tickets = [], clickTicket, usedTicketId = false } = props;

  const sortTickets = [...tickets];

  sortTickets.sort((a: ITicket, b: ITicket) => {
    if (a.state.toLowerCase() === 'c' && b.state.toLowerCase() !== 'c') {
      return 1;
    } else if (a.state.toLowerCase() !== 'c' && b.state.toLowerCase() === 'c') {
      return -1;
    } else if (a.state === b.state) {
      if (new Date(a.updated) > new Date(b.updated)) {
        return -1;
      } else {
        return 1;
      }
    }

    return 1;
  });

  const { id, ticketId } = useParams();

  const [activeOn, setActiveOn] = useState(true);

  const [closedOn, setClosedOn] = useState(false);

  const [leftSlideOn, setLeftSlideOn] = useState(true);

  return (
    <Box
      sx={{
        backgroundColor: 'grey.800',
        position: 'relative',
        minHeight: {
          md: 'inherit',
          lg: 'calc(100vh - 75px)',
        },
      }}
    >
      <Collapse
        in={leftSlideOn}
        orientation="horizontal"
        sx={{
          minWidth: {
            xs: '100vw',
            xl: '400px',
          },
          width: {
            xs: '100vw',
            xl: '400px',
          },
          '& .MuiCollapse-wrapper': {
            display: 'block',
          },
        }}
      >
        <Box
          sx={{
            '@media (min-width: 1280px)': {
              maxHeight: 'calc(100vh - 75px)',
              overflowY: 'auto',
              '&::-webkit-scrollbar': {
                width: '0.6em',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,.1)',
                borderRadius: 2,
              },
            },
          }}
        >
          <Box
            sx={{
              py: 1,
              pl: {
                xs: 2,
                sm: 3,
                md: 4,
                lg: 5,
                xl: 7,
              },
              pr: 2,
              background: '#E8E9EF',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              cursor: 'pointer',
            }}
            onClick={() => {
              setActiveOn(!activeOn);
            }}
          >
            <Typography
              paragraph
              color="textPrimary"
              sx={{
                marginBottom: 0,
                fontSize: '14px',
                lineHeight: '19px',
                fontWeight: 'bold',
              }}
            >
              ACTIVE
            </Typography>
            <IconButton>{activeOn ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</IconButton>
          </Box>
          <Collapse in={activeOn}>
            <Box
              sx={{
                background: {
                  xs: '#FFFFFF',
                  lg: 'inherit',
                },
              }}
            >
              {sortTickets.map((ticket: ITicket, key: number) => {
                if (ticket.state.toLowerCase() === 'c') return null;
                return (
                  <Box
                    key={key}
                    py={2}
                    sx={{
                      cursor: 'pointer',
                      px: {
                        xs: 2,
                        sm: 3,
                        md: 4,
                        lg: 5,
                        xl: 7,
                      },
                      background:
                        (!usedTicketId && id && id === ticket.id) || (usedTicketId && ticketId === ticket.id) ? '#FFFFFF' : 'none',
                    }}
                    onClick={() => {
                      clickTicket(ticket.id);
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: '16px',
                        lineHeight: '22px',
                        color: 'text.secondary',
                        fontWeight: 500,
                        maxWidth: '100%',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {ticket.hasUnread && <BadgeIcon />}
                      {ticket.subject}
                    </Typography>
                    {ticket?.aircraft && (
                      <Typography
                        sx={{
                          fontSize: '12px',
                          fontWeight: 600,
                        }}
                      >
                        <span style={{ fontWeight: 400 }}>Client: </span>
                        {`${ticket?.aircraft?.registration} ${ticket?.aircraft?.owner?.firstName} ${ticket?.aircraft?.owner?.lastName}`}
                      </Typography>
                    )}
                    <Tooltip title={ticket.created.toString()} placement="right-start">
                      <Typography
                        variant="body1"
                        sx={{
                          display: 'inline-block',
                          cursor: 'pointer',
                        }}
                      >
                        {humanDate(ticket.created)}
                      </Typography>
                    </Tooltip>
                  </Box>
                );
              })}
            </Box>
          </Collapse>
          <Box
            sx={{
              py: 1,
              pl: {
                xs: 2,
                sm: 3,
                md: 4,
                lg: 5,
                xl: 7,
              },
              pr: 2,
              background: '#E8E9EF',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              cursor: 'pointer',
            }}
            onClick={() => {
              setClosedOn(!closedOn);
            }}
          >
            <Typography
              paragraph
              color="textPrimary"
              sx={{
                marginBottom: 0,
                fontSize: '14px',
                lineHeight: '19px',
                fontWeight: 'bold',
              }}
            >
              CLOSED
            </Typography>
            <IconButton>{closedOn ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</IconButton>
          </Box>
          <Collapse in={closedOn}>
            <Box
              sx={{
                background: {
                  xs: '#FFFFFF',
                  lg: 'inherit',
                },
              }}
            >
              {sortTickets.map((ticket: ITicket, key: number) => {
                if (ticket.state.toLowerCase() !== 'c') return null;
                return (
                  <Box
                    key={key}
                    py={2}
                    sx={{
                      px: {
                        xs: 2,
                        sm: 3,
                        md: 4,
                        lg: 5,
                        xl: 7,
                      },
                      background:
                        (!usedTicketId && id && id === ticket.id) || (usedTicketId && ticketId === ticket.id) ? '#FFFFFF' : 'none',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      clickTicket(ticket.id);
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: '16px',
                        lineHeight: '22px',
                        color: 'text.secondary',
                        fontWeight: 500,
                        maxWidth: '100%',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {ticket.hasUnread && <BadgeIcon />}
                      {ticket.subject}
                    </Typography>
                    {ticket?.aircraft && (
                      <Typography
                        sx={{
                          fontSize: '12px',
                          fontWeight: 600,
                        }}
                      >
                        <span style={{ fontWeight: 400 }}>Client: </span>
                        {`${ticket?.aircraft?.registration} ${ticket?.aircraft?.owner?.firstName} ${ticket?.aircraft?.owner?.lastName}`}
                      </Typography>
                    )}
                    <Tooltip title={ticket.created.toString()} placement="right-start">
                      <Typography
                        variant="body1"
                        sx={{
                          display: 'inline-block',
                          cursor: 'pointer',
                        }}
                      >
                        {humanDate(ticket.created)}
                      </Typography>
                    </Tooltip>
                  </Box>
                );
              })}
            </Box>
          </Collapse>
        </Box>
      </Collapse>
      <Hidden xlDown>
        <IconButton
          sx={{
            position: 'absolute',
            right: 0,
            top: '50%',
            transform: 'translate(50%, -50%)',
            '&:hover': {
              backgroundColor: 'background.default',
            },
            borderRadius: '6px',
            border: '1px solid',
            borderColor: 'grey.200',
            backgroundColor: 'background.default',
            zIndex: 999,
          }}
          onClick={() => {
            setLeftSlideOn(!leftSlideOn);
          }}
        >
          {leftSlideOn ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </Hidden>
    </Box>
  );
};

export default CollapsedTickets;
