import { gql } from '@apollo/client';
import { FRAGMENT_ME, FRAGMENT_OOP, FRAGMENT_CANNED_RESPONSE, FRAGMENT_BANK_INFO } from 'gql';

// Create Account
export const MUTATION_CREATE_ACCOUNT = gql`
  ${FRAGMENT_ME}
  mutation CreateAccount(
    $email: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $phone: String!
    $registration: String
    $aircraft_manufacturer_id: Int
    $aircraft_manufacturer_name: String
    $aircraft_model_id: Int
    $aircraft_model_name: String
    $certification: String
    $propulsion: String
    $engineCount: String
    $prebuy: Boolean
  ) {
    createAccount(
      input: {
        email: $email
        password: $password
        firstName: $firstName
        lastName: $lastName
        phone: $phone
        registration: $registration
        aircraftManufacturerId: $aircraft_manufacturer_id
        aircraftManufacturerName: $aircraft_manufacturer_name
        aircraftModelId: $aircraft_model_id
        aircraftModelName: $aircraft_model_name
        certification: $certification
        propulsion: $propulsion
        enginesCount: $engineCount
        prebuy: $prebuy
      }
    ) {
      ok
      errors {
        field
        messages
      }
      me {
        ...Me
        aircraft {
          id
          registration
          aircraftModel {
            id
            name
            propulsion
            enginesCount
          }
        }
      }
    }
  }
`;

// Change Personal Info
export const MUTATION_CHANGE_PERSONAL_INFO = gql`
  mutation ChangePersonalInfo(
    $airportId: String
    $countryCode: String
    $mobilePhone: String
    $firstName: String
    $lastName: String
    $email: String
    $timezone: String
    $zipCode: String
    $city: String
    $street: String
    $state: String
  ) {
    changePersonalInfo(
      airportId: $airportId
      countryCode: $countryCode
      mobilePhone: $mobilePhone
      firstName: $firstName
      lastName: $lastName
      email: $email
      timezone: $timezone
      zipCode: $zipCode
      city: $city
      street: $street
      state: $state
    ) {
      ok
      me {
        id
        email
        firstName
        lastName
        phoneMobile
        airportId
        timezone
        zip
        city
        state
        street
        country
      }
      error
    }
  }
`;

export const MUTATION_CHANGE_CLASSIFICATIONS = gql`
  mutation ChangeClassifications($classifications: [ID]) {
    changePersonalInfo(classifications: $classifications) {
      ok
      me {
        id
        classifications {
          id
          name
          category
        }
      }
      error
    }
  }
`;

export const MUTATION_CHANGE_PERSONAL_INFO_FOR_TECHNICIAN = gql`
  mutation ChangePersonalInfo(
    $airportId: String
    $countryCode: String
    $mobilePhone: String
    $firstName: String
    $lastName: String
    $email: String
    $timezone: String
    $zipCode: String
    $city: String
    $street: String
    $state: String
    $classifications: [ID]
  ) {
    changePersonalInfo(
      airportId: $airportId
      countryCode: $countryCode
      mobilePhone: $mobilePhone
      firstName: $firstName
      lastName: $lastName
      email: $email
      timezone: $timezone
      zipCode: $zipCode
      city: $city
      street: $street
      state: $state
      classifications: $classifications
    ) {
      ok
      me {
        id
        email
        firstName
        lastName
        phoneMobile
        airportId
        timezone
        zip
        city
        state
        street
        classifications {
          id
          name
          category
        }
      }
      error
    }
  }
`;

export const MUTATION_UPDATE_CONTACT_FOR_TECHNICIAN = gql`
  mutation UpdateContactForTechnician(
    $airportId: String
    $country: String
    $mobilePhone: String
    $firstName: String
    $lastName: String
    $email: String
    $timezone: String
    $zip: String
    $city: String
    $street: String
    $state: String
    $classifications: [ID]
  ) {
    updateContactForTechnician(
      airportId: $airportId
      country: $country
      phoneMobile: $mobilePhone
      firstName: $firstName
      lastName: $lastName
      email: $email
      ttimezone: $timezone
      zipCode: $zip
      city: $city
      street: $street
      state: $state
      classifications: $classifications
    ) {
      ok
      tech {
        id
        email
        firstName
        lastName
        phoneMobile
        airportId
        timezone
        zip
        city
        state
        street
        classifications {
          id
          name
          category
        }
      }
      error
    }
  }
`;

// Login
export const MUTATION_LOGIN = gql`
  ${FRAGMENT_ME}
  mutation Login($username: String!, $password: String!) {
    login(input: { username: $username, password: $password }) {
      me {
        ...Me
      }
      errors {
        field
        messages
      }
      redirect
      error
    }
  }
`;

// Reset password
export const MUTATION_RESET_PASSWORD = gql`
  mutation ResetPassword($email: String!) {
    resetPassword(input: { email: $email }) {
      errors {
        field
        messages
      }
    }
  }
`;

// Logout
export const MUTATION_LOGOUT = gql`
  mutation Logout {
    logout {
      ok
    }
  }
`;

export const MUTATION_CHANGE_CREDIT_CARD = gql`
  mutation ChangeCreditCardToken($token: String) {
    changeCreditCardToken(token: $token) {
      ok
      me {
        id
        creditCard {
          cardType
          last4
          expirationMonth
          expirationYear
        }
      }
      error
    }
  }
`;

export const MUTATION_CHANGE_ADVANCED_SETTINGS = gql`
  mutation ChangeAdvancedSettings(
    $emailNotificationsForOwnPosts: Boolean
    $publicSharing: Boolean
    $alwaysResetSeriesWhenViewingFlights: Boolean
  ) {
    changeAdvancedSettings(
      emailNotificationsForOwnPosts: $emailNotificationsForOwnPosts
      publicSharing: $publicSharing
      alwaysResetSeriesWhenViewingFlights: $alwaysResetSeriesWhenViewingFlights
    ) {
      ok
      me {
        id
        settings {
          emailNotificationsForOwnPosts
          alwaysResetSeriesWhenViewingFlights
        }
      }
      error
    }
  }
`;

export const MUTATION_CREATE_TOKEN = gql`
  mutation CreateToken($name: String) {
    createToken(name: $name) {
      ok
      me {
        id
        apiTokens {
          id
          name
          token
        }
      }
      error
    }
  }
`;

export const MUTATION_DELETE_TOKEN = gql`
  mutation DeleteToken($tokenId: ID) {
    deleteToken(tokenId: $tokenId) {
      ok
      me {
        apiTokens {
          id
          name
          token
        }
      }
      error
    }
  }
`;

export const MUTATION_RESET_SHARING_URL = gql`
  mutation ResetSharingUrl {
    resetSharingUrl {
      ok
      me {
        sharingUrl
      }
      error
    }
  }
`;

export const MUTATION_CHANGE_PASSWORD = gql`
  mutation ChangePassword($currentPassword: String, $newPassword: String) {
    changePassword(currentPassword: $currentPassword, newPassword: $newPassword) {
      ok
      error
    }
  }
`;

export const MUTATION_CREATE_REPORT_CARD = gql`
  mutation CreateReportCard($aircraftId: ID, $endDate: Date, $startDate: Date, $kind: String) {
    createReportCard(aircraftId: $aircraftId, endDate: $endDate, startDate: $startDate, kind: $kind) {
      ok
      error
      reportCard {
        id
        created
        kind
        dateStart
        dateEnd
        flightCount
        delivery
      }
      reportCardContext {
        aircraft {
          registration
          aircraftModel {
            name
          }
          aircraftManufacturer {
            name
          }
          engineModel {
            name
          }
          engineManufacturer {
            name
          }
        }
        startDate
        endDate
        numberOfFlights
        cohortSizeAircraft
        cohortSizeFlights
        header
        footer
        elements {
          heading
          uri
          comment
          description
        }
      }
      errorCodes
    }
  }
`;

export const MUTATION_CREATE_COHORT_REPORT_CARD = gql`
  mutation CreateCohortReportCard($cohortId: ID, $endDate: Date, $startDate: Date, $kind: String) {
    createCohortReport(cohortId: $cohortId, endDate: $endDate, startDate: $startDate, kind: $kind) {
      ok
      error
      reportCard {
        id
        created
        kind
        dateStart
        dateEnd
        flightCount
        delivery
      }
      reportCardContext {
        aircraft {
          registration
          aircraftModel {
            name
          }
          aircraftManufacturer {
            name
          }
          engineModel {
            name
          }
          engineManufacturer {
            name
          }
        }
        startDate
        endDate
        numberOfFlights
        cohortSizeAircraft
        cohortSizeFlights
        header
        footer
        elements {
          heading
          uri
          comment
          description
        }
      }
    }
  }
`;

export const MUTATION_TEXT_ON_UPDATE = gql`
  mutation TogglePauseTextOnUpdate {
    togglePauseTextOnUpdate {
      ok
      savvy {
        textOnUpdate
      }
      error
    }
  }
`;

export const MUTATION_RELEASE_HIJACK = gql`
  mutation ReleaseHijack {
    releaseHijack {
      ok
      error
    }
  }
`;

export const MUTATION_SEND_ONE_TIME_LOGIN_LINK = gql`
  mutation SendOneTimeLoginLink($email: String) {
    sendOneTimeLoginLink(email: $email) {
      ok
    }
  }
`;

export const MUTATION_LOGIN_WITH_CODE = gql`
  ${FRAGMENT_ME}
  mutation LoginWithCode($code: UUID) {
    loginWithCode(code: $code) {
      ok
      me {
        ...Me
      }
      redirect
    }
  }
`;

export const MUTATION_UPDATE_TECHNICIAN_CONTACT = gql`
  mutation UpdateTechnicianContact($id: ID!, $input: UpdateTechnicianContact!) {
    updateTechnicianContact(id: $id, input: $input) {
      contact {
        id
        firstName
        lastName
        nickname
        country
        zip
        street
        city
        state
        airportId
        email
        phoneMobile
        classifications {
          id
          name
          category
        }
      }
      ok
      error
    }
  }
`;

export const MUTATION_UPDATE_CLIENT_CONTACT = gql`
  mutation UpdateClientContact(
    $id: ID!
    $firstName: String!
    $lastName: String!
    $country: String
    $nickname: String
    $zip: String
    $street: String
    $city: String
    $state: String
    $airportId: String
    $email: String!
    $phoneMobile: String
    $emailCcs: [String]
    $company: String
  ) {
    updateClientContact(
      id: $id
      input: {
        firstName: $firstName
        lastName: $lastName
        nickname: $nickname
        country: $country
        zip: $zip
        street: $street
        city: $city
        state: $state
        airportId: $airportId
        email: $email
        phoneMobile: $phoneMobile
        emailCcs: $emailCcs
        company: $company
      }
    ) {
      contact {
        id
        firstName
        lastName
        nickname
        country
        zip
        street
        city
        state
        airportId
        email
        phoneMobile
        emailCcs
        company
      }
      ok
      error
    }
  }
`;

export const MUTATION_UPDATE_SAVVY_CONTACT = gql`
  mutation UpdateSavvyContact(
    $id: ID!
    $firstName: String!
    $lastName: String!
    $country: String
    $nickname: String
    $zip: String
    $street: String
    $city: String
    $state: String
    $airportId: String
    $email: String!
    $phoneMobile: String
  ) {
    updateSavvyContact(
      id: $id
      input: {
        firstName: $firstName
        lastName: $lastName
        nickname: $nickname
        country: $country
        zip: $zip
        street: $street
        city: $city
        state: $state
        airportId: $airportId
        email: $email
        phoneMobile: $phoneMobile
      }
    ) {
      contact {
        id
        firstName
        lastName
        nickname
        country
        zip
        street
        city
        state
        airportId
        email
        phoneMobile
      }
      ok
      error
    }
  }
`;

export const MUTATION_POST_CONTACT_COMMENT = gql`
  mutation PostContactComment($comment: String, $contactId: ID, $pinned: Boolean) {
    postContactComment(comment: $comment, contactId: $contactId, pinned: $pinned) {
      ok
      comment {
        id
        comment
        pinned
        poster {
          firstName
          lastName
        }
        date
      }
      error
    }
  }
`;

export const MUTATION_PIN_CONTACT_COMMENT = gql`
  mutation PinContactComment($pinned: Boolean, $contactCommentId: ID) {
    pinContactComment(pinned: $pinned, contactCommentId: $contactCommentId) {
      ok
      comment {
        id
        pinned
        date
      }
      error
    }
  }
`;

export const MUTATION_CHANGE_CONTACT_PASSWORD = gql`
  mutation ChangeContactPassword($contactId: ID, $password: String) {
    changeContactPassword(contactId: $contactId, password: $password) {
      ok
      error
    }
  }
`;

export const MUTATION_EXPUNGE_CONTACT_EMAIL = gql`
  mutation ExpungeContactEmail($contactId: ID) {
    expungeContactEmail(contactId: $contactId) {
      ok
      contact {
        id
        email
        comments {
          id
          pinned
          comment
          poster {
            firstName
            lastName
          }
          date
        }
        isActive
        needsUpdate
      }
      error
    }
  }
`;

export const MUTATION_ENABLE_CONTACT = gql`
  mutation EnableContact($contactId: ID) {
    enableContact(contactId: $contactId) {
      ok
      contact {
        id
        email
        comments {
          id
          pinned
          comment
          poster {
            firstName
            lastName
          }
          date
        }
        isActive
      }
      error
    }
  }
`;

export const MUTATION_DISABLE_CONTACT = gql`
  mutation DisableContact($contactId: ID) {
    disableContact(contactId: $contactId) {
      ok
      contact {
        id
        email
        comments {
          id
          pinned
          comment
          poster {
            firstName
            lastName
          }
          date
        }
        isActive
      }
      error
    }
  }
`;

export const MUTATION_ASSIGN_GROUPS_TO_CONTACT = gql`
  mutation AssignGroupsToContact($contactId: ID, $groups: [String]) {
    assignGroupsToContact(contactId: $contactId, groups: $groups) {
      ok
      contact {
        id
        assignedGroups
      }
      error
    }
  }
`;

export const MUTATION_LOGIN_AS = gql`
  mutation LoginAs($contactId: ID) {
    loginAs(contactId: $contactId) {
      ok
      error
    }
  }
`;

export const MUTATION_VERIFY_TECHNICIAN = gql`
  mutation VerifyTechnician($contactId: ID) {
    verifyTechnician(contactId: $contactId) {
      ok
      contact {
        id
        updated
        needsUpdate
        verifiedDate
      }
      error
    }
  }
`;

export const MUTATION_SET_FLIGHT_INFO_PANEL_VISIBILITY = gql`
  mutation SetFlightInfoPanelVisibility($isFlightPanelVisible: Boolean) {
    setFlightInfoPanelVisibility(isFlightPanelVisible: $isFlightPanelVisible) {
      ok
      me {
        id
        settings {
          chartsNumber
          chartsSize
          isFlightPanelVisible
        }
      }
      error
    }
  }
`;

export const MUTATION_DELETE_ALL_CONTACT_FILES = gql`
  mutation DeleteAllContactFiles($contactId: ID) {
    deleteAllContactFiles(contactId: $contactId) {
      ok
      error
    }
  }
`;

export const MUTATION_TOGGLE_SEND_NEW_TICKET_NOTIFICATION = gql`
  mutation ToggleSendNewTicketNotification {
    toggleSendNewTicketNotification {
      ok
      savvy {
        id
        sendNewTicketNotification
      }
      error
    }
  }
`;

export const MUTATION_GET_UPLOAD_ENGINE_DATA_FILE_URL = gql`
  mutation GetUploadEngineDataFileURL($filename: String, $aircraftId: ID) {
    getUploadEngineDataFileUrl(filename: $filename, aircraftId: $aircraftId) {
      ok
      url
      error
      edfId
    }
  }
`;

export const MUTATION_GET_UPLOAD_DOCUMENT_URL = gql`
  mutation GetUploadDocumentURL($filename: String) {
    getUploadDocumentUrl(filename: $filename) {
      ok
      url
    }
  }
`;

export const MUTATION_RESEND_VERIFICATION_EMAIL = gql`
  mutation ResendVerificationEmail {
    resendVerificationEmail {
      ok
    }
  }
`;

export const MUTATION_VERIFY_EMAIL = gql`
  ${FRAGMENT_ME}
  mutation VerifyEmail($verificationCode: String) {
    verifyEmail(verificationCode: $verificationCode) {
      ok
      me {
        ...Me
      }
      error
    }
  }
`;

export const MUTATION_FORGOT_PASSWORD = gql`
  mutation ForgotPassword($password1: String, $password2: String, $token: String) {
    forgotPassword(password1: $password1, password2: $password2, token: $token) {
      ok
      error
    }
  }
`;

export const MUTATION_CREATE_TECHNICIAN = gql`
  mutation CreateTechnicianContact($input: CreateTechnicianContact!) {
    createTechnicianContact(input: $input) {
      contact {
        id
        firstName
        lastName
        serviceCenter {
          id
        }
      }
      ok
      error
      welcomePostText
    }
  }
`;

export const MUTATION_UPDATE_OOP = gql`
  ${FRAGMENT_OOP}
  mutation UpdateOop($contactId: ID, $endTime: DateTime, $startTime: DateTime, $oopId: ID) {
    updateOop(contactId: $contactId, oopId: $oopId, endTime: $endTime, startTime: $startTime) {
      ok
      error
      oop {
        ...OutOfPocketInstance
      }
    }
  }
`;

export const MUTATION_DELETE_OOP = gql`
  mutation DeleteOop($oopId: ID) {
    deleteOop(oopId: $oopId) {
      ok
      error
    }
  }
`;

export const MUTATION_CREATE_OOP = gql`
  mutation CreateOop($contactId: ID, $endTime: DateTime, $startTime: DateTime) {
    createOop(contactId: $contactId, endTime: $endTime, startTime: $startTime) {
      ok
      error
    }
  }
`;

export const MUTATION_SET_RELATED_TICKET_STICKY_STATUS = gql`
  mutation SetRelatedTicketStatusSetting($status: String) {
    setRelatedTicketsStickyStatus(status: $status) {
      ok
      contact {
        id
        settings {
          relatedTicketsStickyStatus
        }
      }
      error
    }
  }
`;

export const MUTATION_SET_SETTING = gql`
  mutation SetSetting($name: String, $value: String) {
    setSetting(name: $name, value: $value) {
      ok
      contact {
        id
        settings {
          relatedTicketsSort
        }
      }
      error
    }
  }
`;

export const MUTATION_CREATE_CANNED_RESPONSE = gql`
  mutation CreateCannedResponse($title: String, $text: String) {
    createCannedResponse(text: $text, title: $title) {
      ok
      error
    }
  }
`;

export const MUTATION_UPDATE_CANNED_RESPONOSE = gql`
  ${FRAGMENT_CANNED_RESPONSE}
  mutation UpdateCannedResponse($cannedResponseId: ID, $title: String, $text: String) {
    updateCannedResponse(cannedResponseId: $cannedResponseId, title: $title, text: $text) {
      ok
      cannedResponse {
        ...CannedResponse
      }
      error
    }
  }
`;

export const MUTATION_DELETE_CANNED_RESPONSE = gql`
  mutation DeleteCannedResponse($cannedResponseId: ID) {
    deleteCannedResponse(cannedResponseId: $cannedResponseId) {
      ok
      error
    }
  }
`;

export const MUTATION_CREATE_TECH_WELCOME_TICKET = gql`
  mutation CreateTechWelcomeTicket($contactId: ID, $post: String) {
    createTechWelcomeTicket(contactId: $contactId, post: $post) {
      ok
      error
    }
  }
`;

export const MUTATION_MERGE_CONTACT = gql`
  mutation MergeContacts($destinationContact: ID, $mergeSimilarAircrafts: Boolean, $sourceContact: ID) {
    mergeContacts(destinationContact: $destinationContact, mergeSimilarAircrafts: $mergeSimilarAircrafts, sourceContact: $sourceContact) {
      ok
      contact {
        id
        firstName
        lastName
        company
        isSavvy
        isTechnician
        isClient
        city
        state
        street
        zip
        country
        email
        phoneMobile
        airportId
        classifications {
          id
          name
          category
        }
        needsUpdate
        isActive
        assignedGroups
        nickname
        emailCcs
        serviceCenter {
          id
          company
        }
        coordinates {
          latitude
          longitude
        }
        aircraft {
          id
          serial
          registration
          aircraftManufacturer {
            name
          }
          aircraftModel {
            name
          }
          year
          default
          subscriptions(current: true) {
            id
            serviceName
          }
          hidden
        }
      }
      error
    }
  }
`;

export const MUTATION_GET_QIF_UPLOAD_URL = gql`
  mutation GetQifUploadUrl($filename: String) {
    getQifUploadUrl(filename: $filename) {
      ok
      error
      url
    }
  }
`;

export const MUTATION_UPDATE_CONTACT_COMMENT = gql`
  mutation UpdateContactComment($comment: String, $commentId: ID, $pinned: Boolean) {
    updateContactComment(comment: $comment, commentId: $commentId, pinned: $pinned) {
      ok
      error
      comment {
        id
        pinned
        comment
        date
      }
    }
  }
`;

export const MUTATION_ACCESS_VIEW = gql`
  mutation AccessView($email: String) {
    accessView(email: $email) {
      ok
      error
    }
  }
`;

export const MUTATION_VERIFY_SECURITY_TOKEN = gql`
  mutation VerifySecurityToken($slug: String) {
    verifySecurityToken(slug: $slug) {
      ok
      error
    }
  }
`;

export const MUTATION_ADD_BANK_INFO = gql`
  ${FRAGMENT_BANK_INFO}
  mutation AddBankInfo(
    $accountHolderName: String!
    $accountNumber: String!
    $accountType: String!
    $legalEntityType: String!
    $routingNumber: String!
    $type: String!
    $tin: String!
  ) {
    addBankInfo(
      accountHolderName: $accountHolderName
      accountNumber: $accountNumber
      accountType: $accountType
      legalEntityType: $legalEntityType
      routingNumber: $routingNumber
      type: $type
      tin: $tin
    ) {
      ok
      error
      me {
        id
        bankInfo {
          ...BankInfo
        }
      }
    }
  }
`;

export const MUTATION_DELETE_BANK_RECIPIENT_ID = gql`
  mutation DeleteBankRecipientId($contactId: ID) {
    deleteBankRecipientId(contactId: $contactId) {
      ok
      error
    }
  }
`;

export const MUTATION_UPDATE_VENDOR_PAYMENTS_STATUS_VOID = gql`
  mutation UpdateVendorPaymentsStatusVoid($paymentIds: [ID]) {
    updateVendorPaymentsStatusVoid(paymentIds: $paymentIds) {
      ok
      error
      payments {
        id
        status
      }
    }
  }
`;

export const MUTATION_CANCEL_SCHEDULED_PAYMENT = gql`
  mutation CancelScheduledPayments($paymentIds: [ID]) {
    cancelScheduledPayments(paymentIds: $paymentIds) {
      ok
      error
      payments {
        id
        status
      }
    }
  }
`;

export const MUTATION_CREATE_VENDOR_PAYMENT = gql`
  mutation CreateVendorPayment($amount: Float, $payeeId: ID, $description: String, $startTime: DateTime) {
    createVendorPayment(amount: $amount, payeeId: $payeeId, description: $description, startTime: $startTime) {
      ok
      error
    }
  }
`;

export const MUTATION_UPDATE_VENDOR_PAYMENTS_STATUS_PAID = gql`
  mutation UpdateVendorPaymentsRequestPayment($paymentIds: [ID]) {
    updateVendorPaymentsRequestPayment(paymentIds: $paymentIds) {
      ok
      error
    }
  }
`;

export const MUTATION_GENERATE_VENDOR_PAYMENTS_QIF_FILE = gql`
  mutation GenerateVendorPaymentsQifFile {
    generateVendorPaymentsQifFile {
      ok
      error
    }
  }
`;
