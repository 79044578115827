import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Typography, TextField, Link, IconButton, Autocomplete, Tabs, Tab, Hidden, Divider } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import PrintIcon from '@mui/icons-material/Print';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import EditIcon from '@mui/icons-material/Edit';
import { ISavvyTicket, ICannedResponse, IPost, IAssignee, IDropDownOption, IAttachment } from 'types';
import { Checkbox } from 'components';
import TicketInfoBox from './TicketInfoBox';
import { TicketPost, PdfDialog, ImgDialog } from 'pages/Tickets/components/ContentComponent';
import { default as TicketPostForm } from './TicketPostForm';
import { EditNotes } from './ContentComponent';

import { useLazyQuery, useMutation } from '@apollo/client';
import { QUERY_ATTACHMENT_DETAIL, MUTATION_REMOVE_WATCHER_FROM_TICKET, QUERY_SAVVY_DETAILT_TICKET } from 'gql';
import { isHeic, urlify } from 'helpers';

import { setUnreadPostIndex, setAlert } from 'state';

import { PATH_CONTACT, NOT_HAPPT_TICKET, COI_WARNING } from 'const';
import { useTimer } from 'hooks';

interface TicketContentsProps {
  ticket: ISavvyTicket | undefined;
  setSlideNum?: (value: number) => void;
  closeTicket?: (id: number, close: boolean) => void;
  cannedResponseText: string;
  cannedResponses?: ICannedResponse[];
  setCannedResponseText: (value: string) => void;
  allowedAssignees?: IAssignee[];
  allowedTimesheetCategories: IDropDownOption[];
  lastTimesheetCategory: IDropDownOption | undefined;
  showTimesheetForm: boolean;
  savvyId: string;
  canViewUnhappyState?: boolean;
  canResetUnhappy?: boolean;
  canUpdateTicketNotes?: boolean;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const a11yProps = (index: number) => {
  return {
    id: `scrollable-prevent-tab-${index}`,
    'aria-controls': `scrollable-prevent-tabpanel-${index}`,
  };
};

const TabPanel = (props: TabPanelProps) => {
  const { children, index, value, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
    >
      <Box>{children}</Box>
    </div>
  );
};

const TicketContents: React.FC<TicketContentsProps> = (props) => {
  const {
    ticket = undefined,
    closeTicket,
    setSlideNum = undefined,
    cannedResponseText,
    cannedResponses,
    setCannedResponseText,
    allowedAssignees = [],
    allowedTimesheetCategories,
    lastTimesheetCategory = undefined,
    showTimesheetForm,
    savvyId,
    canViewUnhappyState = false,
    canResetUnhappy = false,
    canUpdateTicketNotes = false,
  } = props;

  const { id } = useParams();

  const FETCH_TIMEOUT = 60;

  const [fetchDetailTicket, { data: dataDetailTicket, loading: loadingDetailTicket, error: errorDetailTicket }] = useLazyQuery(
    QUERY_SAVVY_DETAILT_TICKET,
    {
      errorPolicy: 'all',
      fetchPolicy: 'cache-and-network',
    },
  );

  const timerAction = async () => {
    if (id) {
      await fetchDetailTicket({
        variables: {
          ticketId: parseInt(id),
        },
      });
    }
  };

  const { create: createTimer, clear: clearTimer } = useTimer({
    timeoutValue: FETCH_TIMEOUT,
    timerAction,
  });

  useEffect(() => {
    if (id) {
      clearTimer();
      createTimer();
    }
    return () => {
      clearTimer();
    };
  }, [id]);

  const location = useLocation();
  const { firstName, lastName } = useSelector((state: any) => state.auth);
  const [removeWatcherMutation, { data: dataRemoveWatcherMutation, error: errorRemoveWatcherMutation }] = useMutation(
    MUTATION_REMOVE_WATCHER_FROM_TICKET,
    {
      errorPolicy: 'all',
    },
  );

  const [sortPosts, setSortPosts] = useState<IPost[]>([]);
  const [attachments, setAttachments] = useState<IAttachment[]>([]);

  useEffect(() => {
    if (ticket?.posts) {
      setSortPosts(
        (ticket?.posts ? [...ticket.posts] : []).sort((a: any, b: any) => {
          if (new Date(a.created) > new Date(b.created)) {
            return -1;
          } else {
            return 1;
          }
        }),
      );
    }
  }, [ticket]);

  useEffect(() => {
    if (dataDetailTicket?.me?.tickets?.length > 0) {
      setSortPosts(
        (dataDetailTicket.me.tickets[0].posts ? [...dataDetailTicket.me.tickets[0].posts] : []).sort((a: any, b: any) => {
          if (new Date(a.created) > new Date(b.created)) {
            return -1;
          } else {
            return 1;
          }
        }),
      );
    }
  }, [dataDetailTicket, loadingDetailTicket, errorDetailTicket]);

  const [showPdfFileDialog, setPdfShowFileDialog] = useState(false);
  const [showImgFileDialog, setImgShowFileDialog] = useState(false);
  const [showFile, setShowFile] = useState('');
  const [showFileName, setShowFileName] = useState('');
  const [previewAttachmentId, setPreviewAttachmentId] = useState('');

  const [showEditNotes, setShowEditNotes] = useState(false);

  const [showPostFilter, setShowPostFilter] = useState(false);

  const [posterArray, setPosterArray] = useState<string[]>([]);

  const [filterText, setFilterText] = useState('');
  const [filterPoster, setFilterPoster] = useState('');
  const [filterAttachment, setFilterAttachment] = useState(false);

  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event: React.ChangeEvent<unknown>, newValue: number) => {
    setTabValue(newValue);
  };

  const { postId: unreadPostId, ticketId: unreadTicketId } = useSelector((state: any) => state.unreadPost);
  const dispatch = useDispatch();

  const [fetchAttachmentData, { data: dataAttachmentData, loading: loadingAttachmentData, error: errorAttachmentData }] =
    useLazyQuery(QUERY_ATTACHMENT_DETAIL);
  const loadAttachmentData = async (attachmentId: string) => {
    setPreviewAttachmentId(attachmentId);
    if (dataAttachmentData?.savvy?.attachment?.id === attachmentId) {
      const attachment = dataAttachmentData?.savvy?.attachment;
      if (isHeic(attachment.name)) {
        if (!attachment.base64Data) {
          dispatch(setAlert('error', 'Unable to load image'));
          return;
        }
        setShowFile(attachment.base64Data);
        setPdfShowFileDialog(false);
        setImgShowFileDialog(true);
        setShowFileName(attachment.name);
        return;
      }
    }
    await fetchAttachmentData({
      variables: {
        attachmentId,
      },
      fetchPolicy: 'network-only',
    });
  };

  const nextAttachmentLoad = (attachmentId: string) => {
    for (let i = 0; i < attachments.length - 1; i++) {
      if (attachmentId === attachments[i].id) {
        loadAttachmentData(attachments[i + 1].id);
      }
    }

    return;
  };

  const prevAttachmentLoad = (attachmentId: string) => {
    for (let i = 1; i < attachments.length; i++) {
      if (attachmentId === attachments[i].id) {
        loadAttachmentData(attachments[i - 1].id);
      }
    }

    return;
  };

  const isMeetCriteria = (post: IPost) => {
    let flag = false;
    if (!filterText) {
      flag = true;
    } else {
      flag = post.body.toLowerCase().includes(filterText.toLowerCase());
    }

    if (!filterPoster) {
      flag = flag && true;
    } else {
      if (!post.poster) {
        flag = false;
      } else {
        flag = flag && `${post.poster.firstName} ${post.poster.lastName}`.toLowerCase().includes(filterPoster.toLowerCase());
      }
    }

    if (!filterAttachment) {
      flag = flag && true;
    } else {
      flag = flag && Boolean(post.attachments?.length);
    }

    return flag;
  };

  useEffect(() => {
    if (location?.pathname.includes('unwatch')) {
      if (id) {
        removeWatcherMutation({
          variables: {
            ticketId: id,
          },
        });
      }
    }
  }, [location, id, removeWatcherMutation]);

  useEffect(() => {
    if (ticket?.posts?.length) {
      const attachmentsTmp: IAttachment[] = [];

      for (let i = 0; i < ticket.posts.length; i++) {
        if (!ticket.posts[i].attachments) continue;

        if (ticket.posts[i].attachments?.length) {
          ticket.posts[i].attachments?.map((attachment: any) => {
            attachmentsTmp.push({
              ...attachment,
              postId: ticket.posts[i].id,
            });
            return null;
          });
        }
      }
      const sortedAttachments = attachmentsTmp.sort((a: IAttachment, b: IAttachment) => {
        return a.created > b.created ? -1 : 1;
      });

      setAttachments([...sortedAttachments]);
    }
  }, [ticket]);

  useEffect(() => {
    if (errorAttachmentData) {
      return;
    } else if (dataAttachmentData) {
      if (dataAttachmentData?.savvy?.attachment?.previewUrl) {
        const attachment = dataAttachmentData?.savvy?.attachment;
        if (attachment.isImage) {
          setImgShowFileDialog(true);
          setPdfShowFileDialog(false);
        } else if (attachment.isPdf) {
          setPdfShowFileDialog(true);
          setImgShowFileDialog(false);
        }
        if (isHeic(attachment.name)) {
          if (!attachment.base64Data) {
            dispatch(setAlert('error', 'Unable to load image'));
            return;
          }
          setShowFile(attachment.base64Data);
        } else {
          setShowFile(attachment.previewUrl);
        }
        setShowFileName(attachment.name);
      }
    }
  }, [dataAttachmentData, errorAttachmentData]);

  useEffect(() => {
    if (ticket) {
      const tmpPoster: string[] = [];

      ticket.posts.map((post: IPost) => {
        if (post.poster?.firstName) {
          if (!tmpPoster.includes(`${post.poster.firstName} ${post.poster.lastName}`))
            tmpPoster.push(`${post.poster.firstName} ${post.poster.lastName}`);
        }

        return null;
      });

      setPosterArray([...tmpPoster]);
    }
  }, [ticket]);

  useEffect(() => {
    setShowPostFilter(false);
    setFilterText('');
    setFilterPoster('');
    setFilterAttachment(false);
  }, [id]);

  useEffect(() => {
    if (id && unreadTicketId && id !== unreadTicketId) {
      dispatch(
        setUnreadPostIndex({
          postId: '',
          ticketId: '',
        }),
      );
    }
  }, [unreadTicketId, id, dispatch]);

  useEffect(() => {
    if (errorRemoveWatcherMutation) {
      dispatch(setAlert('error', 'Unable to remove watcher'));
    }
  }, [errorRemoveWatcherMutation, dispatch]);

  return (
    <Box
      sx={{
        flexGrow: 1,
      }}
    >
      <Box
        sx={{
          display: {
            xl: 'none',
            xs: 'block',
          },
        }}
      >
        <Box>
          <Box
            sx={{
              px: {
                xs: 2,
                lg: 5,
              },
              pt: 3,
              pb: 1,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'start',
              '@media (min-width: 1280px)': {
                px: 7,
              },
              '@media (min-width: 1450px)': {
                px: 10,
              },
            }}
          >
            <IconButton
              sx={{
                '&:hover': {
                  borderRadius: '6px',
                },
                borderRadius: '6px',
                boxShadow: '0px 1px 1px rgba(19, 30, 64, 0.05)',
                border: '1px solid',
                borderColor: 'grey.200',
                backgroundColor: 'background.default',
              }}
              onClick={() => {
                if (setSlideNum) {
                  setSlideNum(1);
                }
              }}
            >
              <ChevronLeftIcon />
            </IconButton>
            <Box
              sx={{
                width: 'calc(100% - 120px)',
              }}
            >
              {Boolean(ticket?.lastViewedBy?.length) && (
                <Box
                  py={1.5}
                  sx={{
                    backgroundColor: 'warning.light',
                    borderRadius: '5px',
                    margin: '0 auto',
                    position: 'relative',
                    mb: 1.5,
                    px: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: 500,
                      lineHeight: '22px',
                      color: 'warning.main',
                      '& a': {
                        color: 'warning.main',
                        textDecoration: 'underline',
                      },
                      textAlign: 'center',
                      width: '100%',
                    }}
                  >
                    Recently viewed by:{' '}
                    {ticket?.lastViewedBy?.map((lastContact: any, key: number) => {
                      return (
                        <React.Fragment key={key}>
                          {key !== 0 ? ', ' : ''}
                          <Link href={`${PATH_CONTACT}/${lastContact.id}`} target="_blank">
                            {`${lastContact.firstName} ${lastContact.lastName}`}
                          </Link>
                        </React.Fragment>
                      );
                    })}
                  </Typography>
                </Box>
              )}
              {Boolean(ticket?.showCoiWarning) && (
                <Box
                  py={1.5}
                  sx={{
                    backgroundColor: 'rgba(255, 51, 51, 0.2)',
                    borderRadius: '5px',
                    margin: '0 auto',
                    position: 'relative',
                    mb: 1.5,
                    px: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: 500,
                      lineHeight: '22px',
                      color: 'error.main',
                      textAlign: 'center',
                      width: '100%',
                    }}
                  >
                    {COI_WARNING}
                  </Typography>
                </Box>
              )}
              {Boolean(ticket?.notHappy) && canViewUnhappyState && (
                <Box
                  py={1.5}
                  sx={{
                    backgroundColor: 'rgba(255, 51, 51, 0.2)',
                    borderRadius: '5px',
                    margin: '0 auto',
                    position: 'relative',
                    mb: 1.5,
                    px: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: 500,
                      lineHeight: '22px',
                      color: 'error.main',
                      textAlign: 'center',
                      width: '100%',
                    }}
                  >
                    {NOT_HAPPT_TICKET}
                  </Typography>
                </Box>
              )}
              <Typography
                variant="h2"
                sx={{
                  fontWeight: 700,
                  fontSize: {
                    xs: '18px',
                    sm: '22px',
                  },
                  textAlign: 'center',
                }}
              >
                {ticket?.subject}
              </Typography>
            </Box>
            <IconButton
              sx={{
                '&:hover': {
                  borderRadius: '6px',
                },
                borderRadius: '6px',
                boxShadow: '0px 1px 1px rgba(19, 30, 64, 0.05)',
                border: '1px solid',
                borderColor: 'grey.200',
                backgroundColor: 'background.default',
              }}
            >
              <PrintIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: {
                xs: 'none',
                md: 'block',
              },
            }}
          >
            {ticket && (
              <TicketInfoBox
                ticket={ticket}
                closeTicket={closeTicket}
                cannedResponses={cannedResponses}
                setCannedResponseText={setCannedResponseText}
                allowedAssignees={allowedAssignees}
                canResetUnhappy={canResetUnhappy}
              />
            )}
          </Box>
        </Box>
      </Box>
      {!ticket && !id && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <Typography>Please select ticket to see more information</Typography>
        </Box>
      )}
      <Hidden mdDown>
        <React.Fragment>
          {ticket && (
            <Box
              sx={{
                py: {
                  md: 2,
                  lg: 5,
                },
                px: {
                  xs: 2,
                  lg: 6,
                  xl: 6,
                },
                '@media (min-width: 1280px)': {
                  maxHeight: 'calc(100vh - 70px)',
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  '&::-webkit-scrollbar': {
                    width: '0.6em',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(0,0,0,.1)',
                    borderRadius: 2,
                  },
                },
              }}
            >
              <Box
                sx={{
                  display: {
                    xs: 'none',
                    xl: 'block',
                  },
                }}
              >
                {Boolean(ticket?.lastViewedBy?.length) && (
                  <Box
                    py={1.5}
                    sx={{
                      backgroundColor: 'warning.light',
                      borderRadius: '5px',
                      margin: '0 auto',
                      position: 'relative',
                      mb: 1.5,
                      px: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontWeight: 500,
                        lineHeight: '22px',
                        color: 'warning.main',
                        '& a': {
                          color: 'warning.main',
                          textDecoration: 'underline',
                        },
                        textAlign: 'center',
                      }}
                    >
                      Recently viewed by:{' '}
                      {ticket?.lastViewedBy?.map((lastContact: any, key: number) => {
                        return (
                          <React.Fragment key={key}>
                            {key !== 0 ? ', ' : ''}
                            <Link href={`${PATH_CONTACT}/${lastContact.id}`} target="_blank">
                              {`${lastContact.firstName} ${lastContact.lastName}`}
                            </Link>
                          </React.Fragment>
                        );
                      })}
                    </Typography>
                  </Box>
                )}
              </Box>
              {location?.pathname.includes('unwatch') && dataRemoveWatcherMutation?.removeWatcherFromTicket?.ok && (
                <Box
                  py={1.5}
                  sx={{
                    backgroundColor: 'warning.light',
                    borderRadius: '5px',
                    margin: '0 auto',
                    position: 'relative',
                    mb: 1.5,
                    px: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: 500,
                      lineHeight: '22px',
                      color: 'warning.main',
                      '& a': {
                        color: 'warning.main',
                        textDecoration: 'underline',
                      },
                      textAlign: 'center',
                      width: '100%',
                    }}
                  >
                    {firstName && lastName && `${firstName} ${lastName} was removed as a watcher`}
                  </Typography>
                </Box>
              )}
              {Boolean(ticket?.riskText) && (
                <Box
                  py={1.5}
                  sx={{
                    backgroundColor: 'warning.light',
                    borderRadius: '5px',
                    margin: '0 auto',
                    position: 'relative',
                    mb: 1.5,
                    px: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: 500,
                      lineHeight: '22px',
                      color: 'warning.main',
                      textAlign: 'center',
                      width: '100%',
                    }}
                  >
                    {ticket?.riskText}
                  </Typography>
                </Box>
              )}
              {Boolean(ticket?.showCoiWarning) && (
                <Box
                  py={1.5}
                  sx={{
                    backgroundColor: 'rgba(255, 51, 51, 0.2)',
                    borderRadius: '5px',
                    margin: '0 auto',
                    position: 'relative',
                    mb: 1.5,
                    px: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: 500,
                      lineHeight: '22px',
                      color: 'error.main',
                      textAlign: 'center',
                      width: '100%',
                    }}
                  >
                    {COI_WARNING}
                  </Typography>
                </Box>
              )}
              {Boolean(ticket?.notHappy) && canViewUnhappyState && (
                <Box
                  py={1.5}
                  sx={{
                    backgroundColor: 'rgba(255, 51, 51, 0.2)',
                    borderRadius: '5px',
                    margin: '0 auto',
                    position: 'relative',
                    mb: 1.5,
                    px: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: 500,
                      lineHeight: '22px',
                      color: 'error.main',
                      textAlign: 'center',
                      width: '100%',
                    }}
                  >
                    {NOT_HAPPT_TICKET}
                  </Typography>
                </Box>
              )}
              <TicketPostForm
                ticket={ticket}
                cannedResponseText={cannedResponseText}
                setCannedResponseText={setCannedResponseText}
                allowedTimesheetCategories={allowedTimesheetCategories}
                lastTimesheetCategory={lastTimesheetCategory}
                showTimesheetForm={showTimesheetForm}
                savvyId={savvyId}
                closeTicket={closeTicket}
                canViewUnhappyState={canViewUnhappyState}
              />
              <Box
                pt={2}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {!showEditNotes && (
                  <IconButton
                    sx={{
                      mr: 0.5,
                    }}
                    onClick={() => {
                      if (showPostFilter) {
                        setFilterText('');
                        setFilterPoster('');
                        setFilterAttachment(false);
                      }

                      setShowPostFilter(!showPostFilter);
                    }}
                  >
                    {!showPostFilter ? <FilterAltIcon /> : <FilterAltOffIcon />}
                  </IconButton>
                )}
                {!showPostFilter && canUpdateTicketNotes && (
                  <IconButton
                    title="Edit Notes"
                    onClick={() => {
                      setShowEditNotes(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                )}
                {showPostFilter && (
                  <React.Fragment>
                    <Box
                      sx={{
                        width: '50%',
                        px: 0.5,
                      }}
                    >
                      <TextField
                        name="filterText"
                        label="Post Body"
                        placeholder="Type text to search post"
                        value={filterText}
                        onChange={(e) => {
                          setFilterText(e.target.value);
                        }}
                        sx={{
                          mb: 0,
                        }}
                        fullWidth
                      />
                    </Box>
                    <Box
                      sx={{
                        width: '30%',
                        px: 0.5,
                      }}
                    >
                      <Autocomplete
                        options={posterArray}
                        value={filterPoster}
                        onChange={(event: any, value: any) => {
                          setFilterPoster(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Poster Name"
                            placeholder="Type poster name to search post"
                            sx={{
                              mb: 0,
                            }}
                            fullWidth
                          />
                        )}
                      />
                    </Box>
                    <Box pl={0.5}>
                      <Checkbox
                        value={filterAttachment}
                        label={
                          <Typography
                            sx={{
                              display: 'inline-block',
                            }}
                          >
                            Includes attachments?
                          </Typography>
                        }
                        onChange={() => {
                          setFilterAttachment(!filterAttachment);
                        }}
                        noMargin
                      />
                    </Box>
                  </React.Fragment>
                )}
              </Box>
              {showEditNotes && (
                <EditNotes open={showEditNotes} setOpen={setShowEditNotes} originNotes={ticket.notes} ticketId={ticket.id} />
              )}
              <Box px={2} pt={2}>
                <Typography
                  sx={{
                    fontWeight: 'bold',
                  }}
                >
                  Notes (visible only to Savvy):
                </Typography>
                <Typography
                  sx={{
                    '& a': {
                      color: 'primary.main',
                    },
                  }}
                  dangerouslySetInnerHTML={{
                    __html: ticket.notes ? urlify(ticket.notes).replace(/\n/g, '<br/>') : 'No notes',
                  }}
                />
              </Box>
              <Box py={1}>
                {sortPosts.map((post: any, key: number) => {
                  if (isMeetCriteria(post))
                    return (
                      <Box key={key}>
                        <TicketPost
                          post={post}
                          setPdfShowFileDialog={setPdfShowFileDialog}
                          setImgShowFileDialog={setImgShowFileDialog}
                          setShowFile={setShowFile}
                          setShowFileName={setShowFileName}
                          ticketId={ticket.id}
                          setPreviewAttachmentId={setPreviewAttachmentId}
                          aircraftId={ticket.aircraft?.id}
                          registration={ticket.aircraft?.registration}
                        />
                        {unreadPostId === post.id && unreadTicketId === ticket?.id && (
                          <Box>
                            <Divider
                              sx={{
                                borderColor: 'error.main',
                              }}
                            />
                          </Box>
                        )}
                      </Box>
                    );
                  return null;
                })}
              </Box>
            </Box>
          )}
        </React.Fragment>
      </Hidden>
      {ticket && (
        <Hidden mdUp>
          <Box py={2} px={1}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="basic tabs example"
              sx={{
                '& .MuiTabs-indicator': {
                  backgroundColor: 'error.dark',
                },
                borderBottom: '1px solid',
                borderBottomColor: 'grey.200',
                '& .MuiTabs-flexContainer': {
                  justifyContent: 'flex-start',
                },
              }}
            >
              <Tab
                label="Ticket History"
                {...a11yProps(0)}
                sx={{
                  py: 3,
                  px: 3,
                  '&.Mui-selected': {
                    color: 'error.dark',
                  },
                  fontSize: '16px',
                  lineHeight: '22px',
                  fontWeight: 600,
                }}
              />
              <Tab
                label="Info"
                {...a11yProps(1)}
                sx={{
                  py: 3,
                  px: 3,
                  '&.Mui-selected': {
                    color: 'error.dark',
                  },
                  fontSize: '16px',
                  lineHeight: '22px',
                  fontWeight: 600,
                }}
              />
            </Tabs>
            <Box>
              <TabPanel value={tabValue} index={0}>
                <Box px={1}>
                  {Boolean(ticket?.lastViewedBy?.length) && (
                    <Box
                      py={1.5}
                      sx={{
                        backgroundColor: 'warning.light',
                        borderRadius: '5px',
                        margin: '0 auto',
                        position: 'relative',
                        mb: 1.5,
                        px: 1,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '16px',
                          fontWeight: 500,
                          lineHeight: '22px',
                          color: 'warning.main',
                          '& a': {
                            color: 'warning.main',
                            textDecoration: 'underline',
                          },
                          textAlign: 'center',
                        }}
                      >
                        Recently viewed by:{' '}
                        {ticket?.lastViewedBy?.map((lastContact: any, key: number) => {
                          return (
                            <React.Fragment key={key}>
                              {key !== 0 ? ', ' : ''}
                              <Link href={`${PATH_CONTACT}/${lastContact.id}`} target="_blank">
                                {`${lastContact.firstName} ${lastContact.lastName}`}
                              </Link>
                            </React.Fragment>
                          );
                        })}
                      </Typography>
                    </Box>
                  )}
                  <TicketPostForm
                    ticket={ticket}
                    cannedResponseText={cannedResponseText}
                    setCannedResponseText={setCannedResponseText}
                    allowedTimesheetCategories={allowedTimesheetCategories}
                    lastTimesheetCategory={lastTimesheetCategory}
                    showTimesheetForm={showTimesheetForm}
                    savvyId={savvyId}
                    closeTicket={closeTicket}
                  />
                  <Box
                    pt={2}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <IconButton
                      sx={{
                        mr: 0.5,
                      }}
                      onClick={() => {
                        if (showPostFilter) {
                          setFilterText('');
                          setFilterPoster('');
                          setFilterAttachment(false);
                        }

                        setShowPostFilter(!showPostFilter);
                      }}
                    >
                      {!showPostFilter ? <FilterAltIcon /> : <FilterAltOffIcon />}
                    </IconButton>
                    {showPostFilter && (
                      <React.Fragment>
                        <Box
                          sx={{
                            width: '50%',
                            px: 0.5,
                          }}
                        >
                          <TextField
                            name="filterText"
                            label="Post Body"
                            placeholder="Type text to search post"
                            value={filterText}
                            onChange={(e) => {
                              setFilterText(e.target.value);
                            }}
                            sx={{
                              mb: 0,
                            }}
                            fullWidth
                          />
                        </Box>
                        <Box
                          sx={{
                            width: '30%',
                            px: 0.5,
                          }}
                        >
                          <Autocomplete
                            options={posterArray}
                            value={filterPoster}
                            onChange={(event: any, value: any) => {
                              setFilterPoster(value);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Poster Name"
                                placeholder="Type poster name to search post"
                                sx={{
                                  mb: 0,
                                }}
                                fullWidth
                              />
                            )}
                          />
                        </Box>
                        <Box pl={0.5}>
                          <Checkbox
                            value={filterAttachment}
                            label={
                              <Typography
                                sx={{
                                  display: 'inline-block',
                                }}
                              >
                                Includes attachments?
                              </Typography>
                            }
                            onChange={() => {
                              setFilterAttachment(!filterAttachment);
                            }}
                            noMargin
                          />
                        </Box>
                      </React.Fragment>
                    )}
                  </Box>
                  <Box py={1}>
                    {sortPosts.map((post: any, key: number) => {
                      if (isMeetCriteria(post))
                        return (
                          <Box key={key}>
                            <TicketPost
                              post={post}
                              setPdfShowFileDialog={setPdfShowFileDialog}
                              setImgShowFileDialog={setImgShowFileDialog}
                              setShowFile={setShowFile}
                              setShowFileName={setShowFileName}
                              ticketId={ticket.id}
                              setPreviewAttachmentId={setPreviewAttachmentId}
                              aircraftId={ticket.aircraft?.id}
                              registration={ticket.aircraft?.registration}
                            />
                            {ticket?.firstUnread?.id === post.id && (
                              <Box>
                                <Divider
                                  sx={{
                                    borderColor: 'error.main',
                                  }}
                                />
                              </Box>
                            )}
                          </Box>
                        );
                      return null;
                    })}
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <TicketInfoBox
                  ticket={ticket}
                  closeTicket={closeTicket}
                  cannedResponses={cannedResponses}
                  setCannedResponseText={setCannedResponseText}
                  allowedAssignees={allowedAssignees}
                  canResetUnhappy={canResetUnhappy}
                />
              </TabPanel>
            </Box>
          </Box>
        </Hidden>
      )}
      <PdfDialog
        showFileDialog={showPdfFileDialog}
        setShowFileDialog={setPdfShowFileDialog}
        showFileName={showFileName}
        showFile={showFile}
        previewAttachmentId={previewAttachmentId}
        nextAttachmentLoad={nextAttachmentLoad}
        prevAttachmentLoad={prevAttachmentLoad}
        loadingAttachmentData={loadingAttachmentData}
      />
      <ImgDialog
        showFileDialog={showImgFileDialog}
        setShowFileDialog={setImgShowFileDialog}
        showFileName={showFileName}
        showFile={showFile}
        previewAttachmentId={previewAttachmentId}
        nextAttachmentLoad={nextAttachmentLoad}
        prevAttachmentLoad={prevAttachmentLoad}
        loadingAttachmentData={loadingAttachmentData}
      />
    </Box>
  );
};

export default TicketContents;
