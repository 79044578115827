import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Grid, Typography, Button } from '@mui/material';
import { StagedEngineFlightFiles } from './components';
import { PageLoading, LayoutDashboard, ComponentLoading } from 'components';

import { PATH_STAGED_ENGINE_DATA_FILES, PATH_AIRCRAFT_CREATE } from 'const';

import { QUERY_USER_AIRCRAFTS_WITHOUT_SUBSCRIPTION, QUERY_STAGED_ENGINE_DATA_FILE } from 'gql';
import { useQuery, useLazyQuery } from '@apollo/client';

import { setAlert } from 'state';

const ViewStagedEngineDataFile: React.FC = () => {
  const { data: aircraftData, loading, error } = useQuery(QUERY_USER_AIRCRAFTS_WITHOUT_SUBSCRIPTION);
  const [queryStagedEngineDataFile, { data: dataStagedEngineDataFile, loading: loadingStagedEngineDataFile }] = useLazyQuery(
    QUERY_STAGED_ENGINE_DATA_FILE,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    if (aircraftData?.me?.aircraft && id) {
      queryStagedEngineDataFile({
        variables: {
          id: id,
        },
      });
    }

    if (!id && aircraftData?.me?.aircraft?.length) {
      let existingDefault = false;

      for (let i = 0; i < aircraftData?.me?.aircraft.length; i++) {
        if (aircraftData.me.aircraft[i].default) {
          navigate(`${PATH_STAGED_ENGINE_DATA_FILES}/${aircraftData.me.aircraft[i].id}`);
          existingDefault = true;
        }
      }

      if (!existingDefault) {
        navigate(`${PATH_STAGED_ENGINE_DATA_FILES}/${aircraftData.me.aircraft[0].id}`);
      }
    }
  }, [id, aircraftData, queryStagedEngineDataFile, navigate]);

  useEffect(() => {
    if (error) {
      dispatch(setAlert('error', 'Unable to load aircraft data'));
    }
  }, [error, dispatch]);

  return (
    <LayoutDashboard backgroundColor="#FFFFFF">
      <Box>
        {loading && <PageLoading />}
        {!aircraftData?.me?.aircraft?.length && (
          <Box
            py={1}
            sx={{
              textAlign: 'center',
            }}
          >
            <Typography
              sx={{
                fontSize: '20px',
              }}
              gutterBottom
            >
              You have no aircraft.
            </Typography>
            <Button
              variant="contained"
              onClick={() => {
                navigate(PATH_AIRCRAFT_CREATE);
              }}
            >
              Add Aircraft...
            </Button>
          </Box>
        )}
        <ComponentLoading loading={loadingStagedEngineDataFile}>
          <Grid container spacing={2}>
            <Grid item xl={12} md={12}>
              <StagedEngineFlightFiles stagedEngineDataFiles={dataStagedEngineDataFile?.me?.aircraft[0]?.stagedEngineDataFiles || []} />
            </Grid>
          </Grid>
        </ComponentLoading>
      </Box>
    </LayoutDashboard>
  );
};

export default ViewStagedEngineDataFile;
