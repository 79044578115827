import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { Box, Typography, Container, Link, Button, TextField } from '@mui/material';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import { LayoutDashboard, PageLoading, Checkbox } from 'components';
import { QUERY_TICKET_RATING_BY_UUID, QUERY_RATING_DEFINITION, MUTATION_CREATE_TICKET_RATING } from 'gql';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

import { ctrlCmdClick } from 'helpers';
import { PATH_TICKETS, RATING_DESCRIPTION } from 'const';
import { setAlert } from 'state';

const TicketRate: React.FC = () => {
  const { uuid: ticketRatingUuid } = useParams();

  const [fetchTicketRating, { data: dataTicketRating, loading: loadingTicketRating, error: errorTicketRating }] =
    useLazyQuery(QUERY_TICKET_RATING_BY_UUID);
  const { data: dataRatingDefinition, loading: loadingRatingDefinition } = useQuery(QUERY_RATING_DEFINITION);
  const [createTicketRating, { data: dataCreate, loading: loadingCreate, error: errorCreate }] = useMutation(MUTATION_CREATE_TICKET_RATING);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [rating, setRating] = useState(0);

  const [ratingKeep, setRatingKeep] = useState(0);

  const [savyRatingIds, setSavyRatingIds] = useState<string[]>([]);
  const [shopRatingIds, setShopRatingIds] = useState<string[]>([]);

  const [comment, setComment] = useState('');
  const [contactRater, setContactRater] = useState(false);

  const isExistingId = (id: string, source: string[]) => {
    return source.includes(id);
  };

  const onSubmit = async () => {
    await createTicketRating({
      variables: {
        ticketRatingUuid,
        contactRater,
        rating,
        savyRatingIds,
        comment,
        shopRatingIds,
      },
    });
  };

  const skipRating = async () => {
    await createTicketRating({
      variables: {
        rating: 0,
        ticketRatingUuid,
        comment: '',
        contactRater: false,
      },
    });
  };

  useEffect(() => {
    if (ticketRatingUuid) {
      fetchTicketRating({
        variables: {
          ticketRatingUuid,
        },
      });
    }
  }, [ticketRatingUuid, fetchTicketRating]);

  useEffect(() => {
    if (errorTicketRating) {
      dispatch(setAlert('error', 'Unable to find ticket with uuid'));
    }
  }, [errorTicketRating, dispatch]);

  useEffect(() => {
    if (errorCreate) {
      dispatch(setAlert('error', 'Unable to rate ticket'));
    } else if (dataCreate) {
      if (dataCreate.createTicketRating?.ok) {
        if (dataCreate.createTicketRating?.rating?.rating) {
          dispatch(setAlert('success', 'Rated ticket'));
        } else {
          dispatch(setAlert('success', 'Skipped rating'));
        }
        navigate(PATH_TICKETS);
      } else {
        dispatch(setAlert('error', dataCreate.createTicketRating?.error || 'Unable to rate ticket'));
      }
    }
  }, [errorCreate, dataCreate, dispatch, navigate]);

  return (
    <LayoutDashboard backgroundColor="#fff">
      {(loadingTicketRating || loadingRatingDefinition) && <PageLoading />}
      {dataTicketRating?.ticketRatingByUuid && (
        <Box p={4}>
          {!dataTicketRating?.ticketRatingByUuid?.rating && (
            <Typography variant="h2" gutterBottom>
              How was your experience?
            </Typography>
          )}
          {!dataTicketRating?.ticketRatingByUuid?.rating && (
            <Box>
              <Container maxWidth="sm" fixed>
                <Box
                  sx={{
                    textAlign: 'center',
                  }}
                >
                  <Typography
                    variant="h3"
                    sx={{
                      color: 'error.main',
                    }}
                    gutterBottom
                    align="center"
                  >
                    Rate your experience
                  </Typography>
                  <Link
                    href={`${PATH_TICKETS}/${dataTicketRating?.ticketRatingByUuid?.ticket?.id}`}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      ctrlCmdClick(e, `${PATH_TICKETS}/${dataTicketRating?.ticketRatingByUuid?.ticket?.id}`, navigate);
                    }}
                  >
                    {dataTicketRating?.ticketRatingByUuid?.ticket.subject}
                  </Link>
                </Box>
                <Box py={1}>
                  <Box
                    sx={{
                      textAlign: 'center',
                    }}
                    py={1}
                  >
                    {Array.apply(1, Array(5)).map((value: unknown, key: number) => {
                      if (key < rating) {
                        return (
                          <StarRoundedIcon
                            key={key}
                            sx={{
                              px: 0.5,
                              color: 'gold',
                              cursor: 'pointer',
                              width: '1.5em',
                              height: '1.5em',
                            }}
                            fontSize="large"
                            onClick={() => {
                              setRating(key + 1);
                              setRatingKeep(key + 1);
                            }}
                            onMouseOver={() => {
                              if (!isMobile) {
                                setRating(key + 1);
                              }
                            }}
                            onMouseLeave={() => {
                              if (!isMobile) {
                                setRating(ratingKeep);
                              }
                            }}
                          />
                        );
                      } else {
                        return (
                          <StarBorderRoundedIcon
                            key={key}
                            sx={{
                              px: 0.5,
                              cursor: 'pointer',
                              width: '1.5em',
                              height: '1.5em',
                            }}
                            fontSize="large"
                            onClick={() => {
                              setRating(key + 1);
                              setRatingKeep(key + 1);
                            }}
                            onMouseEnter={() => {
                              if (!isMobile) {
                                setRating(key + 1);
                              }
                            }}
                          />
                        );
                      }
                    })}
                  </Box>
                  {rating > 0 && (
                    <Box>
                      <Typography
                        sx={{
                          textAlign: 'center',
                          fontSize: '1.5em',
                        }}
                      >
                        {RATING_DESCRIPTION[rating - 1]}
                      </Typography>
                    </Box>
                  )}
                </Box>
                {!!rating && !!ratingKeep && (
                  <Box py={0}>
                    <Typography gutterBottom align="center">
                      Please tell us where we can improve.
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                      }}
                    >
                      <Box
                        sx={{
                          textAlign: 'center',
                          width: '50%',
                        }}
                      >
                        <Typography
                          sx={{
                            color: 'error.main',
                            fontSize: '1.5em',
                          }}
                        >
                          SavvyAviation
                        </Typography>
                        {dataRatingDefinition?.ratingDefinitions?.map((item: any, key: number) => {
                          if (item.applicableTo !== 'M') return null;
                          return (
                            <Box pt={1} key={key}>
                              <Button
                                variant={isExistingId(item.id, savyRatingIds) ? 'contained' : 'outlined'}
                                onClick={() => {
                                  if (isExistingId(item.id, savyRatingIds)) {
                                    const tmp = [...savyRatingIds];
                                    const index = tmp.indexOf(item.id);
                                    tmp.splice(index, 1);
                                    setSavyRatingIds([...tmp]);
                                  } else {
                                    savyRatingIds.push(item.id);
                                    setSavyRatingIds([...savyRatingIds]);
                                  }
                                }}
                                sx={{
                                  width: '170px',
                                  '&.MuiButton-outlined:hover': {
                                    color: 'primary.main',
                                    backgroundColor: 'background.default',
                                  },
                                  '&.MuiButton-outlined:focus': {
                                    color: 'primary.main',
                                    backgroundColor: 'background.default',
                                  },
                                }}
                              >
                                {rating < 5 ? item.displayNameNegative : item.displayNamePositive}
                              </Button>
                            </Box>
                          );
                        })}
                      </Box>
                      {dataTicketRating.ticketRatingByUuid.ticket.serviceCenter && (
                        <Box
                          sx={{
                            textAlign: 'center',
                            width: '50%',
                          }}
                        >
                          <Typography
                            sx={{
                              color: 'error.main',
                              fontSize: '1.5em',
                            }}
                          >
                            {dataTicketRating.ticketRatingByUuid.ticket.serviceCenter.company}
                          </Typography>
                          {dataRatingDefinition?.ratingDefinitions?.map((item: any, key: number) => {
                            if (item.applicableTo !== 'T') return null;
                            return (
                              <Box pt={1} key={key}>
                                <Button
                                  variant={isExistingId(item.id, shopRatingIds) ? 'contained' : 'outlined'}
                                  onClick={() => {
                                    if (isExistingId(item.id, shopRatingIds)) {
                                      const tmp = [...shopRatingIds];
                                      const index = tmp.indexOf(item.id);
                                      tmp.splice(index, 1);
                                      setShopRatingIds([...tmp]);
                                    } else {
                                      shopRatingIds.push(item.id);
                                      setShopRatingIds([...shopRatingIds]);
                                    }
                                  }}
                                  sx={{
                                    width: '170px',
                                    '&.MuiButton-outlined:hover': {
                                      color: 'primary.main',
                                      backgroundColor: 'background.default',
                                    },
                                    '&.MuiButton-outlined:focus': {
                                      color: 'primary.main',
                                      backgroundColor: 'background.default',
                                    },
                                  }}
                                >
                                  {rating < 5 ? item.displayNameNegative : item.displayNamePositive}
                                </Button>
                              </Box>
                            );
                          })}
                        </Box>
                      )}
                    </Box>
                    <Box py={1}>
                      <TextField
                        name="comment"
                        label="Comment"
                        placeholder="Tell us more about your experience (optional)"
                        value={comment}
                        onChange={(e) => {
                          setComment(e.target.value);
                        }}
                        multiline
                        rows={5}
                        fullWidth
                        sx={{
                          mb: 0,
                        }}
                      />
                    </Box>
                    <Box
                      py={1}
                      sx={{
                        textAlign: 'center',
                      }}
                    >
                      <Checkbox
                        checked={contactRater}
                        value={contactRater}
                        onChange={() => {
                          setContactRater(!contactRater);
                        }}
                        label={<Typography>Please contact me to discuss</Typography>}
                        noMargin
                      />
                    </Box>
                    <Box
                      py={1}
                      sx={{
                        textAlign: 'center',
                      }}
                    >
                      <Button variant="contained" disabled={loadingCreate} onClick={onSubmit}>
                        Submit
                      </Button>
                    </Box>
                  </Box>
                )}
                <Box
                  sx={{
                    textAlign: 'center',
                  }}
                >
                  <Link
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      skipRating();
                    }}
                  >
                    Skip rating for this ticket
                  </Link>
                </Box>
              </Container>
            </Box>
          )}
          {!!dataTicketRating?.ticketRatingByUuid?.rating && (
            <Box
              py={1}
              sx={{
                textAlign: 'center',
              }}
            >
              <Typography
                sx={{
                  fontSize: '24px',
                }}
                gutterBottom
              >
                Thanks!
              </Typography>
              <Typography>Feedback for this ticket has already been submitted.</Typography>
            </Box>
          )}
        </Box>
      )}
    </LayoutDashboard>
  );
};

export default TicketRate;
